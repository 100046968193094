import { t } from 'i18next';

import { FormFieldType } from 'components/form/formLayout/types';

import { AccountInfo } from 'types/account';

import { SettingsFormField } from '../../types';

export const FORM_FIELDS: Record<string, keyof AccountInfo> = {
  ACCOUNT_ID: 'uuid',
  ACCOUNT_NAME: 'name',
  PROCESSING_FEE: 'processingFee',
  PROCESSING_FEE_EEA: 'processingFeeEEA',
  IS_CERTIFIED: 'isCertified',
  WEBHOOK_URL: 'webhookUrl',
  ONRAMP: 'onRampEnabled',
  OFFRAMP: 'offRampEnabled',
  PARTNER_FEE: 'partnerFee',
  ARE_CUSTOMER_EMAIL_ENABLED: 'areCustomerEmailsEnabled',
};

export const KEYS = {
  MAX_LOGO_SIZE: 1024 * 1024,
};

export const LABELS = {
  SEARCH_RESULTS: ['page.accountSettings.general.form.searchResults', 'Results'],
  CRYPTO_NOT_FOUND: ['page.accountSettings.general.form.cryptoNotFound', 'We don’t support this cryptocurrency yet! '],
  PANEL_TITLE: ['page.accountSettings.general.title', 'General'],
  SAVE_BUTTON_LABEL: ['page.accountSettings.general.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.accountSettings.general.cancel', 'Cancel'],
  FEES_LABEL: ['page.accountSettings.general.fees', 'Processing Fees (Including Partner Fee):'],
  FORM_FIELDS: {
    ACCOUNT_ID: ['page.accountSettings.general.form.accountId', 'Account ID'],
    ACCOUNT_NAME: ['page.accountSettings.general.form.accountName', 'Account Name'],
    LOGO: ['page.accountSettings.general.form.logo', 'Logo'],
    DEFAULT_CRYPTO_CURRENCY: ['page.accountSettings.general.form.defaultCryptoCurrency', 'Default Crypto Currency'],
    REDIRECT_URL: ['page.accountSettings.general.form.redirectURL', 'Redirect URL'],
    WEBHOOK_URL: ['page.accountSettings.general.form.webhookURL', 'Webhook URL'],
    PROCESSING_FEE: ['page.accountSettings.general.form.processingFee', 'Processing Fee Outside'],
    PROCESSING_FEE_EEA: ['page.accountSettings.general.form.processingFeeEEA', 'Processing Fee in EEA'],
    IS_CERTIFIED: ['page.accountSettings.general.form.isCertified', 'Is Certified'],
    ONRAMP: ['page.accountSettings.general.form.onRamp', 'OnRamp Product'],
    OFFRAMP: ['page.accountSettings.general.form.offRamp', 'OffRamp Product'],
    ARE_CUSTOMER_EMAIL_ENABLED: ['page.accountSettings.general.form.areCustomerEmailsEnabled', 'Send Notification Emails To Customers'],
    PARTNER_FEE: ['page.accountSettings.general.form.partnerFee', 'Partner Fee %'],
    ERRORS: {
      MAX_FILE_SIZE: ['page.accountSettings.general.form.logo.maxFileSizeError', 'Maximum file size is 1MB'],
    },
    PLACEHOLDERS: {
      ACCOUNT_ID: ['page.accountSettings.general.form.accountId.placeholder', 'Account ID'],
      ACCOUNT_NAME: ['page.accountSettings.general.form.accountName.placeholder', 'Account Name'],
      LOGO: ['page.accountSettings.general.form.logo.placeholder', 'Logo'],
      DEFAULT_CRYPTO_CURRENCY: ['page.accountSettings.general.form.defaultCryptoCurrency.placeholder', 'Default Crypto Currency'],
      PROCESSING_FEE: ['page.accountSettings.general.form.processingFee.placeholder', 'Processing Fee %'],
      PROCESSING_FEE_EEA: ['page.accountSettings.general.form.processingFeeEEA.placeholder', 'Processing Fee EEA %'],
      PARTNER_FEE: ['page.accountSettings.general.form.partnerFee.placeholder', 'Partner Fee %'],
    },
    VALUES: {
      PROCESSING_FEE: ['page.accountSettings.general.form.value.processingFee', '{{fee}}%'],
      PROCESSING_FEE_EEA: ['page.accountSettings.general.form.value.processingFeeEEA', '{{fee}}%'],
      PARTNER_FEE: ['page.accountSettings.general.form.value.partnerFee', '{{fee}}%'],
    },
    MESSAGES: {
      LOGO: {
        SUPPORTED_FORMAT: ['page.accountSettings.general.form.messages.logo.supportedFormat', 'Supported Formats: JPG, PNG'],
        MAX_FILE_SIZE: ['page.accountSettings.general.form.messages.logo.maxFileSize', 'Maximum Size: 1MB'],
      },
      YES: ['page.accountSettings.general.form.messages.yes', 'Yes'],
      NO: ['page.accountSettings.general.form.messages.no', 'No'],
    },
  },
  ADORNMENTS: {
    PERCENT: '%',
    HTTPS: 'https://',
  },
};

export const logoAcceptRules = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const formFields: SettingsFormField[] = [
  {
    key: FORM_FIELDS.ACCOUNT_ID,
    label: t(LABELS.FORM_FIELDS.ACCOUNT_ID),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.ACCOUNT_NAME,
    label: t(LABELS.FORM_FIELDS.ACCOUNT_NAME),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.WEBHOOK_URL,
    label: t(LABELS.FORM_FIELDS.WEBHOOK_URL),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.ONRAMP,
    label: t(LABELS.FORM_FIELDS.ONRAMP),
    type: FormFieldType.Boolean,
  },
  {
    key: FORM_FIELDS.OFFRAMP,
    label: t(LABELS.FORM_FIELDS.OFFRAMP),
    type: FormFieldType.Boolean,
  },
  {
    key: FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED,
    label: t(LABELS.FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED),
    type: FormFieldType.Boolean,
  },
  {
    key: FORM_FIELDS.PROCESSING_FEE,
    label: t(LABELS.FORM_FIELDS.PROCESSING_FEE),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.PROCESSING_FEE_EEA,
    label: t(LABELS.FORM_FIELDS.PROCESSING_FEE_EEA),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.PARTNER_FEE,
    label: t(LABELS.FORM_FIELDS.PARTNER_FEE),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.IS_CERTIFIED,
    label: t(LABELS.FORM_FIELDS.IS_CERTIFIED),
    type: FormFieldType.Boolean,
  },
];
