import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { AccountRecordInfo } from 'services/accounts/types';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import { DateFilterPassedProps, RadioFilterPassedProps } from 'components/filters/types';

import { Filter, FilterSection, FilterType } from 'types/filters';

import { COLUMN_IDS, LABELS } from './keys';
import { Filters, PartnerAccountDataRow, RequestFilters } from './types';

export const transformAccountsListDataToTableFormat = (accountRecordInfos?: AccountRecordInfo[]): PartnerAccountDataRow[] => {
  return accountRecordInfos
    ? accountRecordInfos.map((accountRecordInfo) => ({
        ...accountRecordInfo.account,
        id: accountRecordInfo.account.uuid,
      }))
    : [];
};

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.date, filterMap.isCertified],
    },
  ];
};

export const makeFilters = (): Record<string, Filter<unknown>> => {
  const datePresets = getDefaultDatePresets();
  const certifiedStatusFilter: Filter<RadioFilterPassedProps> = {
    key: COLUMN_IDS.IS_CERTIFIED,
    label: t(LABELS.FILTERS.IS_CERTIFIED.LABEL),
    type: FilterType.Radio,
    options: [
      {
        key: 'yes',
        value: 'true',
        label: t(LABELS.FILTERS.IS_CERTIFIED.YES),
      },
      {
        key: 'no',
        value: 'false',
        label: t(LABELS.FILTERS.IS_CERTIFIED.NO),
      },
    ],
  };

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: datePresets,
  };

  return [dateFilter, certifiedStatusFilter].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters): RequestFilters | undefined => {
  if (!filters) {
    return undefined;
  }

  const { date, isCertified } = filters;

  const nextFilters: RequestFilters = {};
  if (date) {
    nextFilters.date = date;
  }

  if (isCertified && !isEmpty(isCertified)) {
    const [value] = isCertified;
    nextFilters.isCertified = value ? value === 'true' : false;
  }

  return nextFilters;
};
