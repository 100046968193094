import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import IconLoading from 'components/icons/loading';
import { Input } from 'components/inputs';
import Modal from 'components/modal/modal';
import { Body, Buttons, CloseButton, PanelTitle } from 'components/modal/styles';
import OptionallyVisible from 'components/optionallyVisible';

import { Asset } from 'types/assets';

import { getCryptoLabel } from 'utils/currencies';

import { KEYS, LABELS } from '../keys';
import {
  Adornment,
  CryptoInlineIcon,
  CryptoLabel,
  CryptoName,
  CryptoNetwork,
  DataRow,
  DataRowTitle,
  DataRowValue,
  Error,
  ModalContent,
  Title,
} from './styles';

interface RefundModalProps {
  amount: number;
  asset: Asset;
  getAsset: (symbol: string) => Asset | undefined;
  getQuote: (cryptoCurrencyCode: string) => Promise<{ networkFeeUsdAmount: number }>;
  submitRefund: (request: { cryptoCurrencyCode: string; walletAddress: string; walletTag?: string }) => Promise<{ success: boolean }>;
  isQuoteFetching: boolean;
  isRefundSubmitting: boolean;
  quoteError?: string;
  refundError?: string;
  open: boolean;
  onClose: () => void;
}

export const RefundModal = ({
  amount,
  asset,
  getQuote,
  submitRefund,
  getAsset,
  isRefundSubmitting,
  isQuoteFetching,
  open,
  onClose,
  refundError,
  quoteError,
}: RefundModalProps) => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [quote, setQuote] = useState<{ networkFeeUsdAmount: number } | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const response = await submitRefund({
      cryptoCurrencyCode: asset.symbol,
      walletAddress,
      walletTag: asset.tagName || undefined,
    });

    if (response.success) {
      handleClose();
    }
  };

  const updateQuote = async () => {
    if (!asset) {
      return;
    }

    try {
      const quote = await getQuote(asset.symbol);
      setQuote(quote);
    } catch (e: unknown) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    updateQuote();
    intervalRef.current = setInterval(updateQuote, KEYS.QUOTE_REFRESH_INTERVAL);
    return () => clearInterval(intervalRef.current);
  }, []);

  const [symbol] = asset.symbol.split(KEYS.SYMBOL_SEPARATOR);
  const canShowQuoteError = Boolean(!isQuoteFetching && !quote && quoteError);
  const isWalletValid = new RegExp(asset.addressRegexp).test(walletAddress);
  const isSubmitDisabled = Boolean(isQuoteFetching || quoteError || !isWalletValid);
  const isQuoteAvailable = Boolean(!isQuoteFetching && quote);

  return (
    <Modal open={open} onClose={onClose}>
      <Panel
        label={<PanelTitle>{t(LABELS.REFUND_MODAL.TITLE)}</PanelTitle>}
        controls={
          <CloseButton flat onClick={handleClose}>
            <IconCross />
          </CloseButton>
        }
      >
        <Body>
          <ModalContent>
            <Title>{t(LABELS.REFUND_MODAL.HEADER)}</Title>
            <DataRow>
              <DataRowTitle>{t(LABELS.REFUND_MODAL.CRYPTOCURRENCY)}</DataRowTitle>
              <DataRowValue>
                <CryptoLabel>
                  <CryptoInlineIcon src={asset.iconUrl} />
                  <CryptoName>{asset.name}</CryptoName>
                  <CryptoNetwork>{getCryptoLabel(asset)}</CryptoNetwork>
                </CryptoLabel>
              </DataRowValue>
            </DataRow>
            <DataRow>
              <DataRowTitle>{t(LABELS.REFUND_MODAL.TOTAL_REFUNDED)}</DataRowTitle>
              <DataRowValue>{`${amount} ${symbol}`}</DataRowValue>
            </DataRow>
            <DataRow>
              <DataRowTitle>{t(LABELS.REFUND_MODAL.NETWORK_FEE)}</DataRowTitle>
              <DataRowValue>
                <OptionallyVisible visible={isQuoteFetching}>
                  <IconLoading scale={0.6} />
                </OptionallyVisible>
                <OptionallyVisible visible={isQuoteAvailable}>{`${quote?.networkFeeUsdAmount} ${KEYS.FEE_CURRENCY}`}</OptionallyVisible>
              </DataRowValue>
            </DataRow>
            <OptionallyVisible visible={canShowQuoteError}>
              <Error>{quoteError!}</Error>
            </OptionallyVisible>
            <DataRow>
              <DataRowTitle>{t(LABELS.REFUND_MODAL.WALLET)}</DataRowTitle>
              <DataRowValue>
                <Input
                  startAdornment={
                    <Adornment>
                      <CryptoInlineIcon src={asset.iconUrl} />
                    </Adornment>
                  }
                  fullWidth
                  placeholder={t(LABELS.REFUND_MODAL.WALLET_PLACEHOLDER)}
                  value={walletAddress}
                  onChange={setWalletAddress}
                />
              </DataRowValue>
            </DataRow>
          </ModalContent>
          <Delimeter />
          <Buttons>
            <Button secondary onClick={handleClose}>
              {t(LABELS.REFUND_MODAL.CANCEL)}
            </Button>
            <Button primary onClick={handleSubmit} disabled={isSubmitDisabled}>
              <OptionallyVisible visible={!isRefundSubmitting}>{t(LABELS.REFUND_MODAL.CONFIRM)}</OptionallyVisible>
              <OptionallyVisible visible={isRefundSubmitting}>
                <IconLoading scale={0.6} />
              </OptionallyVisible>
            </Button>
          </Buttons>
        </Body>
      </Panel>
    </Modal>
  );
};
