import React, { PropsWithChildren, ReactNode } from 'react';

import OptionallyVisible from '../../optionallyVisible';
import { Container, Content, Controls, Header, Label } from './styles';

export interface PanelProps extends PropsWithChildren {
  label?: string | ReactNode;
  controls?: ReactNode | ReactNode[];
}

export const Panel = ({ label, controls, children }: PanelProps) => {
  const isHeaderVisible = Boolean(label || controls);

  return (
    <Container>
      <OptionallyVisible visible={isHeaderVisible}>
        <Header>
          <Label>{label}</Label>
          <Controls>{controls}</Controls>
        </Header>
      </OptionallyVisible>
      <Content>{children}</Content>
    </Container>
  );
};
