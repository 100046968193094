import styled from '@emotion/styled/macro';

export const TitleContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CurrencyName = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CurrencyDescription = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const Icon = styled.img((props) => ({
  width: 20,
  height: 20,
}));

export const SelectedItem = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
}));
