import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M4.66602 9.83333C4.29935 9.83333 3.98557 9.70267 3.72468 9.44133C3.46335 9.18044 3.33268 8.86667 3.33268',
  '8.5C3.33268 8.13333 3.46335 7.81933 3.72468 7.558C3.98557 7.29711 4.29935 7.16667 4.66602 7.16667C5.03268',
  '7.16667 5.34646 7.29711 5.60735 7.558C5.86868 7.81933 5.99935 8.13333 5.99935 8.5C5.99935 8.86667',
  '5.86868 9.18044 5.60735 9.44133C5.34646 9.70267 5.03268 9.83333 4.66602 9.83333ZM4.66602 12.5C3.5549',
  '12.5 2.61046 12.1111 1.83268 11.3333C1.0549 10.5556 0.666016 9.61111 0.666016 8.5C0.666016 7.38889',
  '1.0549 6.44444 1.83268 5.66667C2.61046 4.88889 3.5549 4.5 4.66602 4.5C5.41046 4.5 6.08557 4.68333',
  '6.69135 5.05C7.29668 5.41667 7.77713 5.9 8.13268 6.5H13.9993L15.9993 8.5L12.9993 11.5L11.666 10.5L10.3327',
  '11.5L8.91602 10.5H8.13268C7.77713 11.1 7.29668 11.5833 6.69135 11.95C6.08557 12.3167 5.41046 12.5 4.66602',
  '12.5ZM4.66602 11.1667C5.28824 11.1667 5.83557 10.9778 6.30802 10.6C6.78002 10.2222 7.09379 9.74444',
  '7.24935 9.16667H9.33268L10.2993 9.85L11.666 8.83333L12.8493 9.75L14.0993 8.5L13.4327 7.83333H7.24935C7.09379',
  '7.25556 6.78002 6.77778 6.30802 6.4C5.83557 6.02222 5.28824 5.83333 4.66602 5.83333C3.93268 5.83333',
  '3.3049 6.09444 2.78268 6.61667C2.26046 7.13889 1.99935 7.76667 1.99935 8.5C1.99935 9.23333 2.26046',
  '9.86111 2.78268 10.3833C3.3049 10.9056 3.93268 11.1667 4.66602 11.1667Z',
].join(' ');

const IconKey = ({ width = 16, height = 17, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} />
  </Svg>
);

export default IconKey;
