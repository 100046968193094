import { OrderStatus } from 'types/order';
import { UserAccessLevel } from 'types/user';

import { OrderData } from './types';

export const hasUserS4cRole = (userLevel: UserAccessLevel | null) =>
  userLevel === UserAccessLevel.Support || userLevel === UserAccessLevel.Admin || userLevel === UserAccessLevel.SuperAdmin;

export const hasCardFailure = (orderData?: OrderData) =>
  orderData
    ? [OrderStatus.AuthorizationChargeFailed, OrderStatus.CaptureChargeFailed, OrderStatus.SystemError].includes(orderData?.order.status)
    : false;

export const hasExchangeFailure = (orderData?: OrderData) =>
  orderData ? [OrderStatus.GetQuoteToExecuteFailed, OrderStatus.ExecuteQuoteFailed].includes(orderData.order.status) : false;
