import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { OrderStatusBadge } from '../../../components/orderStatusBadge/orderStatusBadge';
import { LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { formatNumber } from '../utils';
import { Delimiter, Item, ItemName, ItemValue, TopAreaContainer } from './styles';

export const TopAreaSection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <TopAreaContainer>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.ORDER_ID)}:</ItemName>
          <ItemValue>{orderData.general.uuid}</ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.ORDER_TOTAL)}:</ItemName>
          <ItemValue upper>
            {formatNumber(orderData.general?.fiatAmount)} {orderData.general.fiatCurrencyCode}
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.STATUS)}:</ItemName>
          <ItemValue>
            <OrderStatusBadge status={orderData.general.status} />
          </ItemValue>
        </Item>
      </TopAreaContainer>
    </Panel>
  );
};
