import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { makeLabels } from 'utils/i18n';

import { PaymentMethodTableRow } from './types';

export const KEYS = {
  DEFAULT_DOMAIN: 'domain.com',
  DOMAIN_PATH: '/.well-known/apple-developer-merchantid-domain-association',
  DOMAIN_FILE_NAME: 'apple-developer-merchantid-domain-association',
  DOMAIN_FILE_URL: window.s4cConfig.applePay!.domainFileUrl,
  ALREADY_EXISTS: 'err.entityAlreadyExists',
};

export const MESSAGES_NAMESPACE = 'pages.account.edit.sections.paymentMethods';
export const LABELS = makeLabels(
  {
    ADD_DOMAIN: {
      TITLE: ['addDomain.title', 'Register Apple Pay Domain'],
      DOMAIN_LABEL: ['addDomain.domainLabel', '1. Enter your domain'],
      DOMAIN_PLACEHOLDER: ['addDomain.placeholder', 'domain.com'],
      DOWNLOAD_LABEL: ['addDomain.downloadLabel', '2. Download file'],
      DOWNLOAD: ['addDomain.download', 'Download'],
      UPLOAD_LABEL: ['addDomain.uploadLabel', '3. Upload the file to your domain'],
      UPLOAD_CAPTION: ['addDomain.uploadCaption', 'Use the following location'],
      UPLOAD_HINT: ['addDomain.uploadHint', 'Verify that your file is uploaded correctly'],
      CANCEL: ['addDomain.cancel', 'Cancel'],
      SUBMIT: ['addDomain.submit', 'Verify'],
    },
    ADD_PAYMENT_METHOD: {
      TITLE: ['addPaymentMethod.title', 'Request Payment Method'],
      MESSAGE: [
        'addPaymentMethod.message',
        [
          'Please send an email to crypto@shift4.com with the payment method you would like to support',
          'and the volume expected for this method.',
        ].join(' '),
      ],
      APPLE_PAY_TOOLTIP: [
        'addPaymentMethod.applePayTooltip',
        [
          'Apple Pay requires registration and verification of trusted domains to enable web use. Register your domain',
          'to enable Apple Pay for the embedded or overlay version of the Crypto On-Ramp SDK on your website.',
        ].join(' '),
      ],
      CLOSE: ['addPaymentMethod.close', 'Close'],
    },
    DOMAINS: {
      PANEL_TITLE: ['domains.panelTitle', 'Registered Domains'],
      ADD_DOMAIN: ['domains.addDomain', 'Register Domain'],
      PLACEHOLDER: ['domains.placeholder', 'No Domains Registered'],
      TABLE_COLUMNS: {
        DOMAINS: ['domains.tableColumns.domains', 'Domains'],
        ACTIONS: ['domains.tableColumns.actions', ''],
      },
    },
    PAYMENT_METHODS: {
      PANEL_TITLE: ['paymentMethods.panelTitle', 'Payment Methods'],
      ADD_METHOD: ['paymentMethods.addMethod', 'Request Payment Method'],
      YES: ['paymentMethods.yes', 'Yes'],
      NO: ['paymentMethods.no', 'No'],
      TABLE_COLUMNS: {
        NAME: ['paymentMethods.tableColumns.name', 'Name'],
        BUY: ['paymentMethods.tableColumns.buy', 'Buy'],
        SELL: ['paymentMethods.tableColumns.sell', 'Sell'],
      },
    },
    ERRORS: {
      ADD_ERROR: [
        'errors.add',
        "Sorry, we couldn't verify your domain. Please make sure the file is uploaded to the location mentioned above.",
      ],
      ALREADY_EXISTS: ['errors.alreadyExists', 'Provided domain is already registered.'],
    },
  },
  MESSAGES_NAMESPACE,
);

export const PAYMENT_METHODS_COLUMN_IDS = {
  NAME: 'name',
  BUY: 'buyAvailable',
  SELL: 'sellAvailable',
};

export const DOMAIN_COLUMN_IDS = {
  DOMAINS: 'domain',
  ACTIONS: 'actions',
};

export const PAYMENT_METHODS_COLUMNS: DataTableColumn[] = [
  { id: PAYMENT_METHODS_COLUMN_IDS.NAME, label: t(LABELS.PAYMENT_METHODS.TABLE_COLUMNS.NAME) },
  { id: PAYMENT_METHODS_COLUMN_IDS.BUY, label: t(LABELS.PAYMENT_METHODS.TABLE_COLUMNS.BUY) },
  { id: PAYMENT_METHODS_COLUMN_IDS.SELL, label: t(LABELS.PAYMENT_METHODS.TABLE_COLUMNS.SELL) },
];

export const DOMAINS_COLUMNS: DataTableColumn[] = [
  { id: DOMAIN_COLUMN_IDS.DOMAINS, label: t(LABELS.DOMAINS.TABLE_COLUMNS.DOMAINS), sortable: true },
  { id: DOMAIN_COLUMN_IDS.ACTIONS, label: t(LABELS.DOMAINS.TABLE_COLUMNS.ACTIONS) },
];

export const paymentMethods: PaymentMethodTableRow[] = [
  {
    id: 'card',
    name: 'Cards',
    buyAvailable: true,
    sellAvailable: true,
  },
  {
    id: 'applePay',
    name: 'Apple Pay',
    buyAvailable: true,
    sellAvailable: false,
    tooltip: t(LABELS.ADD_PAYMENT_METHOD.APPLE_PAY_TOOLTIP),
  },
  {
    id: 'googlePay',
    name: 'Google Pay',
    buyAvailable: true,
    sellAvailable: false,
  },
];
