import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { FormField } from 'components/form/formField/formField';
import IconCross from 'components/icons/cross';
import IconEnvelope from 'components/icons/envelope';
import { Input } from 'components/inputs';
import { Checkbox } from 'components/inputs/checkbox/checkbox';
import Modal from 'components/modal/modal';

import { UserRoleType } from 'types/user';

import { LABELS } from '../keys';
import { UserTableRow } from '../types';
import {
  Adornment,
  Buttons,
  Delimeter,
  EmailSection,
  ItemCaption,
  ItemDesctription,
  ItemName,
  Label,
  ModalControls,
  ModalRoot,
  PanelContent,
  PermissionItem,
  PermissionSection,
} from './styles';

interface EditUserModalProps {
  availableRoles: UserRoleType[];
  error?: Record<string, string>;
  onEdit: (email: string, role: UserRoleType) => Promise<boolean>;
  isSubmitting: boolean;
  reset: () => void;
  open: boolean;
  onClose: () => void;
  user: UserTableRow | null;
}

export const EditUserModal = ({ onEdit, error, isSubmitting, reset, open, onClose, user, availableRoles }: EditUserModalProps) => {
  const [chosenRole, setChosenRole] = useState<UserRoleType | null>(user?.roles?.[0] || null);
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.roles) {
      setChosenRole(user?.roles?.[0]);
    }
  }, [user]);

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    setChosenRole(null);
    reset();
    onClose();
  };

  const handleEdit = async () => {
    if (isSubmitting) {
      return;
    }

    if (!(user && chosenRole)) {
      return;
    }

    const response = await onEdit(user!.uuid, chosenRole);
    if (response) {
      closeModal();
    }
  };

  const handleCheckboxChange = (role: UserRoleType) => () => {
    const shouldSkip = isSubmitting || !availableRoles.includes(role);
    if (shouldSkip) {
      return;
    }

    if (chosenRole === role) {
      setChosenRole(null);
      return;
    }

    setChosenRole(role);
  };

  const canInvite = user && chosenRole;

  if (!user) {
    return null;
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.EDIT_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            <EmailSection>
              <Label>{t(LABELS.EDIT_MODAL.EMAIL.TITLE)}</Label>
              <FormField error={error?.email}>
                <Input
                  placeholder={t(LABELS.EDIT_MODAL.EMAIL.PLACEHOLDER)}
                  value={user.username}
                  disabled
                  startAdornment={
                    <Adornment>
                      <IconEnvelope />
                    </Adornment>
                  }
                />
              </FormField>
            </EmailSection>
            <PermissionSection>
              <FormField error={error?.level}>
                <Label>{t(LABELS.EDIT_MODAL.PERMISSIONS.LABEL)}</Label>
              </FormField>
              <PermissionItem>
                <Checkbox
                  disabled={!availableRoles.includes(UserRoleType.SuperAdmin)}
                  onChange={handleCheckboxChange(UserRoleType.SuperAdmin)}
                  value={chosenRole === UserRoleType.SuperAdmin}
                />
                <ItemDesctription>
                  <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPER_ADMIN.NAME)}</ItemName>
                  <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPER_ADMIN.CAPTION)}</ItemCaption>
                </ItemDesctription>
              </PermissionItem>
              <PermissionItem>
                <Checkbox onChange={handleCheckboxChange(UserRoleType.Admin)} value={chosenRole === UserRoleType.Admin} />
                <ItemDesctription>
                  <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.ADMIN.NAME)}</ItemName>
                  <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.ADMIN.CAPTION)}</ItemCaption>
                </ItemDesctription>
              </PermissionItem>
              <PermissionItem>
                <Checkbox onChange={handleCheckboxChange(UserRoleType.Support)} value={chosenRole === UserRoleType.Support} />
                <ItemDesctription>
                  <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPPORT.NAME)}</ItemName>
                  <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPPORT.CAPTION)}</ItemCaption>
                </ItemDesctription>
              </PermissionItem>
            </PermissionSection>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={closeModal}>
                {t(LABELS.EDIT_MODAL.BUTTONS.CANCEL)}
              </Button>
              <Button primary onClick={handleEdit} disabled={!canInvite}>
                {t(LABELS.EDIT_MODAL.BUTTONS.EDIT)}
              </Button>
            </Buttons>
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
