export const KEYS = {
  UNIQ_KEY_PROP: 'key',
  PAGE_LOAD_DEBOUNCE: 200,
};

export const LABELS = {
  SELECTION: ['components.inputs.select.selection', 'Custom selection ({{count}})'],
};

export const ITEM_DELIMETER = ',';
