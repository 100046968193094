import React, { ComponentType, FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable/dataTable.types';
import { DataTableCell } from 'components/dataTable/dataTableCell';

import { COLUMN_IDS } from '../../apiKeys.keys';
import { RotateApiKeyFn } from '../../types';
import { DateCell } from './dateCell';
import { RevealSecretKeyCell } from './revealSecretKeyCell';
import { RotateKeyCell } from './rotateKeyCell';

const cellComponentMap: { [key: string]: ComponentType<DataTableCellProps> } = {
  [COLUMN_IDS.KEY_NAME]: DataTableCell,
  [COLUMN_IDS.KEY_VALUE]: RevealSecretKeyCell,
  [COLUMN_IDS.CREATED_AT]: DateCell,
  [COLUMN_IDS.LAST_USED]: DateCell,
  [COLUMN_IDS.ACTION_ROTATE_KEY]: RotateKeyCell,
  default: DataTableCell,
};

interface CustomCellComponentFactoryProps {
  rotateApiKey: RotateApiKeyFn;
}

export const customCellComponentFactory =
  ({ rotateApiKey }: CustomCellComponentFactoryProps): FunctionComponent<DataTableCellProps> =>
  (props: DataTableCellProps) => {
    const { column } = props;
    const SelectedComponent = cellComponentMap[column.id] || cellComponentMap.default;

    return <SelectedComponent {...props} passedProps={{ rotateApiKey }} />;
  };
