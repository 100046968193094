import styled from '@emotion/styled/macro';

export const Controls = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: -8,
  marginBottom: -8,
}));

export const PanelContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 50,
  '@media (max-width: 1400px)': {
    flexDirection: 'column',
  },
}));

export const FormContainer = styled.div(() => ({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));
