import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { AccountUuid, ApiKeys } from 'types/apiKeys';
import { UrlParam } from 'types/base';
import HTTPMethod, { Response } from 'types/http';

import { fetchBaseQueryWithAuth } from '../api';
import { RotateApiKeyForAccountRequestBody, RotateApiKeyForAccountResponse } from './types';

const REDUCER_PATH = 'apiKeysApi';
const TAGS = {
  API_KEYS: 'apiKeys',
};

export const apiKeysApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.API_KEYS],
  endpoints: (builder) => ({
    getAccountApiKeys: builder.query<ApiKeys, AccountUuid>({
      query: (accountUuid) => ({
        url: Endpoint.ApiKeys.ApiKeysByAccountId(accountUuid),
      }),
      transformResponse: (response: Response<ApiKeys>) => {
        return response.data!;
      },
      providesTags: [TAGS.API_KEYS],
    }),
    rotateApiKeyForAccount: builder.mutation<
      RotateApiKeyForAccountResponse | undefined,
      RotateApiKeyForAccountRequestBody & { accountUuid: UrlParam }
    >({
      query: ({ accountUuid, ...requestBody }) => ({
        url: Endpoint.ApiKeys.RotateApiKeyForAccount(accountUuid),
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<RotateApiKeyForAccountResponse>) => {
        return response.data;
      },
      invalidatesTags: (result, error) => (error ? [] : [TAGS.API_KEYS]),
    }),
  }),
});

export const { getAccountApiKeys } = apiKeysApi.endpoints;

export const { useLazyGetAccountApiKeysQuery, useRotateApiKeyForAccountMutation, useGetAccountApiKeysQuery } = apiKeysApi;

export default apiKeysApi;
