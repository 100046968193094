import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M7.5 13.5H10.5V12H7.5V13.5ZM2.25 4.5V6H15.75V4.5H2.25ZM4.5 9.75H13.5V8.25H4.5V9.75Z';

export const IconFilter = ({ width = 18, height = 18, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconFilter;
