import { DateTime, UrlParam } from './base';
import { UserRoleType } from './user';

export type ApiKeyUser = {
  username: string;
  lastName: string;
  firstName: string;
  roles: UserRoleType[];
  uuid: string;
  signupAt: DateTime;
};

export type ApiKeyUsageData = {
  ip: string;
  lastAccessAt: DateTime;
};

export type ApiKeys = {
  publicKey: string;
  publicKeyCreatedAt: string;
  publicKeyCreatedBy: ApiKeyUser;
  secretKey: string;
  secretKeyCreatedAt: string;
  secretKeyCreatedBy: ApiKeyUser;
  secretKeyUsage: ApiKeyUsageData[];
};

export type AccountUuid = UrlParam;

export enum ApiKeyType {
  SecretKey = 'sk',
  PublicKey = 'pk',
}
