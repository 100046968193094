import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';

const selectProfileHandler = (state: RootState) => {
  return {
    profile: state.profile.profile,
  };
};

export const selectProfile = createSelector([(state: RootState) => state], selectProfileHandler);
