import styled from '@emotion/styled';

export const Container = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

export const CountryIcon = styled.img((props) => ({
  height: 16,
  width: 16,
}));

export const CountryName = styled.span((props) => ({}));
