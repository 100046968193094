import { makeLabels } from '../../utils/i18n';

export const NAMESPACE = 'page.notImplemented';
export const LABELS = makeLabels(
  {
    BREADCRUMBS: ['breadcrumbs', 'Under construction'],
    PAGE_TITLE: ['title', 'Not implemented'],
    PAGE_BODY: ['body', 'We are working hard to get this page up and running!'],
  },
  NAMESPACE,
);
