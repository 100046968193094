import React from 'react';

import IconLoading from '../icons/loading';
import LoadingLogo from './logo';
import { Container } from './styles';

export interface LoadingIndicatorProps {
  padded?: boolean;
  size?: number;
  withLogo?: boolean;
}

export const LOGO_DEFAULT_SIZE = 150;
export const SPINNER_DEFAULT_SCALE = 1;

export const LoadingIndicator = ({ withLogo, padded, size }: LoadingIndicatorProps) => {
  if (withLogo)
    return (
      <Container padded={padded}>
        <LoadingLogo size={size || LOGO_DEFAULT_SIZE} />
      </Container>
    );

  return (
    <Container padded={padded}>
      <IconLoading scale={size || SPINNER_DEFAULT_SCALE} />
    </Container>
  );
};

export default LoadingIndicator;
