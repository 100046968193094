import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

interface KycReasonFieldProps {
  reason: string;
}

export const KycReasonField = ({ reason }: KycReasonFieldProps) => {
  const { t } = useTranslation();

  const processedReason = JSON.parse(reason);

  if (Array.isArray(processedReason)) {
    return <Container>{processedReason.map((record) => t(record)).join(' ')}</Container>;
  }

  return <Container>{processedReason}</Container>;
};
