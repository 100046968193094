import styled from '@emotion/styled/macro';

import { Root as ButtonRoot } from 'components/buttons/styles';

export const Container = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    padding: '8px 0',
  },
}));

export const Preview = styled.div<{ color: string }>((props) => ({
  backgroundColor: props.color,
  width: 20,
  height: 20,
  borderRadius: 20,
}));

export const PickerButton = styled.button((props) => ({
  boxSizing: 'border-box',
  backgroundColor: props.theme.colors.container.primary,
  border: 0,
  padding: '8px 12px',
  borderRadius: 5,
  maxHeight: 32,
  outline: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textTransform: 'uppercase',
}));

export const PickerContainer = styled.div((props) => ({
  backgroundColor: '#fff',
  padding: 6,
  borderRadius: 5,
  boxShadow: `0px 2px 5px 0px ${props.theme.colors.shadows['10']}`,

  '.react-colorful__hue-pointer, .react-colorful__saturation-pointer': {
    width: 24,
    height: 24,
    borderWidth: 1,
  },
}));
