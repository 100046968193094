import React from 'react';

import { Path, Svg } from 'components/icons/styles';

const iconPath: Record<string, string> = {
  '0': 'M1.5 0.5H10V2H1.5V9.5H0V2V0.5H1.5Z',
  '2': 'M2 0.5H10V2H2C1.72386 2 1.5 2.22386 1.5 2.5V9.5H0V2.5C0 1.39543 0.895431 0.5 2 0.5Z',
  '5': 'M5 0.5H10V2H5C3.067 2 1.5 3.567 1.5 5.5V9.5H0V5.5C0 2.73858 2.23858 0.5 5 0.5Z',
  '10': 'M0 9.5C0.501724 4.44668 4.76528 0.5 9.95062 0.5V2C5.59452 2 2.00376 5.27683 1.50883 9.5H0Z',
  '20': [
    'M0.951172 9.32596C2.33711 5.03573 5.89246 1.72068 10.3242 0.673828V2.22119C6.72392 3.19144',
    '3.82268 5.86585 2.53891 9.32596H0.951172Z',
  ].join(' '),
};

interface BorderRadiusIconProps {
  radius: string;
}

export const BorderRadiusIcon = ({ radius }: BorderRadiusIconProps) => (
  <Svg width={10} height={10} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <Path d={iconPath[radius]} />
  </Svg>
);
