import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M9.02705 4.5C9.02705 4.20892 8.79108 3.97295 8.5 3.97295C8.20892',
  '3.97295 7.97295 4.20892 7.97295 4.5L9.02705 4.5ZM8.12732 12.8727C8.33315',
  '13.0785 8.66685 13.0785 8.87268 12.8727L12.2268 9.51858C12.4326 9.31275',
  '12.4326 8.97904 12.2268 8.77322C12.021 8.5674 11.6872 8.5674 11.4814',
  '8.77322L8.5 11.7546L5.51858 8.77322C5.31275 8.5674 4.97904 8.5674 4.77322',
  '8.77322C4.5674 8.97904 4.5674 9.31275 4.77322 9.51858L8.12732 12.8727ZM7.97295',
  '4.5L7.97295 12.5L9.02705 12.5L9.02705 4.5L7.97295 4.5Z',
].join(' ');

export const IconDownArrow = ({ width = 17, height = 17, className, color }: IconProps) => {
  return (
    <Svg className={className} width={width} height={height} viewBox="0 0 17 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <Path d={path} />
    </Svg>
  );
};
export default IconDownArrow;
