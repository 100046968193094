import React from 'react';

import { RemoteSelect } from '../../inputs/select/remoteSelect';
import { SelectItem } from '../../inputs/select/select';
import { Container, Row } from '../styles';
import { RemoteSelectFilterPassedProps } from '../types';

interface RemoteSelectFilterProps extends Omit<RemoteSelectFilterPassedProps, 'type'> {
  value: string | string[];
  onChange: (value: string | string[]) => void;
}

export const RemoteSelectFilter = ({ value, getItems, onChange, placeholder, multiple = false }: RemoteSelectFilterProps) => {
  const handleChange = (items: SelectItem | SelectItem[]) => {
    if (multiple) {
      const nextItems = items as SelectItem[];
      onChange(nextItems.map((item) => item.key));
    } else {
      const nextItem = items as SelectItem;
      onChange(nextItem.key);
    }
  };

  return (
    <Container>
      <Row>
        <RemoteSelect<SelectItem, typeof multiple>
          value={value}
          getItems={getItems}
          placeholder={placeholder}
          onChange={handleChange}
          getItemLabel={(item) => item.label}
          multiple={multiple}
          closeDropdownOnSelect
        />
      </Row>
    </Container>
  );
};
