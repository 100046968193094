import { AccountEmbeddableThemeMode } from 'types/account';

export const KEYS = {
  WIDGET_SIZE: { width: 320, height: 480 },
  LANDING_SIZE: { width: 600, height: 800 },
  LANDING_MODE_KEY: 'pageThemeMode',
  WIDGET_MODE_KEY: 'widgetThemeMode',
  HIDE_ILLUSTRATIONS_KEY: 'hideIllustrations',
  SLUG_PLACEHOLDER: '{partner name}',
};

export const LABELS = {
  MODES: {
    [AccountEmbeddableThemeMode.Light]: ['pages.account.edit.sections.components.widget.modes.light', 'Light'],
    [AccountEmbeddableThemeMode.Dark]: ['pages.account.edit.sections.components.widget.modes.dark', 'Dark'],
  },
  WIDGET_TITLE: ['pages.account.edit.sections.components.widget.widgetTitle', 'Widget Preview'],
  LANDING_TITLE: ['pages.account.edit.sections.components.widget.landingTitle', 'Landing Page Preview'],
  LANDING_CAPTION: ['pages.account.edit.sections.components.widget.landingCaption', 'Save changes to update the landing page preview'],
};

export const widgetPropsMap = new Map([
  ['widgetColor', 'themeColor'],
  ['widgetThemeMode', 'themeMode'],
  ['cornerRadius', 'themeRadius'],
  ['defaultCryptoCurrencyCode', 'defaultCryptoCurrencyCode'],
  ['supportedCryptoCurrenciesCodes', 'cryptoCurrencyCodeList'],
  ['popularCryptoCurrenciesCodes', 'cryptoCurrencyCodePopularList'],
  ['supportedFiatCurrenciesCodes', 'fiatCurrencyCodeList'],
  ['displayIllustration', 'hideIllustrations'],
]);

export const landingPropsMap = new Map([['urlSlug', 'urlSlug']]);
