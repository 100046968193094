import RoutePath from 'router/path';

export const KEYS = {
  INVITE_ACCEPT_TIMEOUT: 100,
  INVITE_KEY_QUERY_PARAM: 'key',
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  ROUTE_MATCH_ID: `${RoutePath.Pwc.Accounts.ById}/:route`,
  TABS: {
    GENERAL: 'general',
    API_KEYS: 'api-keys',
    SETTLEMENT: 'settlement',
    USER_MANAGEMENT: 'users',
  },
};

export const LABELS = {
  S4C_USER_BREADCRUMBS: [
    ['page.merchant.s4cUserBreadcrumbsMerchantAccounts', 'Merchant Accounts'],
    ['page.merchant.s4cUserBreadcrumbsAccountSettings', 'Account Settings'],
  ],
  PARTNER_USER_BREADCRUMBS: [['page.merchant.partnerUserBreadcrumbs', 'Account Settings']],
  PAGE_TITLE: ['page.merchant.title', 'Account Settings'],
  TABS: {
    GENERAL: ['page.merchant.tabs.general', 'General'],
    API_KEYS: ['page.merchant.tabs.apiKeys', 'API Keys'],
    SETTLEMENT: ['page.merchant.tabs.settlement', 'Settlement'],
    USER_MANAGEMENT: ['page.merchant.tabs.userManagement', 'User Management'],
  },
};
