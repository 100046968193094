import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React from 'react';

import { FilterBadge } from '../badge/badge';
import { FilterDropdown } from '../dropdown/dropdown';
import { FilterBadges, Filters } from './styles';
import { TableFiltersPropsWithSections } from './types';

export const TableFilters = <T extends Object>({
  onFilterChange,
  onFiltersClear,
  filters,
  filterSettings,
  sections,
  ignoredFilters = [],
}: TableFiltersPropsWithSections<T>) => {
  const handleFilterClear = (filterKey: string) => () => {
    onFilterChange(omit(filters, [filterKey]));
  };

  const appliedFilters = Object.entries(filters).filter(([key, value]) => !isEmpty(value) && !ignoredFilters?.includes(key));

  return (
    <Filters>
      <FilterDropdown<T> onChange={onFilterChange} onClear={onFiltersClear} filters={sections} values={filters} />
      <FilterBadges>
        {appliedFilters.map(([key, value]) => (
          <FilterBadge key={key} filter={filterSettings[key]} value={value} onClear={handleFilterClear(key)} />
        ))}
      </FilterBadges>
    </Filters>
  );
};
