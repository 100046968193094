import React from 'react';

import { DataTableCellProps } from './dataTable.types';
import { StyledTableCell } from './dataTableCell.styles';

export const DataTableCell = ({ row, column }: DataTableCellProps) => {
  return (
    <StyledTableCell align="left" decoration={column.decoration} data-column={column.id}>
      {row[column.id]}
    </StyledTableCell>
  );
};
