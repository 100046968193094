export const LABELS = {
  RESET: ['components.inputs.color.reset', 'Reset'],
  PLACEHOLDER: ['components.inputs.color.placeholder', 'Pick a color'],
};

export const KEYS = {
  DEFAULT_COLOR: '#000000',
};

export const hexColorExp = /^#[0-9A-F]{6}$/;
export const hexColorSymbolsExp = /[^0-9A-Fa-f]/gi;
