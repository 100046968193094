import React, { ReactElement } from 'react';

import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconCross from 'components/icons/cross';
import IconDash from 'components/icons/dash';
import IconRefresh from 'components/icons/refresh';

import { OrderStatus } from 'types/pwcOrder';

import { PwcOrderBadgeType, badgePropsMap } from './keys';
import { BadgeContent } from './styles';

interface StatusBadgeProps {
  status: OrderStatus;
}

const badgeToIconMap: Record<PwcOrderBadgeType, ReactElement | null> = {
  [PwcOrderBadgeType.Initiated]: <IconRefresh />,
  [PwcOrderBadgeType.AwaitingDeposit]: <IconRefresh />,
  [PwcOrderBadgeType.Completed]: <IconChecked />,
  [PwcOrderBadgeType.Expired]: <IconCross />,
  [PwcOrderBadgeType.Canceled]: <IconCross />,
};

const badgeTypeMap: Record<PwcOrderBadgeType, BadgeType> = {
  [PwcOrderBadgeType.Initiated]: BadgeType.Default,
  [PwcOrderBadgeType.AwaitingDeposit]: BadgeType.Default,
  [PwcOrderBadgeType.Completed]: BadgeType.Success,
  [PwcOrderBadgeType.Expired]: BadgeType.Fail,
  [PwcOrderBadgeType.Canceled]: BadgeType.Fail,
};

export const OrderStatusBadge = ({ status }: StatusBadgeProps) => {
  const { type, label } = badgePropsMap[status] || badgePropsMap.default;
  const icon = badgeToIconMap[type];
  const badgeType = badgeTypeMap[type];

  const usedLabel = label || <IconDash />;

  return (
    <Badge type={badgeType}>
      <BadgeContent>
        {icon}
        {usedLabel}
      </BadgeContent>
    </Badge>
  );
};
