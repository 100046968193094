import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M14.25 6L11.25 9H13.5C13.5 11.4825 11.4825 13.5 9 13.5C8.2425 13.5 7.5225 13.3125 6.9 12.975L5.805',
  '14.07C6.7275 14.655 7.8225 15 9 15C12.315 15 15 12.315 15 9H17.25L14.25 6ZM4.5 9C4.5 6.5175 6.5175',
  '4.5 9 4.5C9.7575 4.5 10.4775 4.6875 11.1 5.025L12.195 3.93C11.2725 3.345 10.1775 3 9 3C5.685 3 3',
  '5.685 3 9H0.75L3.75 12L6.75 9H4.5Z',
].join(' ');

const IconRefresh = ({ width = 18, height = 18, color, style, className }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconRefresh;
