export const KEYS = {
  DEFAULT_FORMAT: 'MM/DD/YYYY',
  DEFAULT_MASK: '99/99/9999',
  DATE_STRING_LENGTH: 10,
  NUMBER_OF_CALENDARS_SHOWN: 2, // 1-3, default is 2
};

export const LABELS = {
  FROM: ['components.inputs.date.from', 'From'],
  TO: ['components.inputs.date.to', 'To'],
  CLOSE_MODAL: ['components.inputs.date.close', 'Close'],
};

export default KEYS;
