import dayjs from 'dayjs';

import { DateFilterValue } from 'types/filters';

export const createTimePresets = (): Record<string, DateFilterValue> => {
  const today = dayjs().endOf('day');

  return {
    today: {
      from: dayjs().startOf('day').valueOf(),
      to: today.valueOf(),
    },
    yesterday: {
      from: dayjs().add(-1, 'day').startOf('day').valueOf(),
      to: dayjs().add(-1, 'day').endOf('day').valueOf(),
    },
    thisWeek: {
      from: dayjs().startOf('week').add(1, 'day').valueOf(),
      to: today.valueOf(),
    },
    lastWeek: {
      from: dayjs().add(-7, 'day').startOf('day').valueOf(),
      to: today.valueOf(),
    },
    last30Days: {
      from: dayjs().add(-30, 'day').startOf('day').valueOf(),
      to: today.valueOf(),
    },
    monthToDate: {
      from: dayjs().startOf('month').valueOf(),
      to: today.valueOf(),
    },
    lastMonth: {
      from: dayjs().add(-1, 'month').startOf('month').valueOf(),
      to: dayjs().add(-1, 'month').endOf('month').valueOf(),
    },
    lastThreeMonths: {
      from: dayjs().add(-90, 'day').startOf('day').valueOf(),
      to: today.valueOf(),
    },
    lastSixMonths: {
      from: dayjs().add(-180, 'day').startOf('day').valueOf(),
      to: today.valueOf(),
    },
    yearToDate: {
      from: dayjs().startOf('year').valueOf(),
      to: today.valueOf(),
    },
    last365Days: {
      from: dayjs().add(-365, 'day').startOf('day').valueOf(),
      to: today.valueOf(),
    },
    lastYear: {
      from: dayjs().add(-1, 'year').startOf('year').valueOf(),
      to: dayjs().add(-1, 'year').endOf('year').valueOf(),
    },
  };
};
