import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTableRow } from 'components/dataTable';
import DataTable from 'components/dataTable/dataTable';
import IconExclamationCircled from 'components/icons/exclamationCircled';

import { TablePagination } from 'types/pagination';
import { TableSorting } from 'types/sort';

import { COLUMNS, LABELS } from '../../keys';
import { OrderDataRow } from '../../types';
import { OrderTableCell } from './components/orderTableCell';
import { Notification, PlaceholderContainer, TableContainer } from './styles';

interface CustomerOrdersProps {
  orders: OrderDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  sorting: TableSorting;
  onSort: (event: MouseEvent, field: string) => void;
  onRowClick: (row: DataTableRow) => void;
}

export const CustomerOrdersList = ({
  orders,
  isLoading,
  pagination,
  onChangePage,
  onChangeRowsPerPage,
  sorting,
  onSort,
  onRowClick,
}: CustomerOrdersProps) => {
  const { t } = useTranslation();
  return (
    <Panel label={t(LABELS.ORDER_LIST.TITLE)}>
      <TableContainer>
        <DataTable
          columns={COLUMNS}
          rows={orders}
          loading={isLoading}
          CellComponent={OrderTableCell}
          pagination={pagination}
          onSort={onSort}
          order={sorting.order}
          orderBy={sorting.orderBy}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onRowClick={onRowClick}
          placeholder={
            <PlaceholderContainer>
              <Notification>
                <IconExclamationCircled />
                {t(LABELS.ORDER_LIST.NO_RECORDS_FOUND)}
              </Notification>
            </PlaceholderContainer>
          }
        />
      </TableContainer>
    </Panel>
  );
};
