import React, { ReactElement } from 'react';

import { Backdrop, ModalRoot } from './styles';

interface ModalProps {
  open: boolean;
  children: ReactElement;
  onClose: () => void;
}

export const Modal = ({ open, children, onClose }: ModalProps) => {
  return (
    <ModalRoot open={open} onClose={onClose} slots={{ backdrop: Backdrop }}>
      {children}
    </ModalRoot>
  );
};

export default Modal;
