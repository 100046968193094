import { MouseEvent, useState } from 'react';

import { RequestSorting, SortOrder, TableSorting } from 'types/sort';

export interface useTableSortingArgs {
  defaultSorting: RequestSorting;
}

export const useTableSorting = ({ defaultSorting }: useTableSortingArgs) => {
  const [sorting, setSorting] = useState<TableSorting>({
    orderBy: defaultSorting.field,
    order: defaultSorting.direction,
  });

  const onSort = (_event: MouseEvent, property: string) => {
    setSorting((prevState) => ({
      orderBy: property,
      order: prevState?.order === SortOrder.asc ? SortOrder.desc : SortOrder.asc,
    }));
  };

  return {
    sorting,
    onSort,
  };
};

export const getRequestSorting = (sorting: TableSorting) => {
  return {
    field: sorting.orderBy,
    direction: sorting.order,
  };
};
