import { DialogNotification, NotificationSeverity, NotificationType, ToastNotification } from 'types/notifications';

import { notificationDispatcher } from './notificationDispatcher';
import { NotificationEvent } from './types';

export const useNotifications = () => {
  const pushToast = (notification: Partial<ToastNotification>) => {
    const nextNotification: ToastNotification = {
      key: new Date().getTime(),
      type: NotificationType.Toast,
      message: '',
      severity: NotificationSeverity.default,
      ...notification,
    };

    notificationDispatcher.emit(NotificationEvent.PushNotification, nextNotification);
  };

  const removeNotification = (key: string | number) => {
    notificationDispatcher.emit(NotificationEvent.RemoveNotification, key);
  };

  const pushDialog = (notification: Partial<DialogNotification>) => {
    const nextNotification: DialogNotification = {
      key: new Date().getTime(),
      type: NotificationType.Dialog,
      message: '',
      severity: NotificationSeverity.default,
      ...notification,
    };

    notificationDispatcher.emit(NotificationEvent.PushDialogNotification, nextNotification);
  };

  return {
    removeNotification,
    pushToast,
    pushDialog,
  };
};
