import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M2.59992 7.99935C2.59992 6.85935 3.52659 5.93268 4.66659 5.93268H7.33325V4.66602H4.66659C2.82659',
  '4.66602 1.33325 6.15935 1.33325 7.99935C1.33325 9.83935 2.82659 11.3327 4.66659',
  '11.3327H7.33325V10.066H4.66659C3.52659 10.066 2.59992 9.13935 2.59992 7.99935ZM5.33325',
  '8.66602H10.6666V7.33268H5.33325V8.66602ZM11.3333 4.66602H8.66659V5.93268H11.3333C12.4733 5.93268 13.3999',
  '6.85935 13.3999 7.99935C13.3999 9.13935 12.4733 10.066 11.3333 10.066H8.66659V11.3327H11.3333C13.1733 11.3327',
  '14.6666 9.83935 14.6666 7.99935C14.6666 6.15935 13.1733 4.66602 11.3333 4.66602Z',
].join(' ');

export const IconLink = ({ width = 16, height = 16, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fillRule="evenodd" clipRule="evenodd" />
  </Svg>
);

export default IconLink;
