import dayjs from 'dayjs';

export const addDays = (date: Date, days: number) => dayjs(date).add(days, 'day');
export const addMonths = (date: Date, months: number) => dayjs(date).add(months, 'month');
export const startOfDay = (date: Date) => dayjs(date).startOf('day');
export const endOfDay = (date: Date) => dayjs(date).endOf('day');
export const format = (date: Date, format: string) => dayjs(date).format(format);

export const getTime = (timestamps: string) => {
  const date = new Date(timestamps);

  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const year = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(date);
  const time = new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', hour12: true }).format(date);

  const formattedDate = `${day} ${month} ${year}, ${time}`;

  return formattedDate;
};
