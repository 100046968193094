import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M1.08301 7.58301V6.41634H4.58301V7.58301H1.08301ZM5.02051 5.33717L3.79551',
  '4.11217L4.61217 3.29551L5.83717 4.52051L5.02051 5.33717ZM6.91634',
  '4.08301V0.583008H8.08301V4.08301H6.91634ZM9.97884 5.33717L9.16218 4.52051L10.3872 3.29551L11.2038',
  '4.11217L9.97884 5.33717ZM10.4163 7.58301V6.41634H13.9163V7.58301H10.4163ZM7.49967 8.74967C7.01356',
  '8.74967 6.60037 8.57954 6.26009 8.23926C5.91981 7.89898 5.74967 7.48579 5.74967 6.99967C5.74967',
  '6.51356 5.91981 6.10037 6.26009 5.76009C6.60037 5.41981 7.01356 5.24967 7.49967 5.24967C7.98579',
  '5.24967 8.39898 5.41981 8.73926 5.76009C9.07954 6.10037 9.24967 6.51356 9.24967 6.99967C9.24967',
  '7.48579 9.07954 7.89898 8.73926 8.23926C8.39898 8.57954 7.98579 8.74967 7.49967 8.74967ZM10.3872',
  '10.7038L9.16218 9.47884L9.97884 8.66218L11.2038 9.88718L10.3872 10.7038ZM4.61217 10.7038L3.79551',
  '9.88718L5.02051 8.66218L5.83717 9.47884L4.61217 10.7038ZM6.91634 13.4163V9.91634H8.08301V13.4163H6.91634Z',
].join(' ');

export const IconLight = ({ width = 15, height = 14, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconLight;
