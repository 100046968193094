export const getAuth0Domain = () => {
  if (!window.s4cConfig?.auth0?.domain) {
    throw new Error('No auth0 domain provided');
  }

  return window.s4cConfig.auth0.domain;
};

export const getAuth0ClientId = () => {
  if (!window.s4cConfig?.auth0?.clientId) {
    throw new Error('No auth0 clientId provided');
  }

  return window.s4cConfig.auth0.clientId;
};

export const getAuth0Audience = () => {
  if (!window.s4cConfig?.auth0?.audience) {
    throw new Error('No auth0 audience provided');
  }

  return window.s4cConfig.auth0.audience;
};

export const AUTH0_CONFIG = {
  AUTH0_DOMAIN: getAuth0Domain(),
  AUTH0_CLIENT_ID: getAuth0ClientId(),
  AUTH0_AUDIENCE: getAuth0Audience(),
};

export default AUTH0_CONFIG;
