export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface RequestSorting {
  field: string;
  direction: SortOrder;
}

export interface TableSorting {
  orderBy: string;
  order: SortOrder;
}
