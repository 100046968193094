import styled from '@emotion/styled';

import { ColumnDecoration } from './dataTable.types';
import { TableCell } from './tableCell';

export interface StyledTableCellProps {
  decoration?: string;
}
export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ decoration }) => {
  if (decoration === ColumnDecoration.bold) {
    return { fontWeight: '600' };
  }

  return {};
});
