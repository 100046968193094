import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M13.6654 1.99935C13.6654 1.26602 13.0654 0.666016 12.332 0.666016H1.66536C0.932031 0.666016 0.332031 1.26602',
  '0.332031 1.99935V9.99935C0.332031 10.7327 0.932031 11.3327 1.66536 11.3327H12.332C13.0654 11.3327 13.6654',
  '10.7327 13.6654 9.99935V1.99935ZM12.332 1.99935L6.9987 5.33268L1.66536 1.99935H12.332ZM12.332',
  '9.99935H1.66536V3.33268L6.9987 6.66602L12.332 3.33268V9.99935Z',
].join(' ');

export const IconEnvelope = ({ width = 14, height = 12, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} />
  </Svg>
);

export default IconEnvelope;
