import styled from '@emotion/styled/macro';

export const SuccessContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: 16,
  fontSize: 13,
  textAlign: 'center',
}));

export const Heading = styled.div(() => ({
  width: 280,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
}));

export const TextBlock = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

export const BoldText = styled.div(() => ({
  fontWeight: 600,
}));

export const AccountCreatedImg = styled.img((props) => ({
  width: 113,
  height: 72,
}));
