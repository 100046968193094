import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'M11.0833 2.25H2.91667C2.1434 2.25093 1.40208 2.55851 0.855295 3.1053C0.308514 3.65208 0.00092625 4.3934 0',
    '5.16667H14C13.9991 4.3934 13.6915 3.65208 13.1447 3.1053C12.5979 2.55851 11.8566 2.25093 11.0833 2.25Z',
  ].join(' '),
  [
    'M0 9.83398C0.00092625 10.6072 0.308514 11.3486 0.855295 11.8954C1.40208 12.4421 2.1434 12.7497 2.91667',
    '12.7507H11.0833C11.8566 12.7497 12.5979 12.4421 13.1447 11.8954C13.6915 11.3486 13.9991 10.6072 14',
    '9.83398V6.33398H0V9.83398ZM4.08333 9.54232C4.08333 9.71538 4.03202 9.88455 3.93587 10.0284C3.83972 10.1723',
    '3.70307 10.2845 3.54318 10.3507C3.3833 10.4169 3.20736 10.4343 3.03763 10.4005C2.8679 10.3667 2.71199 10.2834',
    '2.58961 10.161C2.46724 10.0387 2.38391 9.88276 2.35015 9.71302C2.31638 9.54329 2.33371 9.36736 2.39994',
    '9.20747C2.46617 9.04758 2.57832 8.91093 2.72221 8.81478C2.8661 8.71864 3.03527 8.66732 3.20833 8.66732C3.4404',
    '8.66732 3.66296 8.75951 3.82705 8.9236C3.99115 9.08769 4.08333 9.31025 4.08333 9.54232Z',
  ].join(' '),
];

export const IconCreditCard = ({ width = 14, height = 15, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path[0]} fill={color} />
    <Path d={path[1]} fill={color} />
  </Svg>
);

export default IconCreditCard;
