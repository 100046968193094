import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'locales/i18next';

import Routes from 'router/routes.container';

import { setupStore } from 'state/store';

import { muiTheme, theme } from 'theme/theme';

import { Auth0ProviderWithRedirectCallback } from './providers/auth0';
import { NotificationProvider } from './providers/notifications';

const store = setupStore();

const App = () => {
  return (
    <Provider store={store}>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider theme={muiTheme}>
          <BrowserRouter>
            <Auth0ProviderWithRedirectCallback>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <Routes />
                <NotificationProvider />
              </LocalizationProvider>
            </Auth0ProviderWithRedirectCallback>
          </BrowserRouter>
        </ThemeProvider>
      </StyledThemeProvider>
    </Provider>
  );
};

export default App;
