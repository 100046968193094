import cardBrandsImageSource from 'constants/cardBrands';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/inputs/select/select';
import { SelectableItem } from 'components/inputs/select/styles';
import LoadingIndicator from 'components/loadingIndicator';
import OptionallyVisible from 'components/optionallyVisible';

import { LABELS } from '../../keys';
import { CardImage, CardListItem, CardTitle, DataRow, DataRowTitle, DataRowValue, LoadingWrapper, PreviewCardTitle } from './styles';
import { CardSelectItem } from './types';

interface CardSelectSectionProps {
  cardItems: CardSelectItem[];
  selectedCard: CardSelectItem | undefined;
  onCardChange: (item: CardSelectItem) => void;
  isLoading: boolean;
}

export const CardSelectSection = ({ cardItems, selectedCard, onCardChange, isLoading }: CardSelectSectionProps) => {
  const { t } = useTranslation();

  const renderCardListItem = (card: CardSelectItem, selectItem: (event: MouseEvent<HTMLElement>) => void) => (
    <SelectableItem onClick={selectItem} key={card.key}>
      <CardListItem>
        <CardImage src={cardBrandsImageSource[card.brand]} />
        <CardTitle>{t(LABELS.CREATE_ORDER_MODAL.CARD_TITLE, { last4: card.last4 })}</CardTitle>
      </CardListItem>
    </SelectableItem>
  );

  const renderSelectedCardItem = (card: CardSelectItem) => {
    if (!card) {
      return null;
    }

    return (
      <CardListItem>
        <CardImage src={cardBrandsImageSource[card.brand]} />
        <PreviewCardTitle>{t(LABELS.CREATE_ORDER_MODAL.CARD_TITLE, { last4: card.last4 })}</PreviewCardTitle>
      </CardListItem>
    );
  };

  return (
    <DataRow>
      <DataRowTitle>{t(LABELS.CREATE_ORDER_MODAL.CARD)}</DataRowTitle>
      <DataRowValue>
        <OptionallyVisible visible={!isLoading}>
          <Select
            fullWidth
            popperProps={{
              disablePortal: true,
            }}
            items={cardItems}
            closeDropdownOnSelect
            getItemLabel={(card) => card.label || card.last4}
            value={selectedCard}
            renderSelectedItem={renderSelectedCardItem}
            renderListItem={renderCardListItem}
            onChange={onCardChange}
          />
        </OptionallyVisible>
        <OptionallyVisible visible={isLoading}>
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        </OptionallyVisible>
      </DataRowValue>
    </DataRow>
  );
};
