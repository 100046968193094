import styled from '@emotion/styled';

import { Root as ButtonRoot } from '../buttons/styles';
import { Button } from '../inputs/select/styles';

export const PaginationGrid = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}));

export const PaginationWrapper = styled.div(() => ({
  height: 26,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  margin: '4px 8px',
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
  },
}));

export const RowsPerPageWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 15,
  height: 26,
  // @ts-ignore
  [Button]: {
    minWidth: 80,
  },
}));

export const Text = styled.span((props) => ({
  color: props.theme.colors.disabled,
  fontSize: 12,
  lineHeight: '14.5px',
  fontWeight: 500,
}));

export const PaginationButtonContent = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 7,
}));

export const RotatedIcon = styled.div(() => ({
  display: 'flex',
  transform: 'rotate(180deg)',
}));
