import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from './actionMenu';

interface NavigateMenuItemProps {
  children: JSX.Element | string;
  to: string;
}

export const NavigateMenuItem = ({ children, to }: NavigateMenuItemProps) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(to);
  };

  return <MenuItem onClick={handleNavigate}>{children}</MenuItem>;
};
