import { ReactNode } from 'react';

import { DataTableRow } from 'components/dataTable';

import { Order } from 'types/order';

export type OrderDataRow = DataTableRow & Order;

export enum StatusType {
  Success = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
}

export interface InfoFormField {
  id: string;
  label: string;
  hidden?: boolean;
  content: ReactNode | string | null;
}
