import styled from '@emotion/styled';

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  height: '100vh',
  width: '100vw',
}));

export const ErrorText = styled.h5((props) => ({
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.primary,
  fontSize: 20,
}));
