import styled from '@emotion/styled';

export const Container = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 5,
}));

export const Label = styled.h6((props) => ({
  margin: 0,
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.primary,
}));

export const ColumnContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flex: 1,
  alignItems: 'stretch',
  gap: 16,
}));

export const TerminationReasonRow = styled.div(() => ({
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'stretch',
}));
