import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { CustomerKYCStatus, CustomerStatus } from 'types/customer';

import { KYCStatusBadge } from '../../../components/kycStatusBadge/kycStatusBadge';
import { StatusBadge } from '../../../components/statusBadge/statusBadge';
import { LABELS } from '../../keys';
import { Delimiter, Item, ItemName, ItemValue, StatusBarContainer } from './styles';

interface StatusBarProps {
  userId: string;
  kycStatus: CustomerKYCStatus;
  customerStatus: CustomerStatus;
}

export const StatusBar = ({ userId, kycStatus, customerStatus }: StatusBarProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <StatusBarContainer>
        <Item>
          <ItemName>{t(LABELS.STATUS_BAR.USER_ID)}:</ItemName>
          <ItemValue>{userId}</ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.STATUS_BAR.KYC_STATUS)}:</ItemName>
          <ItemValue>
            <KYCStatusBadge status={kycStatus} />
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.STATUS_BAR.STATUS)}:</ItemName>
          <ItemValue>
            <StatusBadge status={customerStatus} />
          </ItemValue>
        </Item>
      </StatusBarContainer>
    </Panel>
  );
};
