import { OptionallyVisible } from 'components';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { AccessSectionContainer } from './sections/access/container';
import { AccountsSectionContainer } from './sections/accounts/container';
import { DeleteProfileSection } from './sections/deleteProfile/deleteProfile';
import { UserInfoSectionContainer } from './sections/userInfo/container';

interface ProfilePageProps extends WithTranslation {
  showAccessSection: boolean;
  showAccountsSection: boolean;
  showDeleteProfileSection: boolean;
}

export const ProfilePage = ({ showAccessSection, showAccountsSection, showDeleteProfileSection }: ProfilePageProps) => {
  return (
    <>
      <UserInfoSectionContainer />

      <OptionallyVisible visible={showAccessSection}>
        <AccessSectionContainer />
      </OptionallyVisible>

      <OptionallyVisible visible={showAccountsSection}>
        <AccountsSectionContainer />
      </OptionallyVisible>

      <OptionallyVisible visible={showDeleteProfileSection}>
        <DeleteProfileSection />
      </OptionallyVisible>
    </>
  );
};

export const Profile = withTranslation()(ProfilePage);
