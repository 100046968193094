import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';
import { Path } from 'components/icons/styles';

export const TablePlaceholderContainer = styled.div(() => ({
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
}));

export const TablePlaceholderText = styled.span((props) => ({
  fontSize: 13,
  color: props.theme.colors.text.primary,
  fontWeight: 400,
  textAlign: 'center',
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const ModalRoot = styled.div((props) => ({
  outline: 'none',
}));

export const Content = styled.div(() => ({
  display: 'flex',
  paddingBottom: 15,
  flexDirection: 'column',
  minWidth: 420,
  gap: 20,
}));

export const Section = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '0 10px',
}));

export const SectionTitle = styled.span((props) => ({
  fontSize: 13,
  fontColor: props.theme.colors.text.primary,
  fontWeight: 400,
}));

export const SectionContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '0 20px',
}));

export const CopyPanel = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 12px',
  gap: 8,
  borderRadius: 5,
  maxWidth: 356,
  backgroundColor: props.theme.colors.container.primary,
}));

export const CopyPanelText = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  fontFamily: props.theme.fonts.primary,
}));

export const CopyButton = styled(Button)((props) => ({
  minWidth: 0,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.accent,
  },
}));

export const Error = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  maxWidth: 400,
  color: props.theme.colors.error.main,
  textAlign: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  width: '100%',
}));

export const RemoveDomainButton = styled(Button)((props) => ({
  minWidth: 0,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.primary,
  },
}));

export const ActionButtons = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));
