import styled from '@emotion/styled';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: props.theme.spacing(3),
}));

export const SubContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: props.theme.spacing(2),
}));
