import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { OrderStatus } from 'types/pwcOrder';

export const KEYS = {
  FILTER_KEY: 'pwcOrders',
  ITEMS_PER_PAGE_OPTIONS: [15, 20, 50, 100],
  SEARCH_DEBOUNCE_TIMEOUT: 700,
  DATE_FORMAT: 'MMM DD, HH:mm A',
  DATE_FORMAT_WITH_YEAR: 'MMM DD, YYYY, HH:mm A',
  CRYPTO_SYMBOL_SEPARATOR: '.',
  CRYPTO_NETWORK_SPACING_SYMBOLS_EXP: /[_]/gi,
  CRYPTO_AMOUNT_PLACEHOLDER: '-',
};

export const filterStatusGroups = {
  completed: [OrderStatus.Completed],
  initiated: [OrderStatus.Initiated],
  awaitingDeposit: [OrderStatus.AwaitingDeposit],
  canceled: [OrderStatus.Canceled],
  expired: [OrderStatus.Expired],
};

export const LABELS = {
  BREADCRUMBS: [['page.pwcOrders.breadcrumbs', 'Charges']],
  PAGE_TITLE: ['page.pwcOrders.list.title', 'Order list'],
  SEARCH_PLACEHOLDER_S4USER: [
    'page.pwcOrders.list.search.placeholderS4User',
    'Search by Order ID, Ext Order ID, User or Account ID, User Email',
  ],
  SEARCH_PLACEHOLDER_PARTNER: ['page.pwcOrders.list.search.placeholderPartner', 'Search by Order ID, Ext Order ID, User Email'],
  CSV_DOWNLOAD_ERROR: ['page.pwcOrders.list.csv.downloadError', 'Something went wrong, please try again later'],
  CSV_FILE_NAME: ['page.pwcOrders.list.csv.fileName', 'Orders export {{date}}.csv'],
  FILTERS: {
    STATUS: {
      LABEL: ['page.pwcOrders.list.filter.status.label', 'Status'],
      COMPLETED: ['page.pwcOrders.list.filter.status.completed', 'Completed'],
      INITIATED: ['page.pwcOrders.list.filter.status.initiated', 'Initiated'],
      AWAITING_DEPOSIT: ['page.pwcOrders.list.filter.status.awaitingDeposit', 'Awaiting Deposit'],
      CANCELED: ['page.pwcOrders.list.filter.status.canceled', 'Canceled'],
      EXPIRED: ['page.pwcOrders.list.filter.status.expired', 'Expired'],
    },
    DATE: {
      LABEL: ['page.pwcOrders.list.filter.date.label', 'Date'],
    },
    FIAT_CURRENCY: {
      LABEL: ['page.pwcOrders.list.filter.fiatCurrency.label', 'Fiat Currency'],
    },
    FIAT_AMOUNT: {
      LABEL: ['page.pwcOrders.list.filter.fiatAmount.label', 'Fiat Amount'],
    },
    CRYPTO_CURRENCY: {
      LABEL: ['page.pwcOrders.list.filter.cryptoCurrency.label', 'Cryptocurrency'],
    },
    CRYPTO_AMOUNT: {
      LABEL: ['page.pwcOrders.list.filter.cryptoAmount.label', 'Crypto Amount'],
    },
    MERCHANT: {
      LABEL: ['page.pwcOrders.list.filter.merchant.label', 'Merchant Account'],
      PLACEHOLDER: ['page.pwcOrders.list.filter.merchant.placeholder', 'Select one'],
    },
    EMAIL: {
      LABEL: ['page.pwcOrders.list.filter.email.label', 'Email'],
      PLACEHOLDER: ['page.pwcOrders.list.filter.email.placeholder', 'Enter email'],
    },
  },
  TABLE_COLUMNS: {
    STATUS: ['page.pwcOrders.list.table.columns.status', 'Status'],
    ID: ['page.pwcOrders.list.table.columns.id', 'Order ID'],
    DATE: ['page.pwcOrders.list.table.columns.date', 'Date'],
    USER: ['page.pwcOrders.list.table.columns.user', 'User'],
    FIAT_AMOUNT: ['page.pwcOrders.list.table.columns.fiatAmount', 'Fiat Amount'],
    CRYPTO_AMOUNT: ['page.pwcOrders.list.table.columns.cryptoAmount', 'Crypto Amount'],
    ACTIONS: ['page.pwcOrders.list.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  STATUS: 'status',
  ID: 'uuid',
  DATE: 'createdAt',
  USER: 'customerEmail',
  FIAT_AMOUNT: 'fiatAmount',
  FIAT_CURRENCY: 'fiatCurrencyCode',
  CRYPTO_AMOUNT: 'cryptoAmount',
  CRYPTO_CURRENCY: 'cryptoCurrencyCode',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS) },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID), sortable: true },
  { id: COLUMN_IDS.DATE, label: t(LABELS.TABLE_COLUMNS.DATE), sortable: true },
  { id: COLUMN_IDS.USER, label: t(LABELS.TABLE_COLUMNS.USER) },
  { id: COLUMN_IDS.CRYPTO_AMOUNT, label: t(LABELS.TABLE_COLUMNS.CRYPTO_AMOUNT), sortable: true },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
