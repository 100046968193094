import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import { OrderWithdrawalStatusBadge } from '../../../components/orderStatusBadge/orderWithdrawalStatusBadge';
import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';
import { getIsMemoOrTagSet, getWalletMemoOrTag } from '../utils';

export const WithdrawalSection = ({ orderData }: OrderSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.WITHDRAWAL.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.WITHDRAWAL.WALLET_ADDRESS)}</ItemName>
          <ItemValue>{orderData.order.walletAddress}</ItemValue>
        </Item>

        <OptionallyVisible visible={getIsMemoOrTagSet(orderData)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.WITHDRAWAL.WALLET_MEMO_TAG)}</ItemName>
            <ItemValue>{getWalletMemoOrTag(orderData)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.WITHDRAWAL.WITHDRAWAL_STATUS)}</ItemName>
          <ItemValue>
            <OrderWithdrawalStatusBadge status={orderData.order.onChainStatus} />
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.WITHDRAWAL.TRANSACTION_HASH)}</ItemName>
          <OnChainTransactionHash displayFullHash>
            <a href={orderData.order.onChainExplorerURL} target="_blank">
              {orderData.order.onChainTransactionHash}
            </a>
          </OnChainTransactionHash>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
