import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu';
import IconDots from 'components/icons/dots';

import { UserRoleType } from 'types/user';

import { COLUMN_IDS, LABELS } from '../keys';
import { ActionsCell, Menu, MenuItem, MenuTitle } from './usersTableCell.styles';

const ActionsTableCell = ({ row, column, passedProps }: DataTableCellProps) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    passedProps?.editUser(row[COLUMN_IDS.ID]);
  };

  const handleRevoke = () => {
    passedProps?.revokeAccess(row[COLUMN_IDS.ID]);
  };

  const canEditUser: boolean = (row.roles as UserRoleType[]).every((role) => passedProps?.availableRoles?.includes(role));

  return (
    <TableCell>
      <ActionsCell>
        <DropdownMenu
          buttonProps={{
            flat: true,
          }}
          popperProps={{
            placement: 'bottom-end',
          }}
          buttonLabel={<IconDots />}
          renderMenu={() => (
            <Menu>
              <OptionallyVisible visible={canEditUser}>
                <MenuTitle>{t(LABELS.ACTIONS.TITLE)}</MenuTitle>
                <MenuItem onClick={handleEdit}>{t(LABELS.ACTIONS.EDIT)}</MenuItem>
                <MenuItem onClick={handleRevoke}>{t(LABELS.ACTIONS.REVOKE_ACCESS)}</MenuItem>
              </OptionallyVisible>
              <OptionallyVisible visible={!canEditUser}>
                <MenuTitle>{t(LABELS.ACTIONS.NO_ACTIONS_AVAILABLE)}</MenuTitle>
              </OptionallyVisible>
            </Menu>
          )}
        />
      </ActionsCell>
    </TableCell>
  );
};

interface makeUsersTableCellParams {
  editUser: (uuid: string) => void;
  revokeAccess: (uuid: string) => void;
  availableRoles: UserRoleType[];
}

export const makeUsersTableCell =
  ({ editUser, revokeAccess, availableRoles }: makeUsersTableCellParams) =>
  (props: DataTableCellProps) => {
    const { row, column } = props;
    if (column.id === COLUMN_IDS.ACTIONS) {
      return <ActionsTableCell {...props} passedProps={{ editUser, revokeAccess, availableRoles }} />;
    }

    return <TableCell>{row[column.id]}</TableCell>;
  };
