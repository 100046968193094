import styled from '@emotion/styled/macro';

import { Root as ButtonRoot } from 'components/buttons/styles';
import { Path } from 'components/icons/styles';
import { InputRoot } from 'components/inputs/input/styles';

export const ButtonContent = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 5,
  marginLeft: -4,
}));

export const ButtonLabel = styled.span((props) => ({
  color: 'inherit',
}));

export const ModalRoot = styled.div((props) => ({
  outline: 'none',
}));

export const ModalControls = styled.div((props) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const PanelContent = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  padding: '0 12px 12px 12px',
}));

export const EmailSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const Label = styled.span((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const PermissionSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 16,
}));

export const PermissionItem = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 10,
}));

export const ItemDesctription = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));

export const ItemName = styled.div((props) => ({
  color: props.theme.colors.primary,
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 500,
  fontFamily: props.theme.fonts.primary,
}));

export const ItemCaption = styled.div((props) => ({
  color: props.theme.colors.inactive,
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Delimeter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
}));

export const Buttons = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));
