import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserRole } from 'state/slices/userSlice';

import { UserRoleType } from 'types/user';

import { AccessSection } from './access';
import { transformRoleDataToTableFormat } from './utils';

export const AccessSectionContainer = () => {
  const role = useSelector(selectUserRole) as UserRoleType;
  const tableData = transformRoleDataToTableFormat(role);

  return <AccessSection tableData={tableData} />;
};
