import { ColumnDecoration, DataTableColumn } from 'components/dataTable';

import i18next from 'locales/i18next';

import { UserRoleType } from 'types/user';

const { t } = i18next;

export const LABELS = {
  PANEL_TITLE: ['page.profile.access.title', 'Access'],
  NAME_LABEL: ['page.profile.access.nameLabel', 'Shift4'],
  ROLES: {
    SUPER_ADMIN: ['page.profile.access.superAdmin', 'Super Admin'],
    ADMIN: ['page.profile.access.admin', 'Admin'],
    SUPPORT: ['page.profile.access.support', 'Support'],
  },
  TABLE_COLUMNS: {
    NAME: ['page.profile.access.table.columns.name', 'Name'],
    ROLES: ['page.profile.access.table.columns.roles', 'Roles'],
  },
};

export const COLUMN_IDS = {
  NAME: 'name',
  ROLES: 'roles',
};

export const COLUMNS: DataTableColumn[] = [
  {
    id: COLUMN_IDS.NAME,
    label: t(LABELS.TABLE_COLUMNS.NAME),
    decoration: ColumnDecoration.bold,
  },
  { id: COLUMN_IDS.ROLES, label: t(LABELS.TABLE_COLUMNS.ROLES) },
];

export const s4cUserRoleToLabelMap: Partial<Record<UserRoleType, string>> = {
  [UserRoleType.SuperAdmin]: t(LABELS.ROLES.SUPER_ADMIN),
  [UserRoleType.Admin]: t(LABELS.ROLES.ADMIN),
  [UserRoleType.Support]: t(LABELS.ROLES.SUPPORT),
};
