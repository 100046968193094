import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantStatus } from 'services/merchants/types';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconCross from 'components/icons/cross';
import IconDash from 'components/icons/dash';

import { LABELS } from '../../keys';
import { BadgeContent } from './partnerStatusCell.styles';

const statusMap = {
  [MerchantStatus.Active]: {
    type: BadgeType.Success,
    icon: <IconChecked />,
    label: LABELS.BADGES.ACTIVE,
  },
  [MerchantStatus.Inactive]: {
    type: BadgeType.Default,
    icon: <IconDash />,
    label: LABELS.BADGES.INACTIVE,
  },
  [MerchantStatus.Deactivated]: {
    type: BadgeType.Fail,
    icon: <IconCross />,
    label: LABELS.BADGES.DEACTIVATED,
  },
};

export const PartnerStatusCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const { t } = useTranslation();
  const columnName = column.id;
  const cellValue = row[columnName] as MerchantStatus;

  const { type, icon, label } = statusMap[cellValue];

  return (
    <TableCell>
      <Badge type={type}>
        <BadgeContent>
          {icon}
          {t(label)}
        </BadgeContent>
      </Badge>
    </TableCell>
  );
};
