import styled from '@emotion/styled/macro';

import { Title as ModalContentTitle } from 'components/modal/styles';

export const CryptoIcon = styled.img((props) => ({
  width: 20,
  height: 20,
  margin: 0,
  padding: 0,
}));

export const DataRow = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: 8,
}));

export const DataRowTitle = styled.span((props) => ({
  color: props.theme.colors.primary,
  fontSize: 13,
}));

export const DataRowValue = styled.div((props) => ({
  color: props.theme.colors.primary,
  fontSize: 13,
  fontWeight: 600,
}));

export const CryptoLabel = styled.div(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const CryptoName = styled.span((props) => ({
  color: props.theme.colors.primary,
}));

export const CryptoNetwork = styled.span((props) => ({
  color: props.theme.colors.inactive,
  fontWeight: 400,
}));

export const Adornment = styled.div(() => ({
  marginRight: 8,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
}));

export const ModalContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: 340,
}));

export const Title = styled(ModalContentTitle)(() => ({
  textAlign: 'center',
  width: '100%',
}));

export const Error = styled.span((props) => ({
  color: props.theme.colors.error.main,
  fontSize: 13,
}));

export const Delimeter = styled.div((props) => ({
  width: '100%',
  height: 1,
  borderBottom: `0.5px solid ${props.theme.colors.container.tertiary}`,
}));

export const BreakdownContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '0 12px 12px 12px',
}));

export const BreakdownHeader = styled.h4((props) => ({
  margin: 0,
  padding: '8px 0',
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.text.primary,
}));

export const BreakdownTable = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const BreakdownRow = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: '[title-start] 65% [title-end] 10px [value-start] auto [value-end]',
  gridRowTemplate: '16',
}));

export const BreakdownRowTitle = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.text.primary,
  gridColumn: 'title-start / title-end',
  gridRow: 0,
}));

export const BreakdownRowSymbol = styled.span(() => ({
  gridColumn: 'title-end / value-start',
  gridRow: 0,
}));

export const BreakdownRowValue = styled.span(() => ({
  gridColumn: 'value-start / value-end',
  gridRow: 0,
  textAlign: 'right',
}));

export const CardListItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

export const CardImage = styled.img(() => ({}));

export const CardTitle = styled.span(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

export const AssetListItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

export const AssetImage = styled.img(() => ({
  maxHeight: 16,
  maxWidth: 16,
  height: 16,
  width: 16,
}));

export const AssetTitleContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));

export const AssetTitle = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  lineHeight: '16px',
}));

export const AssetSubtitle = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  lineHeight: '16px',
}));

export const PreviewAssetTitle = styled(AssetTitle)((props) => ({
  color: props.theme.colors.inactive,
}));

export const PreviewCardTitle = styled(CardTitle)((props) => ({
  color: props.theme.colors.inactive,
}));

export const LoadingWrapper = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
}));

export const TransactionDetailsTitle = styled.span(() => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  height: 16,
  paddingRight: 10,
  overflow: 'visible',
}));
