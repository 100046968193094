import { useEffect, useState } from 'react';

import { KEYS } from './keys';

export const useBackgroundAuthCheck = (checkToken: () => void) => {
  const [isVisible, setVisible] = useState<boolean>(!document.hidden);
  const visibilityHandler = () => {
    setVisible(!document.hidden);
  };

  const subscribe = () => {
    document.addEventListener(KEYS.VISIBILITY_CHANGE_EVENT, visibilityHandler);
  };

  const unsubscribe = () => {
    document.removeEventListener(KEYS.VISIBILITY_CHANGE_EVENT, visibilityHandler);
  };

  useEffect(() => {
    subscribe();
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isVisible) {
      checkToken();
      const interval = setInterval(checkToken, KEYS.CHECK_AUTH_INTERVAL);
      return () => clearInterval(interval);
    }

    return undefined;
  }, [isVisible]);
};
