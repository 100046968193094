import { jwtDecode } from 'jwt-decode';
import accountsApi from 'services/accounts';

import { UserAccessTokenInfo } from 'types/user';

import processUserTokenInfo from 'utils/user';

import { setUserData, setUserLevel, setAuthToken as userSliceSetAuthToken } from '../../slices/userSlice';
import { AppDispatch } from '../../store';
import { processUserInfoArgs } from './types';

export const getUserDataFromToken = (accessToken: string, auth0User: processUserInfoArgs['auth0User']) => {
  const decodedAccessTokenInfo = jwtDecode<UserAccessTokenInfo>(accessToken);

  return {
    ...auth0User,
    ...decodedAccessTokenInfo,
  };
};

export const fetchSelectedAccount = async (dispatch: AppDispatch) => {
  const result = await dispatch(accountsApi.endpoints?.getAccountsList.initiate({}));
  return result.data?.data?.[0];
};

export const updateAuthToken = (accessToken: string, dispatch: AppDispatch) => {
  dispatch(userSliceSetAuthToken(accessToken));
};

export const getAndSetUserLevelAndData = async (
  accessToken: string,
  auth0User: processUserInfoArgs['auth0User'],
  dispatch: AppDispatch,
) => {
  const userTokenInfo = getUserDataFromToken(accessToken, auth0User);
  const { userData, userLevel } = processUserTokenInfo(userTokenInfo);

  dispatch(setUserLevel(userLevel));
  dispatch(setUserData(userData));
};
