export const KEYS = {
  DATE_FORMAT: 'MM/DD/YYYY',
  DATE_SEPARATOR: ' - ',
  STATUS_SEPARATOR: ', ',
};
export const LABELS = {
  IS_BETWEEN: ['components.filters.badge.variant.isBetween', 'between {{gte}} and {{lte}}'],
  IS_EQUAL: ['components.filters.badge.variant.isEqual', 'equal to {{gte}}'],
  LESS_THAN: ['components.filters.badge.variant.lessThan', 'less than {{lte}}'],
  MORE_THAN: ['components.filters.badge.variant.moreThan', 'greater than {{gte}}'],
  DATE_BEFORE: ['components.filters.badge.variant.dateBefore', 'before {{date}}'],
  DATE_AFTER: ['components.filters.badge.variant.dateAfter', 'after {{date}}'],
};
