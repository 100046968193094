import { MerchantInfo } from './types';

const KEYS = {
  URL_DECORATOR: 'https://',
};

export const decorateUpdateRequest = (requestBody: Partial<MerchantInfo>) => {
  const { uuid, ...body } = requestBody;

  const result = {
    ...body,
  };

  if (body.webhookUrl) {
    result.webhookUrl = [KEYS.URL_DECORATOR, body.webhookUrl].join('');
  }

  return result;
};

export const undecorateMerchantData = (merchant: MerchantInfo): MerchantInfo => ({
  ...merchant,
  webhookUrl: (merchant.webhookUrl || '').replace(KEYS.URL_DECORATOR, ''),
});
