import { t } from 'i18next';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import {
  AmountFilterPassedProps,
  CheckboxFilterPassedProps,
  CurrencyFilterPassedProps,
  DateFilterPassedProps,
  SelectFilterPassedProps,
} from 'components/filters/types';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';
import { SelectItem } from 'components/inputs/select/select';

import { Filter, FilterSection, FilterType } from 'types/filters';

import { LABELS, filterStatusGroups } from './keys';
import { Filters } from './types';

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>, isAdmin: boolean): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.statuses, filterMap.date],
    },
    {
      key: 'currencyFilters',
      content: [filterMap.cryptoCurrencyCode, filterMap.cryptoAmount, filterMap.fiatCurrencyCode, filterMap.fiatAmount],
    },
    {
      key: 'partnerFilters',
      content: isAdmin ? [filterMap.merchantUuids, filterMap.customerEmail] : [filterMap.customerEmail],
    },
  ];
};

export const makeFilters = ({
  cryptoOptions,
  fiatOptions,
  merchantAccounts,
  emails,
}: {
  cryptoOptions: CurrencyItem[];
  fiatOptions: CurrencyItem[];
  merchantAccounts: SelectItem[];
  emails: SelectItem[];
}): Record<string, Filter<unknown>> => {
  const statusFilter: Filter<CheckboxFilterPassedProps> = {
    key: 'statuses',
    label: t(LABELS.FILTERS.STATUS.LABEL),
    type: FilterType.Checkbox,
    options: [
      {
        key: 'completed',
        value: filterStatusGroups.completed,
        label: t(LABELS.FILTERS.STATUS.COMPLETED),
      },
      {
        key: 'initiated',
        value: filterStatusGroups.initiated,
        label: t(LABELS.FILTERS.STATUS.INITIATED),
      },
      {
        key: 'awaitingDeposit',
        value: filterStatusGroups.awaitingDeposit,
        label: t(LABELS.FILTERS.STATUS.AWAITING_DEPOSIT),
      },
      {
        key: 'canceled',
        value: filterStatusGroups.canceled,
        label: t(LABELS.FILTERS.STATUS.CANCELED),
      },
      {
        key: 'expired',
        value: filterStatusGroups.expired,
        label: t(LABELS.FILTERS.STATUS.EXPIRED),
      },
    ],
  };

  const datePresets = getDefaultDatePresets();

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: datePresets,
  };

  const cryptoCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'cryptoCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.CRYPTO_CURRENCY.LABEL),
    options: cryptoOptions,
    multiple: false,
  };

  const cryptoCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'cryptoAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.CRYPTO_AMOUNT.LABEL),
  };

  const fiatCurrencyFilter: Filter<CurrencyFilterPassedProps> = {
    key: 'fiatCurrencyCode',
    type: FilterType.CurrencySelect,
    label: t(LABELS.FILTERS.FIAT_CURRENCY.LABEL),
    options: fiatOptions,
    multiple: false,
  };

  const fiatCurrencyAmountFilter: Filter<AmountFilterPassedProps> = {
    key: 'fiatAmount',
    type: FilterType.Amount,
    label: t(LABELS.FILTERS.FIAT_AMOUNT.LABEL),
  };

  const accountFilter: Filter<SelectFilterPassedProps> = {
    key: 'merchantUuids',
    label: t(LABELS.FILTERS.MERCHANT.LABEL),
    type: FilterType.Select,
    placeholder: t(LABELS.FILTERS.MERCHANT.PLACEHOLDER),
    multiple: false,
    options: merchantAccounts,
  };

  const emailFilter: Filter<SelectFilterPassedProps> = {
    key: 'customerEmail',
    label: t(LABELS.FILTERS.EMAIL.LABEL),
    type: FilterType.Select,
    placeholder: t(LABELS.FILTERS.EMAIL.PLACEHOLDER),
    multiple: false,
    options: emails,
  };

  return [
    statusFilter,
    dateFilter,
    cryptoCurrencyFilter,
    cryptoCurrencyAmountFilter,
    fiatCurrencyFilter,
    fiatCurrencyAmountFilter,
    accountFilter,
    emailFilter,
  ].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters) => {
  const output = {
    ...filters,
  };

  if (filters.merchantUuids) {
    output.merchantUuids = Array.isArray(filters.merchantUuids) ? filters.merchantUuids : [filters.merchantUuids];
  }

  return output;
};
