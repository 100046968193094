import { BadgeType } from 'components/elements/badge/types';

import { OrderStatus, OrderTradeStatus, OrderWithdrawalStatus } from 'types/order';

export const LABELS = {
  STATUS: {
    INITIATED: ['page.orders.components.status.initiated', 'Initiated'],
    COMPLETED: ['page.orders.components.status.completed', 'Completed'],
    FAILED: ['page.orders.components.status.failed', 'Failed'],
    PROCESSING: ['page.orders.components.status.processing', 'Processing'],
    PENDING: ['page.orders.components.status.pending', 'Pending'],
    SETTLED: ['page.orders.components.status.settled', 'Settled'],
  },
};

export const orderStatusLabelByStatus: Record<OrderStatus, string[]> = {
  [OrderStatus.Initiated]: LABELS.STATUS.INITIATED,
  [OrderStatus.AuthorizationChargeFailed]: LABELS.STATUS.FAILED,
  [OrderStatus.GetQuoteToExecuteFailed]: LABELS.STATUS.FAILED,
  [OrderStatus.ExecuteQuoteFailed]: LABELS.STATUS.FAILED,
  [OrderStatus.CaptureChargeFailed]: LABELS.STATUS.FAILED,
  [OrderStatus.Completed]: LABELS.STATUS.COMPLETED,
  [OrderStatus.SystemError]: LABELS.STATUS.FAILED,
  [OrderStatus.Processing]: LABELS.STATUS.PROCESSING,
  [OrderStatus.ExchangeFailed]: LABELS.STATUS.FAILED,
  [OrderStatus.AwaitingDeposit]: LABELS.STATUS.PROCESSING,
  [OrderStatus.AwaitingClientConfirmation]: LABELS.STATUS.PROCESSING,
  [OrderStatus.PaymentFailed]: LABELS.STATUS.FAILED,
};

export const orderWithdrawalStatusLabelByStatus: Record<OrderWithdrawalStatus, string[]> = {
  [OrderWithdrawalStatus.Pending]: LABELS.STATUS.PROCESSING,
  [OrderWithdrawalStatus.Confirmed]: LABELS.STATUS.COMPLETED,
};

export const orderTradeStatusLabelByStatus: Record<OrderTradeStatus, string[]> = {
  [OrderTradeStatus.PendingTrade]: LABELS.STATUS.PENDING,
  [OrderTradeStatus.Pending]: LABELS.STATUS.PENDING,
  [OrderTradeStatus.Approved]: LABELS.STATUS.SETTLED,
  [OrderTradeStatus.Rejected]: LABELS.STATUS.FAILED,
  [OrderTradeStatus.Settled]: LABELS.STATUS.SETTLED,
};

export const orderStatusToBadgeTypeMap: Record<OrderStatus, BadgeType> = {
  [OrderStatus.Initiated]: BadgeType.Default,
  [OrderStatus.AuthorizationChargeFailed]: BadgeType.Fail,
  [OrderStatus.GetQuoteToExecuteFailed]: BadgeType.Fail,
  [OrderStatus.ExecuteQuoteFailed]: BadgeType.Fail,
  [OrderStatus.CaptureChargeFailed]: BadgeType.Fail,
  [OrderStatus.Completed]: BadgeType.Success,
  [OrderStatus.SystemError]: BadgeType.Fail,
  [OrderStatus.Processing]: BadgeType.Default,
  [OrderStatus.ExchangeFailed]: BadgeType.Fail,
  [OrderStatus.AwaitingDeposit]: BadgeType.Default,
  [OrderStatus.AwaitingClientConfirmation]: BadgeType.Default,
  [OrderStatus.PaymentFailed]: BadgeType.Fail,
};

export const orderWithdrawalStatusToBadgeTypeMap: Record<OrderWithdrawalStatus, BadgeType> = {
  [OrderWithdrawalStatus.Pending]: BadgeType.Default,
  [OrderWithdrawalStatus.Confirmed]: BadgeType.Success,
};

export const tradeStatusToBadgeTypeMap: Record<OrderTradeStatus, BadgeType> = {
  [OrderTradeStatus.PendingTrade]: BadgeType.Default,
  [OrderTradeStatus.Pending]: BadgeType.Default,
  [OrderTradeStatus.Approved]: BadgeType.Default,
  [OrderTradeStatus.Rejected]: BadgeType.Fail,
  [OrderTradeStatus.Settled]: BadgeType.Default,
};
