const MESSAGES_NAMESPACE = 'receipt';
export const LABELS = {
  BREADCRUMBS: [
    ['page.orders.view.breadcrumbs.orders', 'Orders'],
    ['page.orders.view.breadcrumbs.orderReceipt', 'Order receipt'],
  ],
  GET_PDF: ['page.orders.receipt.buttons.getPdf', 'Download PDF'],
  PAGE_TITLE: ['page.orders.receipt.title', 'Receipt'],
  DETAILS_TITLE: `${MESSAGES_NAMESPACE}.detailsTitle`,
  TOTAL: `${MESSAGES_NAMESPACE}.total`,
  CRYPTO: `${MESSAGES_NAMESPACE}.crypto`,
  PROCESSING_FEE: `${MESSAGES_NAMESPACE}.processingFee`,
  NETWORK_FEE: `${MESSAGES_NAMESPACE}.networkFee`,
  ACCOUNT_ID: `${MESSAGES_NAMESPACE}.accountId`,
  RECEIPT_DATE_TITLE: `${MESSAGES_NAMESPACE}.receiptDateTitle`,
  PRICE: `${MESSAGES_NAMESPACE}.price`,
  RECEIVED: `${MESSAGES_NAMESPACE}.received`,
  RECEIPT_ID_TITLE: `${MESSAGES_NAMESPACE}.receiptIdTitle`,
  PAID: `${MESSAGES_NAMESPACE}.paid`,
  QUOTE: `${MESSAGES_NAMESPACE}.quote`,
  PAYMENT_METHOD: `${MESSAGES_NAMESPACE}.paymentMethod`,
  WALLET: `${MESSAGES_NAMESPACE}.wallet`,
  TRANSACTION_ID: `${MESSAGES_NAMESPACE}.transactionId`,
};

export const KEYS = {
  TAG_OR_MEMO_SPLIT_STRING: '?tag=',
};

export default LABELS;
