import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { FilterPreset } from 'types/filters';

import { asArray } from 'utils/string';

import { Checkbox } from '../../inputs/checkbox/checkbox';
import { Container, Label, Preset } from '../styles';
import { CheckboxFilterPassedProps } from '../types';

interface CheckboxFilterProps extends Omit<CheckboxFilterPassedProps, 'type'> {
  value: string[];
  onChange: (value: string[]) => void;
}

export const CheckboxFilter = ({ value, options, onChange }: CheckboxFilterProps) => {
  const [selected, setSelected] = useState<string[]>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelectPreset = (preset: FilterPreset<string | string[]>) => (value: boolean) => {
    const nextValue: string[] = value
      ? [...selected, ...asArray(preset.value)]
      : selected.filter((item) => !asArray(preset.value).includes(item));

    setSelected(nextValue);
    onChange(nextValue);
  };

  const isPresetSelected = (preset: FilterPreset<string | string[]>): boolean => {
    return !isEmpty(intersection(selected, asArray(preset.value)));
  };

  if (options) {
    return (
      <Container>
        {options.map((option) => (
          <Preset key={option.key}>
            <Checkbox value={isPresetSelected(option)} onChange={handleSelectPreset(option)} />
            <Label>{option.label}</Label>
          </Preset>
        ))}
      </Container>
    );
  }

  return null;
};
