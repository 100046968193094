import React from 'react';

import { Typography } from '../typography';
import { HomeIcon, StyledBreadcrumbs, StyledTypography } from './breadcrumbs.styles';
import { BreadcrumbsItem } from './types';

export interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <StyledBreadcrumbs aria-label="breadcrumb" separator={<Typography variant="body1">-</Typography>}>
      <HomeIcon />
      {items.map((item) => (
        <StyledTypography variant="body1" key={item}>
          {item}
        </StyledTypography>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
