import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delimeter } from 'components/elements/delimeter';

import { Button } from '../../buttons';
import { Panel } from '../../containers';
import IconCross from '../../icons/cross';
import IconDownload from '../../icons/download';
import Modal from '../../modal/modal';
import OptionallyVisible from '../../optionallyVisible';
import { FilterBadge } from '../badge/badge';
import { TableFiltersProps } from '../table/types';
import { LABELS } from './keys';
import {
  ButtonLabel,
  Buttons,
  CloseButton,
  EmptyFiltersMessage,
  Filters,
  FiltersContainer,
  FiltersWrapper,
  PanelTitle,
  Title,
} from './styles';

interface ExportButtonProps<T extends object> {
  onExport: () => void;
  isLoading: boolean;
  filterProps: TableFiltersProps<T>;
}

export const ExportButton = <T extends object>({ onExport, isLoading, filterProps }: ExportButtonProps<T>) => {
  const { filters, filterSettings, onFilterChange } = filterProps;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!isOpen);
  };

  const appliedFilters = Object.entries(filters).filter(([key, value]) => !isEmpty(value));
  const handleFilterClear = (filterKey: string) => () => {
    onFilterChange(omit(filters, [filterKey]));
  };

  return (
    <>
      <Button flat onClick={handleOpen}>
        <IconDownload />
        <ButtonLabel>{t(LABELS.EXPORT)}</ButtonLabel>
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <Panel
          label={<PanelTitle>{t(LABELS.MODAL_TITLE)}</PanelTitle>}
          controls={
            <CloseButton flat onClick={handleClose}>
              <IconCross />
            </CloseButton>
          }
        >
          <FiltersContainer>
            <Title>{t(LABELS.FILTERS_TITLE)}</Title>
            <OptionallyVisible visible={isEmpty(filters)}>
              <EmptyFiltersMessage>{t(LABELS.EMPTY_FILTERS)}</EmptyFiltersMessage>
            </OptionallyVisible>
            <FiltersWrapper>
              <Filters>
                {appliedFilters.map(([key, value]) => (
                  <FilterBadge key={key} filter={filterSettings[key]} value={value} onClear={handleFilterClear(key)} />
                ))}
              </Filters>
            </FiltersWrapper>
            <Title>{t(LABELS.DISCLAIMER)}</Title>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={handleClose}>
                {t(LABELS.CLOSE_BUTTON_TITLE)}
              </Button>
              <Button primary onClick={onExport} disabled={isLoading}>
                {t(LABELS.EXPORT_BUTTON_TITLE)}
              </Button>
            </Buttons>
          </FiltersContainer>
        </Panel>
      </Modal>
    </>
  );
};
