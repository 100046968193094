import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';

import { InputRoot } from './styles';

export enum InputType {
  Text = 'text',
  Number = 'number',
  Color = 'color',
  Password = 'password',
}

export interface InputProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: InputType;
  forwardRef?: HTMLInputElement | ((ref: HTMLInputElement) => void);
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  className?: string;
}

export const Input = ({
  onChange,
  value,
  disabled,
  placeholder,
  fullWidth,
  startAdornment,
  endAdornment,
  type = InputType.Text,
  forwardRef,
  onFocus,
  onBlur,
  className,
}: InputProps) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onChange?.(value);
  };

  return (
    <InputRoot
      onChange={handleInputChange}
      value={value}
      readOnly={disabled}
      placeholder={placeholder}
      fullWidth={fullWidth}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      type={type}
      ref={forwardRef}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
    />
  );
};
