import styled from '@emotion/styled/macro';

export const NothingFoundContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
}));

export const NothingFoundImage = styled.img((props) => ({}));

export const NothingFoundCaption = styled.span((props) => ({
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
  fontSize: 12,
  fontWeight: 500,
  textAlign: 'center',
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
}));
