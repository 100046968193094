import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';

import { LABELS } from '../keys';
import { TablePlaceholderContainer, TablePlaceholderText } from './styles';

interface SettlementTablePlaceholderProps {
  action: () => void;
}

export const SettlementTablePlaceholder = ({ action }: SettlementTablePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <TablePlaceholderContainer>
      <TablePlaceholderText>{t(LABELS.SETTLEMENT.PLACEHOLDER)}</TablePlaceholderText>
      <Button secondary onClick={action}>
        {t(LABELS.SETTLEMENT.ADD_METHOD)}
      </Button>
    </TablePlaceholderContainer>
  );
};
