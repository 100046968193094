import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { FormField } from 'components/form/formField/formField';
import IconCross from 'components/icons/cross';
import IconLoading from 'components/icons/loading';
import { Input } from 'components/inputs';
import LoadingIndicator from 'components/loadingIndicator';
import Modal from 'components/modal/modal';
import { Body, Buttons, CloseButton, PanelTitle } from 'components/modal/styles';
import OptionallyVisible from 'components/optionallyVisible';

import { Asset } from 'types/assets';
import { CustomerCard } from 'types/customer';

import { getCryptoLabel } from 'utils/currencies';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { KEYS, LABELS } from '../../keys';
import { OrderCreatePreview } from '../../types';
import { OrderBreakdown } from './breakdown';
import { CardSelectSection } from './cardSelectSection';
import CurrencySelectSection from './currencySelectSection';
import {
  Adornment,
  AssetImage,
  CryptoLabel,
  CryptoName,
  CryptoNetwork,
  DataRow,
  DataRowTitle,
  DataRowValue,
  Delimeter,
  Error,
  ModalContent,
  Title,
  TransactionDetailsTitle,
} from './styles';
import { AssetSelectItem, CardSelectItem } from './types';

interface OrderModalProps {
  fiatCurrencies: Asset[];
  getQuote: (query: { cryptoCurrencyCode: string; fiatCurrencyCode: string; amount: number }) => Promise<OrderCreatePreview | undefined>;
  isQuoteFetching: boolean;
  quoteError?: Record<string, string>;
  cards: CustomerCard[];
  areCardsFetching: boolean;
  getAsset: (symbol: string) => Asset | undefined;
  submitOrder: (quoteId: string, paymentToken: string) => Promise<any>;
  isOrderSubmitting: boolean;
  asset: Asset;
  amount: number;
  open: boolean;
  onClose: () => void;
}

export const OrderModal = ({
  asset,
  amount: defaultAmount,
  fiatCurrencies,
  cards,
  areCardsFetching,
  getAsset,
  submitOrder,
  isQuoteFetching,
  isOrderSubmitting,
  getQuote,
  quoteError,
  open,
  onClose,
}: OrderModalProps) => {
  const cardItems: CardSelectItem[] = cards.map((card) => ({
    ...card,
    key: card.id,
  }));

  const fiatCurrencyItems: AssetSelectItem[] = fiatCurrencies.map((fiat) => ({
    ...fiat,
    key: fiat.symbol,
    label: fiat.name,
  }));

  const [amount, setAmount] = useState<string>(defaultAmount.toString());
  const [selectedCard, setSelectedCard] = useState<CardSelectItem | undefined>(undefined);
  const [selectedCurrency, setSelectedCurrency] = useState<AssetSelectItem>(fiatCurrencyItems[0]);
  const [quote, setQuote] = useState<OrderCreatePreview | undefined>(undefined);
  const query = useDebouncedQuery(
    () => ({
      cryptoCurrencyCode: asset.symbol,
      fiatCurrencyCode: selectedCurrency.symbol,
      amount: Number(amount) || defaultAmount,
    }),
    [asset, selectedCurrency, amount],
  );
  const intervalRef = useRef<ReturnType<typeof setInterval> | number | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedCard) {
      const foundDefaultCard = cardItems.find((card) => card.isDefault);
      if (foundDefaultCard) {
        setSelectedCard(foundDefaultCard);
      }
    }
  }, [cardItems]);

  const handleAmountChange = (value: string) => {
    const isValueValid = KEYS.AMOUNT_EXP.test(value);
    if (!isValueValid) {
      return;
    }

    setAmount(value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleAmountInputBlur = () => {
    if (amount) {
      setAmount(Number(amount).toString());
    } else {
      setAmount(defaultAmount.toString());
    }
  };

  const handleSubmit = async () => {
    if (!quote || !selectedCard) {
      return;
    }

    const response = await submitOrder(quote.quoteId, selectedCard?.id);

    if (response.success) {
      handleClose();
    }
  };

  const updateQuote = async (query: { cryptoCurrencyCode: string; fiatCurrencyCode: string; amount: number }) => {
    if (!asset) {
      return null;
    }

    try {
      const quote = await getQuote(query);
      if (!quote) {
        return null;
      }

      setQuote(quote);
      return quote.quoteId;
    } catch (e: unknown) {
      setQuote(undefined);
      clearInterval(intervalRef.current);
    }

    return null;
  };

  const handleChangeCard = (item: CardSelectItem) => {
    setSelectedCard(item);
  };

  const handleChangeCurrency = (item: AssetSelectItem) => {
    setSelectedCurrency(item);
  };

  useEffect(() => {
    updateQuote(query);
    intervalRef.current = setInterval(updateQuote, KEYS.QUOTE_REFRESH_INTERVAL, query);
    return () => clearInterval(intervalRef.current);
  }, [query]);

  const canShowQuoteError = Boolean(!isQuoteFetching && !quote && quoteError);
  const isSubmitDisabled = Boolean(isQuoteFetching || quoteError);

  return (
    <Modal open={open} onClose={onClose}>
      <Panel
        label={<PanelTitle>{t(LABELS.CREATE_ORDER_MODAL.TITLE)}</PanelTitle>}
        controls={
          <CloseButton flat onClick={handleClose}>
            <IconCross />
          </CloseButton>
        }
      >
        <Body>
          <ModalContent>
            <Title>{t(LABELS.CREATE_ORDER_MODAL.HEADER)}</Title>
            <DataRow>
              <DataRowTitle>{t(LABELS.CREATE_ORDER_MODAL.CRYPTOCURRENCY)}</DataRowTitle>
              <DataRowValue>
                <CryptoLabel>
                  <AssetImage src={asset.iconUrl} />
                  <CryptoName>{asset.name}</CryptoName>
                  <CryptoNetwork>{getCryptoLabel(asset)}</CryptoNetwork>
                </CryptoLabel>
              </DataRowValue>
            </DataRow>
            <DataRow>
              <DataRowTitle>{t(LABELS.CREATE_ORDER_MODAL.AMOUNT)}</DataRowTitle>
              <DataRowValue>
                <FormField error={quoteError?.cryptoAmount}>
                  <Input
                    startAdornment={
                      <Adornment>
                        <AssetImage src={asset.iconUrl} />
                      </Adornment>
                    }
                    fullWidth
                    placeholder={t(LABELS.CREATE_ORDER_MODAL.AMOUNT_PLACEHOLDER)}
                    value={amount}
                    onBlur={handleAmountInputBlur}
                    onChange={handleAmountChange}
                  />
                </FormField>
              </DataRowValue>
            </DataRow>
            <CurrencySelectSection
              fiatCurrencyItems={fiatCurrencyItems}
              onCurrencyChange={handleChangeCurrency}
              selectedCurrency={selectedCurrency}
            />
            <Delimeter />
            <DataRow>
              <DataRowTitle>
                <TransactionDetailsTitle>
                  {t(LABELS.CREATE_ORDER_MODAL.TRANSACTION_DETAILS)}
                  <OptionallyVisible visible={isQuoteFetching}>
                    <LoadingIndicator size={0.7} />
                  </OptionallyVisible>
                </TransactionDetailsTitle>
              </DataRowTitle>
              <DataRowValue>
                <OrderBreakdown quote={quote} asset={asset} currency={selectedCurrency} />
              </DataRowValue>
            </DataRow>
            <OptionallyVisible visible={canShowQuoteError}>
              <Error>{quoteError?.general}</Error>
            </OptionallyVisible>
            <CardSelectSection
              cardItems={cardItems}
              selectedCard={selectedCard}
              onCardChange={handleChangeCard}
              isLoading={areCardsFetching}
            />
          </ModalContent>
          <Delimeter />
          <Buttons>
            <Button secondary onClick={handleClose}>
              {t(LABELS.REFUND_MODAL.CANCEL)}
            </Button>
            <Button primary onClick={handleSubmit} disabled={isSubmitDisabled}>
              <OptionallyVisible visible={!isOrderSubmitting}>{t(LABELS.CREATE_ORDER_MODAL.CONFIRM)}</OptionallyVisible>
              <OptionallyVisible visible={isOrderSubmitting}>
                <IconLoading scale={0.6} />
              </OptionallyVisible>
            </Button>
          </Buttons>
        </Body>
      </Panel>
    </Modal>
  );
};
