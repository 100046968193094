import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M5.16057 1.46777L8.69293 5.00013M8.69293 5.00013L5.16057 8.53249M8.69293 5.00013L1.30709 5.00013';

export const IconArrow = ({ width = 10, height = 10, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} strokeWidth={1.4} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default IconArrow;
