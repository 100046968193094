import styled from '@emotion/styled';

export const BadgeContent = styled.span((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 5,
  minWidth: 'fit-content',
  maxHeight: 16,
  whiteSpace: 'nowrap',
}));
