import React, { CSSProperties, FunctionComponent, MouseEvent, useMemo } from 'react';

import { DataTableCellProps, DataTableColumn, DataTableRow, EMPTY_COLUMN_ID } from './dataTable.types';
import { DataTableCell } from './dataTableCell';
import { StyledTableRow } from './dataTableRow.styles';
import { TableCell } from './tableCell';

export interface DataTableRowComponentProps {
  row: DataTableRow;
  columns: DataTableColumn[];
  onClick?: (_event: MouseEvent, id: number | string) => void;
  selected?: boolean;
  CellComponent?: FunctionComponent<DataTableCellProps>;
  updateRow?: (nextRow: Partial<DataTableRow>) => Promise<any>;
  styles?: CSSProperties;
}

export const DataTableRowComponent = React.memo(
  ({ row, columns = [], onClick, selected, updateRow, CellComponent = DataTableCell, styles }: DataTableRowComponentProps) => {
    const handleClick = (event: MouseEvent<HTMLElement>) => onClick?.(event, row.id);
    const usedColumns = useMemo(() => columns.filter((column: DataTableColumn) => !column.hidden), [columns]);

    const renderCell = (column: DataTableColumn) => {
      const { id, label } = column;
      if (id === EMPTY_COLUMN_ID) {
        return <TableCell key={`${id}-${label}`} padding="checkbox" />;
      }
      return <CellComponent key={id} row={row} column={column} updateRow={updateRow} />;
    };

    return (
      <StyledTableRow
        hover
        onClick={handleClick}
        role="checkbox"
        aria-checked={selected}
        tabIndex={-1}
        key={row.id}
        selected={selected}
        extraStyles={styles}
      >
        {usedColumns.map(renderCell)}
      </StyledTableRow>
    );
  },
);
