import React from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from 'components/dataTable/dataTable';

import { COLUMNS, LABELS } from '../keys';
import { DataTableContainer } from '../styles';
import { Text, TextContainer } from './missingAccountsWarning.styles';

export const MissingAccountsWarnings = () => {
  const { t } = useTranslation();

  return (
    <>
      <DataTableContainer>
        <DataTable columns={COLUMNS} rows={[]} />
      </DataTableContainer>
      <TextContainer>
        <Text>{t(LABELS.MISSING_ACCOUNTS_WARNING)}</Text>
        <Text>{t(LABELS.MISSING_ACCOUNTS_CONTACT_PHRASE)}</Text>
      </TextContainer>
    </>
  );
};
