import { t } from 'i18next';

import { FilterPreset } from 'types/filters';

import { createTimePresets } from 'utils/filters/date';

export const DEFAULT_PRESET_KEY = 'custom';

export const LABELS = {
  CUSTOM_FILTER: ['components.filter.date.custom', 'Custom Period'],
  PRESETS: {
    TODAY: ['components.filter.date.presets.today', 'Today'],
    YESTERDAY: ['components.filter.date.presets.yesterday', 'Yesterday'],
    WEEK_TO_DATE: ['components.filter.date.presets.weekToDate', 'This Week (WTD)'],
    LAST_WEEK: ['components.filter.date.presets.lastWeek', 'Last 7 Days'],
    LAST_30_DAYS: ['components.filter.date.presets.last30Days', 'Last 30 Days'],
    MONTH_TO_DATE: ['components.filter.date.presets.monthToDate', 'This Month (MTD)'],
    LAST_MONTH: ['components.filter.date.presets.lastMonth', 'Last Month'],
    LAST_3_MONTHS: ['components.filter.date.presets.lastThreeMonths', 'Last 90 Days'],
    LAST_6_MONTHS: ['components.filter.date.presets.lastSixMonths', 'Last 180 Days'],
    YEAR_TO_DATE: ['components.filter.date.presets.yearToDate', 'Year To Date (YTD)'],
    LAST_365_DAYS: ['components.filter.date.presets.last365Days', 'Last 365 Days'],
    LAST_YEAR: ['components.filter.date.presets.lastYear', 'Last Year'],
  },
};

export const getDefaultDatePresets = () => {
  const presets = createTimePresets();
  return [
    {
      key: 'today',
      label: t(LABELS.PRESETS.TODAY),
      value: presets.today,
    },
    {
      key: 'yesterday',
      label: t(LABELS.PRESETS.YESTERDAY),
      value: presets.yesterday,
    },
    {
      key: 'weekToDate',
      label: t(LABELS.PRESETS.WEEK_TO_DATE),
      value: presets.thisWeek,
    },
    {
      key: 'lastWeek',
      label: t(LABELS.PRESETS.LAST_WEEK),
      value: presets.lastWeek,
    },
    {
      key: 'last30Days',
      label: t(LABELS.PRESETS.LAST_30_DAYS),
      value: presets.last30Days,
    },
    {
      key: 'monthToDate',
      label: t(LABELS.PRESETS.MONTH_TO_DATE),
      value: presets.monthToDate,
    },
    {
      key: 'lastMonth',
      label: t(LABELS.PRESETS.LAST_MONTH),
      value: presets.lastMonth,
    },
    {
      key: 'lastThreeMonths',
      label: t(LABELS.PRESETS.LAST_3_MONTHS),
      value: presets.lastThreeMonths,
    },
    {
      key: 'lastSixMonths',
      label: t(LABELS.PRESETS.LAST_6_MONTHS),
      value: presets.lastSixMonths,
    },
    {
      key: 'yearToDate',
      label: t(LABELS.PRESETS.YEAR_TO_DATE),
      value: presets.yearToDate,
    },
    {
      key: 'last365Days',
      label: t(LABELS.PRESETS.LAST_365_DAYS),
      value: presets.last365Days,
    },
    {
      key: 'lastYear',
      label: t(LABELS.PRESETS.LAST_YEAR),
      value: presets.lastYear,
    },
  ];
};

export const defaultItem: FilterPreset<any> = {
  key: DEFAULT_PRESET_KEY,
  label: LABELS.CUSTOM_FILTER[1],
  value: null,
};
