import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { APIError, FailedAttributes, ValidationErrorMeta } from 'types/error';

import { beautifyFieldsInQuotes } from './string';

export const getValidationDetails = (meta: ValidationErrorMeta) => {
  const hasAttributes = meta.failedAttributes;

  if (!hasAttributes) {
    return undefined;
  }

  const reduce = (details: Record<string, string>, attributes: FailedAttributes) => {
    if (attributes) {
      const { attributeName, message } = attributes;
      const displayMessage = beautifyFieldsInQuotes(message);
      return {
        ...details,
        [attributeName]: displayMessage,
      };
    }

    return details;
  };

  return meta.failedAttributes?.reduce(reduce, {});
};

export const getServerSideValidationDetails = (error: FetchBaseQueryError | SerializedError | undefined, generalErrorField: string) => {
  const errorMeta = error ? ((error as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta : undefined;
  const fieldError = errorMeta ? getValidationDetails(errorMeta) : undefined;

  if (error && !fieldError) {
    return { [generalErrorField]: ((error as FetchBaseQueryError).data as APIError).errorMessage };
  }

  return fieldError;
};

export const getErrorDetails = (errorBase: FetchBaseQueryError | undefined, defaultErrorField?: string) => {
  if (!errorBase) {
    return undefined;
  }

  const errorMeta = (errorBase.data as { meta: ValidationErrorMeta }).meta;
  const validationDetails = getValidationDetails(errorMeta);

  if (validationDetails) {
    return validationDetails;
  }

  if (defaultErrorField) {
    return { [defaultErrorField]: (errorBase.data as APIError).errorMessage };
  }

  return (errorBase.data as APIError).errorMessage;
};
