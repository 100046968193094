import { UrlParam } from '../types/base';

export const routes = {
  Root: '/',
  Login: '/login',
  Register: '/register',
  GeneralSettings: {
    Root: '/general-settings',
    Users: 'users',
    Reconciliations: '/general-settings/settlement-reconciliations',
    ReconciliationsById: '/general-settings/settlement-reconciliations/:uuid',
  },
  AccountSettings: {
    Root: '/account',
    ById: '/account/:uuid',
    General: 'general',
    PaymentMethods: 'payment-methods',
    APIKeys: 'api-keys',
    Users: 'users',
  },
  AcceptInvite: '/invite/accept',
  Ramp: {
    Orders: {
      Root: '/ramp/orders',
      ById: '/ramp/orders/:uuid',
      ReceiptById: '/ramp/orders/:uuid/receipt',
    },
    Customers: {
      Root: '/ramp/customers',
      ById: '/ramp/customers/:uuid',
    },
    Accounts: {
      List: '/ramp/accounts',
    },
  },
  Pwc: {
    Orders: {
      Root: '/pwc/orders',
      ById: '/pwc/orders/:uuid',
      ReceiptById: '/ramp/orders/:uuid/receipt',
    },
    Customers: {
      Root: '/pwc/customers',
      ById: '/pwc/customers/:uuid',
    },
    Accounts: {
      Root: '/pwc/accounts',
      ById: '/pwc/accounts/:uuid',
      General: 'general',
    },
    AccountSettings: {
      Root: '/pwc/account',
      General: '/pwc/account/general',
      APIKeys: 'api-keys',
      Settlement: 'settlement',
      Users: 'users',
    },
  },
  Documentation: {
    Root: '/documentation',
  },
  Profile: {
    Root: '/profile',
  },
};

export const RoutePath = {
  ...routes,
  accountById: (uuid: UrlParam) => [RoutePath.AccountSettings.Root, uuid].join('/'),
  orderById: (uuid: UrlParam) => [RoutePath.Ramp.Orders.Root, uuid].join('/'),
  orderReceiptById: (uuid: UrlParam) => [RoutePath.Ramp.Orders.Root, uuid, 'receipt'].join('/'),
  customerById: (uuid: UrlParam) => RoutePath.Ramp.Customers.ById.replace(':uuid', uuid.toString()),
  ReconciliationsById: (uuid: UrlParam) => [RoutePath.GeneralSettings.Reconciliations, uuid].join('/'),
  pwc: {
    accountById: (uuid: UrlParam) =>
      [RoutePath.Pwc.Accounts.ById.replace(':uuid', uuid.toString()), RoutePath.Pwc.Accounts.General].join('/'),
    orderById: (uuid: UrlParam) => [RoutePath.Pwc.Orders.Root, uuid].join('/'),
    customerById: (uuid: UrlParam) => RoutePath.Pwc.Customers.ById.replace(':uuid', uuid.toString()),
  },
};

export default RoutePath;
