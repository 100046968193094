import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const Svg = styled.svg(() => ({}));

export const Path = styled.path<{ fill?: string }>((props) => ({
  fill: props.fill || props.theme.colors.secondary,
}));

const spinnerAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }`;

export const LoadingContainer = styled(Svg)<{ scale: number; color?: string }>((props) => ({
  position: 'relative',
  minWidth: 20 * props.scale,
  minHeight: 20 * props.scale,
  '& path': {
    fill: props.color || props.theme.colors.inactive,
    animation: `${spinnerAnimation} 0.8s linear infinite`,
  },
  '& path:nth-of-type(1)': {
    animationDelay: '-0.8s',
  },
  '& path:nth-of-type(2)': {
    animationDelay: '-0.7s',
  },
  '& path:nth-of-type(3)': {
    animationDelay: '-0.6s',
  },
  '& path:nth-of-type(4)': {
    animationDelay: '-0.5s',
  },
  '& path:nth-of-type(5)': {
    animationDelay: '-0.4s',
  },
  '& path:nth-of-type(6)': {
    animationDelay: '-0.3s',
  },
  '& path:nth-of-type(7)': {
    animationDelay: '-0.2s',
  },
  '& path:nth-of-type(8)': {
    animationDelay: '-0.1s',
  },
  '& path:nth-of-type(9)': {
    animationDelay: '0s',
  },
}));
