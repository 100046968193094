/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { fetchKYCStatus, getCustomer } from '../../services/customers';
import { Customer } from '../../types/customer';

interface CustomerState {
  customer: Customer | null;
}

const SLICE_NAME = 'customer';

const initialState: CustomerState = {
  customer: null,
};

export const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(getCustomer.matchFulfilled, (state, action) => {
        state.customer = action.payload;
      })
      .addMatcher(fetchKYCStatus.matchFulfilled, (state, action) => {
        if (!state.customer || !action.payload) {
          return;
        }

        state.customer.kycStatus = action.payload.kycStatus;
        state.customer.kycReason = action.payload.kycReason;
        state.customer.kycTimestamp = action.payload.kycTimestamp;
      });
  },
});

export const selectCustomer = (state: RootState) => state.customer.customer;

export default userSlice.reducer;
