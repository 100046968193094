import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M9.1465 4.1815L9.5695 3.7585C8.7195 2.6535 7.4005 1.9995 6.0005 1.9995C3.519 2 1.5 4.0185 1.5 6.5C1.5 8.9815',
  '3.519 11 6 11C8.225 11 10.14 9.3425 10.454 7.144C10.513 6.7345 10.8945 6.4505 11.3025 6.5075C11.7125 6.5665',
  '11.998 6.9465 11.939 7.356C11.5195 10.2885 8.9665 12.5 5.9995 12.5C2.6915 12.5 0 9.8085 0 6.5C0 3.1915 2.6915',
  '0.5 6 0.5C7.801 0.5 9.501 1.311 10.6365 2.6915L11.1875 2.1405C11.4875 1.8405 12 2.053 12 2.477V4.392C12 4.7245',
  '11.7305 4.994 11.3975 4.994H9.4825C9.0585 4.994 8.8465 4.4815 9.1465 4.1815ZM6 10.5C6.4145 10.5 6.75 10.164',
  '6.75 9.75V9.4155C7.474 9.1845 8 8.5055 8 7.7065C8 6.9685 7.5575 6.315 6.873 6.0415L5.684 5.5655C5.572 5.521',
  '5.5 5.414 5.5 5.2935C5.5 5.132 5.6315 5 5.7935 5H6.384C6.4745 5 6.5555 5.047 6.601 5.1255C6.8085 5.484',
  '7.2675 5.607 7.626 5.3995C7.9845 5.192 8.107 4.733 7.9 4.3745C7.6505 3.9425 7.228 3.642 6.7505 3.5395V3.25C6.7505',
  '2.836 6.415 2.5 6.0005 2.5C5.586 2.5 5.2505 2.836 5.2505 3.25V3.5845C4.5265 3.815 4.0005 4.4945 4.0005 5.2935C4.0005',
  '6.032 4.443 6.685 5.1275 6.9585L6.316 7.434C6.428 7.479 6.5 7.586 6.5 7.7065C6.5 7.868 6.3685 8 6.2065 8H5.616C5.5255',
  '8 5.4445 7.953 5.399 7.8745C5.1915 7.516 4.7325 7.3935 4.3745 7.6005C4.016 7.808 3.8935 8.267 4.101 8.6255C4.3505',
  '9.0575 4.7725 9.358 5.25 9.461V9.75C5.25 10.164 5.5855 10.5 6 10.5Z',
].join(' ');

export const IconRefundAlt = ({ width = 12, height = 13, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} />
  </Svg>
);

export default IconRefundAlt;
