import { AccountInfo } from '../../types/account';
import { AccountUpdateRequestBody } from './types';

const KEYS = {
  URL_DECORATOR: 'https://',
};

export const decorateUpdateRequest = (requestBody: AccountUpdateRequestBody) => {
  const { uuid, cryptoExchangeSpread, defaultAccountUuid, ...body } = requestBody;

  const result = {
    ...body,
  };

  if (body.orderCompleteRedirectUrl) {
    result.orderCompleteRedirectUrl = [KEYS.URL_DECORATOR, body.orderCompleteRedirectUrl].join('');
  }

  if (body.webhookUrl) {
    result.webhookUrl = [KEYS.URL_DECORATOR, body.webhookUrl].join('');
  }

  return result;
};

export const undecorateAccountData = (account: AccountInfo): AccountInfo => ({
  ...account,
  orderCompleteRedirectUrl: (account.orderCompleteRedirectUrl || '').replace(KEYS.URL_DECORATOR, ''),
  webhookUrl: (account.webhookUrl || '').replace(KEYS.URL_DECORATOR, ''),
});
