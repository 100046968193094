import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';

import { LABELS } from '../keys';
import { TablePlaceholderContainer, TablePlaceholderText } from './styles';

interface DomainTablePlaceholderProps {
  addDomain: () => void;
}

export const DomainTablePLaceholder = ({ addDomain }: DomainTablePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <TablePlaceholderContainer>
      <TablePlaceholderText>{t(LABELS.DOMAINS.PLACEHOLDER)}</TablePlaceholderText>
      <Button secondary onClick={addDomain}>
        {t(LABELS.DOMAINS.ADD_DOMAIN)}
      </Button>
    </TablePlaceholderContainer>
  );
};
