import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';

import { Select, SelectItem, SelectProps } from 'components/inputs/select/select';

import { RequestPagination, ServerPagination } from 'types/pagination';

import { decoratePagination, getDefaultPagination, undecoratePagination } from 'utils/pagination';
import { usePagination } from 'utils/tables/pagination';
import { useDebouncedQuery } from 'utils/tables/useDebouncedQuery';

import { KEYS } from './keys';

interface RemoteSelectProps<T extends SelectItem, M extends boolean>
  extends Omit<SelectProps<T, M>, 'onSearch' | 'withSearch' | 'items' | 'value'> {
  value: string | string[];
  compareItem?: (search: string, item: T) => boolean;
  getItems: (params: {
    search?: string;
    pagination?: RequestPagination;
    ids?: string | string[];
  }) => Promise<{ data: T[]; pagination?: ServerPagination }>;
}

export const compareSelectItem = (search: string, item: any) => item?.label?.toLowerCase().includes(search.toLowerCase());

const uniq = (items: any[]) => uniqBy(items, KEYS.UNIQ_KEY_PROP);
export const RemoteSelect = <T extends SelectItem, M extends boolean>({
  compareItem = compareSelectItem,
  getItems,
  multiple,
  value,
  ...restProps
}: RemoteSelectProps<T, M>) => {
  const [shownItems, setShownItems] = useState<T[]>([]);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [isFetching, setFetching] = useState<boolean>(false);
  const { pagination, changePage, setPagination } = usePagination(getDefaultPagination(), [search]);
  const isMultiple: boolean = Boolean(multiple);

  const query = useDebouncedQuery(
    () => ({
      search,
      pagination: undecoratePagination(pagination),
    }),
    [search, pagination.page, pagination.itemsPerPage],
  );

  const preloadItems = async () => {
    setFetching(true);
    const options = [];
    if (value) {
      const { data: selectedOptions } = await getItems({
        ids: value,
      });
      options.push(...selectedOptions);
    }
    const { data: initialOptions, pagination: initialPagination } = await getItems(query);
    options.push(...initialOptions);
    setShownItems(uniq(options));
    if (initialPagination) {
      setPagination(decoratePagination(initialPagination));
    }
    setFetching(false);
    setLoaded(true);
  };

  const fetchItems = async (asNextPage: boolean) => {
    if (isFetching || !isLoaded) {
      return;
    }

    setFetching(true);
    const { data: items, pagination } = await getItems(query);

    if (asNextPage) {
      setShownItems(uniq([...shownItems, ...items]));
    } else {
      setShownItems(uniq(items));
    }

    if (pagination) {
      setPagination(decoratePagination(pagination));
    }

    setFetching(false);
  };

  const loadNextPage = () => {
    changePage(pagination.page + 1);
  };

  useEffect(() => {
    preloadItems();
  }, []);

  useEffect(() => {
    if (pagination.page > 1) {
      fetchItems(true);
    } else {
      fetchItems(false);
    }
  }, [query]);

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const compareItems = (a: T, b: T) => ((a?.label || '') < (b?.label || '') ? -1 : 1);

  const selectedItems = shownItems
    .filter((item) => (multiple ? (value as string[])?.includes(item.key) : (value as string) === item.key))
    .sort(compareItems);

  const notSelectedItems = shownItems.filter((item) => !selectedItems.includes(item));
  const sortedItems = [...selectedItems, ...notSelectedItems];
  const hasNextPage = !isFetching && pagination.page < (pagination.pages || 1);

  return (
    // @ts-ignore TODO: SelectValue<T, M> -> T | T[] problem once again
    <Select<T, typeof isMultiple>
      items={sortedItems}
      value={multiple ? selectedItems : selectedItems[0]}
      {...restProps}
      multiple={multiple}
      onSearch={handleSearch}
      withSearch
      paginationProps={{
        loadNextPage,
        hasNextPage,
      }}
    />
  );
};
