import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { FormField } from 'components/form/formField/formField';
import IconAdd from 'components/icons/add';
import IconCross from 'components/icons/cross';
import IconEnvelope from 'components/icons/envelope';
import { Input } from 'components/inputs';
import { Checkbox } from 'components/inputs/checkbox/checkbox';
import Modal from 'components/modal/modal';

import { UserRoleType } from 'types/user';

import { LABELS } from '../keys';
import {
  Adornment,
  ButtonContent,
  ButtonLabel,
  Buttons,
  Delimeter,
  EmailSection,
  ItemCaption,
  ItemDesctription,
  ItemName,
  Label,
  ModalControls,
  ModalRoot,
  PanelContent,
  PermissionItem,
  PermissionSection,
} from './styles';

interface InviteButtonProps {
  availableRoles: UserRoleType[];
  error?: Record<string, string>;
  onInvite: (email: string, role: UserRoleType) => Promise<boolean>;
  isSubmitting: boolean;
  reset: () => void;
}

export const InviteButton = ({ onInvite, error, isSubmitting, availableRoles, reset }: InviteButtonProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [chosenRole, setChosenRole] = useState<UserRoleType | null>(null);
  const { t } = useTranslation();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    setModalOpen(false);
    setEmail('');
    setChosenRole(null);
    reset();
  };

  const handleEditEmail = (value: string) => {
    if (isSubmitting) {
      return;
    }

    setEmail(value);
  };

  const handleInvite = async () => {
    if (isSubmitting) {
      return;
    }

    if (!(email && chosenRole)) {
      return;
    }

    const response = await onInvite(email, chosenRole);
    if (response) {
      closeModal();
    }
  };

  const handleCheckboxChange = (role: UserRoleType) => () => {
    const shouldSkip = isSubmitting || !availableRoles.includes(role);
    if (shouldSkip) {
      return;
    }

    if (chosenRole === role) {
      setChosenRole(null);
      return;
    }

    setChosenRole(role);
  };

  const canInvite = email && chosenRole;

  return (
    <>
      <Button flat onClick={openModal}>
        <ButtonContent>
          <IconAdd />
          <ButtonLabel>{t(LABELS.INVITE_USER)}</ButtonLabel>
        </ButtonContent>
      </Button>
      <Modal open={isModalOpen} onClose={closeModal}>
        <ModalRoot>
          <Panel
            label={t(LABELS.INVITE_MODAL.TITLE)}
            controls={
              <ModalControls>
                <Button flat onClick={closeModal}>
                  <IconCross />
                </Button>
              </ModalControls>
            }
          >
            <PanelContent>
              <EmailSection>
                <Label>{t(LABELS.INVITE_MODAL.EMAIL.TITLE)}</Label>
                <FormField error={error?.email}>
                  <Input
                    placeholder={t(LABELS.INVITE_MODAL.EMAIL.PLACEHOLDER)}
                    onChange={handleEditEmail}
                    value={email}
                    startAdornment={
                      <Adornment>
                        <IconEnvelope />
                      </Adornment>
                    }
                  />
                </FormField>
              </EmailSection>
              <PermissionSection>
                <FormField error={error?.level}>
                  <Label>{t(LABELS.INVITE_MODAL.PERMISSIONS.LABEL)}</Label>
                </FormField>
                <PermissionItem>
                  <Checkbox
                    disabled={!availableRoles.includes(UserRoleType.SuperAdmin)}
                    onChange={handleCheckboxChange(UserRoleType.SuperAdmin)}
                    value={chosenRole === UserRoleType.SuperAdmin}
                  />
                  <ItemDesctription>
                    <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPER_ADMIN.NAME)}</ItemName>
                    <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPER_ADMIN.CAPTION)}</ItemCaption>
                  </ItemDesctription>
                </PermissionItem>
                <PermissionItem>
                  <Checkbox onChange={handleCheckboxChange(UserRoleType.Admin)} value={chosenRole === UserRoleType.Admin} />
                  <ItemDesctription>
                    <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.ADMIN.NAME)}</ItemName>
                    <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.ADMIN.CAPTION)}</ItemCaption>
                  </ItemDesctription>
                </PermissionItem>
                <PermissionItem>
                  <Checkbox onChange={handleCheckboxChange(UserRoleType.Support)} value={chosenRole === UserRoleType.Support} />
                  <ItemDesctription>
                    <ItemName>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPPORT.NAME)}</ItemName>
                    <ItemCaption>{t(LABELS.INVITE_MODAL.PERMISSIONS.SUPPORT.CAPTION)}</ItemCaption>
                  </ItemDesctription>
                </PermissionItem>
              </PermissionSection>
              <Delimeter />
              <Buttons>
                <Button secondary onClick={closeModal}>
                  {t(LABELS.INVITE_MODAL.BUTTONS.CANCEL)}
                </Button>
                <Button primary onClick={handleInvite} disabled={!canInvite}>
                  {t(LABELS.INVITE_MODAL.BUTTONS.INVITE)}
                </Button>
              </Buttons>
            </PanelContent>
          </Panel>
        </ModalRoot>
      </Modal>
    </>
  );
};
