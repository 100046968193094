import styled from '@emotion/styled/macro';

import { Root as ButtonRoot } from 'components/buttons/styles';

export const ActionsCellWrapper = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  // @ts-ignore
  [ButtonRoot]: {
    width: 20,
    height: 20,
    minWidth: 'unset',
  },
}));

export const Menu = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  backgroundColor: props.theme.colors.background,
  width: 180,
}));

export const MenuTitle = styled.div((props) => ({
  padding: '9.5px 10px',
  backgroundColor: props.theme.colors.transparent,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '14.5px',
}));

export const MenuItem = styled.button<{ disabled?: boolean }>((props) => ({
  padding: '9.5px 10px',
  backgroundColor: props.theme.colors.transparent,
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  fontSize: 12,
  border: 0,
  textAlign: 'left',
  lineHeight: '14.5px',
  color: props.disabled ? props.theme.colors.inactive : props.theme.colors.primary,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.disabled ? props.theme.colors.transparent : props.theme.colors.highlight,
  },
}));
