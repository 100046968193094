import React from 'react';

import { MenuItem } from './actionMenu';

interface ExportMenuItemProps {
  children: JSX.Element | string;
  onClick: () => void;
  isLoading: boolean;
}

export const ExportMenuItem = ({ children, onClick, isLoading }: ExportMenuItemProps) => {
  return (
    <MenuItem onClick={onClick} disabled={isLoading}>
      {children}
    </MenuItem>
  );
};
