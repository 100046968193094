import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import MaterialTypography, { TypographyProps } from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

import { KEYS } from './keys';

type VariantToStylesMapping = {
  [key: string]: CSSObject;
};

const variantToStylesMapping: VariantToStylesMapping = {
  body1: {
    fontSize: 12,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  body2: {
    fontSize: 13,
    lineHeight: 'normal',
  },
  caption: {
    fontSize: 14,
    lineHeight: 'normal',
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 12,
    lineHeight: 'normal',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: 'normal',
  },
  button: {
    fontSize: 12,
    lineHeight: 'normal',
  },
  overline: {
    fontSize: 10,
    lineHeight: 'normal',
  },
  h2: {
    fontSize: 40,
    lineHeight: '58px',
  },
  h1: {
    fontSize: 50,
    lineHeight: '72px',
  },
  h3: {
    fontSize: 30,
    lineHeight: '43px',
  },
  h4: {
    fontSize: 20,
    lineHeight: '29px',
  },
  h5: {
    fontSize: 16,
    lineHeight: '23px',
  },
  h6: {
    fontSize: 14,
    lineHeight: '20px',
  },
};

const getStyleBasedOnVariant = (variant?: Variant | 'inherit') => {
  if (!variant) {
    return variantToStylesMapping[KEYS.DEFAULT_TYPOGRAPHY_VARIANT];
  }
  return variantToStylesMapping[variant];
};

export const StyledMaterialTypography = styled(MaterialTypography)((props: TypographyProps) => getStyleBasedOnVariant(props.variant));
