import styled from '@emotion/styled';

import { Path } from '../../icons/styles';
import { Input } from '../input/input';

export const DateRangeContainer = styled.div(() => ({
  display: 'flex',
  gap: 10,
}));

export const CalendarContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: props.theme.colors.background,
  border: `1px solid ${props.theme.colors.container.tertiary}`,
  borderRadius: 5,
}));

export const AdornedDateInput = styled(Input)((props) => ({
  minWidth: 0,
  input: {
    minWidth: 0,
  },
}));

export const Adornment = styled.button((props) => ({
  marginRight: 8,
  padding: 0,
  border: 0,
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    color: props.theme.colors.primary,
  },
}));

export const Buttons = styled.div((props) => ({
  display: 'flex',
  paddingBottom: 10,
  paddingRight: 10,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  width: '100%',
}));
