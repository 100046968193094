import styled from '@emotion/styled/macro';

export const TablePlaceholderContainer = styled.div(() => ({
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
}));

export const TablePlaceholderText = styled.span((props) => ({
  fontSize: 13,
  color: props.theme.colors.text.primary,
  fontWeight: 400,
  textAlign: 'center',
}));
