import React, { CSSProperties, MouseEvent } from 'react';

import { SortOrder } from 'types/sort';

import { DataTableColumn } from './dataTable.types';
import { StyledTableHead, StyledTableRow } from './dataTableHead.styles';
import { TableHeadCell } from './dataTableHeadCell';

export interface DataTableHeadProps {
  order: SortOrder;
  orderBy: string;
  onRequestSort: (event: MouseEvent, property: string) => void;
  columns: DataTableColumn[];
  styles?: CSSProperties;
}

export const DataTableHead = ({ order, orderBy, onRequestSort, columns, styles }: DataTableHeadProps) => {
  const createSortHandler = (property: string) => (event: MouseEvent) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead extraStyles={styles}>
      <StyledTableRow>
        {columns.map((column: DataTableColumn) => (
          <TableHeadCell key={column.id} column={column} orderBy={orderBy} order={order} onSort={createSortHandler(column.id)} />
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};
