import React from 'react';
import { useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';
import { Body, Buttons, Content, Delimeter, Image, Message, ModalControls } from 'components/modal/styles';

import { LABELS } from '../keys';
import { ModalRoot } from './styles';

interface AddPaymentMethodModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddPaymentMethodModal = ({ open, onClose }: AddPaymentMethodModalProps) => {
  const { t } = useTranslation();

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.ADD_PAYMENT_METHOD.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <Body>
            <Content>
              <Image src={questionImage} />
              <Message>{t(LABELS.ADD_PAYMENT_METHOD.MESSAGE)}</Message>
            </Content>
            <Delimeter />
            <Buttons>
              <Button onClick={closeModal}>{t(LABELS.ADD_PAYMENT_METHOD.CLOSE)}</Button>
            </Buttons>
          </Body>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
