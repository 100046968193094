import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { Path, Svg } from 'components/icons/styles';

export const Menu = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  backgroundColor: props.theme.colors.background,
  width: 180,
}));

export const MenuTitle = styled.div((props) => ({
  padding: '9.5px 10px',
  backgroundColor: props.theme.colors.transparent,
  color: props.theme.colors.inactive,
  fontFamily: props.theme.fonts.primary,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '14.5px',
}));

export const MenuItem = styled.button((props) => ({
  padding: '9.5px 10px',
  backgroundColor: props.theme.colors.transparent,
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  fontSize: 12,
  border: 0,
  textAlign: 'left',
  lineHeight: '15px',
  minHeight: 34,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: props.theme.colors.primary,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.theme.colors.highlight,
  },
}));

export const MenuItemLabel = styled.span(() => ({
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

export const Delimeter = styled.div((props) => ({
  width: '100%',
  height: 1,
  borderBottom: `0.5px solid ${props.theme.colors.container.tertiary}`,
  margin: '8px 0',
}));

export const ButtonLabelContent = styled.div((props) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
  color: props.theme.colors.primary,
  textOverflow: 'ellipsis',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.primary,
  },
}));

export const ButtonLabel = styled.span((props) => ({
  fontSize: 12,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.primary,
  maxWidth: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const ScrollableItemList = styled.div((props) => ({
  maxHeight: 340,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const ProfileIcon = styled.div((props) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    stroke: props.theme.colors.transparent,
    fill: props.theme.colors.primary,
    strokeWidth: 0.1,
  },
}));

export const ArrowIcon = styled.div<{ rotated: boolean }>((props) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Svg]: {
    transition: 'all .3s ease',
    transform: props.rotated ? '' : 'rotate(180deg)',
  },
}));

export const MenuButton = styled(Button)(() => ({
  padding: '4px 6px',
}));
