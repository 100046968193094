import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';
import { Body, Buttons, CloseButton, Content, Image, Message, PanelTitle, Title } from 'components/modal/styles';
import OptionallyVisible from 'components/optionallyVisible';

import { LABELS } from '../../keys';
import { Container } from './styles';

interface KycRetryFieldProps {
  canRetry: boolean;
  retryKyc: () => void;
  isUpdating: boolean;
}

export const KycRetryField = ({ canRetry, retryKyc, isUpdating }: KycRetryFieldProps) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const confirmRetry = async () => {
    await retryKyc();
    setModalOpen(false);
  };

  return (
    <Container>
      <Badge type={BadgeType.Default}>{t(canRetry ? LABELS.YES : LABELS.NO)}</Badge>
      <OptionallyVisible visible={!canRetry}>
        <Button small secondary={!isUpdating} flat={isUpdating} onClick={handleOpen} disabled={isUpdating}>
          {t(LABELS.RETRY_KYC.ALLOW_KYC_RETRY)}
        </Button>
      </OptionallyVisible>
      <Modal open={modalOpen} onClose={handleClose}>
        <Panel
          label={<PanelTitle>{t(LABELS.RETRY_KYC.MODAL_TITLE)}</PanelTitle>}
          controls={
            <CloseButton flat onClick={handleClose}>
              <IconCross />
            </CloseButton>
          }
        >
          <Body>
            <Content>
              <Image src={questionImage} />
              <Title>{t(LABELS.RETRY_KYC.CONFIRM_TITLE)}</Title>
              <Message>
                {t(LABELS.RETRY_KYC.CONFIRM_MESSAGE_1)}
                <br />
                {t(LABELS.RETRY_KYC.CONFIRM_MESSAGE_2)}
              </Message>
            </Content>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={handleClose}>
                {t(LABELS.RETRY_KYC.CANCEL)}
              </Button>
              <Button primary onClick={confirmRetry} disabled={isUpdating}>
                {t(LABELS.RETRY_KYC.CONFIRM)}
              </Button>
            </Buttons>
          </Body>
        </Panel>
      </Modal>
    </Container>
  );
};
