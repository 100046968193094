import React from 'react';
import { useTranslation } from 'react-i18next';

import accountCreatedImage from 'assets/images/accountCreated.svg';

import { LABELS } from '../keys';
import { CreatedAccount } from '../types';
import { AccountCreatedImg, BoldText, Heading, SuccessContainer, TextBlock } from './success.styles';

interface CreateAccountModalSuccessProps {
  formValues: CreatedAccount;
}
export const CreateAccountModalSuccess = ({ formValues }: CreateAccountModalSuccessProps) => {
  const { t } = useTranslation();

  return (
    <SuccessContainer>
      <AccountCreatedImg src={accountCreatedImage} />
      <Heading>{t(LABELS.CREATE_MODAL.SUCCESS_STEP.TITLE)}</Heading>
      <TextBlock>
        <BoldText>{formValues.accountName}</BoldText>
        <div>{t(LABELS.CREATE_MODAL.SUCCESS_STEP.CREATED_LABEL)}.</div>
      </TextBlock>
    </SuccessContainer>
  );
};
