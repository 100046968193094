import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import RoutePath from 'router/path';

import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { Item, ItemName, ItemValue, Link, SectionWrapper } from '../styles';

interface CustomerSectionProps extends OrderSectionProps {
  showCustomerLink: boolean;
}

export const CustomerSection = ({ orderData, showCustomerLink }: CustomerSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.CUSTOMER.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.USER_EMAIL)}</ItemName>
          <ItemValue>{orderData.order.userEmail}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.USER_ID)}</ItemName>
          <ItemValue>
            <OptionallyVisible visible={!showCustomerLink}>{orderData.order.userUuid}</OptionallyVisible>
            <OptionallyVisible visible={showCustomerLink}>
              <Link to={RoutePath.customerById(orderData.order.userUuid)}>{orderData.order.userUuid}</Link>
            </OptionallyVisible>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.order.externalUserId)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.CUSTOMER.EXTERNAL_USER_ID)}</ItemName>
            <ItemValue>{orderData.order.externalUserId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.PARTICIPANT_CODE)}</ItemName>
          <ItemValue>{orderData.order.participantCode}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
