export const formatFiatCurrencyI18n = (locale: string) => (value: number, fiatCurrency: string) =>
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency: fiatCurrency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

export const formatNumberI18n =
  (locale: string) =>
  (value: number = 0) => {
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedValue = formatter.format(value);
    return formattedValue;
  };

export const makeLabels = <T extends Record<string, string | string[] | object>>(labels: T, prefix: string): T =>
  Object.entries(labels).reduce((accumulator, [key, value]) => {
    if (typeof value === 'string') {
      return { ...accumulator, [key]: `${prefix}.${value}` };
    }

    if (Array.isArray(value)) {
      const [translationKey, translationDefault] = value;
      return { ...accumulator, [key]: [`${prefix}.${translationKey}`, translationDefault] };
    }

    if (typeof value === 'object') {
      return { ...accumulator, [key]: makeLabels(value as Record<string, string | object>, prefix) };
    }

    return accumulator;
  }, labels);
