import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { FormField } from 'components/form/formField/formField';
import IconCross from 'components/icons/cross';
import IconEnvelope from 'components/icons/envelope';
import { Input } from 'components/inputs';
import { Checkbox } from 'components/inputs/checkbox/checkbox';
import Modal from 'components/modal/modal';
import { Content, Image, Message, Title } from 'components/modal/styles';

import { AccountAccessLevel } from 'types/account';

import { LABELS } from '../keys';
import { UserTableRow } from '../types';
import {
  Adornment,
  Buttons,
  Delimeter,
  EmailSection,
  ItemCaption,
  ItemDesctription,
  ItemName,
  Label,
  ModalControls,
  ModalRoot,
  PanelContent,
  PermissionItem,
  PermissionSection,
} from './styles';

interface EditUserModalProps {
  error?: Record<string, string>;
  onEdit: (email: string, role: AccountAccessLevel) => Promise<boolean>;
  isSubmitting: boolean;
  reset: () => void;
  open: boolean;
  onClose: () => void;
  user: UserTableRow | null;
  availableRoles: AccountAccessLevel[];
}

export const EditUserModal = ({ onEdit, error, isSubmitting, reset, open, onClose, user, availableRoles }: EditUserModalProps) => {
  const [chosenRole, setChosenRole] = useState<AccountAccessLevel | null>(user?.level || null);
  const [isConfirmingOwnershipChange, setConfirmingOwnershipChange] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.level) {
      setChosenRole(user?.level);
    }
  }, [user?.level]);

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    setChosenRole(null);
    cancelSubmission();
    reset();
    onClose();
  };

  const handleEdit = async () => {
    if (isSubmitting) {
      return;
    }

    if (!(user && chosenRole)) {
      return;
    }

    if (chosenRole === user.level) {
      closeModal();
      return;
    }

    if (chosenRole === AccountAccessLevel.Owner) {
      setConfirmingOwnershipChange(true);
      return;
    }

    await submit();
  };

  const submit = async () => {
    if (isSubmitting) {
      return;
    }

    if (!(user && chosenRole)) {
      return;
    }

    const response = await onEdit(user!.uuid, chosenRole);
    if (response) {
      closeModal();
    }
  };

  const handleCheckboxChange = (role: AccountAccessLevel) => () => {
    if (isSubmitting) {
      return;
    }

    if (chosenRole === role) {
      setChosenRole(null);
      return;
    }

    setChosenRole(role);
  };

  const cancelSubmission = () => {
    setConfirmingOwnershipChange(false);
  };

  const canInvite = user && chosenRole;

  if (!user) {
    return null;
  }

  const canSubmit = canInvite && !isSubmitting;

  const renderEditForm = () => (
    <PanelContent>
      <EmailSection>
        <Label>{t(LABELS.EDIT_MODAL.EMAIL.TITLE)}</Label>
        <FormField error={error?.email}>
          <Input
            placeholder={t(LABELS.EDIT_MODAL.EMAIL.PLACEHOLDER)}
            value={user.username}
            disabled
            startAdornment={
              <Adornment>
                <IconEnvelope />
              </Adornment>
            }
          />
        </FormField>
      </EmailSection>
      <PermissionSection>
        <FormField error={error?.level}>
          <Label>{t(LABELS.EDIT_MODAL.PERMISSIONS.LABEL)}</Label>
        </FormField>
        <PermissionItem>
          <Checkbox
            disabled={!availableRoles.includes(AccountAccessLevel.Owner)}
            onChange={handleCheckboxChange(AccountAccessLevel.Owner)}
            value={chosenRole === AccountAccessLevel.Owner}
          />
          <ItemDesctription>
            <ItemName>{t(LABELS.EDIT_MODAL.PERMISSIONS.SUPER_ADMIN.NAME)}</ItemName>
            <ItemCaption>{t(LABELS.EDIT_MODAL.PERMISSIONS.SUPER_ADMIN.CAPTION)}</ItemCaption>
          </ItemDesctription>
        </PermissionItem>
        <PermissionItem>
          <Checkbox
            disabled={!availableRoles.includes(AccountAccessLevel.Administrator)}
            onChange={handleCheckboxChange(AccountAccessLevel.Administrator)}
            value={chosenRole === AccountAccessLevel.Administrator}
          />
          <ItemDesctription>
            <ItemName>{t(LABELS.EDIT_MODAL.PERMISSIONS.ADMIN.NAME)}</ItemName>
            <ItemCaption>{t(LABELS.EDIT_MODAL.PERMISSIONS.ADMIN.CAPTION)}</ItemCaption>
          </ItemDesctription>
        </PermissionItem>
        <PermissionItem>
          <Checkbox
            disabled={!availableRoles.includes(AccountAccessLevel.Viewer)}
            onChange={handleCheckboxChange(AccountAccessLevel.Viewer)}
            value={chosenRole === AccountAccessLevel.Viewer}
          />
          <ItemDesctription>
            <ItemName>{t(LABELS.EDIT_MODAL.PERMISSIONS.SUPPORT.NAME)}</ItemName>
            <ItemCaption>{t(LABELS.EDIT_MODAL.PERMISSIONS.SUPPORT.CAPTION)}</ItemCaption>
          </ItemDesctription>
        </PermissionItem>
      </PermissionSection>
      <Delimeter />
      <Buttons>
        <Button secondary onClick={closeModal}>
          {t(LABELS.EDIT_MODAL.BUTTONS.CANCEL)}
        </Button>
        <Button primary onClick={handleEdit} disabled={!canSubmit}>
          {t(LABELS.EDIT_MODAL.BUTTONS.EDIT)}
        </Button>
      </Buttons>
    </PanelContent>
  );

  const renderConfirmation = () => (
    <PanelContent>
      <Content>
        <Image src={questionImage} />
        <Title>{t(LABELS.EDIT_MODAL.CONFIRM_TITLE)}</Title>
        <Message>{t(LABELS.EDIT_MODAL.CONFIRM_MESSAGE)}</Message>
      </Content>
      <Delimeter />
      <Buttons>
        <Button secondary onClick={cancelSubmission}>
          {t(LABELS.EDIT_MODAL.BUTTONS.CANCEL)}
        </Button>
        <Button primary onClick={submit} disabled={!canSubmit}>
          {t(LABELS.EDIT_MODAL.BUTTONS.CONFIRM)}
        </Button>
      </Buttons>
    </PanelContent>
  );

  const contentRenderer = isConfirmingOwnershipChange ? renderConfirmation : renderEditForm;

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.EDIT_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          {contentRenderer()}
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
