import styled from '@emotion/styled';

export const TextContainer = styled.div((props) => ({
  paddingTop: 30,
  paddingBottom: 30,
}));

export const Text = styled.div((props) => ({
  fontSize: 13,
  textAlign: 'center',
}));
