import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetCountriesListQuery } from 'services/assets';
import { useGetMerchantsBankAccountsQuery } from 'services/merchants';

import { SelectItem } from 'components/inputs/select/select';

import selectAccount from 'state/selectors/accounts/accountSelector';

import { KEYS } from './keys';
import { Settlement } from './settlement';
import { transformBankAccountsListDataToTableFormat } from './utils';

export const SettlementContainer = () => {
  const match = useMatch(KEYS.ROUTE_MATCH);
  const { account } = useSelector(selectAccount);
  const { uuid } = match?.params ?? { uuid: account.uuid };

  const { data: countries = [] } = useGetCountriesListQuery();
  const { data, isFetching } = useGetMerchantsBankAccountsQuery(uuid!, { skip: !uuid });

  const bankAccounts = transformBankAccountsListDataToTableFormat(data?.data);

  const mappedCountries: SelectItem[] = countries.map((country) => ({
    key: country.isoAlpha3,
    icon: country.iconUrl,
    label: country.name,
  }));

  return <Settlement countries={mappedCountries} merchantId={uuid!} loading={isFetching} bankAccounts={bankAccounts} />;
};
