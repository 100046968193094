import { t } from 'i18next';

import { AmountInputVariant, AmountInputVariantItem } from './types';

export const KEYS = {
  LESS_THAN: 'lte',
  GREATER_THAN: 'gte',
};

export const LABELS = {
  IS_BETWEEN: ['components.filters.amount.variant.isBetween', 'is between'],
  IS_EQUAL: ['components.filters.amount.variant.isEqual', 'is equal to'],
  LESS_THAN: ['components.filters.amount.variant.lessThan', 'is less than or equal to'],
  MORE_THAN: ['components.filters.amount.variant.moreThan', 'is greater than or equal to'],
};

export const variants: AmountInputVariantItem[] = [
  {
    key: AmountInputVariant.IsBetween,
    label: t(LABELS.IS_BETWEEN),
  },
  {
    key: AmountInputVariant.IsEqual,
    label: t(LABELS.IS_EQUAL),
  },
  {
    key: AmountInputVariant.GreaterThan,
    label: t(LABELS.MORE_THAN),
  },
  {
    key: AmountInputVariant.LessThan,
    label: t(LABELS.LESS_THAN),
  },
];
