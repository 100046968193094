import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M8 5.33325L4 9.33325L4.94 10.2733L8 7.21992L11.06 10.2733L12 9.33325L8 5.33325Z';

export const IconChevron = ({ width = 16, height = 16, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconChevron;
