import styled from '@emotion/styled';

import { Button } from '../../buttons';

export const ButtonLabel = styled.span(() => ({
  textTransform: 'uppercase',
}));

export const FiltersContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 360,
  maxWidth: 420,
  padding: '5px 10px 20px 10px',
}));

export const FiltersWrapper = styled.div(() => ({
  minHeight: 150,
}));

export const Filters = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  lineHeight: '20px',
  gap: 10,
}));

export const EmptyFiltersMessage = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const Title = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const PanelTitle = styled.span((props) => ({
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const CloseButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 'unset',
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: 20,
}));
