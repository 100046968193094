import styled from '@emotion/styled/macro';

export const Button = styled.button<{ fullWidth?: boolean }>((props) => ({
  width: props.fullWidth ? '100%' : 'auto',
  boxSizing: 'border-box',
  backgroundColor: props.theme.colors.container.primary,
  border: 0,
  padding: '8px 12px',
  borderRadius: 5,
  maxHeight: 32,
  outline: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const DropdownContainer = styled.div((props) => ({
  borderRadius: 5,
  border: `1px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.background,
  overflow: 'hidden',
}));

export const DropdownIconContainer = styled.div<{ rotated?: boolean }>((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 16,
  width: 8,
  transform: props.rotated ? `rotate(180deg)` : undefined,
  path: {
    fill: props.theme.colors.inactive,
  },
}));

export const DropdownList = styled.div((props) => ({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  maxHeight: 340,
  overflowY: 'scroll',
  gap: 10,
}));

export const SearchLabel = styled.h5((props) => ({
  margin: 0,
  fontSize: 12,
  fontWeight: 500,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
}));

export const Wrapper = styled.div();
