import { MerchantBankInfo } from 'services/merchants/types';

import { BankAccountsDataRow } from './types';

export const transformBankAccountsListDataToTableFormat = (bankAccountInfos?: MerchantBankInfo[]): BankAccountsDataRow[] => {
  return bankAccountInfos
    ? bankAccountInfos.map((bankAccountInfo) => ({
        ...bankAccountInfo,
        id: bankAccountInfo.uuid,
      }))
    : [];
};
