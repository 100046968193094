import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/material';
import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

import { Button } from '../../buttons';
import { Input } from '../input/input';
import { KEYS, LABELS, hexColorExp, hexColorSymbolsExp } from './keys';
import { Container, PickerContainer, Preview } from './styles';

interface ColorPickerProps {
  defaultColor: string;
  onChange: (color: string) => void;
  color: string;
  disabled?: boolean;
}

const prepareColor = (value: string) => `#${value.replace(hexColorSymbolsExp, '').toUpperCase()}`.slice(0, 7);

export const ColorPicker = ({ defaultColor, color, onChange, disabled }: ColorPickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const usedColor = color || KEYS.DEFAULT_COLOR;
  const [localColor, setColor] = useState<string>(usedColor);
  const inputRef = useRef<HTMLInputElement>();

  const handleReset = () => {
    onChange(defaultColor);
  };

  useEffect(() => {
    setColor(usedColor);
  }, [color]);

  const handleRef = (ref: HTMLInputElement) => {
    inputRef.current = ref;
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }

    setOpen(true);
    inputRef.current?.focus();
  };

  const handleClose = () => {
    if (!isFocused) {
      setOpen(false);
      onChange(prepareColor(localColor));
    }
  };

  const handleChange = (newColor: string) => {
    if (disabled) {
      return;
    }

    const processedValue = prepareColor(newColor);
    setColor(processedValue);
  };

  const handleTextChange = (value: string) => {
    const processedValue = prepareColor(value);
    setColor(processedValue);
    const isValidColor = hexColorExp.test(processedValue);
    if (isValidColor) {
      onChange(processedValue);
    }
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    setOpen(true);
    setIsFocused(true);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
  };

  return (
    <Container>
      <Preview color={localColor} onClick={handleOpen} />
      <Input
        forwardRef={handleRef}
        value={localColor}
        onChange={handleTextChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        disabled={disabled}
        placeholder={t(LABELS.PLACEHOLDER)}
      />
      <Button flat onClick={handleReset} disabled={defaultColor === color || disabled}>
        {t(LABELS.RESET)}
      </Button>
      <Popper open={open} anchorEl={inputRef.current}>
        <ClickAwayListener onClickAway={handleClose}>
          <PickerContainer>
            <HexColorPicker color={usedColor} onChange={handleChange} />
          </PickerContainer>
        </ClickAwayListener>
      </Popper>
    </Container>
  );
};
