import i18n from 'locales/i18next';

import { getShortenedCryptoCurrencyCode } from 'utils/currencies';
import { formatNumberI18n } from 'utils/i18n';
import { capitalizeFirstLetter } from 'utils/string';

import { KEYS } from '../keys';
import { OrderData } from '../types';

export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));
export const formatCryptoCode = (code?: string) => getShortenedCryptoCurrencyCode(code);

export const formatCryptoNetworkFee = (num?: number) => parseFloat((num ?? 0).toFixed(KEYS.CRYPTO_NETWORK_FEE_DIGITS));

export const convertToBooleanString = (value?: boolean) => capitalizeFirstLetter(Boolean(value).toString());

export const getWalletMemoOrTag = (orderData: OrderData) => {
  if (!orderData.order.walletAddress) {
    return '';
  }
  return orderData.order.walletAddress.split(KEYS.TAG_OR_MEMO_SPLIT_STRING)[1];
};

export const getIsMemoOrTagSet = (orderData: OrderData) => {
  if (!orderData.order.walletAddress) {
    return false;
  }

  return orderData.order.walletAddress.includes(KEYS.TAG_OR_MEMO_SPLIT_STRING);
};
