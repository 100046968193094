import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';
import { Path } from 'components/icons/styles';

import { COLUMN_IDS } from '../../keys';

export const Container = styled.div((props) => ({
  [`& [data-column="${COLUMN_IDS.STATUS}"]`]: {
    minWidth: 100,
  },
  [`& [data-column="${COLUMN_IDS.DATE}"]`]: {
    minWidth: 180,
  },
  [`
  & [data-column="${COLUMN_IDS.ID}"],
  & [data-column="${COLUMN_IDS.USER}"]
  `]: {
    minWidth: 120,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`
  & [data-column="${COLUMN_IDS.FIAT_AMOUNT}"],
  & [data-column="${COLUMN_IDS.CRYPTO_AMOUNT}"]
  `]: {
    maxWidth: 300,
    textAlign: 'right',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
  },
}));

export const PlaceholderContainer = styled.div((props) => ({
  padding: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Notification = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 6,
  padding: 8,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.container.primary,
  borderRadius: 5,
  color: props.theme.colors.primary,
  fontSize: 12,

  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.primary,
  },
}));

export const TableContainer = styled.div((props) => ({
  [`& [data-column="${COLUMN_IDS.STATUS}"]`]: {
    minWidth: 100,
  },
  [`& [data-column="${COLUMN_IDS.DATE}"]`]: {
    minWidth: 180,
  },
  [`
  & [data-column="${COLUMN_IDS.ID}"],
  & [data-column="${COLUMN_IDS.USER}"]
  `]: {
    minWidth: 120,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`
  & [data-column="${COLUMN_IDS.FIAT_AMOUNT}"],
  & [data-column="${COLUMN_IDS.CRYPTO_AMOUNT}"]
  `]: {
    maxWidth: 300,
    textAlign: 'right',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
  },
}));

export const RefreshButton = styled(Button)((props) => ({
  span: {
    fontWeight: 500,
    color: props.theme.colors.primary,
    fontSize: 13,
    gap: 8,
  },
}));
