import React, { MouseEvent, useEffect, useState } from 'react';

import { Select, SelectItem, SelectProps, SelectValue } from 'components/inputs/select/select';

import { Checkbox } from '../../checkbox/checkbox';
import { SelectableItem } from '../styles';
import { CurrencyDescription, CurrencyName, Icon, SelectedItem, TitleContainer } from './styles';
import { CurrencyItem } from './types';

interface SearchableSelectProps<T extends SelectItem, M extends boolean> extends Omit<SelectProps<T, M>, 'onSearch' | 'withSearch'> {
  compareItem?: (search: string, item: T) => boolean;
}

const ITEM_DELIMETER = ',';

export const compareCurrencyItem = (search: string, item: CurrencyItem) =>
  item.name.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase());

export const CurrencySelect = <T extends CurrencyItem, M extends boolean>({
  items,
  compareItem = compareCurrencyItem,
  multiple,
  value,
  ...restProps
}: SearchableSelectProps<T, M>) => {
  const [shownItems, setShownItems] = useState<T[]>(items);
  const isMultiple: boolean = Boolean(multiple);

  useEffect(() => {
    setShownItems(items);
  }, [items]);

  const handleSearch = (search: string) => {
    if (!search) {
      setShownItems(items);
      return;
    }

    const filteredItems = items.filter((item: T) => compareItem(search, item));
    setShownItems(filteredItems);
  };

  const renderListItem = (item: T, selectItem: (event: MouseEvent<HTMLElement>) => void) => {
    if (!item) {
      return null;
    }

    if (multiple) {
      return (
        <SelectableItem onClick={selectItem} key={item.key}>
          <Checkbox value={(value as T[])?.includes(item)} />
          <Icon src={item.icon} />
          <TitleContainer>
            <CurrencyName>{restProps.getItemLabel(item)}</CurrencyName>
            <CurrencyDescription>{item.label}</CurrencyDescription>
          </TitleContainer>
        </SelectableItem>
      );
    }

    return (
      <SelectableItem onClick={selectItem} key={item.key}>
        <Icon src={item.icon} />
        <TitleContainer>
          <CurrencyName>{restProps.getItemLabel(item)}</CurrencyName>
          <CurrencyDescription>{item.label}</CurrencyDescription>
        </TitleContainer>
      </SelectableItem>
    );
  };

  const renderSelectedItems = (items: SelectValue<CurrencyItem, typeof isMultiple>) => {
    if (!items) {
      return null;
    }

    const isNotLast = (index: number) => index < (items as CurrencyItem[]).length - 1;
    if (multiple) {
      return (
        <>
          {(items as CurrencyItem[]).map((item, index) => (
            <SelectedItem key={item.key}>
              <Icon src={item.icon} />
              <CurrencyName>
                {item.code}
                {isNotLast(index) && ITEM_DELIMETER}
              </CurrencyName>
            </SelectedItem>
          ))}
        </>
      );
    }

    const item = items as CurrencyItem;
    return (
      <SelectedItem>
        <Icon src={item.icon} />
        <CurrencyName>{item.code}</CurrencyName>
      </SelectedItem>
    );
  };

  const compareCurrencies = (a: CurrencyItem, b: CurrencyItem) => (a.name < b.name ? -1 : 1);

  const selectedItems = shownItems
    .filter((item) => (multiple ? (value as T[])?.includes(item) : (value as T)?.key === item.key))
    .sort(compareCurrencies);
  const notSelectedItems = shownItems.filter((item) => !selectedItems.includes(item)).sort(compareCurrencies);
  const sortedItems = [...selectedItems, ...notSelectedItems];

  return (
    <Select<T, typeof isMultiple>
      renderListItem={renderListItem}
      items={sortedItems}
      value={value}
      // @ts-ignore TODO: TS mistakes SelectValue for T | T[] somehow, need to research
      renderSelectedItem={renderSelectedItems}
      {...restProps}
      multiple={multiple}
      onSearch={handleSearch}
      withSearch
    />
  );
};
