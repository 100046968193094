import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M4.66667 11.0003C4.50139 11.0003 4.36275 10.9443 4.25075 10.8323C4.13914 10.7207 4.08333 10.5823 4.08333',
  '10.417C4.08333 10.2517 4.13914 10.1133 4.25075 10.0017C4.36275 9.88966 4.50139 9.83366 4.66667',
  '9.83366C4.83194 9.83366 4.97058 9.88966 5.08258 10.0017C5.19419 10.1133 5.25 10.2517 5.25 10.417C5.25',
  '10.5823 5.19419 10.7207 5.08258 10.8323C4.97058 10.9443 4.83194 11.0003 4.66667 11.0003ZM4.66667',
  '8.66699C4.50139 8.66699 4.36275 8.61099 4.25075 8.49899C4.13914 8.38738 4.08333 8.24894 4.08333',
  '8.08366C4.08333 7.91838 4.13914 7.77974 4.25075 7.66774C4.36275 7.55613 4.50139 7.50033 4.66667',
  '7.50033C4.83194 7.50033 4.97058 7.55613 5.08258 7.66774C5.19419 7.77974 5.25 7.91838 5.25 8.08366C5.25',
  '8.24894 5.19419 8.38738 5.08258 8.49899C4.97058 8.61099 4.83194 8.66699 4.66667 8.66699ZM7 11.0003C6.83472',
  '11.0003 6.69628 10.9443 6.58467 10.8323C6.47267 10.7207 6.41667 10.5823 6.41667 10.417C6.41667 10.2517',
  '6.47267 10.1133 6.58467 10.0017C6.69628 9.88966 6.83472 9.83366 7 9.83366C7.16528 9.83366 7.30392 9.88966',
  '7.41592 10.0017C7.52753 10.1133 7.58333 10.2517 7.58333 10.417C7.58333 10.5823 7.52753 10.7207 7.41592',
  '10.8323C7.30392 10.9443 7.16528 11.0003 7 11.0003ZM7 8.66699C6.83472 8.66699 6.69628 8.61099 6.58467',
  '8.49899C6.47267 8.38738 6.41667 8.24894 6.41667 8.08366C6.41667 7.91838 6.47267 7.77974 6.58467',
  '7.66774C6.69628 7.55613 6.83472 7.50033 7 7.50033C7.16528 7.50033 7.30392 7.55613 7.41592 7.66774C7.52753',
  '7.77974 7.58333 7.91838 7.58333 8.08366C7.58333 8.24894 7.52753 8.38738 7.41592 8.49899C7.30392 8.61099',
  '7.16528 8.66699 7 8.66699ZM9.33333 11.0003C9.16806 11.0003 9.02961 10.9443 8.918 10.8323C8.806 10.7207',
  '8.75 10.5823 8.75 10.417C8.75 10.2517 8.806 10.1133 8.918 10.0017C9.02961 9.88966 9.16806 9.83366 9.33333',
  '9.83366C9.49861 9.83366 9.63705 9.88966 9.74867 10.0017C9.86067 10.1133 9.91667 10.2517 9.91667 10.417C9.91667',
  '10.5823 9.86067 10.7207 9.74867 10.8323C9.63705 10.9443 9.49861 11.0003 9.33333 11.0003ZM9.33333',
  '8.66699C9.16806 8.66699 9.02961 8.61099 8.918 8.49899C8.806 8.38738 8.75 8.24894 8.75 8.08366C8.75',
  '7.91838 8.806 7.77974 8.918 7.66774C9.02961 7.55613 9.16806 7.50033 9.33333 7.50033C9.49861 7.50033',
  '9.63705 7.55613 9.74867 7.66774C9.86067 7.77974 9.91667 7.91838 9.91667 8.08366C9.91667 8.24894 9.86067',
  '8.38738 9.74867 8.49899C9.63705 8.61099 9.49861 8.66699 9.33333 8.66699ZM2.91667 13.3337C2.59583 13.3337',
  '2.32108 13.2195 2.09242 12.9912C1.86414 12.7626 1.75 12.4878 1.75 12.167V4.00033C1.75 3.67949 1.86414 3.40494',
  '2.09242 3.17666C2.32108 2.94799 2.59583 2.83366 2.91667',
  '2.83366H3.5V1.66699H4.66667V2.83366H9.33333V1.66699H10.5V2.83366H11.0833C11.4042',
  '2.83366 11.6789 2.94799 11.9076 3.17666C12.1359 3.40494 12.25 3.67949 12.25 4.00033V12.167C12.25 12.4878',
  '12.1359 12.7626 11.9076 12.9912C11.6789 13.2195 11.4042 13.3337 11.0833 13.3337H2.91667ZM2.91667',
  '12.167H11.0833V6.33366H2.91667V12.167ZM2.91667 5.16699H11.0833V4.00033H2.91667V5.16699Z',
].join(' ');

export const IconCalendar = ({ width = 14, height = 15, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconCalendar;
