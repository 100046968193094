import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M3.83301 11.9999V7.33325H5.16634V11.9999H3.83301ZM7.83301 11.9999V7.33325H9.16634V11.9999H7.83301ZM1.83301',
  '14.6666V13.3333H15.1663V14.6666H1.83301ZM11.833 11.9999V7.33325H13.1663V11.9999H11.833ZM1.83301',
  '5.99992V4.66659L8.49967 1.33325L15.1663 4.66659V5.99992H1.83301ZM4.79967 4.66659H12.1997L8.49967',
  '2.83325L4.79967 4.66659Z',
].join(' ');

const IconBank = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <Path fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconBank;
