import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTable } from 'components/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { AccountAccessLevel } from 'types/account';

import { EditUserModal } from './components/editUserModal';
import { InviteButton } from './components/inviteButton';
import { RevokeAccessModal } from './components/revokeAccessModal';
import { makeUsersTableCell } from './components/usersTableCell';
import { COLUMNS, LABELS } from './keys';
import { ActionPanel } from './styles';
import { UserTableRow } from './types';

export interface UserSectionProps extends WithTranslation {
  users: UserTableRow[];
  isLoading: boolean;
  inviteUser: {
    isAvailable: boolean;
    error?: Record<string, string>;
    onInvite: (email: string, role: AccountAccessLevel) => Promise<boolean>;
    isSubmitting: boolean;
    reset: () => void;
  };
  resendInvite: {
    isAvailable: (user: UserTableRow) => boolean;
    onResend: (email: string) => Promise<boolean>;
    isSubmitting: boolean;
  };
  revokeInvite: {
    isAvailable: (user: UserTableRow) => boolean;
    onRevoke: (email: string) => Promise<boolean>;
    isSubmitting: boolean;
    reset: () => void;
  };
  editUser: {
    isAvailable: (user: UserTableRow) => boolean;
    error?: Record<string, string>;
    onEdit: (email: string, role: AccountAccessLevel) => Promise<boolean>;
    isSubmitting: boolean;
    availableRoles: AccountAccessLevel[];
    reset: () => void;
  };
}

enum UserActionModalType {
  Edit,
  Revoke,
}

export const UsersSectionComponent = ({ users, inviteUser, editUser, resendInvite, revokeInvite, isLoading, t }: UserSectionProps) => {
  const [selectedUser, setSelectedUser] = useState<UserTableRow | null>(null);
  const [selectedModal, setSelectedModal] = useState<UserActionModalType | null>(null);

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <LoadingIndicator padded />
      </Panel>
    );
  }

  const findUser = (uuid: string) => {
    if (!uuid) {
      return null;
    }

    return users.find((user) => user.uuid === uuid) || null;
  };

  const getOpenModalHandler = (type: UserActionModalType) => (uuid: string) => {
    const user = findUser(uuid);
    if (user) {
      setSelectedUser(user);
      setSelectedModal(type);
    }
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setSelectedModal(null);
  };

  const handleResend = (uuid: string) => {
    if (!uuid) {
      return;
    }

    const user = users.find((user) => user.uuid === uuid) || null;
    if (!user) {
      return;
    }

    resendInvite.onResend(user.uuid);
  };

  const UsersTableCell = makeUsersTableCell({
    editUser: getOpenModalHandler(UserActionModalType.Edit),
    revokeAccess: getOpenModalHandler(UserActionModalType.Revoke),
    resendInvite: handleResend,
    canEditUser: editUser.isAvailable,
    canResendInvite: resendInvite.isAvailable,
  });

  const getModalOpenState = (type: UserActionModalType): boolean => Boolean(selectedModal === type && selectedUser);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <ActionPanel>
        <InviteButton {...inviteUser} />
      </ActionPanel>
      <EditUserModal {...editUser} open={getModalOpenState(UserActionModalType.Edit)} onClose={handleCloseUserModal} user={selectedUser} />
      <RevokeAccessModal
        {...revokeInvite}
        open={getModalOpenState(UserActionModalType.Revoke)}
        onClose={handleCloseUserModal}
        user={selectedUser}
      />
      <DataTable columns={COLUMNS} rows={users} CellComponent={UsersTableCell} />
    </Panel>
  );
};

export const UsersSection = withTranslation()(UsersSectionComponent);
