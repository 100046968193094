import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M14.5157 7.36667V12.6667C14.5157 13.0333 14.3853 13.3473 14.1244 13.6087C13.8631',
  '13.8696 13.5491 14 13.1824 14H3.84908C3.48242 14 3.16864 13.8696 2.90775 13.6087C2.64642',
  '13.3473 2.51575 13.0333 2.51575 12.6667V7.36667C2.26019 7.13333 2.06308 6.83333 1.92442',
  '6.46667C1.78531 6.1 1.78242 5.7 1.91575 5.26667L2.61575 3C2.70464 2.71111 2.86308 2.47222',
  '3.09108 2.28333C3.31864 2.09444 3.58242 2 3.88242 2H13.1491C13.4491 2 13.7102 2.09156 13.9324',
  '2.27467C14.1546 2.45822 14.3158 2.7 14.4158 3L15.1157 5.26667C15.2491 5.7 15.2464 6.09444',
  '15.1077 6.45C14.9686 6.80556 14.7713 7.11111 14.5157 7.36667ZM9.98242 6.66667C10.2824 6.66667',
  '10.5102 6.56378 10.6658 6.358C10.8213 6.15267 10.8824 5.92222 10.8491 5.66667L10.4824 3.33333H9.18242V5.8C9.18242',
  '6.03333 9.26019 6.23622 9.41575 6.40867C9.57131 6.58067 9.7602 6.66667 9.98242 6.66667ZM6.98242 6.66667C7.23797',
  '6.66667 7.44642 6.58067 7.60775 6.40867C7.76864 6.23622 7.84908 6.03333 7.84908 5.8V3.33333H6.54908L6.18242 5.66667C6.13797',
  '5.93333 6.19642 6.16667 6.35775 6.36667C6.51864 6.56667 6.72686 6.66667 6.98242 6.66667ZM4.01575 6.66667C4.21575 6.66667',
  '4.39064 6.59444 4.54042 6.45C4.69064 6.30556 4.78242 6.12222 4.81575 5.9L5.18242 3.33333H3.88242L3.21575 5.56667C3.14908',
  '5.78889 3.18508 6.02778 3.32375 6.28333C3.46286 6.53889 3.69353 6.66667 4.01575 6.66667ZM13.0157 6.66667C13.338 6.66667',
  '13.5713 6.53889 13.7158 6.28333C13.8602 6.02778 13.8935 5.78889 13.8158 5.56667L13.1157 3.33333H11.8491L12.2158 5.9C12.2491',
  '6.12222 12.3409 6.30556 12.4911 6.45C12.6409 6.59444 12.8158 6.66667 13.0157 6.66667ZM3.84908 12.6667H13.1824V7.96667C13.1269',
  '7.98889 13.0909 8 13.0744 8H13.0157C12.7157 8 12.4518 7.95 12.2238 7.85C11.9962 7.75 11.7713 7.58889 11.5491 7.36667C11.3491',
  '7.56667 11.1213 7.72222 10.8658 7.83333C10.6102 7.94444 10.338 8 10.0491 8C9.74908 8 9.46842 7.94444 9.20708 7.83333C8.9462 7.72222',
  '8.71575 7.56667 8.51575 7.36667C8.32686 7.56667 8.10753 7.72222 7.85775 7.83333C7.60753 7.94444 7.33797 8 7.04908 8C6.72686 8 6.43508',
  '7.94444 6.17375 7.83333C5.91286 7.72222 5.68242 7.56667 5.48242 7.36667C5.24908 7.6 5.01864 7.76378 4.79108 7.858C4.56308',
  '7.95267 4.30464 8 4.01575 8H3.94108C3.91308 8 3.88242 7.98889 3.84908 7.96667V12.6667Z',
].join(' ');

const IconMarketplace = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <Path fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconMarketplace;
