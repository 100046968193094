export const KEYS = {
  SIDEBAR_WIDTH_EXPANDED_DESKTOP: '209px',
  SIDEBAR_WIDTH_COLLAPSED_DESKTOP: '86px',
  DRAWER_TRANSITION_EASING: 'cubic-bezier(0.4, 0.0, 0.6, 1)',
  DRAWER_TRANSITION_DURATION: '0.3s',
  LOGO_TRANSITION_EASING: 'ease-in-out',
  LOGO_TRANSITION_DURATION: '0.15s',
  MENU_ITEM_EASING: 'cubic-bezier(0.4, 0.0, 0.6, 1)',
  MENU_ITEM_DURATION: '0.5s',
};
