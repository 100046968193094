import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';

import { NotificationSeverity, ToastNotification } from 'types/notifications';

import { useNotificationsController } from './notificationController';

export const ToastNotificationController = () => {
  const { toastNotifications: notifications, removeNotification } = useNotificationsController();
  const localQueue = useRef<SnackbarKey[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addToQueue = (key: SnackbarKey) => {
    localQueue.current = [...localQueue.current, key];
  };

  const removeFromLocalQueue = (key: SnackbarKey) => {
    localQueue.current = localQueue.current.filter((displayed) => key !== displayed);
  };

  const hasInQueue = (key: SnackbarKey) => localQueue.current.includes(key);

  const updateNotification = (notification: ToastNotification) => {
    const canSkip = hasInQueue(notification.key);
    if (canSkip) {
      return;
    }

    const { key, dismissed, message, severity, persist, preventDuplicate } = notification as ToastNotification;

    if (dismissed) {
      closeSnackbar(key);
      return;
    }

    addToQueue(key);

    const onExited = (_: any, key: SnackbarKey) => {
      removeFromLocalQueue(key);
      removeNotification(key);
      closeSnackbar(key);
    };

    const queuingOptions: OptionsObject = {
      key,
      onExited,
      persist,
      variant: severity || NotificationSeverity.default,
      preventDuplicate,
    };

    enqueueSnackbar(message, queuingOptions);
  };

  useEffect(() => {
    notifications.forEach(updateNotification);
  }, [notifications]);

  return null;
};

export default ToastNotificationController;
