import { useEffect, useState } from 'react';

import { TablePagination } from 'types/pagination';

import { getDefaultPagination } from '../pagination';

export const usePagination = (defaultState: TablePagination, dependencies: any[]) => {
  const [pagination, setPagination] = useState<TablePagination>(defaultState || getDefaultPagination());

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 1,
    });
  }, dependencies);

  const changePage = (page: number) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  const changeRowsPerPage = (itemsPerPage: number) => {
    setPagination({
      ...pagination,
      itemsPerPage,
      page: 1,
    });
  };

  return {
    pagination,
    setPagination,
    changePage,
    changeRowsPerPage,
  };
};
