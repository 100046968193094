import React, { useEffect, useState } from 'react';

import { Container, Handle } from './styles';

interface SwitchProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

export const Switch = ({ onChange, value, disabled }: SwitchProps) => {
  const [checked, setChecked] = useState<boolean>(Boolean(value));

  useEffect(() => {
    setChecked(Boolean(value));
  }, [value]);

  const handleSwitch = () => {
    if (disabled) {
      return;
    }

    const nextValue = !value;
    setChecked(nextValue);
    onChange?.(nextValue);
  };

  return (
    <Container onClick={handleSwitch} checked={checked}>
      <Handle />
    </Container>
  );
};
