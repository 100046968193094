import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M13.4475 10.9896C12.6954 11.2849 11.8764 11.4471 11.0196 11.4471C7.3495 11.4471 4.37432',
  '8.47192 4.37432 4.80186C4.37432 3.20045 4.94078 1.73134 5.88426 0.583984C3.41508 1.55361',
  '1.66699 3.95852 1.66699 6.7717C1.66699 10.4418 4.64217 13.4169 8.31224 13.4169C10.3809',
  '13.4169 12.2288 12.4717 13.4475 10.9896Z',
].join(' ');

export const IconCrescent = ({ width = 15, height = 14, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fillRule="evenodd" clipRule="evenodd" />
  </Svg>
);

export default IconCrescent;
