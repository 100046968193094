/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import accountsApi from 'services/accounts';
import { undecorateAccountData } from 'services/accounts/utils';
import profileApi from 'services/profile';
import usersApi from 'services/users';

import { RootState } from 'state/store';

import { AccountAccessInfo, AccountAccessLevel, AccountEmbeddableThemeMode, AccountInfo, AccountType } from 'types/account';

interface AccountState {
  account: AccountInfo;
  access?: AccountAccessInfo;
}

const SLICE_NAME = 'account';

const initialState: AccountState = {
  account: {
    onRampEnabled: false,
    offRampEnabled: false,
    uuid: '',
    name: '',
    webhookUrl: '',
    processingFee: 0,
    processingFeeEEA: 0,
    isCertified: false,
    cryptoExchangeSpread: {},
    urlSlug: '',
    pageTitle: '',
    enableRedirectUrl: false,
    orderCompleteRedirectUrl: '',
    pageColor: '',
    pageThemeMode: AccountEmbeddableThemeMode.SameAsBrowser,
    landingLogo: '',
    landingLogoDark: '',
    landingPageEnabled: false,
    widgetColor: '',
    widgetThemeMode: AccountEmbeddableThemeMode.SameAsBrowser,
    cornerRadius: undefined,
    displayIllustration: true,
    defaultCryptoCurrencyCode: '',
    supportedCryptoCurrenciesCodes: undefined,
    supportedFiatCurrenciesCodes: undefined,
    popularCryptoCurrenciesCodes: undefined,
    widgetLogo: '',
    widgetLogoDark: '',
    redirectDomain: '',
    partnerFee: 0,
    areCustomerEmailsEnabled: true,
    type: AccountType.Pwc,
  },
  access: undefined,
};

export const accountSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<{ account: AccountInfo; access: AccountAccessInfo } | undefined>) => {
      if (!action.payload) {
        state.account = initialState.account;
        state.access = initialState.access;
        return;
      }

      state.account = undecorateAccountData(action.payload.account);
      state.access = action.payload.access;
    },
    updateAccountInfo: (state, action: PayloadAction<Partial<AccountInfo>>) => {
      state.account = {
        ...state.account,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getAccountsList.matchFulfilled, (state, { payload }) => {
      const records = payload?.data || [];
      let defaultRecord = records.find((record) => record.isDefault);
      if (!defaultRecord) {
        [defaultRecord] = records;
      }

      if (!defaultRecord) {
        return;
      }

      state.account = undecorateAccountData(defaultRecord.account);
      state.access = defaultRecord.access;
    });
    builder
      .addMatcher(accountsApi.endpoints.getAccount.matchFulfilled, (state, { payload }) => {
        const record = payload?.data;
        if (!record) {
          return;
        }

        state.account = undecorateAccountData(record.account);
        state.access = record.access;
      })
      .addMatcher(accountsApi.endpoints.updateAccount.matchFulfilled, (state, { payload, meta }) => {
        state.account = {
          ...state.account,
          ...meta.arg.originalArgs,
        };
      })
      .addMatcher(
        isAnyOf(profileApi.endpoints.getAvailableAccounts.matchFulfilled, usersApi.endpoints.getAvailableAccounts.matchFulfilled),
        (state, { payload }) => {
          const defaultRecord = payload.data.find((record) => record.isDefault);

          if (defaultRecord) {
            state.account = defaultRecord.account;
            state.access = defaultRecord.access;
          }
        },
      )
      .addMatcher(accountsApi.endpoints.updateAccountUserAccess.matchFulfilled, (state, { payload, meta }) => {
        if (!state.access) {
          return;
        }

        const shouldUpdateAccessLevel =
          state.access?.level === AccountAccessLevel.Owner &&
          state.account.uuid === meta.arg.originalArgs.accountUuid &&
          meta.arg.originalArgs.level === AccountAccessLevel.Owner;

        if (shouldUpdateAccessLevel) {
          state.access.level = AccountAccessLevel.Administrator;
        }
      });
  },
});

export const { updateAccountInfo, setAccount } = accountSlice.actions;

export const selectAccountInfo = (state: RootState) => state.user.userLevel;
export const selectAccountType = (state: RootState) => state.account.account.type;

export default accountSlice.reducer;
