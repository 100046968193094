import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: '5px 0',
  [`& [data-column="${COLUMN_IDS.NAME}"]`]: {
    minWidth: 150,
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`& [data-column="${COLUMN_IDS.CREATED_AT}"]`]: {
    minWidth: 180,
  },
}));

export const ActionBar = styled.div((props) => ({
  display: 'flex',
  gap: 8,
}));
