import TableSortLabel from '@mui/material/TableSortLabel';
import React, { MouseEvent } from 'react';

import { SortOrder } from 'types/sort';

import OptionallyVisible from '../optionallyVisible';
import { DataTableColumn } from './dataTable.types';
import { StyledTableCell } from './dataTableHeadCell.styles';

interface TableHeadCellProps {
  column: DataTableColumn;
  orderBy: string;
  order: SortOrder;
  onSort: (event: MouseEvent, property: string) => void;
}

export const TableHeadCell = ({ column, orderBy, order, onSort }: TableHeadCellProps) => (
  <StyledTableCell
    key={column.id}
    align="left"
    padding={column.disablePadding ? 'none' : 'normal'}
    sortDirection={orderBy === column.id ? order : false}
    data-column={column.id}
    style={{
      minWidth: column.minWidth,
    }}
    {...column.headCellProps}
  >
    <OptionallyVisible visible={!column.sortable}>{column.label}</OptionallyVisible>
    <OptionallyVisible visible={column.sortable}>
      <TableSortLabel
        active={orderBy === column.id}
        direction={orderBy === column.id ? order : SortOrder.asc}
        onClick={(event) => onSort(event, column.id)}
      >
        {column.label}
      </TableSortLabel>
    </OptionallyVisible>
  </StyledTableCell>
);
