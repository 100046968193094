export const LABELS = {
  CREATE_MODAL: {
    TITLE: ['orderTableCell.layout.createAccountModal.title', 'Create New Account'],
    HEADER: {
      RAMP_TITLE: ['orderTableCell.layout.createAccountModal.header.rampTitle', 'Crypto Ramp'],
      PWC_TITLE: ['orderTableCell.layout.createAccountModal.header.pwcTitle', 'Pay with Crypto'],
    },
    BUTTONS: {
      CREATE: ['orderTableCell.layout.createAccountModal.buttons.create', 'Create Account'],
      CANCEL: ['orderTableCell.layout.createAccountModal.buttons.cancel', 'Cancel'],
      EDIT: ['orderTableCell.layout.createAccountModal.buttons.edit', 'Edit'],
      CONFIRM: ['orderTableCell.layout.createAccountModal.buttons.confirm', 'Confirm'],
      CLOSE: ['orderTableCell.layout.createAccountModal.buttons.close', 'Close'],
    },
    SECTIONS: {
      ACCOUNT_SECTION: {
        TITLE: ['orderTableCell.layout.createAccountModal.sections.accountSection.title', 'Account Name'],
      },
      MERCHANT_ID_SECTION: {
        TITLE: ['orderTableCell.layout.createAccountModal.sections.merchantSection.title', 'Shift4 Merchant ID'],
      },
      ACCOUNT_OWNER_SECTION: {
        TITLE: ['orderTableCell.layout.createAccountModal.sections.accountOwnerSection.title', 'Account Owner Information'],
      },
    },
    FORM_FIELDS: {
      OPTIONAL: ['orderTableCell.layout.createAccountModal.form.optional', 'Optional'],
      PLACEHOLDERS: {
        ACCOUNT_NAME: ['orderTableCell.layout.createAccountModal.form.accountName.placeholder', 'Enter Account Name'],
        EMAIL: ['orderTableCell.layout.createAccountModal.form.email.placeholder', 'Email'],
        MERCHANT_ID: ['orderTableCell.layout.createAccountModal.form.merchant.placeholder', 'Enter Merchant ID'],
        FIRST_NAME: ['orderTableCell.layout.createAccountModal.form.firstName.placeholder', 'First Name'],
        LAST_NAME: ['orderTableCell.layout.createAccountModal.form.lastName.placeholder', 'Last Name'],
      },
    },
    VERIFY_STEP: {
      PRODUCT: {
        TITLE: ['orderTableCell.layout.createAccountModal.verifyStep.product.type', 'Product'],
        RAMP: ['orderTableCell.layout.createAccountModal.verifyStep.product.type', 'Crypto Ramp'],
        PWC: ['orderTableCell.layout.createAccountModal.verifyStep.product.type', 'Pay with Crypto'],
      },
      TITLE: ['orderTableCell.layout.createAccountModal.verifyStep.title', "Please verify the account's information"],
      ACCOUNT_NAME_LABEL: ['orderTableCell.layout.createAccountModal.verifyStep.accountNameLabel', 'Account Name'],
      MERCHANT_ID_LABEL: ['orderTableCell.layout.createAccountModal.verifyStep.accountNameLabel', 'Shift4 Merchant ID'],
      ACCOUNT_OWNER_LABEL: ['orderTableCell.layout.createAccountModal.verifyStep.accountOwnerLabel', 'Account Owner Information'],
      NO_VALUE: ['orderTableCell.layout.createAccountModal.verifyStep.noValue', '-'],
    },
    SUCCESS_STEP: {
      TITLE: ['orderTableCell.layout.createAccountModal.successStep.title', 'Success!'],
      CREATED_LABEL: ['orderTableCell.layout.createAccountModal.successStep.createdLabel', 'successfully created'],
    },
  },
};

const FORM_FIELDS = {
  ACCOUNT_NAME: 'accountName',
  MERCHANT_ID: 'shift4MerchantId',
  EMAIL: 'username',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
};

export const KEYS: { FORM_FIELDS: typeof FORM_FIELDS; OPTIONAL: string[] } = {
  FORM_FIELDS,
  OPTIONAL: [FORM_FIELDS.MERCHANT_ID],
};
