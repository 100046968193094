import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

export const LABELS = {
  PANEL_TITLE: ['page.reconciliations.panelTitle', 'Settlement Reconciliations'],
  TABLE_COLUMNS: {
    DATE: ['page.reconciliations.list.table.columns.date', 'Date of Reconciliation'],
    ID: ['page.reconciliations.list.table.columns.id', 'Reconciliation ID'],
    STATUS: ['page.reconciliations.list.table.columns.status', 'Status'],
    ORDERS: ['page.reconciliations.list.table.columns.orders', '# Orders'],
    FIAT_AMOUNT: ['page.reconciliations.list.table.columns.fiatAmount', 'Volume'],
    ACTIONS: ['page.reconciliations.list.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  DATE: 'createdAt',
  ID: 'uuid',
  STATUS: 'status',
  ORDERS: 'ordersCount',
  FIAT_AMOUNT: 's4PayoutAmount',
  FIAT_CURRENCY: 'currency',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.DATE, label: t(LABELS.TABLE_COLUMNS.DATE) },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID) },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS) },
  { id: COLUMN_IDS.ORDERS, label: t(LABELS.TABLE_COLUMNS.ORDERS) },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT) },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
