import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { CellBlock } from './accountIdCell.styles';

export const AccountIdCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const columnName = column.id;
  const cellValue = row[columnName];

  return (
    <TableCell>
      <CellBlock>{cellValue}</CellBlock>
    </TableCell>
  );
};
