export const TAGS = {
  CUSTOMER_RELATED: 'customerRelated',
  CARDS: 'customerCards',
};

export const KEYS = {
  USD_SYMBOL: 'USD',
  FIAT_CURRENCY_TYPE: 'fiat',
};

export const LABELS = {
  ERRORS: {
    GET_ORDER_PREVIEW: {
      MIN_FIAT_AMOUNT: [
        'services.customers.errors.getOrderPreview.minFiatAmount',
        'The amount requested of {{requestAmount}} is too low, please enter an amount of {{minAmount}} or higher.',
      ],
      MAX_FIAT_AMOUNT: [
        'services.customers.errors.getOrderPreview.minFiatAmount',
        'The amount requested of {{requestAmount}} is too high, please enter a lower amount than {{maxAmount}}.',
      ],
    },
  },
};
