import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import IconDownArrow from 'components/icons/downArrow';
import OptionallyVisible from 'components/optionallyVisible';

import { Order } from 'types/order';

import { getShortenedCryptoCurrencyCode } from 'utils/currencies';
import { getTime } from 'utils/time';

import DetailsSection from './details.buy';
import LABELS, { KEYS } from './keys';
import {
  ArrowIconContainer,
  Card,
  CardBrandCell,
  Cell,
  Circle,
  Container,
  Header,
  HeaderInfoBlock,
  HeaderInfoBlockLighterFont,
  ImportantCell,
  Section,
  SectionContent,
  SectionContentItem,
  SectionSeparator,
  SectionTitle,
  SectionTitleCellLeft,
  TransactionIdText,
  WalletCell,
} from './styles';

interface ReceiptProps {
  order: Order;
  targetRef?: any;
}

const Receipt = ({ order, targetRef }: ReceiptProps) => {
  const { t } = useTranslation();

  const showCardInformation = order?.cardBrand && order.cardLast4;
  const fiatAmountWithoutSymbol = order?.fiatAmount ?? 0;

  const userIdMessage = t(LABELS.ACCOUNT_ID);
  const orderIdMessage = t(LABELS.RECEIPT_DATE_TITLE);

  const approxCryptoPrice = order
    ? t(LABELS.PRICE, {
        symbol: order.cryptoCurrencyCode,
        price: order.cryptoPrice,
        fiatSymbol: order.fiatCurrencyCode,
      })
    : null;

  const walletTag = order.walletAddress?.split(KEYS.TAG_OR_MEMO_SPLIT_STRING)[1];

  return (
    <Container ref={targetRef}>
      <Header>
        <HeaderInfoBlock>
          {orderIdMessage}
          <OptionallyVisible visible={Boolean(order?.createdAt)}>
            <HeaderInfoBlockLighterFont>
              {' - '}
              {getTime(order?.createdAt ?? '')}
            </HeaderInfoBlockLighterFont>
          </OptionallyVisible>
        </HeaderInfoBlock>
        <HeaderInfoBlock>
          {userIdMessage} <HeaderInfoBlockLighterFont>{order?.participantCode}</HeaderInfoBlockLighterFont>
        </HeaderInfoBlock>
      </Header>
      <Card>
        <Section>
          <SectionTitle>
            <SectionTitleCellLeft>
              <Trans>
                {t(LABELS.RECEIPT_ID_TITLE)}
                <TransactionIdText>{order.uuid}</TransactionIdText>
              </Trans>
            </SectionTitleCellLeft>
          </SectionTitle>
          <SectionSeparator />
          <SectionContent>
            <SectionContentItem>
              <ImportantCell>{t(LABELS.PAID)}</ImportantCell>
              <ImportantCell>{`${fiatAmountWithoutSymbol} ${order?.fiatCurrencyCode}`}</ImportantCell>
            </SectionContentItem>
            <OptionallyVisible visible={Boolean(showCardInformation)}>
              <SectionContentItem>
                <Cell>{t(LABELS.PAYMENT_METHOD)}</Cell>
                <CardBrandCell>{`${order?.cardBrand} •••• ${order?.cardLast4}`}</CardBrandCell>
              </SectionContentItem>
            </OptionallyVisible>
            <SectionSeparator>
              <ArrowIconContainer>
                <Circle>
                  <IconDownArrow />
                </Circle>
              </ArrowIconContainer>
            </SectionSeparator>
            <SectionContentItem>
              <ImportantCell>{t(LABELS.RECEIVED)}</ImportantCell>
              <ImportantCell>
                {order.cryptoAmount} {getShortenedCryptoCurrencyCode(order?.cryptoCurrencyCode)}
              </ImportantCell>
            </SectionContentItem>
            <SectionContentItem>
              <Cell>{t(LABELS.QUOTE)}</Cell>
              <Cell>{approxCryptoPrice}</Cell>
            </SectionContentItem>
            <SectionContentItem>
              <Cell>{t(LABELS.WALLET)}</Cell>
              <WalletCell>
                <span>{order?.walletAddress?.split(KEYS.TAG_OR_MEMO_SPLIT_STRING)[0]}</span>
                <OptionallyVisible visible={Boolean(walletTag)}>
                  <>
                    <br />
                    <span>{walletTag}</span>
                  </>
                </OptionallyVisible>
              </WalletCell>
            </SectionContentItem>
          </SectionContent>
        </Section>
        <DetailsSection order={order} fiatAmount={Number(order?.fiatAmount ?? 0)} />
      </Card>
    </Container>
  );
};

export default Receipt;
