import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';

import { CustomerStatus, CustomerTerminationReason } from 'types/customer';

import { StatusBadge } from '../../../components/statusBadge/statusBadge';
import { LABELS } from '../../keys';
import { StatusButton } from '../../styles';
import { BasicStatusChangeModal } from './basicStatusChangeModal';
import { ExtendedStatusChangeModal } from './extendedStatusChangeModal';
import { Container } from './styles';

interface StatusFieldProps {
  status: CustomerStatus;
  canChangeStatus: boolean;
  updateStatus: (nextStatus: CustomerStatus, options?: { reasonCode?: CustomerTerminationReason; notes?: string }) => Promise<boolean>;
  isTerminationAvailable: boolean;
  isUpdating: boolean;
}

export const StatusField = ({ canChangeStatus, status, updateStatus, isUpdating, isTerminationAvailable }: StatusFieldProps) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isCustomerActive = status === CustomerStatus.Active;
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleUpdate = async (nextStatus: CustomerStatus, options?: { reasonCode?: CustomerTerminationReason; notes?: string }) => {
    await updateStatus(nextStatus, options);
    setModalOpen(false);
  };

  const buttonTitle = t(isCustomerActive ? LABELS.CHANGE_STATUS.BLOCK_USER : LABELS.CHANGE_STATUS.MODAL_TITLE);

  const basicModalProps = {
    open: modalOpen,
    onClose: handleClose,
    status,
    onSubmit: handleUpdate,
    isUpdating,
  };

  const modal = isTerminationAvailable ? (
    <ExtendedStatusChangeModal {...basicModalProps} />
  ) : (
    <BasicStatusChangeModal {...basicModalProps} />
  );

  return (
    <Container>
      <StatusBadge status={status} />
      <OptionallyVisible visible={canChangeStatus}>
        <StatusButton small secondary={!isUpdating} flat={isUpdating} onClick={handleOpen} disabled={isUpdating}>
          {buttonTitle}
        </StatusButton>
      </OptionallyVisible>
      {modal}
    </Container>
  );
};
