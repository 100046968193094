import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUpdateAccountMutation } from 'services/accounts';
import { useGetAccountApiKeysQuery } from 'services/apiKeys';
import { useGetActiveAssetListQuery } from 'services/assets';
import { useUploadAssetMutation } from 'services/staticAssets';

import { FormFieldType } from 'components/form/formLayout/types';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountWidgetSettings } from 'types/account';
import { Asset, AssetType } from 'types/assets';
import { ValidationErrorMeta } from 'types/error';
import { UserAccessLevel } from 'types/user';

import { getValidationDetails } from 'utils/error';
import { capitalizeFirstLetter } from 'utils/string';

import { getFormValues } from '../../utils';
import { KEYS, LABELS, formFields } from './keys';
import { WidgetSection } from './widget';

export const WidgetSectionContainer = () => {
  const { t } = useTranslation();
  const { account } = useSelector(selectAccount);
  const accountData = getFormValues(account, formFields) as AccountWidgetSettings;
  const [values, setValues] = useState<AccountWidgetSettings>(accountData);
  const [previewSeed, setPreviewSeed] = useState<string>(Math.random().toString());
  const [saveAccountInfo, { isLoading: isUpdating, error, reset }] = useUpdateAccountMutation();
  const [uploadAsset, { isLoading: isAssetUploading, reset: resetUpload }] = useUploadAssetMutation();
  const { data } = useGetAccountApiKeysQuery(account.uuid, {
    skip: !account.uuid,
  });
  const { data: assets = [] } = useGetActiveAssetListQuery();
  const userLevel = useSelector(selectUserLevel);

  useEffect(() => {
    setValues(getFormValues(account, formFields) as AccountWidgetSettings);
  }, [account.uuid]);

  const handleFormValueChange = (key: string, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
    reset();
    resetUpload();
  };

  const isAdmin = Boolean(userLevel && userLevel >= UserAccessLevel.Admin);
  const disabledFields = {
    isCertified: !isAdmin,
    processingFee: !isAdmin,
  };

  const readOnlyMode = Boolean(userLevel && userLevel === UserAccessLevel.Support);

  const resetFormValues = () => {
    setValues(getFormValues(account, formFields) as AccountWidgetSettings);
    reset();
    resetUpload();
    setPreviewSeed(Math.random().toString());
  };

  const uploadFormFile = async (fieldName: keyof AccountWidgetSettings): Promise<boolean | { [field: string]: string }> => {
    const isFileUpdated = !isEqual(account[fieldName], values[fieldName]);
    const isFile = values[fieldName] instanceof File;
    const shouldUploadFile = isFileUpdated && isFile;
    if (shouldUploadFile) {
      const response = await uploadAsset(values[fieldName] as File);
      if ('error' in response) {
        return false;
      }

      return { [fieldName]: response.data };
    }

    return true;
  };

  const uploadFormFiles = async (): Promise<boolean | { [field: string]: string }> => {
    const fileFields = formFields.filter((field) => field.type === FormFieldType.File);
    const promises = fileFields.map((field) => {
      const fieldName = field.key as keyof AccountWidgetSettings;
      return uploadFormFile(fieldName);
    });

    const result = await Promise.all(promises);
    if (!result.every(Boolean)) {
      return false;
    }

    return result.reduce((output, response) => {
      if (typeof response === 'object') {
        return {
          ...(output as object),
          ...response,
        };
      }

      return output;
    }, {});
  };

  const saveFormValues = async () => {
    if (readOnlyMode) {
      return;
    }

    const changedValues = await uploadFormFiles();
    if (!changedValues) {
      return;
    }

    const payload: Partial<AccountWidgetSettings> = {
      ...values,
      ...(changedValues as object),
    };

    const disabledFieldKeys = Object.entries(disabledFields)
      .filter(([, value]) => Boolean(value))
      .map(([key]) => key);

    const diffValues = (Object.keys(payload) as (keyof AccountWidgetSettings)[])
      .filter((key) => account[key] !== payload[key])
      .reduce((result, key) => ({ ...result, [key]: payload[key] }), { uuid: account.uuid });

    const preparedValues = omit(diffValues, disabledFieldKeys);
    await saveAccountInfo(preparedValues);
    setPreviewSeed(Math.random().toString());
    setValues({
      ...values,
      ...omit(preparedValues, KEYS.UUID),
    });
  };

  const isFormChanged = !isEqual(values, accountData);
  const saveErrorMeta = error ? ((error as FetchBaseQueryError).data as { meta: ValidationErrorMeta })?.meta : undefined;
  const saveError = saveErrorMeta ? getValidationDetails(saveErrorMeta) : undefined;
  const possibleFiles = formFields.reduce((output, field) => {
    if (field.type !== FormFieldType.File) {
      return output;
    }

    return {
      ...output,
      [field.key]: values[field.key as keyof AccountWidgetSettings],
    };
  }, {});

  const fileErrors = Object.entries(possibleFiles).reduce((result, [key, value]) => {
    const shouldAddError = value instanceof File && value.size > KEYS.MAX_LOGO_SIZE;
    if (shouldAddError) {
      return {
        ...result,
        [key]: t(LABELS.FORM_FIELDS.ERRORS.MAX_FILE_SIZE),
      };
    }

    return result;
  }, {});

  const displayedError = saveError || fileErrors;
  const isSaveAvailable = isFormChanged && isEmpty(displayedError);
  const getCryptoLabel = (asset: Asset) => {
    const [symbol] = asset.symbol.split(KEYS.CRYPTO_SYMBOL_SEPARATOR);
    const chainName = capitalizeFirstLetter(asset.chainCode.replaceAll(KEYS.CRYPTO_NETWORK_SPACING_SYMBOLS_EXP, ' '));
    return `${symbol} - ${chainName}`;
  };

  const cryptoCurrencies: CurrencyItem[] = assets
    .filter((asset) => asset.type === AssetType.Crypto)
    .map((asset) => ({
      key: asset.symbol,
      icon: asset.iconUrl,
      name: asset.name,
      label: getCryptoLabel(asset),
      code: asset.symbol,
    }));

  const fiatCurrencies: CurrencyItem[] = assets
    .filter((asset) => asset.type === AssetType.Fiat)
    .map((asset) => ({
      key: asset.symbol,
      icon: asset.iconUrl,
      name: asset.name,
      label: asset.symbol,
      code: asset.symbol,
    }));

  return (
    <WidgetSection
      account={values}
      initialValues={account}
      onFormValueChange={handleFormValueChange}
      isFormChanged={isFormChanged}
      isSaveAvailable={isSaveAvailable}
      resetFormValues={resetFormValues}
      saveFormValues={saveFormValues}
      disabledFields={disabledFields}
      error={displayedError}
      isUpdating={isUpdating || isAssetUploading}
      readOnlyMode={readOnlyMode}
      cryptoCurrencies={cryptoCurrencies}
      fiatCurrencies={fiatCurrencies}
      previewOptions={{
        publicKey: data?.publicKey,
        seed: previewSeed,
      }}
    />
  );
};
