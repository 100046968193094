import React, { FunctionComponent } from 'react';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { Icon } from './isPartnerCertifiedCell.styles';

export const IsPartnerCertifiedCell: FunctionComponent<DataTableCellProps> = ({ row, column }) => {
  const columnName = column.id;
  const cellValue = row[columnName];

  return <TableCell align="center">{cellValue ? <Icon /> : ''}</TableCell>;
};
