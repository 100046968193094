import React from 'react';
import { useTranslation } from 'react-i18next';

import QuestionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers/panel/panel';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';
import { Body, Buttons, Content, Image, Message, PanelTitle, Title } from 'components/modal/styles';

import { DialogNotification } from 'types/notifications';

import { LABELS } from './keys';
import { useNotificationsController } from './notificationController';
import { ModalControls, ModalRoot } from './styles';

export const DialogNotifications = () => {
  const { dialogNotifications, removeNotification } = useNotificationsController();
  const { t } = useTranslation();

  const renderDialogNotification = ({ key, onClose, message, title, image, dialogTitle }: DialogNotification) => {
    const closeModal = () => {
      removeNotification(key);
      onClose?.();
    };

    const modalTitle = dialogTitle || t(LABELS.DIALOG.TITLE);

    const usedImage = image || <Image src={QuestionImage} />;

    return (
      <Modal open onClose={closeModal}>
        <ModalRoot>
          <Panel
            label={<PanelTitle>{modalTitle}</PanelTitle>}
            controls={
              <ModalControls>
                <Button flat onClick={closeModal}>
                  <IconCross />
                </Button>
              </ModalControls>
            }
          >
            <Body>
              <Content>
                {usedImage}
                <Title>{title}</Title>
                <Message>{message}</Message>
              </Content>
              <Delimeter />
              <Buttons>
                <Button primary onClick={closeModal}>
                  {t(LABELS.DIALOG.DISMISS)}
                </Button>
              </Buttons>
            </Body>
          </Panel>
        </ModalRoot>
      </Modal>
    );
  };

  if (!dialogNotifications.length) {
    return null;
  }

  const [nextNotification] = dialogNotifications;

  return renderDialogNotification(nextNotification);
};
