import { OptionallyVisible } from 'components';
import React, { useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { MerchantInfo, MerchantStatus } from 'services/merchants/types';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';

import { SuspendMerchantModal } from './components/modal';
import { GeneralAccountSettingForm } from './form/form';
import { LABELS } from './keys';
import { Controls, FormContainer } from './styles';

interface GeneralSectionProps extends WithTranslation {
  merchant: MerchantInfo;
  isFormChanged: boolean;
  isSaveAvailable: boolean;
  resetFormValues: () => void;
  saveFormValues: () => void;
  onFormValueChange: (key: string, value: any) => void;
  onStatusChange: (status: MerchantStatus) => void;
  hiddenFields: Record<string, boolean>;
  isUpdating: boolean;
  isUpdatingStatus: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
}

export const GeneralSectionComponent = ({
  t,
  merchant,
  isSaveAvailable,
  isFormChanged,
  resetFormValues,
  onFormValueChange,
  onStatusChange,
  saveFormValues,
  hiddenFields,
  isUpdating,
  isUpdatingStatus,
  error,
  readOnlyMode,
}: GeneralSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleStatusChange = async (status: MerchantStatus) => {
    if (status === MerchantStatus.Deactivated) {
      setIsModalVisible(true);
      return;
    }

    onStatusChange(status);
  };

  return (
    <>
      <Panel
        label={t(LABELS.PANEL_TITLE)}
        controls={
          readOnlyMode ? undefined : (
            <Controls>
              <OptionallyVisible visible={isFormChanged}>
                <Button onClick={resetFormValues} secondary disabled={isUpdating}>
                  {t(LABELS.CANCEL_BUTTON_LABEL)}
                </Button>
              </OptionallyVisible>
              <Button onClick={saveFormValues} primary disabled={!isSaveAvailable || isUpdating}>
                {t(LABELS.SAVE_BUTTON_LABEL)}
              </Button>
            </Controls>
          )
        }
      >
        <FormContainer>
          <GeneralAccountSettingForm
            merchant={merchant}
            onFieldValueChange={onFormValueChange}
            onStatusChange={handleStatusChange}
            hiddenFields={hiddenFields}
            isUpdating={isUpdating}
            isUpdatingStatus={isUpdatingStatus}
            error={error}
            readOnlyMode={readOnlyMode}
          />
        </FormContainer>
      </Panel>
      <SuspendMerchantModal
        onClose={() => setIsModalVisible(false)}
        onConfirm={() => onStatusChange(MerchantStatus.Deactivated)}
        open={isModalVisible}
      >
        <Trans
          i18nkey={LABELS.MODAL.DEACTIVATION_MESSAGE[0]}
          defaults={LABELS.MODAL.DEACTIVATION_MESSAGE[1]}
          values={{
            name: merchant.name,
          }}
          components={{ b: <b /> }}
        />
      </SuspendMerchantModal>
    </>
  );
};

export const GeneralSection = withTranslation()(GeneralSectionComponent);
