import React from 'react';

import s4cLogo from 'assets/images/logoExpanded.svg';

import { LogoIcon } from './styles';

interface LogoProps {
  className?: string;
  size: number;
}

export const Logo = ({ className, size }: LogoProps) => (
  <LogoIcon
    src={s4cLogo}
    alt="loading"
    className={className}
    style={{
      maxWidth: size,
    }}
  />
);

export default Logo;
