import RoutePath from 'router/path';

import { AccountAccessLevel, AccountType } from 'types/account';
import { GetDefaultLoginRedirectRoute, RouteSetup, RouteSetupFactory } from 'types/routing';

import PartnerAdministratorRouterSetup from './administrator';
import PartnerOwnerRouterSetup from './owner';
import PartnerViewerRouterSetup from './viewer';

const getDefaultLoginRedirectRoute = (hasAccounts: boolean, accountType: AccountType): string => {
  if (!hasAccounts) {
    return RoutePath.Profile.Root;
  }

  return accountType === AccountType.Pwc ? RoutePath.Pwc.Orders.Root : RoutePath.Ramp.Orders.Root;
};

const getRouteSetupMap: Record<
  AccountAccessLevel,
  (hasAccount: boolean, accountType: AccountType, getDefaultLoginRedirectRoute: GetDefaultLoginRedirectRoute) => RouteSetup
> = {
  [AccountAccessLevel.Owner]: PartnerOwnerRouterSetup,
  [AccountAccessLevel.Administrator]: PartnerAdministratorRouterSetup,
  [AccountAccessLevel.Viewer]: PartnerViewerRouterSetup,
};

export const PartnerRouterSetup: RouteSetupFactory = ({ accountAccessLevel, hasAccounts, accountType }): RouteSetup =>
  accountAccessLevel
    ? getRouteSetupMap[accountAccessLevel as AccountAccessLevel](hasAccounts, accountType, getDefaultLoginRedirectRoute)
    : PartnerViewerRouterSetup(hasAccounts, accountType, getDefaultLoginRedirectRoute);

export default PartnerRouterSetup;
