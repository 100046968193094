import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

let getAccessTokenFn: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

export const auth0TokenStorage = {
  getAccessToken: () => getAccessTokenFn,
  setAuth0AccessTokenFn: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) => {
    getAccessTokenFn = func;
  },
};
