import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import LayoutWithSidebarDesktop from './desktop/layoutWithSidebar.desktop';
import LayoutWithSidebarMobile from './mobile/layoutWithSidebar.mobile';
import { LayoutWithSidebarProps } from './types';

export const LayoutWithSidebar: React.FC<LayoutWithSidebarProps> = ({ ...other }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return isDesktop ? <LayoutWithSidebarDesktop {...other} /> : <LayoutWithSidebarMobile {...other} />;
};
export default LayoutWithSidebar;
