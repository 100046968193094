import React, { useState } from 'react';

import { AmountFilterValue } from 'types/filters';

import { Input, InputType } from '../../inputs';
import { Select } from '../../inputs/select/select';
import OptionallyVisible from '../../optionallyVisible';
import { Container, Row } from '../styles';
import { AmountFilterPassedProps } from '../types';
import { KEYS, variants } from './keys';
import { Inputs } from './styles';
import { AmountInputVariant, AmountInputVariantItem } from './types';

interface AmountFilterProps extends Omit<AmountFilterPassedProps, 'type'> {
  value: AmountFilterValue;
  onChange: (value: AmountFilterValue) => void;
}

export const AmountFilter = ({ value = {}, onChange }: AmountFilterProps) => {
  const [selectedVariant, setSelectedVariant] = useState<AmountInputVariantItem>(variants[0]);
  const [fieldValues, setFieldValues] = useState<{ lte: string; gte: string }>({
    lte: value?.lte?.toString() || '',
    gte: value?.gte?.toString() || '',
  });

  const handleInputChange = (field: string) => (nextValue: string) => {
    setFieldValues({
      ...fieldValues,
      [field]: nextValue,
    });

    if (selectedVariant.key === AmountInputVariant.IsEqual) {
      onChange({
        gte: nextValue ? Number(nextValue) : undefined,
        lte: nextValue ? Number(nextValue) : undefined,
      });

      return;
    }

    onChange({
      ...value,
      [field]: nextValue ? Number(nextValue) : undefined,
    });
  };

  const handleVariantChange = (variant: AmountInputVariantItem) => {
    setSelectedVariant(variant);
    if (variant.key === AmountInputVariant.GreaterThan) {
      onChange({
        ...value,
        lte: undefined,
      });
      return;
    }

    if (variant.key === AmountInputVariant.LessThan) {
      onChange({
        ...value,
        gte: undefined,
      });
    }
  };

  return (
    <Container>
      <Row>
        <Select<AmountInputVariantItem>
          value={selectedVariant}
          items={variants}
          onChange={handleVariantChange}
          getItemLabel={(item) => item.label}
          closeDropdownOnSelect
        />
        <Inputs>
          <OptionallyVisible visible={selectedVariant.key === AmountInputVariant.IsBetween}>
            <Input type={InputType.Number} value={fieldValues.gte || ''} onChange={handleInputChange(KEYS.GREATER_THAN)} />
            <Input type={InputType.Number} value={fieldValues.lte || ''} onChange={handleInputChange(KEYS.LESS_THAN)} />
          </OptionallyVisible>
          <OptionallyVisible visible={[AmountInputVariant.IsEqual, AmountInputVariant.GreaterThan].includes(selectedVariant.key)}>
            <Input type={InputType.Number} value={fieldValues.gte || ''} onChange={handleInputChange(KEYS.GREATER_THAN)} />
          </OptionallyVisible>
          <OptionallyVisible visible={selectedVariant.key === AmountInputVariant.LessThan}>
            <Input type={InputType.Number} value={fieldValues.lte || ''} onChange={handleInputChange(KEYS.LESS_THAN)} />
          </OptionallyVisible>
        </Inputs>
      </Row>
    </Container>
  );
};
