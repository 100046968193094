import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M6.25731 8.18712L1.75 5.63982V10.7344L6.25731 13.3327V8.18712Z',
  'M2.56949 4.26427L7.07681 1.66602L11.5329 4.26427L7.07681 7.01536L2.56949 4.26427Z',
  'M7.74269 13.3327V8.18713L12.25 5.58887V10.7854L7.74269 13.3327Z',
];

export const IconCrypto = ({ width = 14, height = 15, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path[0]} fill={color} />
    <Path d={path[1]} fill={color} />
    <Path d={path[2]} fill={color} />
  </Svg>
);

export default IconCrypto;
