import { OptionallyVisible } from 'components';
import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { LABELS } from '../../apiKeys.keys';
import { ApiKeysTableRow } from '../../types';
import { Button, Icon, RevealedText } from './revealSecretKeyCell.styles';

type RevealSecretKeyCellComponentProps = DataTableCellProps & WithTranslation;

const RevealSecretKeyCellComponent: FunctionComponent<RevealSecretKeyCellComponentProps> = ({ row, t }) => {
  const { isSecretKey, keyValue } = row as ApiKeysTableRow;
  const [showSecretKey, setShowSecretKey] = useState(false);

  const handleOnButtonClick = () => {
    setShowSecretKey(true);
  };

  if (isSecretKey) {
    return (
      <TableCell align="left">
        <OptionallyVisible visible={showSecretKey}>
          <RevealedText>{keyValue}</RevealedText>
        </OptionallyVisible>
        <OptionallyVisible visible={!showSecretKey}>
          <Button flat onClick={handleOnButtonClick}>
            <Icon /> {t(LABELS.REVEAL)}
          </Button>
        </OptionallyVisible>
      </TableCell>
    );
  }

  return <TableCell align="left">{keyValue}</TableCell>;
};

export const RevealSecretKeyCell = withTranslation()(RevealSecretKeyCellComponent);
