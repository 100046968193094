import React, { ReactNode } from 'react';

import { ErrorMessage, FormFieldRoot } from './styles';

export type FormFieldProps<T> = T & {
  error?: string;
  hideErrorMessage?: boolean;
  touched?: boolean;
  children: ReactNode;
};

export const FormField = <T extends object>({ error, touched, children, hideErrorMessage }: FormFieldProps<T>) => (
  <FormFieldRoot error={Boolean(error)} touched={touched}>
    {children}
    <ErrorMessage visible={Boolean(error && !hideErrorMessage)}>{error}</ErrorMessage>
  </FormFieldRoot>
);
