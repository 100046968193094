export const downloadFile = ({ name, source }: { name: string; source: string }) => {
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = source;
  downloadLink.target = '_self';
  downloadLink.download = name;
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadRemoteFile = async ({ name, source }: { name: string; source: string }) => {
  const downloadLink = document.createElement('a');
  const response = await fetch(source);
  const blob = await response.blob();
  const blobSource = URL.createObjectURL(blob);
  document.body.appendChild(downloadLink);
  downloadLink.href = blobSource;
  downloadLink.target = '_self';
  downloadLink.download = name;
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default downloadFile;
