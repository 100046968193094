import { t } from 'i18next';

import { FormFieldType } from 'components/form/formLayout/types';

import { AccountEmbeddableThemeMode, AccountWidgetSettings } from 'types/account';

import { SettingsFormField } from '../../types';
import { WidgetModeItem } from './types';

export const FORM_FIELDS: Record<string, keyof AccountWidgetSettings> = {
  THEME_COLOR: 'widgetColor',
  THEME_MODE: 'widgetThemeMode',
  BORDER_RADIUS: 'cornerRadius',
  DISPLAY_ILLUSTRATIONS: 'displayIllustration',
  DEFAULT_CRYPTO_CURRENCY: 'defaultCryptoCurrencyCode',
  SUPPORTED_CRYPTO_CURRENCIES: 'supportedCryptoCurrenciesCodes',
  POPULAR_CRYPTO_CURRENCIES: 'popularCryptoCurrenciesCodes',
  SUPPORTED_FIAT_CURRENCIES: 'supportedFiatCurrenciesCodes',
  WIDGET_LOGO: 'widgetLogo',
  WIDGET_LOGO_DARK: 'widgetLogoDark',
};

export const KEYS = {
  UUID: 'uuid',
  MAX_LOGO_SIZE: 1024 * 1024,
  DEFAULT_BORDER_RADIUS: 5,
  DEFAULT_WIDGET_COLOR: '#000',
  CRYPTO_SYMBOL_SEPARATOR: '.',
  CRYPTO_NETWORK_SPACING_SYMBOLS_EXP: /[_]/gi,
};

export const LABELS = {
  SEARCH_RESULTS: ['page.accountSettings.widget.form.searchResults', 'Results'],
  CRYPTO_NOT_FOUND: ['page.accountSettings.widget.form.cryptoNotFound', 'We don’t support this cryptocurrency yet! '],
  PANEL_TITLE: ['page.accountSettings.widget.title', 'Widget'],
  SAVE_BUTTON_LABEL: ['page.accountSettings.widget.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.accountSettings.widget.cancel', 'Cancel'],
  FORM_FIELDS: {
    THEME_COLOR: ['page.accountSettings.widget.form.widgetColor.title', 'Theme Color'],
    THEME_MODE: ['page.accountSettings.widget.form.widgetThemeMode.title', 'Widget Theme Mode'],
    BORDER_RADIUS: ['page.accountSettings.widget.form.cornerRadius.title', 'Corner Radius'],
    DISPLAY_ILLUSTRATIONS: ['page.accountSettings.widget.form.displayIllustrations.title', 'Display Illustrations'],
    DEFAULT_CRYPTO_CURRENCY: ['page.accountSettings.widget.form.defaultCryptoCurrency.title', 'Default Crypto Currency'],
    SUPPORTED_CRYPTO_CURRENCIES: ['page.accountSettings.widget.form.supportedCryptoCurrencies.title', 'Supported Crypto Currencies'],
    POPULAR_CRYPTO_CURRENCIES: ['page.accountSettings.widget.form.popularCryptoCurrencies.title', 'Popular Crypto Currencies'],
    SUPPORTED_FIAT_CURRENCIES: ['page.accountSettings.widget.form.supportedFiatCurrencies.title', 'Supported Fiat Currencies'],
    WIDGET_LOGO: ['page.accountSettings.widget.form.widgetLogo.title', 'Logo in Light Mode Widget'],
    WIDGET_LOGO_DARK: ['page.accountSettings.widget.form.widgetLogoDark.title', 'Logo in Dark Mode Widget'],
    ERRORS: {
      MAX_FILE_SIZE: ['page.accountSettings.widget.form.logo.maxFileSizeError', 'Maximum file size is 1MB'],
    },
    PLACEHOLDERS: {
      THEME_MODE: ['page.accountSettings.widget.form.widgetThemeMode.placeholder', 'Widget mode'],
      WIDGET_LOGO: ['page.accountSettings.widget.form.widgetLogo.placeholder', 'Logo'],
      DEFAULT_CRYPTO_CURRENCY: ['page.accountSettings.widget.form.defaultCryptoCurrency.placeholder', 'Default Crypto Currency'],
      SUPPORTED_CRYPTO_CURRENCIES: ['page.accountSettings.widget.form.supportedCryptoCurrencies.placeholder', 'Choose Crypto Currencies'],
      POPULAR_CRYPTO_CURRENCIES: ['page.accountSettings.widget.form.popularCryptoCurrencies.placeholder', 'Choose Popular Crypto'],
      SUPPORTED_FIAT_CURRENCIES: ['page.accountSettings.widget.form.supportedFiatCurrencies.placeholder', 'Choose Fiat Currencies'],
    },
    VALUES: {
      PROCESSING_FEE: ['page.accountSettings.widget.form.value.processingFee', '{{fee}}%'],
    },
    MESSAGES: {
      LOGO: {
        SUPPORTED_FORMAT: ['page.accountSettings.widget.form.messages.logo.supportedFormat', 'Supported Formats: JPG, PNG'],
        MAX_FILE_SIZE: ['page.accountSettings.widget.form.messages.logo.maxFileSize', 'Maximum Size: 1MB'],
        SAVE_TO_UPDATE: ['page.accountSettings.widget.form.messages.logo.saveToUpdate', 'Image will be updated in preview only after save'],
      },
    },
  },
  WIDGET_MODES: {
    LIGHT: ['page.accoutnSettings.widget.form.widgetThemeMode.label.light', 'Light'],
    DARK: ['page.accoutnSettings.widget.form.widgetThemeMode.label.dark', 'Dark'],
    SAME_AS_BROWSER: ['page.accoutnSettings.widget.form.widgetThemeMode.label.sameAsBrowser', 'Same as browser'],
  },
};

export const logoAcceptRules = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const widgetModeItems: WidgetModeItem[] = [
  {
    key: AccountEmbeddableThemeMode.Light,
    label: t(LABELS.WIDGET_MODES.LIGHT),
  },
  {
    key: AccountEmbeddableThemeMode.Dark,
    label: t(LABELS.WIDGET_MODES.DARK),
  },
  {
    key: AccountEmbeddableThemeMode.SameAsBrowser,
    label: t(LABELS.WIDGET_MODES.SAME_AS_BROWSER),
  },
];

export const formFields: SettingsFormField[] = [
  {
    key: FORM_FIELDS.THEME_COLOR,
    label: t(LABELS.FORM_FIELDS.THEME_COLOR),
    type: FormFieldType.Color,
  },
  {
    key: FORM_FIELDS.THEME_MODE,
    label: t(LABELS.FORM_FIELDS.THEME_MODE),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.BORDER_RADIUS,
    label: t(LABELS.FORM_FIELDS.BORDER_RADIUS),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.DISPLAY_ILLUSTRATIONS,
    label: t(LABELS.FORM_FIELDS.DISPLAY_ILLUSTRATIONS),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.DEFAULT_CRYPTO_CURRENCY,
    label: t(LABELS.FORM_FIELDS.DEFAULT_CRYPTO_CURRENCY),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.SUPPORTED_CRYPTO_CURRENCIES,
    label: t(LABELS.FORM_FIELDS.SUPPORTED_CRYPTO_CURRENCIES),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.POPULAR_CRYPTO_CURRENCIES,
    label: t(LABELS.FORM_FIELDS.POPULAR_CRYPTO_CURRENCIES),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.SUPPORTED_FIAT_CURRENCIES,
    label: t(LABELS.FORM_FIELDS.SUPPORTED_FIAT_CURRENCIES),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.WIDGET_LOGO,
    label: t(LABELS.FORM_FIELDS.WIDGET_LOGO),
    type: FormFieldType.File,
  },
  {
    key: FORM_FIELDS.WIDGET_LOGO_DARK,
    label: t(LABELS.FORM_FIELDS.WIDGET_LOGO_DARK),
    type: FormFieldType.File,
  },
];
