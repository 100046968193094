import { OptionallyVisible } from 'components';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import { MultilineInput } from 'components/inputs/input/multilineInput';
import { RadioGroup } from 'components/inputs/radio/radioGroup';
import Modal from 'components/modal/modal';
import { Body, CloseButton, PanelTitle } from 'components/modal/styles';

import { CustomerStatus, CustomerTerminationReason } from 'types/customer';

import { KEYS, LABELS } from '../../keys';
import { StatusChangeModalBasicMessage, StatusChangeModalButtons } from './modalComponents';
import { ColumnContainer, Label, TerminationReasonRow } from './styles';

interface ExtendedStatusChangeModalProps {
  open: boolean;
  onClose: () => void;
  status: CustomerStatus;
  onSubmit: (nextStatus: CustomerStatus, options?: { reasonCode?: CustomerTerminationReason; notes?: string }) => Promise<void>;
  isUpdating: boolean;
}

export const ExtendedStatusChangeModal = ({ open, onClose, status, onSubmit, isUpdating }: ExtendedStatusChangeModalProps) => {
  const isCustomerActive = status === CustomerStatus.Active;
  const defaultStatus = isCustomerActive ? CustomerStatus.Blocked : CustomerStatus.Active;
  const [blockType, setBlockType] = useState<CustomerStatus>(defaultStatus);
  const [terminationReason, setTerminationReason] = useState<CustomerTerminationReason>(CustomerTerminationReason.ComplianceIssue);
  const [notes, setNotes] = useState<string>('');
  const { t } = useTranslation();

  const blockOptions = [
    isCustomerActive
      ? {
          key: CustomerStatus.Blocked,
          value: CustomerStatus.Blocked,
          label: t(LABELS.CHANGE_STATUS.BLOCK_USER),
        }
      : {
          key: CustomerStatus.Active,
          value: CustomerStatus.Active,
          label: t(LABELS.CHANGE_STATUS.UNBLOCK_USER),
        },
    {
      key: CustomerStatus.Terminated,
      value: CustomerStatus.Terminated,
      label: t(LABELS.CHANGE_STATUS.TERMINATE_USER),
    },
  ];

  const terminationReasonOptions = [
    {
      key: CustomerTerminationReason.ComplianceIssue,
      value: CustomerTerminationReason.ComplianceIssue,
      label: t(LABELS.CHANGE_STATUS.COMPLIANCE_ISSUE),
    },
    {
      key: CustomerTerminationReason.UserRequest,
      value: CustomerTerminationReason.UserRequest,
      label: t(LABELS.CHANGE_STATUS.USER_REQUEST),
    },
  ];

  const flushState = () => {
    setNotes('');
    setTerminationReason(CustomerTerminationReason.ComplianceIssue);
    setBlockType(defaultStatus);
  };

  const handleBlockTypeChange = (nextValue: string) => {
    setBlockType(nextValue as CustomerStatus);
  };

  const handleTerminationReasonChange = (nextValue: string) => {
    setTerminationReason(nextValue as CustomerTerminationReason);
  };

  const handleSubmit = async () => {
    if (!isCustomerActive) {
      const nextStatus = CustomerStatus.Active;
      await onSubmit(nextStatus);
      flushState();
      return;
    }

    const options = { reasonCode: terminationReason, notes };

    await onSubmit(blockType, options);
    flushState();
  };

  const handleClose = () => {
    flushState();
    onClose();
  };

  useEffect(() => {
    flushState();
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Panel
        label={<PanelTitle>{t(LABELS.CHANGE_STATUS.MODAL_TITLE)}</PanelTitle>}
        controls={
          <CloseButton flat onClick={handleClose}>
            <IconCross />
          </CloseButton>
        }
      >
        <Body>
          <ColumnContainer>
            <Label>{t(LABELS.CHANGE_STATUS.MODAL_TITLE)}</Label>
            <RadioGroup horizontal options={blockOptions} onChange={handleBlockTypeChange} value={blockType} />
          </ColumnContainer>
          <Delimeter />
          <OptionallyVisible visible={blockType !== CustomerStatus.Terminated}>
            <StatusChangeModalBasicMessage isCustomerActive={isCustomerActive} />
          </OptionallyVisible>
          <ColumnContainer>
            <OptionallyVisible visible={blockType === CustomerStatus.Terminated}>
              <TerminationReasonRow>
                <Label>
                  <Trans
                    i18nKey={LABELS.CHANGE_STATUS.TERMINATION_DISCLAIMER[0]}
                    defaults={LABELS.CHANGE_STATUS.TERMINATION_DISCLAIMER[1]}
                    components={[<br />]}
                  />
                </Label>
              </TerminationReasonRow>
            </OptionallyVisible>
            <OptionallyVisible visible={blockType === CustomerStatus.Terminated}>
              <TerminationReasonRow>
                <ColumnContainer>
                  <Label>{t(LABELS.CHANGE_STATUS.TERMINATION_REASON_LABEL)}</Label>
                  <RadioGroup
                    options={terminationReasonOptions}
                    value={terminationReason}
                    onChange={handleTerminationReasonChange}
                    horizontal
                  />
                </ColumnContainer>
              </TerminationReasonRow>
            </OptionallyVisible>
            <OptionallyVisible visible={status === CustomerStatus.Active || blockType === CustomerStatus.Terminated}>
              <TerminationReasonRow>
                <ColumnContainer>
                  <Label>{t(LABELS.CHANGE_STATUS.TERMINATION_NOTES_LABEL)}</Label>
                  <MultilineInput
                    fullWidth
                    value={notes}
                    onChange={setNotes}
                    placeholder={t(LABELS.CHANGE_STATUS.TERMINATION_NOTES_PLACEHOLDER)}
                    rows={KEYS.TERMINATION_NOTES_MIN_ROWS}
                  />
                </ColumnContainer>
              </TerminationReasonRow>
            </OptionallyVisible>
          </ColumnContainer>
          <Delimeter />
          <StatusChangeModalButtons onClose={onClose} onSubmit={handleSubmit} isUpdating={isUpdating} />
        </Body>
      </Panel>
    </Modal>
  );
};
