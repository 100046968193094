import { OptionallyVisible } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCopy from 'components/icons/copy';
import IconCross from 'components/icons/cross';
import IconLink from 'components/icons/link';
import { Input } from 'components/inputs';
import Modal from 'components/modal/modal';
import { Buttons, Delimeter, ModalControls, Text } from 'components/modal/styles';

import { KEYS, LABELS } from '../keys';
import {
  Adornment,
  Content,
  CopyButton,
  CopyPanel,
  CopyPanelText,
  Error,
  ModalRoot,
  Section,
  SectionContent,
  SectionTitle,
} from './styles';

interface AddDomainModalProps {
  onSubmit: (domain: string) => Promise<boolean>;
  isSubmitting: boolean;
  reset: () => void;
  open: boolean;
  onClose: () => void;
  downloadFile: () => void;
  error?: string;
}

export const AddDomainModal = ({ onSubmit, error, isSubmitting, reset, open, onClose, downloadFile }: AddDomainModalProps) => {
  const [domain, setDomain] = useState<string>('');
  const { t } = useTranslation();

  const handleChangeDomain = (value: string) => {
    setDomain(value);
  };

  const closeModal = () => {
    reset();
    setDomain('');
    onClose();
  };

  const handleSubmit = async () => {
    const result = await onSubmit(domain);
    if (result) {
      closeModal();
    }
  };

  const domainPath = [domain || KEYS.DEFAULT_DOMAIN, KEYS.DOMAIN_PATH].join('');

  const copyText = async () => {
    await navigator.clipboard.writeText(domainPath);
  };

  const isSubmitDisabled = !domain || isSubmitting;

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.ADD_DOMAIN.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <Content>
            <Section>
              <SectionTitle>{t(LABELS.ADD_DOMAIN.DOMAIN_LABEL)}</SectionTitle>
              <SectionContent>
                <Input
                  value={domain}
                  placeholder={t(LABELS.ADD_DOMAIN.DOMAIN_PLACEHOLDER)}
                  onChange={handleChangeDomain}
                  startAdornment={
                    <Adornment>
                      <IconLink />
                    </Adornment>
                  }
                />
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>{t(LABELS.ADD_DOMAIN.DOWNLOAD_LABEL)}</SectionTitle>
              <SectionContent>
                <Button secondary onClick={downloadFile}>
                  {t(LABELS.ADD_DOMAIN.DOWNLOAD)}
                </Button>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>{t(LABELS.ADD_DOMAIN.UPLOAD_LABEL)}</SectionTitle>
              <SectionContent>
                <Text>{t(LABELS.ADD_DOMAIN.UPLOAD_CAPTION)}</Text>
                <CopyPanel>
                  <CopyPanelText>{domainPath}</CopyPanelText>
                  <CopyButton flat onClick={copyText}>
                    <IconCopy />
                  </CopyButton>
                </CopyPanel>
                <Text>{t(LABELS.ADD_DOMAIN.UPLOAD_HINT)}</Text>
              </SectionContent>
            </Section>
            <OptionallyVisible visible={Boolean(error)}>
              <Error>{error}</Error>
            </OptionallyVisible>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={closeModal}>
                {t(LABELS.ADD_DOMAIN.CANCEL)}
              </Button>
              <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
                {t(LABELS.ADD_DOMAIN.SUBMIT)}
              </Button>
            </Buttons>
          </Content>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
