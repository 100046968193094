import { MerchantInfo } from 'services/merchants/types';

import { MerchantSettingsFormField } from './sections/checkout/types';

export const getFormValues = (data = {}, formFields: MerchantSettingsFormField[]) => {
  const allowedFields = formFields.map((field: any) => field.key);
  const formValues: Partial<MerchantInfo> = Object.entries(data).reduce((output, [key, value]) => {
    if (allowedFields.includes(key as keyof MerchantInfo)) {
      return {
        ...output,
        [key]: value,
      };
    }
    return output;
  }, {});

  return formValues;
};
