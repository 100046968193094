import React, { MouseEvent, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import IconDelete from 'components/icons/delete';
import IconFile from 'components/icons/file';
import IconUpload from 'components/icons/upload';
import OptionallyVisible from 'components/optionallyVisible';

import { IMAGE_FILE_TYPES, LABELS } from './keys';
import { Caption, Container, ControlPanel, FileName, FilePreview, Hint, Hints, Icon, Input } from './styles';

export interface FileDropzoneProps {
  className?: string;
  disabled?: boolean;
  onFileChange?: (files: File[]) => void;
  filledInputLabel?: string;
  maxFileSize?: number;
  accept?: Accept;
  hints?: string[];
  initialFile?: File | string;
}

interface DisplayedFile {
  URL: string;
  name: string;
  size?: string;
}

const decorateFiles = (files: (File | string)[]): DisplayedFile[] =>
  files.map((fileRecord) => {
    if (typeof fileRecord === 'string') {
      const [name] = fileRecord.split('/').reverse();

      return {
        URL: fileRecord,
        name,
      };
    }

    if (!IMAGE_FILE_TYPES.includes(fileRecord.type)) {
      return {
        URL: '',
        name: fileRecord.name,
        size: fileRecord.size?.toString(),
      };
    }

    return {
      URL: URL.createObjectURL(fileRecord),
      name: fileRecord.name,
      size: fileRecord.size?.toString(),
    };
  });

export const FileDropzone = ({
  initialFile,
  className,
  disabled,
  onFileChange = (nextValue: File[]) => {},
  maxFileSize,
  accept,
  hints,
}: FileDropzoneProps) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<DisplayedFile[]>(decorateFiles(initialFile ? [initialFile] : []));
  const onDrop = (files: File[]) => {
    if (!files.length) {
      return;
    }

    setSelectedFiles(decorateFiles(files));
    onFileChange(files);
  };

  useEffect(() => {
    setSelectedFiles(decorateFiles(initialFile ? [initialFile] : []));
  }, [initialFile]);

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    disabled,
    maxSize: maxFileSize,
  });

  const renderFilePreview = (file: DisplayedFile) => {
    if (!file.URL) {
      return (
        <>
          <IconFile />
          <FileName>{file.name}</FileName>
        </>
      );
    }

    return <FilePreview src={file.URL} key={file.name} />;
  };

  const dropzoneText = t(LABELS.DRAG_AND_DROP_TEXT_EMPTY);
  const containerProps = getRootProps({ isFocused, isDragAccept, isDragReject });
  const inputProps = getInputProps();

  const preventPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleEditClick = (event: MouseEvent) => {
    preventPropagation(event);
    open();
  };

  const handleDeleteClick = (event: MouseEvent) => {
    preventPropagation(event);
    setSelectedFiles([]);
    onFileChange([]);
  };

  return (
    <>
      <Container {...containerProps} className={className} disabled={disabled}>
        <ControlPanel onClick={preventPropagation} active={Boolean(selectedFiles.length) && !disabled}>
          <Button secondary onClick={handleEditClick}>
            <IconUpload width={14} height={14} />
            <span>{t(LABELS.EDIT)}</span>
          </Button>
          <Button secondary onClick={handleDeleteClick}>
            <IconDelete width={18} height={18} />
            <span>{t(LABELS.DELETE)}</span>
          </Button>
        </ControlPanel>
        <Input {...inputProps} />
        <OptionallyVisible visible={Boolean(selectedFiles.length)}>{selectedFiles.map(renderFilePreview)}</OptionallyVisible>
        <OptionallyVisible visible={!selectedFiles.length}>
          <Icon>
            <IconUpload />
          </Icon>
          <Caption>{dropzoneText}</Caption>
        </OptionallyVisible>
      </Container>
      <Hints visible={Boolean(hints?.length)}>{hints?.map((hint) => <Hint key={hint}>{hint}</Hint>)}</Hints>
    </>
  );
};

export default FileDropzone;
