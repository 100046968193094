import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { OptionallyVisible } from 'components/optionallyVisible';

import { OrderSide } from 'types/order';

import { OrderTradeStatusBadge } from '../../../components/orderStatusBadge/orderTradeStatusBadge';
import { KEYS, LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { AmountBlock, AmountNumericValue, AmountValue, Item, ItemName, ItemValue, SectionWrapper } from '../styles';
import { formatCryptoCode, formatCryptoNetworkFee, formatNumber } from '../utils';

export const ExchangeDetailsSection = ({ orderData }: OrderSectionProps) => {
  const { t } = useTranslation();

  const isBuyOrder = Boolean(orderData.order.side === OrderSide.Buy);

  return (
    <Panel label={t(LABELS.SECTIONS.EXCHANGE_DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTE_ID)}</ItemName>
          <ItemValue>{orderData.executedQuote?.quoteId}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTED_CURRENCY)}</ItemName>
          <ItemValue>{orderData.executedQuote?.fiatCurrencyCode}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.order.fiatConversionRate)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.FIAT_CONVERSION_RATE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountNumericValue>{KEYS.BASE_CURRENCY_UNIT}</AmountNumericValue>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountValue>{KEYS.APPROX_EQUAL_SYMBOL}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.order.fiatConversionRate!)}</AmountNumericValue>
                <AmountValue>{orderData.order.fiatCurrencyCode}</AmountValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isBuyOrder}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTED_TOTAL_FIAT_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.executedQuote?.quotedFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTED_NETWORK_FEE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.executedQuote?.networkFeeFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.NETWORK_FEE_CRYPTO_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{formatCryptoCode(orderData.executedQuote?.cryptoCurrencyCode)}</AmountValue>
                <AmountNumericValue>{formatCryptoNetworkFee(orderData.executedQuote?.networkFeeCryptoAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTED_EXCHANGED_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.executedQuote?.cryptoCostFiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.QUOTED_CRYPTO_PRICE)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{KEYS.BASE_CURRENCY_UNIT}</AmountNumericValue>
              <AmountValue>{formatCryptoCode(orderData.executedQuote?.cryptoCurrencyCode)}</AmountValue>
              <AmountValue>{KEYS.APPROX_EQUAL_SYMBOL}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.executedQuote?.cryptoPrice)}</AmountNumericValue>
              <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.TRADE_STATUS)}</ItemName>
          <ItemValue>
            <OrderTradeStatusBadge status={orderData.order.tradeStatus} />
          </ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
