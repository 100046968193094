import { USER_ROLE_TO_LEVEL_MAP } from 'constants/user';

import { UserAccessLevel, UserData, UserRoleType } from 'types/user';

const calculateUserLevel = (roles: UserRoleType[]) => Math.max(...roles.map((role) => USER_ROLE_TO_LEVEL_MAP[role]));

export const processUserTokenInfo = (userTokenInfo: UserData) => {
  const roles: UserRoleType[] = userTokenInfo.s4corRoles;
  const userLevel: UserAccessLevel = calculateUserLevel(roles);

  return { userData: userTokenInfo, userLevel };
};

export default processUserTokenInfo;
