import styled from '@emotion/styled/macro';

import { Root as ButtonRoot } from 'components/buttons/styles';
import { Path, Svg } from 'components/icons/styles';

import { Button } from '../../buttons';

export const ButtonContent = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

export const ButtonLabel = styled.span((props) => ({
  textTransform: 'uppercase',
  fontFamily: props.theme.fonts.primary,
}));

export const ItemContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '9.5px 0',
}));

export const ItemContent = styled.div<{ expanded: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: props.expanded ? 'auto' : 1,
}));

export const FilterButton = styled(Button)<{ active: boolean }>((props) => ({
  // @ts-ignore
  [ButtonLabel]: {
    color: props.active ? props.theme.colors.accent : props.theme.colors.primary,
  },
  // @ts-ignore
  [Path]: {
    fill: props.active ? props.theme.colors.accent : props.theme.colors.primary,
  },
}));

export const ExpandButton = styled(Button)<{ open: boolean }>((props) => ({
  padding: '0 8px',
  // @ts-ignore
  [Svg]: {
    transform: !props.open && 'rotate(180deg)',
  },
}));

export const ItemLabelRow = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
  },
}));

export const ItemLabel = styled.span((props) => ({
  flex: 1,
  fontSize: 14,
  lineHeight: '14px',
  fontWeight: 500,
  color: props.theme.colors.primary,
}));

export const FilterPanel = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: props.theme.colors.background,
  minWidth: 180,
  width: 340,
  gap: 10,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  boxShadow: `0 3px 12px 0px ${props.theme.colors.shadows['10']}`,
  borderRadius: 5,
}));

export const Filters = styled.div(() => ({
  maxHeight: 450,
  overflowY: 'auto',
  padding: '0 10px',
}));

export const Title = styled.h5((props) => ({
  margin: 0,
  fontSize: 12,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.primary,
}));

export const Controls = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  // @ts-ignore
  [ButtonRoot]: {
    padding: '4px 8px',
    minWidth: 'unset',
  },
}));

export const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Header = styled(Section)((props) => ({
  padding: 10,
  gap: 10,
  borderBottom: `0.5px solid ${props.theme.colors.container.tertiary}`,
}));
