import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Path } from 'components/icons/styles';

import { OrderSide } from 'types/order';

import { StatusType } from '../../../types';

const failedBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.error.main}4D`,
  backgroundColor: `${props.theme.colors.error.main}0D`,
  color: props.theme.colors.error.main,
});

const successBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.success.main}4D`,
  backgroundColor: `${props.theme.colors.success.main}0D`,
  color: props.theme.colors.success.main,
});

const pendingBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.inactive}4D`,
  backgroundColor: props.theme.colors.highlight,
  color: props.theme.colors.primary,
});

export const OrderSideIcon = styled.div<{ side: OrderSide }>((props) => {
  const colorStyling =
    props.side === OrderSide.Buy
      ? {
          display: 'flex',
          border: '0.5px solid rgba(151, 30, 247, 0.30)',
          backgroundColor: 'rgba(151, 30, 247, 0.05)',
          boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
          // @ts-ignore
          [Path]: {
            stroke: '#971EF7',
          },
        }
      : {
          border: '0.5px solid rgba(34, 83, 255, 0.30)',
          backgroundColor: 'rgba(34, 83, 255, 0.05)',
          transform: 'rotate(180deg)',
          boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
          // @ts-ignore
          [Path]: {
            stroke: props.theme.colors.accent,
          },
        };

  return {
    display: 'flex',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    ...colorStyling,
  };
});

const getStatusStyle = (props: { theme: Theme; status: StatusType }) => {
  const { status } = props;
  if (status === StatusType.Success) {
    return successBadge(props);
  }

  if (status === StatusType.Failed) {
    return failedBadge(props);
  }

  return pendingBadge(props);
};

export const StatusBadge = styled.span<{ status: StatusType }>(
  (props) => ({
    padding: '3px 6px',
    borderRadius: 5,
    borderWidth: 0.5,
    borderStyle: 'solid',
  }),
  getStatusStyle,
);

export const GreyText = styled.span((props) => ({
  display: 'inline',
  fontSize: 13,
  lineHeight: '13px',
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
}));

export const CryptoCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 40,
    justifyContent: 'flex-end',
  },
}));

export const FiatCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 30,
    justifyContent: 'flex-end',
  },
}));
