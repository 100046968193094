/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { AccountAccessInfo } from 'types/account';
import { DialogNotification, ToastNotification } from 'types/notifications';

interface NotificationsState {
  toastNotificationsQueue: ToastNotification[];
  dialogNotificationsQueue: DialogNotification[];
  access?: AccountAccessInfo;
}

const SLICE_NAME = 'notifications';

const initialState: NotificationsState = {
  toastNotificationsQueue: [],
  dialogNotificationsQueue: [],
};

export const accountSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    pushToastNotification: (state, action: PayloadAction<ToastNotification>) => {
      state.toastNotificationsQueue.push(action.payload);
    },
    pushDialogNotification: (state, action: PayloadAction<DialogNotification>) => {
      state.dialogNotificationsQueue.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string | number>) => {
      state.toastNotificationsQueue = state.toastNotificationsQueue.filter((notification) => notification.key !== action.payload);
      state.dialogNotificationsQueue = state.dialogNotificationsQueue.filter((notification) => notification.key !== action.payload);
    },
  },
});

export const { pushDialogNotification, pushToastNotification, removeNotification } = accountSlice.actions;

export const selectToastNotifications = (state: RootState) => state.notifications.toastNotificationsQueue;
export const selectDialogNotifications = (state: RootState) => state.notifications.dialogNotificationsQueue;

export default accountSlice.reducer;
