import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';

import { LABELS } from '../../keys';
import { AccountDataRow } from '../../types';

export const AccountNameCell = ({ row, column }: DataTableCellProps) => {
  const { t } = useTranslation();

  const value = row[column.id];

  const { isDefault } = row as AccountDataRow;

  return (
    <TableCell data-column={column.id}>
      {value}
      <OptionallyVisible visible={isDefault}>
        {' '}
        <Badge type={BadgeType.Default} tooltip={t(LABELS.DEFAULT_ACCOUNT_BADGE_TOOLTIP)}>
          {t(LABELS.DEFAULT_ACCOUNT_BADGE)}
        </Badge>
      </OptionallyVisible>
    </TableCell>
  );
};
