import { startCase } from 'lodash';

export const beautifyFieldsInQuotes = (text: string): string => {
  if (!text) {
    return '';
  }

  const parseQuote = (field: string, char: string) => `${char}${startCase(field)}${char}`;

  return text
    .replace(/"[^"]*"/gi, (match) => parseQuote(match, '"'))
    .replace(/'[^']*'/gi, (match) => parseQuote(match, "'"))
    .replace(/`[^`]*`/gi, (match) => parseQuote(match, '`'));
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const asArray = (input: string | string[]): string[] => (Array.isArray(input) ? (input as string[]) : [input as string]);
