export const colorSchema = {
  grey: '#928E9C',
  lightGrey: '#D5DDE3',
  insubstantialGrey: '#AAAAAA',
  primary: '#344054',
  secondary: '#2253FF',
  white: '#FFFFFF',
  black: '#000000',
  lightBlue: '#F2F7FF',
  transparent: 'transparent',
};

export const colors = {
  ...colorSchema,
  background: colorSchema.white,
  container: {
    tertiary: '#CAD7ED',
    secondary: '#E3E8F1',
    primary: '#EEF1F7',
  },
  icon: {
    primary: colorSchema.lightGrey,
    secondary: colorSchema.grey,
  },
  text: {
    primary: colorSchema.primary,
    secondary: colorSchema.grey,
    insubstantialGrey: colorSchema.insubstantialGrey,
    accent: colorSchema.secondary,
    highlight: colorSchema.lightBlue,
  },
  button: {
    background: {
      primary: colorSchema.secondary,
      secondary: 'linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%)',
    },
    color: {
      primary: colorSchema.primary,
    },
  },
  success: {
    main: '#089B17',
    light: 'rgb(237, 247, 237)',
    dark: 'rgb(30, 70, 32)',
  },
  warning: {
    main: '#f4772e',
    light: 'rgb(255, 244, 229)',
    dark: 'rgb(102, 60, 0)',
  },
  info: {
    main: '#2196f3',
    light: 'rgb(232, 244, 253)',
    dark: 'rgb(13, 60, 97)',
  },
  error: {
    main: '#DC0030',
    light: 'rgb(253, 236, 234)',
    dark: 'rgb(97, 26, 21)',
  },
  modal: {
    backdrop: {
      primary: `${colorSchema.grey}80`,
      secondary: `${colorSchema.secondary}d0`,
    },
  },
  pageWrapper: '#FAFAFA',
  shift4LogoBlue: '#136EF6',
  highlight: colorSchema.lightBlue,
  accent: colorSchema.secondary,
  darkAccent: '#0047B1',
  disabled: '#98A2B3',
  inactive: '#6276A0',
  errorBackground: '#FFEDED',
  shadows: {
    25: 'rgba(19, 110, 246, 0.25)',
    10: 'rgba(19, 110, 246, 0.10)',
  },
};

export default colors;
