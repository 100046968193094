export interface Reconciliation {
  uuid: string;
  fromTimestamp: string; // Starting timestamp for the reconciliation, ISO Date
  toTimestamp: string; // End timestamp for the reconciliation, ISO Date
  date: string; // Human-readable DD-MM-YYYY date, tells for what date reconciliation was performed
  status: ReconciliationStatus;
  failureReason: string | null; // If reconciliation failed, this field contains the reason

  ordersCount: number | null; // Count of orders processed by us
  zhTradesCount: number | null; // Count of ZH trades fetched
  s4TransactionsCount: number | null; // Count of S4 "credit" and "charge" transactions Payout is calculated for

  totalS4AcquiringVolume: string | null; // Total $ charged AND credited during on/off-ramp orders
  totalZhFees: string | null; // Total $ owed to ZH as fees from the Quote spread
  totalDueToZh: string | null; // Total due to ZH, sum of (on-ramp trade amounts - off-ramp trade amounts)
  totalProcessingFee: string | null; // Total Processing Fees, sum of processing fees (1.99%) we take on top of the ZH Quote
  totalPartnerFee: string | null; // Total Partner Fees, sum of all partner fees we will pay to partners from Processing fee
  totalAcquirerFee: string | null; // Total Acquirer Fee Amount as indicated in Payout entity
  totalNetFeeRevenue: string | null; // Amount left from 1.99% processing fees after Acquirer and Partner fees are taken
  totalSpreadAmount: string | null; // Sum of spread values taken in each ZH Quote
  totalSpreadRevenue: string | null; // Amount left after ZH fees are taken from ZH Quote spread

  s4PayoutId: string; // S4 Payout identifier
  s4PayoutAmount: string; // Total USD settled, this is amount from Payout object we received from S4
  s4MerchantId: string | null; // Merchant ID that has Payout

  currency: string; // Tells in what currency all numbers are, USD by default
  csvReportURL: string | null; // private S3 URL for CSV report file
  createdAt: string;
}

export enum ReconciliationStatus {
  SUCCESSFUL = 'successful',
  MISMATCH = 'mismatch',
  IN_PROGRESS = 'inProgress',
  ERROR = 'error',
}
