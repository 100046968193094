export const KEYS = {
  DATE_FORMAT: 'D MMM YYYY, h:mm A',
};

export const LABELS = {
  SECTIONS: {
    RECONCILIATION: {
      TITLE: ['page.reconciliations.view.sections.reconciliation.title', 'Settlement Reconciliation'],
      RECONCILIATION_ID: ['page.reconciliations.view.sections.reconciliation.reconciliationId', 'Reconciliation ID'],
      STATUS: ['page.reconciliations.view.sections.reconciliation.status', 'Status'],
      ERROR_REASON: ['page.reconciliations.view.sections.reconciliation.errorReason', 'Error Reason'],
      DATE: ['page.reconciliations.view.sections.reconciliation.date', 'Reconciliation Date'],
      FROM_TIMESTAMP: ['page.reconciliations.view.sections.reconciliation.fromTimestamp', 'From Timestamp'],
      TO_TIMESTAMP: ['page.reconciliations.view.sections.reconciliation.toTimestamp', 'To Timestamp'],
    },
    REPORT: {
      TITLE: ['page.reconciliations.view.sections.report.title', 'Detailed Reconciliation CSV Report'],
      CSV_REPORT: ['page.reconciliations.view.sections.report.csvReport', 'CSV Report'],
      CREATED_AT: ['page.reconciliations.view.sections.report.createdAt', 'Created At'],
      DOWNLOAD: ['page.reconciliations.view.sections.report.download', 'Download'],
    },
    DETAILS: {
      TITLE: ['page.reconciliations.view.sections.details.title', 'Details'],
      ORDERS_COUNT: ['page.reconciliations.view.sections.details.ordersCount', 'Orders Count'],
      ZH_TRADES_COUNT: ['page.reconciliations.view.sections.details.zhTradesCount', 'ZH Trades Count'],
      S4_TRANSACTIONS_COUNT: ['page.reconciliations.view.sections.details.s4TransactionsCount', 'S4 Transactions Count'],
      CURRENCY: ['page.reconciliations.view.sections.details.currency', 'Currency'],
      ACQUIRING_VOLUME: ['page.reconciliations.view.sections.details.acquiringVolume', 'Acquiring Volume'],
      S4_PAYOUT_AMOUNT: ['page.reconciliations.view.sections.details.s4PayoutAmount', 'S4 Payout Amount'],
      AMOUNT_DUE_TO_ZH: ['page.reconciliations.view.sections.details.amountDueToZh', 'Amount Due to ZH'],
      PROCESSING_FEE_AMOUNT: ['page.reconciliations.view.sections.details.processingFeeAmount', 'Processing Fee Amount'],
      PARTNER_FEE_AMOUNT: ['page.reconciliations.view.sections.details.partnerFeeAmount', 'Partner Fee Amount'],
      ACQUIRER_FEE_AMOUNT: ['page.reconciliations.view.sections.details.acquirerFeeAmount', 'Acquirer Fee Amount'],
      NET_FEE_AMOUNT: ['page.reconciliations.view.sections.details.netFeeAmount', 'Net Fee Amount'],
      SPREAD_AMOUNT: ['page.reconciliations.view.sections.details.spreadAmount', 'Spread Amount'],
      ZH_FEE_AMOUNT: ['page.reconciliations.view.sections.details.zhFeeAmount', 'ZH Fee Amount'],
      SPREAD_REVENUE_AMOUNT: ['page.reconciliations.view.sections.details.spreadRevenueAmount', 'Spread Revenue Amount'],
    },
  },
};
