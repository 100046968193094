/* eslint-disable react/no-array-index-key */
import MenuIcon from '@mui/icons-material/Menu';
import { ListItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom';

import LogoExpanded from 'assets/images/logoExpanded.svg';

import { LayoutWithSidebarProps } from '../types';
import {
  DrawerToggleButton,
  Link,
  LogoWrapper,
  Main,
  MenuItemButton,
  MenuItemText,
  MenuItemsList,
  MenuItemsListDrawer,
  TopAppBar,
  TopAppToolBar,
} from './layoutWithSidebar.mobile.styles';

export const LayoutWithSidebarMobile: React.FC<LayoutWithSidebarProps> = ({ layoutMenuItems }) => {
  const [open, setOpen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(0);

  const location = useLocation();

  const menuItemsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (menuItemsListRef.current?.offsetHeight) {
      setDrawerHeight(menuItemsListRef.current?.offsetHeight);
    }
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <TopAppBar>
        <TopAppToolBar>
          <LogoWrapper>
            <img src={LogoExpanded} alt="Shift4 Logo" width="161" height="18" />
          </LogoWrapper>
          <DrawerToggleButton onClick={handleDrawerToggle} edge="end">
            <MenuIcon />
          </DrawerToggleButton>
        </TopAppToolBar>
      </TopAppBar>
      <MenuItemsListDrawer variant="persistent" anchor="top" open={open}>
        <MenuItemsList ref={menuItemsListRef}>
          {layoutMenuItems.map(({ label, icon: Icon, to }, index) => (
            <ListItem key={index} disablePadding>
              <Link to={to!} component={RouterLink}>
                <MenuItemButton selected={location.pathname === to}>
                  {Icon && <Icon width={18} height={18} />}
                  <MenuItemText primary={label} />
                </MenuItemButton>
              </Link>
            </ListItem>
          ))}
        </MenuItemsList>
      </MenuItemsListDrawer>
      <Main open={open} drawerHeight={drawerHeight}>
        <Outlet />
      </Main>
    </div>
  );
};
export default LayoutWithSidebarMobile;
