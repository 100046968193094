import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M10.7501 1.66602C6.15008 1.66602 2.41675 5.39935 2.41675 9.99935C2.41675 14.5993 6.15008 18.3327 10.7501',
  '18.3327C15.3501 18.3327 19.0834 14.5993 19.0834 9.99935C19.0834 5.39935 15.3501 1.66602 10.7501',
  '1.66602ZM9.08341 14.166L4.91675 9.99935L6.09175 8.82435L9.08341 11.8077L15.4084 5.48268L16.5834 6.66602L9.08341 14.166Z',
].join(' ');

const IconCheckCircle = ({ width = 20, height = 20, color, style, className }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconCheckCircle;
