import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { StatusBadge } from 'pages/reconciliations/components/statusBadge/statusBadge';

import { convertValueToDayjs } from 'utils/date';

import { KEYS, LABELS } from '../../keys';
import { ReconciliationSectionProps } from '../../types';
import { Item, ItemName, ItemValue, SectionWrapper } from '../styles';

export const ReconciliationSection = ({ reconciliationData }: ReconciliationSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.RECONCILIATION.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.RECONCILIATION_ID)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.uuid}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.STATUS)}</ItemName>
          <ItemValue>
            <StatusBadge status={reconciliationData.reconciliation.status} />
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(reconciliationData.reconciliation.failureReason)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.ERROR_REASON)}</ItemName>
            <ItemValue>{reconciliationData.reconciliation.failureReason}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.DATE)}</ItemName>
          <ItemValue>{convertValueToDayjs(reconciliationData.reconciliation.createdAt)?.format(KEYS.DATE_FORMAT)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.FROM_TIMESTAMP)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.fromTimestamp}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.RECONCILIATION.TO_TIMESTAMP)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.toTimestamp}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
