import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const breatheAnimation = keyframes`
  0%, 20%, 80%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30%, 70% {
    opacity: 0.7;
    transform: scale(1.2);
  }
`;

export const LogoIcon = styled.img(() => ({
  animation: `${breatheAnimation} 1.8s linear infinite`,
}));

export const Container = styled.div<{ padded?: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: props.padded ? 40 : 'initial',
}));
