import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import API_CONFIG from 'constants/api';

import { auth0TokenStorage } from '../tokenStorage';
import { CustomHeaderType } from '../types';

export const fetchBaseQueryWithAuth = fetchBaseQuery({
  baseUrl: API_CONFIG.ROOT_URL,
  prepareHeaders: async (headers) => {
    const accessToken = await auth0TokenStorage.getAccessToken()();
    if (accessToken) {
      headers.set(CustomHeaderType.Auth, `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export default fetchBaseQueryWithAuth;
