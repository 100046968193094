import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAddApplePayDomainMutation, useGetApplePayDomainsQuery, useRemoveApplePayDomainMutation } from 'services/accounts';

import selectAccount from 'state/selectors/accounts/accountSelector';

import { ErrorResponse } from 'types/http';

import { downloadRemoteFile } from 'utils/downloadFile';

import { ApplePayDomainsSection } from './applePayDomains';
import { KEYS, LABELS, paymentMethods } from './keys';
import { PaymentMethodsSection } from './paymentMethods';
import { DomainTableRow } from './types';

export const PaymentMethodsSectionContainer = () => {
  const { t } = useTranslation();
  const { account } = useSelector(selectAccount);
  const { data: domainRecords = [] } = useGetApplePayDomainsQuery(account?.uuid, {
    skip: !account.uuid,
  });

  const [addApplePayDomain, { isLoading: isAdding, error: addError, reset: resetAdd }] = useAddApplePayDomainMutation();
  const [removeApplePayDomain, { isLoading: isRemoving }] = useRemoveApplePayDomainMutation();

  const addDomain = async (domain: string) => {
    if (!account) {
      return false;
    }

    const response = await addApplePayDomain({ accountUuid: account!.uuid, domain });

    return Boolean('data' in response);
  };

  const removeDomain = async (domainUuid: string) => {
    if (!account) {
      return false;
    }

    const response = await removeApplePayDomain({ accountUuid: account!.uuid, domainUuid });
    return Boolean(response);
  };

  const downloadDomainFile = () => {
    downloadRemoteFile({
      name: KEYS.DOMAIN_FILE_NAME,
      source: KEYS.DOMAIN_FILE_URL,
    });
  };

  const domainRows: DomainTableRow[] = domainRecords.map((record) => ({
    id: record.uuid,
    ...record,
  }));

  const getError = (): Record<string, string> => {
    const error = {} as Record<string, string>;
    if (addError) {
      const { errorType } = addError as ErrorResponse;
      if (errorType === KEYS.ALREADY_EXISTS) {
        error.add = t(LABELS.ERRORS.ALREADY_EXISTS);
      } else {
        error.add = t(LABELS.ERRORS.ADD_ERROR);
      }
    }

    return error;
  };

  return (
    <>
      <PaymentMethodsSection methods={paymentMethods} />
      <ApplePayDomainsSection
        domains={domainRows}
        addDomain={addDomain}
        removeDomain={removeDomain}
        downloadFile={downloadDomainFile}
        isSubmitting={isAdding}
        isRemoving={isRemoving}
        error={getError()}
        reset={resetAdd}
      />
    </>
  );
};
