import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { IconEnvelope } from 'components/icons/envelope';
import { Input } from 'components/inputs';
import OptionallyVisible from 'components/optionallyVisible';

import { AccountType } from 'types/account';

import { RadioGroup } from '../../../inputs/radio/radioGroup';
import { KEYS, LABELS } from '../keys';
import { Delimeter } from '../styles';
import { CreatedAccount } from '../types';
import { AccountOwnerSection, AccountSection, Adornment, Label, MerchantIdSection, Optional } from './form.styles';

interface CreateAccountFormProps {
  formValues: CreatedAccount;
  onFieldValueChange: (key: string, value: any) => void;
  error?: Record<string, string>;
  accountType: AccountType;
  setAccountType: (accountType: AccountType) => void;
}

export const CreateAccountModalForm = ({ formValues, error, onFieldValueChange, accountType, setAccountType }: CreateAccountFormProps) => {
  const { t } = useTranslation();

  const handleFormChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleAccountTypeChange = (type: string) => {
    setAccountType(type as AccountType);
  };

  const blockOptions = [
    {
      key: AccountType.Ramp,
      value: AccountType.Ramp,
      label: t(LABELS.CREATE_MODAL.HEADER.RAMP_TITLE),
    },
    {
      key: AccountType.Pwc,
      value: AccountType.Pwc,
      label: t(LABELS.CREATE_MODAL.HEADER.PWC_TITLE),
    },
  ];

  return (
    <>
      <RadioGroup horizontal options={blockOptions} onChange={handleAccountTypeChange} value={accountType} />

      <Delimeter />

      <AccountSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_SECTION.TITLE)}</Label>
        <FormField error={error?.accountName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NAME)}
            value={formValues.accountName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.ACCOUNT_NAME)}
          />
        </FormField>
      </AccountSection>

      <OptionallyVisible visible={accountType === AccountType.Pwc}>
        <MerchantIdSection>
          <Label>
            {t(LABELS.CREATE_MODAL.SECTIONS.MERCHANT_ID_SECTION.TITLE)} <Optional>{t(LABELS.CREATE_MODAL.FORM_FIELDS.OPTIONAL)}</Optional>
          </Label>
          <FormField error={error?.shift4MerchantId}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.MERCHANT_ID)}
              value={formValues.shift4MerchantId}
              onChange={handleFormChange(KEYS.FORM_FIELDS.MERCHANT_ID)}
            />
          </FormField>
        </MerchantIdSection>
      </OptionallyVisible>

      <AccountOwnerSection>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_OWNER_SECTION.TITLE)}</Label>
        <FormField error={error?.username}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.EMAIL)}
            value={formValues.username}
            onChange={handleFormChange(KEYS.FORM_FIELDS.EMAIL)}
            startAdornment={
              <Adornment>
                <IconEnvelope />
              </Adornment>
            }
          />
        </FormField>

        <FormField error={error?.firstName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.FIRST_NAME)}
            value={formValues.firstName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.FIRST_NAME)}
          />
        </FormField>

        <FormField error={error?.lastName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.LAST_NAME)}
            value={formValues.lastName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.LAST_NAME)}
          />
        </FormField>
      </AccountOwnerSection>
    </>
  );
};
