import { t } from 'i18next';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import { CheckboxFilterPassedProps, DateFilterPassedProps, SelectFilterPassedProps } from 'components/filters/types';
import { SelectItem } from 'components/inputs/select/select';

import { CustomerKYCStatus, CustomerStatus } from 'types/customer';
import { Filter, FilterSection, FilterType } from 'types/filters';

import { COLUMN_IDS, LABELS } from './keys';
import { Filters, RequestFilters } from './types';

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.status, filterMap.kycStatus, filterMap.date, filterMap.lastLoginAt, filterMap.country],
    },
  ];
};

export const makeFilters = ({ countries }: { countries: SelectItem[] }): Record<string, Filter<unknown>> => {
  const kycStatusFilter: Filter<CheckboxFilterPassedProps> = {
    key: COLUMN_IDS.KYC_STATUS,
    label: t(LABELS.FILTERS.KYC_STATUS.LABEL),
    type: FilterType.Checkbox,
    options: [
      {
        key: CustomerKYCStatus.Approved,
        value: [CustomerKYCStatus.Approved],
        label: t(LABELS.FILTERS.KYC_STATUS.APPROVED),
      },
      {
        key: CustomerKYCStatus.Rejected,
        value: [CustomerKYCStatus.Rejected],
        label: t(LABELS.FILTERS.KYC_STATUS.REJECTED),
      },
      {
        key: CustomerKYCStatus.Submitted,
        value: [CustomerKYCStatus.Submitted],
        label: t(LABELS.FILTERS.KYC_STATUS.SUBMITTED),
      },
      {
        key: CustomerKYCStatus.PendingApproval,
        value: [CustomerKYCStatus.PendingApproval],
        label: t(LABELS.FILTERS.KYC_STATUS.PENDING_APPROVAL),
      },
      {
        key: CustomerKYCStatus.Null,
        value: [CustomerKYCStatus.Null],
        label: t(LABELS.FILTERS.KYC_STATUS.NULL),
      },
    ],
  };

  const statusFilter: Filter<CheckboxFilterPassedProps> = {
    key: COLUMN_IDS.STATUS,
    label: t(LABELS.FILTERS.STATUS.LABEL),
    type: FilterType.Checkbox,
    options: [
      {
        key: CustomerStatus.Active,
        value: [CustomerStatus.Active],
        label: t(LABELS.FILTERS.STATUS.ACTIVE),
      },
      {
        key: CustomerStatus.Blocked,
        value: [CustomerStatus.Blocked],
        label: t(LABELS.FILTERS.STATUS.BLOCKED),
      },
      {
        key: CustomerStatus.Terminated,
        value: [CustomerStatus.Terminated],
        label: t(LABELS.FILTERS.STATUS.TERMINATED),
      },
    ],
  };

  const dateFilterPresets = getDefaultDatePresets();

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: dateFilterPresets,
  };

  const lastLoginFilter: Filter<DateFilterPassedProps> = {
    key: 'lastLoginAt',
    label: t(LABELS.FILTERS.LAST_LOGIN.LABEL),
    type: FilterType.Date,
    presets: dateFilterPresets,
  };

  const countryFilter: Filter<SelectFilterPassedProps> = {
    key: 'country',
    label: t(LABELS.FILTERS.COUNTRY.LABEL),
    type: FilterType.Select,
    multiple: false,
    options: countries,
  };

  return [statusFilter, kycStatusFilter, dateFilter, lastLoginFilter, countryFilter].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters): RequestFilters | undefined => {
  if (!filters) {
    return undefined;
  }

  const nextFilters: RequestFilters = {};

  if (filters.kycStatus) {
    nextFilters.kycStatuses = filters.kycStatus.map((value) => (value === CustomerKYCStatus.Null ? null : value));
  }

  if (filters.status) {
    nextFilters.statuses = filters.status;
  }

  if (filters.date) {
    nextFilters.date = filters.date;
  }

  if (filters.lastLoginAt) {
    nextFilters.lastLoginAt = filters.lastLoginAt;
  }

  if (filters.country) {
    nextFilters.country = filters.country;
  }

  return nextFilters;
};
