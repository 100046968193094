import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M1.52849 12.9079H7.75679C8.62892 12.9079 9.05674 12.4801 9.05674 11.5339V6.74541C9.05674',
  '5.9062 8.71118 5.47014 8.02007 5.38786V3.75878C8.02007 1.23291 6.32518 0.0810547 4.64674',
  '0.0810547C2.96009 0.0810547 1.27343 1.23291 1.27343 3.75878V5.41254C0.623449 5.53595 0.228516',
  '5.96379 0.228516 6.74541V11.5339C0.228516 12.4801 0.664581 12.9079 1.52849 12.9079ZM2.63099 3.61069C2.63099',
  '2.13795 3.56894 1.38101 4.64674 1.38101C5.71634 1.38101 6.66251 2.13795 6.66251 3.61069V5.37139L2.63099',
  '5.37963V3.61069Z',
].join(' ');

export const IconLock = ({ width = 10, height = 13, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconLock;
