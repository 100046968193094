import React, { ReactElement } from 'react';

import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconCross from 'components/icons/cross';
import IconDash from 'components/icons/dash';

import { CustomerStatus } from 'types/customer';

import { badgePropsMap } from './keys';
import { BadgeContent } from './styles';

interface StatusBadgeProps {
  status: CustomerStatus;
}

const badgeToIconMap: Record<BadgeType, ReactElement | null> = {
  [BadgeType.Success]: <IconChecked />,
  [BadgeType.Fail]: <IconCross />,
  [BadgeType.Default]: null,
  [BadgeType.Info]: null,
  [BadgeType.Warning]: null,
};

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { type, label } = badgePropsMap[status] || badgePropsMap.default;
  const icon = badgeToIconMap[type];

  const usedLabel = label || <IconDash />;

  return (
    <Badge type={type}>
      <BadgeContent>
        {icon}
        {usedLabel}
      </BadgeContent>
    </Badge>
  );
};
