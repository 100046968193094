import React from 'react';

import { InfoFormField } from '../../types';
import { Container, Item, ItemName, ItemValue } from './styles';

interface InfoFormProps {
  fields: InfoFormField[];
}

export const InfoForm = ({ fields }: InfoFormProps) => {
  return (
    <Container>
      {fields.map((field) => {
        if (field.hidden) {
          return null;
        }

        return (
          <Item key={field.id}>
            <ItemName>{field.label}</ItemName>
            <ItemValue>{field.content}</ItemValue>
          </Item>
        );
      })}
    </Container>
  );
};
