import styled from '@emotion/styled';

export const CellBlock = styled.div((props) => ({
  width: 120,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [props.theme.breakpoints.up('md')]: {
    whiteSpace: 'nowrap',
    width: 'auto',
  },
}));
