import { LayoutWithSidebar } from 'components';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AcceptInvite } from 'pages/account/edit/acceptInvite';

import { AvailableRoute, RouteSetup } from 'types/routing';

import { AuthenticationGuard } from './authenticationGuard';
import RoutePath from './path';

export interface RoutesWrapperProps {
  routeSetup: RouteSetup;
}

export const renderRoute = ({ path, component: Component, children, defaultRedirectRoute }: AvailableRoute) => {
  if (children) {
    const redirectRoute = defaultRedirectRoute ? <Route path="" element={<Navigate to={defaultRedirectRoute!} replace />} /> : null;
    const defaultNestedRoute = defaultRedirectRoute ? <Route path="*" element={<Navigate to={defaultRedirectRoute!} replace />} /> : null;

    return (
      <Route path={path} element={<AuthenticationGuard component={Component} />} key={path}>
        {children.map(renderRoute)}
        {redirectRoute}
        {defaultNestedRoute}
      </Route>
    );
  }

  return <Route path={path} element={<AuthenticationGuard component={Component} />} key={path} />;
};

export const RoutesWrapper = (props: RoutesWrapperProps) => {
  const { routeSetup } = props;
  const { availableRoutes, defaultLoginRedirectRoute, menuItems } = routeSetup;

  const renderDefaultRoute = () => {
    if (defaultLoginRedirectRoute) {
      return <Route path="*" element={<Navigate to={defaultLoginRedirectRoute} replace />} />;
    }
    return null;
  };

  return (
    <Routes>
      <Route element={<LayoutWithSidebar layoutMenuItems={menuItems ?? []} />}>{availableRoutes.map(renderRoute)}</Route>
      <Route path={RoutePath.AcceptInvite} element={<AuthenticationGuard component={AcceptInvite} />} />
      {renderDefaultRoute()}
    </Routes>
  );
};

export default RoutesWrapper;
