import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { KEYS } from 'pages/reconciliations/keys';

import { LABELS } from '../../keys';
import { ReconciliationSectionProps } from '../../types';
import { AmountBlock, AmountNumericValue, AmountValue, Delimiter, Item, ItemName, ItemValue, SectionWrapper } from '../styles';

export const DetailsSection = ({ reconciliationData }: ReconciliationSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.ORDERS_COUNT)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.ordersCount ?? KEYS.AMOUNT_PLACEHOLDER}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.ZH_TRADES_COUNT)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.zhTradesCount ?? KEYS.AMOUNT_PLACEHOLDER}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.S4_TRANSACTIONS_COUNT)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.s4TransactionsCount ?? KEYS.AMOUNT_PLACEHOLDER}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.CURRENCY)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.currency}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.ACQUIRING_VOLUME)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalS4AcquiringVolume ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.S4_PAYOUT_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.s4PayoutAmount}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.AMOUNT_DUE_TO_ZH)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalDueToZh ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.PROCESSING_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalProcessingFee ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.PARTNER_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalPartnerFee ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.ACQUIRER_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalAcquirerFee ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.NET_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalNetFeeRevenue ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.SPREAD_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalSpreadAmount ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.ZH_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalZhFees ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DETAILS.SPREAD_REVENUE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{reconciliationData.reconciliation.currency}</AmountValue>
              <AmountNumericValue>{reconciliationData.reconciliation.totalSpreadRevenue ?? KEYS.AMOUNT_PLACEHOLDER}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
