import styled from '@emotion/styled';
import { TableHead as MaterialTableHead, TableRow as MaterialTableRow } from '@mui/material';
import { CSSProperties } from 'react';

export interface StyledTableHeadProps {
  extraStyles?: CSSProperties;
}

export const StyledTableHead = styled(MaterialTableHead, {
  shouldForwardProp: (prop) => prop !== 'extraStyles',
})<StyledTableHeadProps>((props) => ({
  ...props.extraStyles,
}));

export const StyledTableRow = styled(MaterialTableRow)(() => ({
  cursor: 'pointer',
  '& > th:first-of-type': {
    paddingLeft: 10,
  },
  '& > th:last-child': {
    paddingRight: 30,
    textAlign: 'right',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
  },
}));
