import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import IconExclamationCircled from 'components/icons/exclamationCircled';
import IconLoading from 'components/icons/loading';
import IconRefresh from 'components/icons/refresh';
import OptionallyVisible from 'components/optionallyVisible';

import { Asset } from 'types/assets';
import { CustomerCard } from 'types/customer';

import { makeBalanceTableCell } from './components/balanceTableCell';
import { OrderModal } from './components/orderModal';
import { RefundModal } from './components/refundModal';
import { COLUMNS, LABELS } from './keys';
import { Notification, PlaceholderContainer, RefreshButton, TableContainer } from './styles';
import { BalanceDataRow, OrderCreatePreview } from './types';

interface CustomerOrdersProps {
  balances: BalanceDataRow[];
  isLoading: boolean;
  refresh: () => void;
  refundProps: {
    getQuote: (cryptoCurrencyCode: string) => Promise<{ networkFeeUsdAmount: number }>;
    isQuoteFetching: boolean;
    getAsset: (symbol: string) => Asset | undefined;
    submitRefund: (request: { cryptoCurrencyCode: string; walletAddress: string; walletTag?: string }) => Promise<{ success: boolean }>;
    isRefundSubmitting: boolean;
    quoteError?: string;
    refundError?: string;
  };
  createOrderProps: {
    getQuote: (query: { cryptoCurrencyCode: string; fiatCurrencyCode: string; amount: number }) => Promise<OrderCreatePreview | undefined>;
    isQuoteFetching: boolean;
    quoteError?: Record<string, string>;
    fiatCurrencies: Asset[];
    cards: CustomerCard[];
    areCardsFetching: boolean;
    getAsset: (symbol: string) => Asset | undefined;
    submitOrder: (quoteId: string, paymentToken: string) => Promise<any>;
    isOrderSubmitting: boolean;
  };
}

export const CustomerBalanceList = ({ balances, isLoading, refresh, refundProps, createOrderProps }: CustomerOrdersProps) => {
  const [refundAsset, setRefundAsset] = useState<{ asset: Asset; amount: number } | null>(null);
  const [orderAsset, setOrderAsset] = useState<{ asset: Asset; amount: number } | null>(null);
  const { t } = useTranslation();
  const handleOpenRefundModal = useCallback(
    (asset: Asset, amount: number) => {
      const foundAsset = refundProps.getAsset(asset.symbol);
      if (foundAsset) {
        setRefundAsset({ asset: foundAsset, amount });
      }
    },
    [refundProps],
  );

  const handleOpenOrderModal = useCallback(
    (asset: Asset, amount: number) => {
      const foundAsset = createOrderProps.getAsset(asset.symbol);
      if (foundAsset) {
        setOrderAsset({ asset: foundAsset, amount });
      }
    },
    [createOrderProps],
  );

  const handleCloseRefundModal = () => {
    setRefundAsset(null);
  };

  const handleCloseOrderModal = () => {
    setOrderAsset(null);
  };

  const BalanceTableCell = useMemo(
    () => makeBalanceTableCell({ refund: handleOpenRefundModal, createOrder: handleOpenOrderModal }),
    [handleOpenRefundModal, handleOpenOrderModal],
  );
  const renderRefundModal = () =>
    refundAsset ? <RefundModal {...refundAsset} {...refundProps} open onClose={handleCloseRefundModal} /> : null;
  const renderOrderModal = () =>
    orderAsset ? <OrderModal {...orderAsset} {...createOrderProps} open onClose={handleCloseOrderModal} /> : null;

  return (
    <Panel
      label={t(LABELS.BALANCE_LIST.TITLE)}
      controls={
        <RefreshButton flat disabled={isLoading} onClick={refresh}>
          <OptionallyVisible visible={isLoading}>
            <IconLoading />
            {t(LABELS.BALANCE_LIST.REFRESHING)}
          </OptionallyVisible>
          <OptionallyVisible visible={!isLoading}>
            <IconRefresh />
            {t(LABELS.BALANCE_LIST.REFRESH)}
          </OptionallyVisible>
        </RefreshButton>
      }
    >
      {renderRefundModal()}
      {renderOrderModal()}
      <TableContainer>
        <DataTable
          columns={COLUMNS}
          rows={balances}
          loading={isLoading}
          CellComponent={BalanceTableCell}
          placeholder={
            <PlaceholderContainer>
              <Notification>
                <IconExclamationCircled />
                {t(LABELS.BALANCE_LIST.NO_RECORDS_FOUND)}
              </Notification>
            </PlaceholderContainer>
          }
        />
      </TableContainer>
    </Panel>
  );
};
