import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const DataTableContainer = styled.div((props) => ({
  [`& [data-column="${COLUMN_IDS.NAME}"]`]: {
    width: 350,
    minWidth: 200,
  },
  [`&& [data-column="${COLUMN_IDS.ROLES}"]`]: {
    textAlign: 'left',
  },
}));
