import i18next from 'locales/i18next';

import { ApiKeys } from 'types/apiKeys';

import { LABELS } from './apiKeys.keys';
import { ApiKeysTableRow } from './types';

const { t } = i18next;

export const transformApiKeysDataToTableFormat = (apiKeys?: ApiKeys): ApiKeysTableRow[] => {
  return apiKeys
    ? [
        {
          id: apiKeys.publicKey,
          keyName: t(LABELS.PUBLIC_KEY),
          keyValue: apiKeys.publicKey,
          createdAt: apiKeys.publicKeyCreatedAt,
          lastUsed: null, // backend does not provide this data at the moment
          isSecretKey: false,
        },
        {
          id: apiKeys.secretKey,
          keyName: t(LABELS.SECRET_KEY),
          keyValue: apiKeys.secretKey,
          createdAt: apiKeys.secretKeyCreatedAt,
          lastUsed: apiKeys.secretKeyUsage[0]?.lastAccessAt,
          isSecretKey: true,
        },
      ]
    : [];
};
