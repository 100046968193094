import styled from '@emotion/styled';
import MaterialTableCell from '@mui/material/TableCell';

export const StyledTableCell = styled(MaterialTableCell)((props) => ({
  fontSize: '13px',
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.primary,
  minHeight: 28,
  height: 28,
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
}));
