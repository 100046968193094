import styled from '@emotion/styled';

export const FormRow = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
}));

export const FieldTitle = styled.div((props) => ({
  minWidth: 150,
  fontFamily: props.theme.fonts.primary,
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '16px',
  color: props.theme.colors.inactive,
  display: 'flex',
  alignItems: 'center',
}));

export const FieldValue = styled.div<{ autoWidth?: boolean }>((props) => ({
  width: props.autoWidth ? 'auto' : 290,
  display: 'flex',
  alignItems: 'center',
  fontFamily: props.theme.fonts.primary,
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '16px',
  color: props.theme.colors.primary,
}));

export const Error = styled.span<{ visible?: boolean }>((props) => ({
  color: props.theme.colors.error.main,
  display: props.visible ? 'block' : 'none',
  fontSize: 11,
  fontFamily: props.theme.fonts.primary,
  marginLeft: 170,
}));
