import Box from '@mui/material/Box';
import { PageWrapper, Typography } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LABELS } from './keys';

export const DocumentationContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <Box>
        <Typography variant="caption">Location: {location.pathname}</Typography>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Rhoncus Rhoncus dolor purus non enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in
          hendrerit gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi
          quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Cursus euismod quis
          viverra nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa
          tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget arcu dictum varius duis at consectetur lorem. Velit sed
          ullamcorper morbi tincidunt. Lorem donec massa sapien faucibus et molestie ac.
        </Typography>
      </Box>
    </PageWrapper>
  );
};
