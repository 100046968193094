import Logger from 'utils/logger';

import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { KEYS } from './keys';
import { processUserInfoArgs } from './types';
import { getAndSetUserLevelAndData, updateAuthToken } from './utils';

export const processUserInfo = createAppAsyncThunk(
  KEYS.THUNK_NAME,
  async ({ auth0User, getAccessTokenSilently }: processUserInfoArgs, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      updateAuthToken(accessToken, dispatch);

      return getAndSetUserLevelAndData(accessToken, auth0User, dispatch);
    } catch (error: any) {
      Logger.error(`Authentication error: ${error.message}`);
      return rejectWithValue(error.message);
    }
  },
);
