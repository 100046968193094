import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useCreateUserMutation, useEditUserMutation, useGetUsersListQuery } from 'services/users';

import { selectUserLevel } from 'state/slices/userSlice';

import { APIError, ValidationErrorMeta } from 'types/error';
import { UserAccessLevel, UserRoleType } from 'types/user';

import { getValidationDetails } from 'utils/error';

import { UserTableRow } from './types';
import { UsersSection } from './users';

export const UsersSectionContainer = () => {
  const userLevel = useSelector(selectUserLevel);
  const { data = [], isLoading } = useGetUsersListQuery({
    roles: [UserRoleType.SuperAdmin, UserRoleType.Admin, UserRoleType.Support],
  });
  const [inviteUser, { isLoading: isSubmittingInvite, error: inviteErrorBase, reset: inviteReset }] = useCreateUserMutation();
  const [editUser, { isLoading: isSubmittingEdit, error: editErrorBase, reset: editReset }] = useEditUserMutation();

  const handleInviteUser = async (email: string, role: UserRoleType) => {
    const response = await inviteUser({ username: email, roles: [role] });
    if ('data' in response) {
      return true;
    }

    return false;
  };

  const handleEditUser = async (userUuid: string, role: UserRoleType) => {
    const response = await editUser({ uuid: userUuid, roles: [role] });
    if ('data' in response) {
      return true;
    }

    return false;
  };

  const users: UserTableRow[] = data.map((user) => ({
    ...user,
    id: user.uuid,
  }));

  const inviteErrorMeta = inviteErrorBase
    ? ((inviteErrorBase as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta
    : undefined;
  let inviteError = inviteErrorMeta ? getValidationDetails(inviteErrorMeta) : undefined;
  if (inviteErrorBase && !inviteError) {
    inviteError = { email: ((inviteErrorBase as FetchBaseQueryError).data as APIError).errorMessage };
  }

  const editErrorMeta = editErrorBase ? ((editErrorBase as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta : undefined;
  let editError = editErrorMeta ? getValidationDetails(editErrorMeta) : undefined;
  if (inviteErrorBase && !inviteError) {
    editError = { email: ((editErrorBase as FetchBaseQueryError).data as APIError).errorMessage };
  }

  const availableRoles =
    userLevel === UserAccessLevel.SuperAdmin
      ? [UserRoleType.SuperAdmin, UserRoleType.Admin, UserRoleType.Support]
      : [UserRoleType.Admin, UserRoleType.Support];

  return (
    <UsersSection
      users={users}
      isLoading={isLoading}
      inviteUser={{
        availableRoles,
        onInvite: handleInviteUser,
        isSubmitting: isSubmittingInvite,
        error: inviteError,
        reset: inviteReset,
      }}
      editUser={{
        availableRoles,
        onEdit: handleEditUser,
        isSubmitting: isSubmittingEdit,
        error: editError,
        reset: editReset,
      }}
    />
  );
};
