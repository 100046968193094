import dayjs from 'dayjs';
import { useNotifications } from 'providers/notifications/useNotifications';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetReconciliationCSVQuery } from 'services/reconciliations';

import { DataTableCellProps } from 'components/dataTable';
import { ActionMenu, ExportMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { TableCell } from 'components/dataTable/tableCell';
import OptionallyVisible from 'components/optionallyVisible';

import { StatusBadge } from 'pages/reconciliations/components/statusBadge/statusBadge';
import { LABELS as COMMON_LABELS, KEYS } from 'pages/reconciliations/keys';

import RoutePath from 'router/path';

import { NotificationSeverity } from 'types/notifications';

import { convertValueToDayjs } from 'utils/date';
import downloadFile from 'utils/downloadFile';

import { COLUMN_IDS } from '../keys';
import { ReconciliationDataRow } from '../types';
import { LABELS } from './keys';
import { FiatCurrency, GreyText } from './styles';

const StatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      <StatusBadge status={value} />
    </TableCell>
  );
};

const GreyTextCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return (
    <TableCell data-column={column.id}>
      <GreyText>{value ?? KEYS.AMOUNT_PLACEHOLDER}</GreyText>
    </TableCell>
  );
};

const DateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const now = dayjs();
  const dateValue = convertValueToDayjs(value);
  const isSameYear = now.year() === dateValue?.year();
  const format = isSameYear ? KEYS.DATE_FORMAT : KEYS.DATE_FORMAT_WITH_YEAR;
  return <TableCell>{convertValueToDayjs(value)?.format(format)}</TableCell>;
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const FiatAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.FIAT_CURRENCY];
  return (
    <TableCell data-column={column.id}>
      <OptionallyVisible visible={Boolean(value)}>
        <FiatCurrency>
          <span>{value}</span>
          <span>{currencyCode}</span>
        </FiatCurrency>
      </OptionallyVisible>
    </TableCell>
  );
};

const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const { pushToast } = useNotifications();
  const { uuid } = row as ReconciliationDataRow;
  const [trigger, { isFetching }] = useLazyGetReconciliationCSVQuery();

  const exportCSV = async () => {
    try {
      const result = await trigger(uuid).unwrap();

      if (result.type !== 'text/csv') {
        throw new Error('Unexpected file type');
      }

      const objectUrl = window.URL.createObjectURL(result);
      downloadFile({
        name: t(COMMON_LABELS.CSV_FILE_NAME, { date: dayjs().format(KEYS.DATE_FORMAT) }),
        source: objectUrl,
      });
    } catch (e: any) {
      pushToast({
        severity: NotificationSeverity.error,
        message: t(COMMON_LABELS.CSV_DOWNLOAD_ERROR),
      });
    }
  };

  return (
    <TableCell>
      <ActionMenu>
        <NavigateMenuItem to={RoutePath.ReconciliationsById(uuid)}>{t(LABELS.ACTIONS.NAVIGATE_TO_RECONCILIATION)}</NavigateMenuItem>
        <ExportMenuItem onClick={exportCSV} isLoading={isFetching}>
          {t(LABELS.ACTIONS.DOWNLOAD_CSV_REPORT)}
        </ExportMenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.DATE]: DateCell,
  [COLUMN_IDS.ID]: GreyTextCell,
  [COLUMN_IDS.STATUS]: StatusCell,
  [COLUMN_IDS.ORDERS]: GreyTextCell,
  [COLUMN_IDS.FIAT_AMOUNT]: FiatAmountCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const OrderTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
