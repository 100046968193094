import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components/elements/badge/badge';

import { OrderStatus } from 'types/order';

import { orderStatusLabelByStatus, orderStatusToBadgeTypeMap } from './keys';

interface OrderStatusBadgeProps {
  status: OrderStatus;
}

export const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
  const { t } = useTranslation();

  const orderStatusBadgeType = orderStatusToBadgeTypeMap[status];
  const orderStatusLabel = t(orderStatusLabelByStatus[status]);

  return <Badge type={orderStatusBadgeType}>{orderStatusLabel}</Badge>;
};
