import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M9.83381 6.60015V5.4668C10.2005 5.31124 10.5756 5.19457 10.9592 5.11679C11.3423 5.03901 11.745 5.00012',
  '12.1672 5.00012C12.4561 5.00012 12.7394 5.02234 13.0172 5.06679C13.295 5.11123 13.5672 5.16679 13.8339',
  '5.23346V6.30014C13.5672 6.20014 13.2979 6.12525 13.0259 6.07547C12.7534 6.02525 12.4672 6.00014 12.1672',
  '6.00014C11.745 6.00014 11.3394 6.05303 10.9505 6.15881C10.5616 6.26414 10.1894 6.41126 9.83381 6.60015ZM9.83381',
  '10.2669V9.13353C10.2005 8.97797 10.5756 8.8613 10.9592 8.78352C11.3423 8.70574 11.745 8.66685 12.1672',
  '8.66685C12.4561 8.66685 12.7394 8.68907 13.0172 8.73352C13.295 8.77796 13.5672 8.83352 13.8339',
  '8.90019V9.96687C13.5672 9.86687 13.2979 9.79198 13.0259 9.7422C12.7534 9.69198 12.4672 9.66687 12.1672',
  '9.66687C11.745 9.66687 11.3394 9.71687 10.9505 9.81687C10.5616 9.91687 10.1894 10.0669 9.83381',
  '10.2669ZM9.83381 8.43351V7.30016C10.2005 7.1446 10.5756 7.02793 10.9592 6.95016C11.3423 6.87238',
  '11.745 6.83349 12.1672 6.83349C12.4561 6.83349 12.7394 6.85571 13.0172 6.90015C13.295 6.9446',
  '13.5672 7.00016 13.8339 7.06682V8.13351C13.5672 8.03351 13.2979 7.95862 13.0259 7.90884C12.7534',
  '7.85862 12.4672 7.8335 12.1672 7.8335C11.745 7.8335 11.3394 7.88639 10.9505 7.99217C10.5616',
  '8.09751 10.1894 8.24462 9.83381 8.43351ZM4.83372 10.6669C5.35595 10.6669 5.86441 10.7251',
  '6.35908 10.8416C6.85331 10.9584 7.34488 11.1336 7.83377 11.3669V4.80012C7.37821 4.53345 6.89487',
  '4.33344 6.38375 4.20011C5.87263 4.06677 5.35595 4.0001 4.83372 4.0001C4.43372 4.0001 4.0366',
  '4.03899 3.64237 4.11677C3.24769 4.19455 2.86702 4.31122 2.50035 4.46678V11.0669C2.88924',
  '10.9336 3.27547 10.8336 3.65903 10.7669C4.04215 10.7002 4.43372 10.6669 4.83372 10.6669ZM9.16713',
  '11.3669C9.65603 11.1336 10.1478 10.9584 10.6425 10.8416C11.1367 10.7251 11.6449 10.6669 12.1672',
  '10.6669C12.5672 10.6669 12.959 10.7002 13.3425 10.7669C13.7257 10.8336 14.1117 10.9336 14.5006',
  '11.0669V4.46678C14.1339 4.31122 13.7534 4.19455 13.3592 4.11677C12.9645 4.03899 12.5672 4.0001',
  '12.1672 4.0001C11.6449 4.0001 11.1283 4.06677 10.6172 4.20011C10.106 4.33344 9.62269 4.53345 9.16713',
  '4.80012V11.3669ZM8.50045 13.3336C7.96711 12.9114 7.38932 12.5836 6.76709 12.3502C6.14486 12.1169 5.5004',
  '12.0002 4.83372 12.0002C4.36705 12.0002 3.90882 12.0614 3.45903 12.1836C3.0088 12.3058 2.57813',
  '12.478 2.16701 12.7003C1.93367 12.8225 1.70878 12.8169 1.49233 12.6836C1.27544 12.5503 1.16699',
  '12.3558 1.16699 12.1002V4.06677C1.16699 3.94455 1.19766 3.82788 1.25899 3.71677C1.31988 3.60565',
  '1.41144 3.52232 1.53367 3.46676C2.04479 3.20009 2.57813 3.00009 3.13369 2.86675C3.68926 2.73342',
  '4.25593 2.66675 4.83372 2.66675C5.47818 2.66675 6.10886 2.75008 6.72575 2.91675C7.34221 3.08342',
  '7.93377 3.33343 8.50045 3.66677C9.06713 3.33343 9.65892 3.08342 10.2758 2.91675C10.8923 2.75008',
  '11.5227 2.66675 12.1672 2.66675C12.745 2.66675 13.3116 2.73342 13.8672 2.86675C14.4228 3.00009 14.9561',
  '3.20009 15.4672 3.46676C15.5895 3.52232 15.6812 3.60565 15.7426 3.71677C15.8035 3.82788 15.8339 3.94455',
  '15.8339 4.06677V12.1002C15.8339 12.3558 15.7257 12.5503 15.5092 12.6836C15.2923 12.8169 15.0672 12.8225',
  '14.8339 12.7003C14.4228 12.478 13.9921 12.3058 13.5419 12.1836C13.0921 12.0614 12.6339 12.0002',
  '12.1672 12.0002C11.5005 12.0002 10.856 12.1169 10.2338 12.3502C9.61158 12.5836 9.03379 12.9114 8.50045 13.3336Z',
].join(' ');

const IconBook = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <Path fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconBook;
