import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'M7.3275 1.16602C4.1075 1.16602 1.5 3.77935 1.5 6.99935C1.5 10.2193 4.1075 12.8327 7.3275 12.8327C10.5533',
    '12.8327 13.1667 10.2193 13.1667 6.99935C13.1667 3.77935 10.5533 1.16602 7.3275 1.16602ZM7.33333 11.666C4.755',
    '11.666 2.66667 9.57768 2.66667 6.99935C2.66667 4.42102 4.755 2.33268 7.33333 2.33268C9.91167 2.33268 12 4.42102',
    '12 6.99935C12 9.57768 9.91167 11.666 7.33333 11.666Z',
  ].join(' '),
  'M7.625 4.08268H6.75V7.58268L9.8125 9.42018L10.25 8.70268L7.625 7.14518V4.08268Z',
];

export const IconClock = ({ width = 15, height = 14, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path[0]} fill={color} />
    <Path d={path[1]} fill={color} />
  </Svg>
);

export default IconClock;
