import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import RoutePath from 'router/path';

import { LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, Link, SectionWrapper } from '../styles';

interface CustomerSectionProps extends OrderPwcSectionProps {
  showCustomerLink: boolean;
}

export const CustomerSection = ({ orderData, showCustomerLink }: CustomerSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.CUSTOMER.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.CUSTOMER_ID)}</ItemName>
          <ItemValue>
            <OptionallyVisible visible={!showCustomerLink}>{orderData.customer.uuid}</OptionallyVisible>
            <OptionallyVisible visible={showCustomerLink}>
              <Link to={RoutePath.pwc.customerById(orderData.customer.uuid)}>{orderData.customer.uuid}</Link>
            </OptionallyVisible>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.EXTERNAL_CUSTOMER_ID)}</ItemName>
          <ItemValue>{orderData.customer.externalCustomerId}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.CUSTOMER.CUSTOMER_EMAIL)}</ItemName>
          <ItemValue>{orderData.customer.email}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
