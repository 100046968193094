import React from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { AccountOwnerType } from 'services/merchants/types';

import { FormField } from 'components/form/formField/formField';
import { Input } from 'components/inputs';
import { RadioGroup } from 'components/inputs/radio/radioGroup';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';
import OptionallyVisible from 'components/optionallyVisible';

import { KEYS, LABELS } from '../keys';
import { BankAccountForm } from '../types';
import { useCreateBankAccountsModal } from '../useCreateBankAccountsModal';
import { CountriesList, Label, Section } from './form.styles';

interface CreateAccountFormProps {
  formValues: BankAccountForm;
  onFieldValueChange: (key: string, value: any) => void;
  error?: Record<string, string>;
  accountType: AccountOwnerType | null;
  setAccountType: (accountType: AccountOwnerType) => void;
  countryProps: ReturnType<typeof useCreateBankAccountsModal>['modalProps']['countryProps'];
}

export const CreateAccountModalForm = ({
  formValues,
  error,
  onFieldValueChange,
  accountType,
  setAccountType,
  countryProps: { countries, selectedCountry, handleCountryChange },
}: CreateAccountFormProps) => {
  const { t } = useTranslation();

  const blockOptions = [
    {
      key: AccountOwnerType.Individual,
      value: AccountOwnerType.Individual,
      label: t(LABELS.CREATE_MODAL.ACCOUNT_TYPE.INDIVIDUAL),
    },
    {
      key: AccountOwnerType.Business,
      value: AccountOwnerType.Business,
      label: t(LABELS.CREATE_MODAL.ACCOUNT_TYPE.BUSINESS),
    },
  ];

  const handleFormChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleAccountTypeChange = (type: string) => {
    setAccountType(type as AccountOwnerType);
  };

  const handleAccountNumberChange = (value: any) => {
    const normalized = value.replaceAll(' ', '');

    if (normalized.length > 12) {
      //  doing additional check here as react-input-mask appears to have a bug
      return;
    }

    handleFormChange(KEYS.FORM_FIELDS.ACCOUNT_NUMBER)(normalized);
  };

  const handleRoutingNumberChange = (value: any) => {
    const normalized = value.replaceAll(' ', '');

    if (normalized.length > 9) {
      //  doing additional check here as react-input-mask appears to have a bug
      return;
    }

    handleFormChange(KEYS.FORM_FIELDS.ROUTING_NUMBER)(normalized);
  };

  return (
    <>
      <Section>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.CURRENCY_SECTION.TITLE)}</Label>

        <FormField error={error?.currency}>{KEYS.HARDCODED_CURRENCY.toUpperCase()}</FormField>
      </Section>

      <Section>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.BANK_NAME_SECTION.TITLE)}</Label>
        <FormField error={error?.bankName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.BANK_NAME)}
            value={formValues.bankName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.BANK_NAME)}
          />
        </FormField>
      </Section>

      <Section>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_OWNER_NAME_SECTION.TITLE)}</Label>
        <FormField error={error?.accountOwnerName}>
          <Input
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_OWNER_NAME)}
            value={formValues.accountOwnerName}
            onChange={handleFormChange(KEYS.FORM_FIELDS.ACCOUNT_OWNER_NAME)}
          />
        </FormField>
      </Section>

      <Section>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_NUMBER_SECTION.TITLE)}</Label>
        <FormField error={error?.['account.accountNumber']}>
          <InputMask
            mask={KEYS.MASK_ACCOUNT_NUMBER}
            onChange={handleAccountNumberChange}
            value={formValues.accountNumber}
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NUMBER)}
            maskPlaceholder=""
          >
            <Input />
          </InputMask>
        </FormField>
      </Section>

      <Section>
        <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ROUTING_NUMBER_SECTION.TITLE)}</Label>
        <FormField error={error?.['account.routingNumber']}>
          <InputMask
            mask={KEYS.MASK_ROUTING_NUMBER}
            onChange={handleRoutingNumberChange}
            value={formValues.routingNumber}
            placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.ROUTING_NUMBER)}
            maskPlaceholder=""
          >
            <Input />
          </InputMask>
        </FormField>
      </Section>

      <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ACCOUNT_TYPE.TITLE)}</Label>

      <RadioGroup options={blockOptions} onChange={handleAccountTypeChange} value={accountType || ''} />

      <OptionallyVisible visible={accountType === AccountOwnerType.Individual}>
        <Section>
          <Label>{t(LABELS.CREATE_MODAL.SECTIONS.FIRST_NAME_SECTION.TITLE)}</Label>
          <FormField error={error?.firstName}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.FIRST_NAME)}
              value={formValues.firstName}
              onChange={handleFormChange(KEYS.FORM_FIELDS.FIRST_NAME)}
            />
          </FormField>
        </Section>

        <Section>
          <Label>{t(LABELS.CREATE_MODAL.SECTIONS.LAST_NAME_SECTION.TITLE)}</Label>
          <FormField error={error?.lastName}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.LAST_NAME)}
              value={formValues.lastName}
              onChange={handleFormChange(KEYS.FORM_FIELDS.LAST_NAME)}
            />
          </FormField>
        </Section>
      </OptionallyVisible>

      <OptionallyVisible visible={accountType === AccountOwnerType.Business}>
        <Section>
          <Label>{t(LABELS.CREATE_MODAL.SECTIONS.BUSINESS_NAME_SECTION.TITLE)}</Label>
          <FormField error={error?.businessName}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.BUSINESS_NAME)}
              value={formValues.businessName}
              onChange={handleFormChange(KEYS.FORM_FIELDS.BUSINESS_NAME)}
            />
          </FormField>
        </Section>
      </OptionallyVisible>

      <OptionallyVisible visible={accountType === AccountOwnerType.Individual || accountType === AccountOwnerType.Business}>
        <Section>
          <Label>{t(LABELS.CREATE_MODAL.SECTIONS.ADDRESS_SECTION.TITLE)}</Label>
          <FormField error={error?.['address.streetLine1']}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.STREET_LINE_1)}
              value={formValues.streetLine1}
              onChange={handleFormChange(KEYS.FORM_FIELDS.STREET_LINE_1)}
            />
          </FormField>

          <FormField error={error?.['address.streetLine2']}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.STREET_LINE_2)}
              value={formValues.streetLine2}
              onChange={handleFormChange(KEYS.FORM_FIELDS.STREET_LINE_2)}
            />
          </FormField>

          <FormField error={error?.['address.city']}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.CITY)}
              value={formValues.city}
              onChange={handleFormChange(KEYS.FORM_FIELDS.CITY)}
            />
          </FormField>

          <FormField error={error?.['address.state']}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.STATE)}
              value={formValues.state}
              onChange={handleFormChange(KEYS.FORM_FIELDS.STATE)}
            />
          </FormField>

          <FormField error={error?.['address.postalCode']}>
            <Input
              placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.POSTAL_CODE)}
              value={formValues.postalCode}
              onChange={handleFormChange(KEYS.FORM_FIELDS.POSTAL_CODE)}
            />
          </FormField>

          <CountriesList>
            <FormField error={error?.['address.country']}>
              <SearchableSelect<SelectItem, false>
                fullWidth
                value={selectedCountry}
                items={countries}
                placeholder={t(LABELS.CREATE_MODAL.FORM_FIELDS.PLACEHOLDERS.COUNTRY)}
                onChange={handleCountryChange}
                getItemLabel={(item) => item.label}
                multiple={false}
                closeDropdownOnSelect
                popperProps={{ disablePortal: true }}
              />
            </FormField>
          </CountriesList>
        </Section>
      </OptionallyVisible>
    </>
  );
};
