import { OptionallyVisible } from 'components';
import React, { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTable } from 'components/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { COLUMNS, LABELS } from './apiKeys.keys';
import { customCellComponentFactory } from './components/cells';
import { ApiKeysTableRow, RotateApiKeyFn } from './types';

interface APIKeysSectionProps extends WithTranslation {
  apiKeys: ApiKeysTableRow[];
  isPending: boolean;
  rotateApiKey: RotateApiKeyFn;
}

export const APIKeysSectionComponent = ({ apiKeys, isPending, t, rotateApiKey }: APIKeysSectionProps) => {
  const CustomCellComponent = useMemo(() => customCellComponentFactory({ rotateApiKey }), [rotateApiKey]);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={isPending}>
        <LoadingIndicator padded />
      </OptionallyVisible>

      <OptionallyVisible visible={!isPending}>
        <DataTable columns={COLUMNS} rows={apiKeys} CellComponent={CustomCellComponent} />
      </OptionallyVisible>
    </Panel>
  );
};

export const APIKeysSection = withTranslation()(APIKeysSectionComponent);
