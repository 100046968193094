import styled from '@emotion/styled/macro';
import { MaterialDesignContent } from 'notistack';

import { Button } from 'components/buttons';
import { Root as ButtonRoot } from 'components/buttons/styles';

export const ButtonContent = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 5,
  marginLeft: -4,
}));

export const ButtonLabel = styled.span((props) => ({
  color: 'inherit',
}));

export const ModalRoot = styled.div((props) => ({
  outline: 'none',
}));

export const ModalControls = styled.div((props) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const PanelContent = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  padding: '0 12px 12px 12px',
}));

export const Delimeter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
}));

export const Buttons = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
}));

export const ContentContainer = styled.div(() => ({
  minWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 15,
  alignItems: 'center',
}));

export const Caption = styled.div((props) => ({
  fontWeight: 400,
  fontSize: 13,
  color: props.theme.colors.primary,
  maxWidth: 250,
  textAlign: 'center',
}));

export const Title = styled.div((props) => ({
  fontWeight: 600,
  fontSize: 16,
  color: props.theme.colors.primary,
}));

export const Image = styled.img(() => ({}));

export const ToastCloseButton = styled(Button)(() => ({
  minWidth: 0,
  padding: 4,
}));

export const StyledToast = styled(MaterialDesignContent)((props) => ({
  padding: 8,
  display: 'flex',
  justifyContent: 'space-between',
  div: {
    margin: 0,
    padding: 0,
    lineHeight: 'normal',
    display: 'flex',
    gap: 6,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: props.theme.colors.success.light,
    border: `0.5px solid ${props.theme.colors.success.main}`,
    color: props.theme.colors.success.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: props.theme.colors.errorBackground,
    border: `0.5px solid ${props.theme.colors.error.main}`,
    color: props.theme.colors.error.main,
  },
}));
