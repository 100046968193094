import React from 'react';

import LoadingIndicator from 'components/loadingIndicator';

import { Container } from './styles';

export const LoadingScreen = () => (
  <Container>
    <LoadingIndicator withLogo />
  </Container>
);
