import React, { ReactElement } from 'react';

import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconCross from 'components/icons/cross';
import IconDash from 'components/icons/dash';
import IconProhibitedCircled from 'components/icons/prohibitedCircled';
import IconRefresh from 'components/icons/refresh';

import { ReconciliationStatus } from 'types/reconciliations';

import { ReconciliationBadgeType, badgePropsMap } from './keys';
import { BadgeContent } from './styles';

interface StatusBadgeProps {
  status: ReconciliationStatus;
}

const badgeToIconMap: Record<ReconciliationBadgeType, ReactElement | null> = {
  [ReconciliationBadgeType.Success]: <IconChecked />,
  [ReconciliationBadgeType.Fail]: <IconCross />,
  [ReconciliationBadgeType.Info]: <IconRefresh />,
  [ReconciliationBadgeType.Prohibition]: <IconProhibitedCircled />,
  [ReconciliationBadgeType.Default]: null,
  [ReconciliationBadgeType.Warning]: null,
};

const badgeTypeMap: Record<ReconciliationBadgeType, BadgeType> = {
  [ReconciliationBadgeType.Success]: BadgeType.Success,
  [ReconciliationBadgeType.Fail]: BadgeType.Fail,
  [ReconciliationBadgeType.Info]: BadgeType.Info,
  [ReconciliationBadgeType.Prohibition]: BadgeType.Fail,
  [ReconciliationBadgeType.Default]: BadgeType.Default,
  [ReconciliationBadgeType.Warning]: BadgeType.Warning,
};

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { type, label } = badgePropsMap[status] || badgePropsMap.default;
  const icon = badgeToIconMap[type];
  const badgeType = badgeTypeMap[type];

  const usedLabel = label || <IconDash />;

  return (
    <Badge type={badgeType}>
      <BadgeContent>
        {icon}
        {usedLabel}
      </BadgeContent>
    </Badge>
  );
};
