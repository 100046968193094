import React from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination } from 'types/pagination';

import { Button } from '../buttons';
import IconBorderArrow from '../icons/borderArrow';
import IconSmallChevron from '../icons/smallChevron';
import { Select } from '../inputs/select/select';
import { KEYS, LABELS } from './dataTable.keys';
import { ItemsPerPageOption } from './dataTable.types';
import {
  PaginationButtonContent,
  PaginationGrid,
  PaginationWrapper,
  RotatedIcon,
  RowsPerPageWrapper,
  Text,
} from './dataTablePagination.styles';

export interface DataTablePaginationProps {
  pagination?: Pagination;
  onChangeRowsPerPage?: (nextItemsPerPage: number) => void;
  onChangePage?: (page: number) => void;
}

export const DataTablePagination = ({ pagination, onChangeRowsPerPage, onChangePage }: DataTablePaginationProps) => {
  const { t } = useTranslation();
  const handleChangeRowsPerPage = (selected: ItemsPerPageOption) => {
    onChangeRowsPerPage?.(selected.value);
  };

  const goToNextPage = () => {
    if (!pagination) {
      return;
    }

    onChangePage?.(pagination.page + 1);
  };

  const goToPrevPage = () => {
    if (!pagination) {
      return;
    }

    onChangePage?.(pagination.page - 1);
  };

  const goToFirstPage = () => {
    if (!pagination) {
      return;
    }

    onChangePage?.(1);
  };

  const goToLastPage = () => {
    if (!pagination) {
      return;
    }

    onChangePage?.(pagination.pages || 1);
  };

  const selectedPaginationOption = KEYS.DEFAULT_PAGINATION_OPTIONS.find((item) => item.value === pagination?.itemsPerPage);
  const { itemsPerPage = 0, page = 1, count = 0 } = pagination || {};
  const currentPageDescription = {
    from: (page - 1) * itemsPerPage + 1,
    to: Math.min(page * itemsPerPage, count),
    total: count,
  };

  const isFirstPage = pagination ? pagination.page === 1 : true;
  const isLastPage = pagination ? pagination.page >= (pagination.pages || 0) : true;

  return (
    <PaginationGrid>
      <RowsPerPageWrapper>
        <Text>{t(LABELS.ROWS_PER_PAGE)}</Text>
        <Select<ItemsPerPageOption>
          value={selectedPaginationOption}
          onChange={handleChangeRowsPerPage}
          placeholder={t(LABELS.ROWS_PER_PAGE)}
          items={KEYS.DEFAULT_PAGINATION_OPTIONS}
          getItemLabel={(item) => item.label}
          closeDropdownOnSelect
        />
        <Text>{t(LABELS.RECORDS, currentPageDescription)}</Text>
      </RowsPerPageWrapper>
      <PaginationWrapper>
        <Button flat onClick={goToFirstPage} disabled={isFirstPage}>
          <PaginationButtonContent>
            <RotatedIcon>
              <IconBorderArrow />
            </RotatedIcon>
          </PaginationButtonContent>
        </Button>
        <Button flat onClick={goToPrevPage} disabled={isFirstPage}>
          <PaginationButtonContent>
            <RotatedIcon>
              <IconSmallChevron />
            </RotatedIcon>
            {t(LABELS.PREVIOUS)}
          </PaginationButtonContent>
        </Button>
        <Button flat onClick={goToNextPage} disabled={isLastPage}>
          <PaginationButtonContent>
            {t(LABELS.NEXT)} <IconSmallChevron />
          </PaginationButtonContent>
        </Button>
        <Button flat onClick={goToLastPage} disabled={isLastPage}>
          <PaginationButtonContent>
            <IconBorderArrow />
          </PaginationButtonContent>
        </Button>
      </PaginationWrapper>
    </PaginationGrid>
  );
};
