import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { ActionMenu, MenuItem } from 'components/dataTable/cells/actionMenu';

import { LABELS } from '../../keys';
import { AccountDataRow } from '../../types';

export const ActionsCell = ({ row, passedProps }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { id } = row as AccountDataRow;

  const handleSetAsDefault = () => {
    const setAccountAsDefault = passedProps?.setAccountAsDefault;
    setAccountAsDefault?.(id);
  };

  const handleRevokeAccess = () => {
    const revokeAccessToAccount = passedProps?.revokeAccessToAccount;
    revokeAccessToAccount?.(id);
  };

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <MenuItem onClick={handleRevokeAccess}>{t(LABELS.ACTIONS.REVOKE_ACCESS)}</MenuItem>
        <MenuItem onClick={handleSetAsDefault}>{t(LABELS.ACTIONS.SET_AS_DEFAULT)}</MenuItem>
      </ActionMenu>
    </TableCell>
  );
};
