import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M14.25 9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75Z';

export const IconAdd = ({ width = 18, height = 18, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} />
  </Svg>
);

export default IconAdd;
