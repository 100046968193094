export enum AmountInputVariant {
  IsEqual = 'isEqual',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  IsBetween = 'isBetween',
}

export interface AmountInputVariantItem {
  key: AmountInputVariant;
  label: string;
}
