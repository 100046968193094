import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M7 7.5C8.51937 7.5 9.75 6.26937 9.75 4.75C9.75 3.23062 8.51937 2 7 2C5.48062 2 4.25 3.23062 4.25 4.75C4.25 6.26937',
  '5.48062 7.5 7 7.5ZM7 8.875C5.16437 8.875 1.5 9.79625 1.5 11.625V13H12.5V11.625C12.5 9.79625 8.83563 8.875 7 8.875Z',
].join(' ');

export const IconProfile = ({ width = 14, height = 15, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} />
  </Svg>
);

export default IconProfile;
