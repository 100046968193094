import dayjs from 'dayjs';
import { useNotifications } from 'providers/notifications/useNotifications';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetReconciliationCSVQuery } from 'services/reconciliations';

import { Panel } from 'components/containers';
import IconDownload from 'components/icons/download';

import { LABELS as COMMON_LABELS, KEYS } from 'pages/reconciliations/keys';

import { NotificationSeverity } from 'types/notifications';

import downloadFile from 'utils/downloadFile';

import { LABELS } from '../../keys';
import { ReconciliationSectionProps } from '../../types';
import { DownloadButton, Item, ItemName, ItemValue, SectionWrapper } from '../styles';

export const ReportSection = ({ reconciliationData }: ReconciliationSectionProps) => {
  const [trigger, { isFetching }] = useLazyGetReconciliationCSVQuery();
  const { pushToast } = useNotifications();
  const { t } = useTranslation();

  const exportCSV = async () => {
    try {
      const result = await trigger(reconciliationData.reconciliation.uuid).unwrap();

      if (result.type !== 'text/csv') {
        throw new Error('Unexpected file type');
      }

      const objectUrl = window.URL.createObjectURL(result);
      downloadFile({
        name: t(COMMON_LABELS.CSV_FILE_NAME, { date: dayjs().format(KEYS.DATE_FORMAT) }),
        source: objectUrl,
      });
    } catch (e: any) {
      pushToast({
        severity: NotificationSeverity.error,
        message: t(COMMON_LABELS.CSV_DOWNLOAD_ERROR),
      });
    }
  };

  return (
    <Panel label={t(LABELS.SECTIONS.REPORT.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.REPORT.CSV_REPORT)}</ItemName>
          <ItemValue>
            <DownloadButton secondary={!isFetching} flat={isFetching} onClick={exportCSV} disabled={isFetching}>
              <IconDownload />
              {t(LABELS.SECTIONS.REPORT.DOWNLOAD)}
            </DownloadButton>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.REPORT.CREATED_AT)}</ItemName>
          <ItemValue>{reconciliationData.reconciliation.createdAt}</ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
