import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M11.6666 6.41699H2.33325V7.58366H11.6666V6.41699Z';

export const IconDash = ({ width = 14, height = 14, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconDash;
