import { Tooltip } from '@mui/material';
import { OptionallyVisible } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTableCellProps } from 'components/dataTable';
import DataTable from 'components/dataTable/dataTable';
import IconAdd from 'components/icons/add';
import IconInfoCircled from 'components/icons/infoCircled';

import { AddPaymentMethodModal } from './components/addPaymentMethodModal';
import { LABELS, PAYMENT_METHODS_COLUMNS, PAYMENT_METHODS_COLUMN_IDS, paymentMethods } from './keys';
import {
  ActionPanel,
  AddRecordButton,
  InfoIconContainer,
  PaymentMethodName,
  PaymentMethodsContainer,
  PaymentMethodsTableCell,
  StyledTooltip,
} from './styles';
import { PaymentMethodTableRow } from './types';

interface PaymentMethodsSectionProps {
  methods: PaymentMethodTableRow[];
}

const PaymentMethodCellComponent = (props: DataTableCellProps) => {
  const { t } = useTranslation();
  const { row, column } = props;
  const booleanColumns = [PAYMENT_METHODS_COLUMN_IDS.BUY, PAYMENT_METHODS_COLUMN_IDS.SELL];
  if (column.id === PAYMENT_METHODS_COLUMN_IDS.NAME) {
    return (
      <PaymentMethodsTableCell data-column={column.id}>
        <PaymentMethodName>
          {row[column.id]}
          <OptionallyVisible visible={Boolean(row.tooltip)}>
            <Tooltip slots={{ tooltip: StyledTooltip }} placement="top-start" title={row.tooltip}>
              <InfoIconContainer>
                <IconInfoCircled />
              </InfoIconContainer>
            </Tooltip>
          </OptionallyVisible>
        </PaymentMethodName>
      </PaymentMethodsTableCell>
    );
  }

  if (booleanColumns.includes(column.id)) {
    const label = t(row[column.id] ? LABELS.PAYMENT_METHODS.YES : LABELS.PAYMENT_METHODS.NO);
    return <PaymentMethodsTableCell data-column={column.id}>{label}</PaymentMethodsTableCell>;
  }

  return <PaymentMethodsTableCell data-column={column.id}>{row[column.id]}</PaymentMethodsTableCell>;
};

export const PaymentMethodsSection = ({ methods }: PaymentMethodsSectionProps) => {
  const { t } = useTranslation();
  const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setAddModalVisible(true);
  };

  const closeModal = () => {
    setAddModalVisible(false);
  };

  return (
    <Panel label={t(LABELS.PAYMENT_METHODS.PANEL_TITLE)}>
      <PaymentMethodsContainer>
        <ActionPanel>
          <AddRecordButton flat onClick={openModal}>
            <IconAdd />
            {t(LABELS.PAYMENT_METHODS.ADD_METHOD)}
          </AddRecordButton>
        </ActionPanel>
        <AddPaymentMethodModal open={isAddModalVisible} onClose={closeModal} />
        <DataTable columns={PAYMENT_METHODS_COLUMNS} rows={paymentMethods} CellComponent={PaymentMethodCellComponent} />
      </PaymentMethodsContainer>
    </Panel>
  );
};
