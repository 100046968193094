import styled from '@emotion/styled';
import { colors } from 'theme';

export const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing(3),
  background: colors.pageWrapper,
  minHeight: '100vh',
}));

export const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const Header = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const TestEnvironmentBadge = styled.span((props) => ({
  padding: `4px 10px`,
  backgroundColor: '#FFECDC',
  color: '#CC3203',
  fontWeight: 600,
  borderRadius: 100,
  textAlign: 'right',
  fontSize: 12,
}));

export const TestEnvironmentIndicator = styled.div((props) => ({
  width: '100%',
  borderBottom: `1.5px solid #CC3203`,
}));

export const ProfileMenuContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 30,
}));
