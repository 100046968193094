import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M0.192383 1.5575L3.63488 5L0.192383 8.4425L1.24988 9.5L5.74988 5L1.24988 0.5L0.192383',
  '1.5575ZM7.99988 0.5H9.49988V9.5H7.99988V0.5Z',
].join(' ');

export const IconBorderArrow = ({ width = 10, height = 10, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} />
  </Svg>
);

export default IconBorderArrow;
