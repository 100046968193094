import React from 'react';
import { useTranslation } from 'react-i18next';

import QuestionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';

import { LABELS } from '../keys';
import { UserTableRow } from '../types';
import { Buttons, Caption, ContentContainer, Delimeter, Image, ModalControls, ModalRoot, PanelContent, Question } from './styles';

interface RevokeAccessModalProps {
  onRevoke: (email: string) => Promise<boolean>;
  isSubmitting: boolean;
  reset: () => void;
  open: boolean;
  onClose: () => void;
  user: UserTableRow | null;
}

export const RevokeAccessModal = ({ onRevoke, isSubmitting, reset, open, onClose, user }: RevokeAccessModalProps) => {
  const { t } = useTranslation();

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    reset();
    onClose();
  };

  const handleRevoke = async () => {
    const canNotRevoke = isSubmitting || !user;
    if (canNotRevoke) {
      return;
    }

    onRevoke(user!.uuid);
    closeModal();
  };

  if (!user) {
    return null;
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.REMOVE_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            <ContentContainer>
              <Image src={QuestionImage} />
              <Question>{t(LABELS.REMOVE_MODAL.QUESTION)}</Question>
              <Caption>{t(LABELS.REMOVE_MODAL.CAPTION, { email: user.username })}</Caption>
            </ContentContainer>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={closeModal}>
                {t(LABELS.REMOVE_MODAL.BUTTONS.CANCEL)}
              </Button>
              <Button primary onClick={handleRevoke}>
                {t(LABELS.REMOVE_MODAL.BUTTONS.REMOVE)}
              </Button>
            </Buttons>
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
