import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M0.5 0.25L4.25 4L0.5 7.75V0.25Z';

export const IconSmallChevron = ({ width = 5, height = 8, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconSmallChevron;
