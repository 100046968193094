import { Typography } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';

import { LABELS } from '../keys';
import { AccountDataRow } from '../types';
import { ButtonClose, Buttons, Divider, ModalContent, ModalControls, ModalWrapper, QuestionText } from './revokeAccountAccessModal.styles';

export interface RevokeAccountAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  account: AccountDataRow | null;
  onRevokeAccess: (accountId: string) => void;
}
export const RevokeAccountAccessModal = ({ isOpen, onClose, account, onRevokeAccess }: RevokeAccountAccessModalProps) => {
  const { t } = useTranslation();

  const handleRevokeAccess = () => {
    if (!account) {
      return;
    }
    onRevokeAccess(account.uuid);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div>
        <Panel
          label={t(LABELS.REVOKE_ACCESS_MODAL.TITLE)}
          controls={
            <ModalControls>
              <ButtonClose flat onClick={onClose}>
                <IconCross />
              </ButtonClose>
            </ModalControls>
          }
        >
          <ModalWrapper>
            <ModalContent>
              <img src={questionImage} alt={t(LABELS.REVOKE_ACCESS_MODAL.CONTENT.QUESTION)} />
              <QuestionText>{t(LABELS.REVOKE_ACCESS_MODAL.CONTENT.QUESTION)}</QuestionText>
              <Typography variant="body2">{t(LABELS.REVOKE_ACCESS_MODAL.CONTENT.REMARK, { accountName: account?.name })}</Typography>
            </ModalContent>
            <Divider />
            <Buttons>
              <Button secondary onClick={onClose}>
                {t(LABELS.REVOKE_ACCESS_MODAL.BUTTONS.CANCEL)}
              </Button>
              <Button primary onClick={handleRevokeAccess}>
                {t(LABELS.REVOKE_ACCESS_MODAL.BUTTONS.CONFIRM)}
              </Button>
            </Buttons>
          </ModalWrapper>
        </Panel>
      </div>
    </Modal>
  );
};
