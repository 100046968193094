export enum CustomHeaderType {
  Auth = 'Authorization',
  ApiKey = 'x-public-key',
}

export type CustomHeaderProvider = () => CustomHeader;

export interface CustomHeader {
  key: CustomHeaderType;
  value: string;
}
