import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from '../../dropdownMenu/dropdownMenu';
import IconDots from '../../icons/dots';
import { LABELS } from './keys';
import { ActionsCellWrapper, Menu, MenuTitle } from './styles';

export { MenuItem } from './styles';

export interface DefaultActionsDropdownMenuCellProps {
  title?: string;
  children: ReactNode;
}

export const ActionMenu = ({ title, children }: DefaultActionsDropdownMenuCellProps) => {
  const { t } = useTranslation();
  const menuTitle = title || t(LABELS.ACTION_MENU_TITLE);

  return (
    <ActionsCellWrapper>
      <DropdownMenu
        buttonProps={{
          flat: true,
        }}
        popperProps={{
          placement: 'bottom-end',
        }}
        buttonLabel={<IconDots />}
        renderMenu={() => (
          <Menu>
            <MenuTitle>{menuTitle}</MenuTitle>
            {children}
          </Menu>
        )}
      />
    </ActionsCellWrapper>
  );
};
