import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'M7.9987 1.33301C4.3187 1.33301 1.33203 4.31967 1.33203 7.99967C1.33203 11.6797 4.3187 14.6663 7.9987',
    '14.6663C11.6787 14.6663 14.6654 11.6797 14.6654 7.99967C14.6654 4.31967 11.6787 1.33301 7.9987',
    '1.33301ZM7.9987 13.333C5.05203 13.333 2.66536 10.9463 2.66536 7.99967C2.66536 5.05301 5.05203 2.66634',
    '7.9987 2.66634C10.9454 2.66634 13.332 5.05301 13.332 7.99967C13.332 10.9463 10.9454 13.333 7.9987 13.333Z',
  ].join(' '),
  [
    'M11.2008 5.44535L10.5562 4.80078L8.00078 7.35621L5.44535 4.80078L4.80078 5.44535L7.35621 8.00078L4.80078',
    '10.5562L5.44535 11.2008L8.00078 8.64535L10.5562 11.2008L11.2008 10.5562L8.64535 8.00078L11.2008 5.44535Z',
  ].join(' '),
];

export const IconCrossCircled = ({ width = 16, height = 16, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path[0]} fill={color} />
    <Path fillRule="evenodd" clipRule="evenodd" d={path[1]} fill={color} />
  </Svg>
);

export default IconCrossCircled;
