import { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DateRange } from 'types/time';

import DatePicker from './date';
import { KEYS, LABELS } from './keys';
import { DateRangeContainer } from './styles';

interface DateRangePickerProps {
  format?: string;
  value: DateRange;
  onChange: (value: DateRange) => void;
  mobile?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholders?: {
    from?: string;
    to?: string;
  };
}

export const DateRangePicker = ({
  format = KEYS.DEFAULT_FORMAT,
  value,
  onChange,
  disabled,
  fullWidth,
  mobile,
  placeholders = {},
}: DateRangePickerProps) => {
  const { t } = useTranslation();
  const { from = t(LABELS.FROM), to = t(LABELS.TO) } = placeholders;
  const [startDate, setStartDate] = useState<Dayjs | null>(value[0]);
  const [endDate, setEndDate] = useState<Dayjs | null>(value[1]);

  useEffect(() => {
    const [start, end] = value;
    setStartDate(start);
    setEndDate(end);
  }, [value]);

  const handleStartChange = (value: Dayjs | null) => {
    setStartDate(value);
    onChange([value, endDate]);
  };

  const handleEndChange = (value: Dayjs | null) => {
    setEndDate(value);
    onChange([startDate, value]);
  };

  return (
    <DateRangeContainer>
      <DatePicker value={startDate} format={format} onChange={handleStartChange} mobile={mobile} disabled={disabled} inputLabel={from} />
      <DatePicker value={endDate} format={format} onChange={handleEndChange} mobile={mobile} disabled={disabled} inputLabel={to} />
    </DateRangeContainer>
  );
};

export default DateRangePicker;
