export const KEYS = {
  DEFAULT_PAGINATION_OPTIONS: [
    {
      key: '15',
      value: 15,
      label: '15',
    },
    {
      key: '30',
      value: 30,
      label: '30',
    },
    {
      key: '60',
      value: 60,
      label: '60',
    },
    {
      key: '100',
      value: 100,
      label: '100',
    },
  ],
};

export const LABELS = {
  ROWS_PER_PAGE: ['orderTableCell.table.pagination.itemsPerPage', 'Items per page'],
  RECORDS: ['orderTableCell.table.pagination.records', '{{from}}-{{to}} of {{total}} items'],
  NEXT: ['orderTableCell.table.pagination.next', 'NEXT'],
  PREVIOUS: ['orderTableCell.table.pagination.previous', 'PREVIOUS'],
};
