export interface Asset {
  symbol: string;
  name: string;
  chainCode: string;
  type: AssetType;
  model: string;
  minAmount: string;
  maxAmount: string;
  stablecoin: boolean;
  precision: number;
  tagName: string | null;
  feeAsset: string;
  contractAddress: string;
  addressRegexp: string;
  addressTagRegexp: string;
  iconUrl: string;
  displayDecimals: number;
  defaultAmount: number;
}

export enum AssetType {
  Fiat = 'fiat',
  Crypto = 'crypto',
}
