import React from 'react';
import ReactDOM from 'react-dom/client';

import 'locales/i18next';

import initSentry from 'utils/sentry/init';

import App from './app';

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
