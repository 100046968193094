import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { InputRoot } from 'components/inputs/input/styles';

const getErrorFieldStyles = (props: { error?: boolean; touched?: boolean; theme: Theme }) => {
  if (!props.error) {
    return {};
  }

  return {
    // @ts-ignore
    [InputRoot]: {
      backgroundColor: props.theme.colors.background,
      border: `1px solid ${props.theme.colors.error.main}`,
      input: {
        backgroundColor: props.theme.colors.background,
      },
    },
  };
};

export const FormFieldRoot = styled.div<{ error?: boolean; touched?: boolean }>(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
  getErrorFieldStyles,
);

export const ErrorMessage = styled.span<{ visible?: boolean }>((props) => ({
  color: props.theme.colors.error.main,
  display: props.visible ? 'inline' : 'none',
  fontSize: 11,
  fontFamily: props.theme.fonts.primary,
}));
