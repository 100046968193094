import styled from '@emotion/styled';

import { InputRoot } from 'components/inputs/input/styles';

export const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const CountriesList = styled.div((props) => ({
  width: 290,
}));

export const Label = styled.span((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));
