import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

const searchStorage: Record<string, any> = {};

export const usePersistentSearch = (debounceTimeout: number, key: string) => {
  const initialState = searchStorage[key] || '';
  const [search, setSearch] = useState<string>(initialState);

  const handleSearch = (search: string) => {
    setSearch(search);
    searchStorage[key] = search;
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, debounceTimeout), [setSearch]);

  return { search, setSearch: debounceTimeout ? debouncedHandleSearch : handleSearch };
};
