/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';
import { ColorPicker } from 'components/inputs/color/colorPicker';
import { MultilineInput } from 'components/inputs/input/multilineInput';
import { Select, SelectItem } from 'components/inputs/select/select';
import { Switch } from 'components/inputs/switch/switch';

import { SettingsFormField } from 'pages/account/edit/types';

import { AccountLandingPageSettings } from 'types/account';

import { FileDropzone } from '../../components/fileDropzone';
import { FORM_FIELDS, LABELS, formFields, logoAcceptRules, themeModeItems } from '../keys';
import { ThemeModeItem } from '../types';
import { Adornment, Slug, SlugAdornment } from './styles';

interface LandingSettingsFormProps {
  account: AccountLandingPageSettings;
  initialValues: AccountLandingPageSettings;
  onFieldValueChange: (key: string, value: any) => void;
  disabledFields: Record<string, boolean>;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
}

export const LandingSettingsForm = ({
  account,
  initialValues,
  onFieldValueChange,
  disabledFields,
  isUpdating,
  error,
  readOnlyMode,
}: LandingSettingsFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleSelectChange = (fieldName: string) => (value: SelectItem) => {
    onFieldValueChange(fieldName, value.key);
  };

  const handleLogoChange = (fieldName: keyof AccountLandingPageSettings) => (files: File[]) => {
    const onChange = handleChange(fieldName);
    const [logo] = files;
    const isRemovingLogo = !logo && account[fieldName];
    if (isRemovingLogo) {
      onChange('');
      return;
    }

    onChange(logo);
  };

  const themeMode = themeModeItems.find((item) => item.key === account[FORM_FIELDS.PAGE_THEME_MODE]);
  const shouldBeHidden = (formField: SettingsFormField) =>
    [FORM_FIELDS.REDIRECT_URL, FORM_FIELDS.REDIRECT_DOMAIN].includes(formField.key as keyof AccountLandingPageSettings);

  const fieldRenderers: Record<string, FormFieldDescription['renderInput']> = {
    [FORM_FIELDS.CUSTOM_LANDING]: ({ readonly }) => (
      <Switch value={account.landingPageEnabled} onChange={handleChange(FORM_FIELDS.CUSTOM_LANDING)} disabled={readonly} />
    ),
    [FORM_FIELDS.LANDING_PAGE_URL]: ({ readonly }) => (
      <Slug>
        <SlugAdornment>{LABELS.ADORNMENTS.URL_SLUG_PREFIX}</SlugAdornment>
        <Input
          value={account.urlSlug}
          onChange={handleChange(FORM_FIELDS.LANDING_PAGE_URL)}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.LANDING_PAGE_URL)}
          type={InputType.Text}
          disabled={isUpdating || readonly}
        />
      </Slug>
    ),
    [FORM_FIELDS.PAGE_TITLE]: ({ readonly }) => (
      <MultilineInput
        fullWidth
        value={account.pageTitle}
        onChange={handleChange(FORM_FIELDS.PAGE_TITLE)}
        placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PAGE_TITLE)}
        type={InputType.Text}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.REDIRECT_ENABLED]: ({ readonly }) => (
      <Switch value={account.enableRedirectUrl} onChange={handleChange(FORM_FIELDS.REDIRECT_ENABLED)} disabled={readonly} />
    ),
    [FORM_FIELDS.REDIRECT_DOMAIN]: () => (
      <Input
        fullWidth
        value={account.redirectDomain}
        onChange={handleChange(FORM_FIELDS.REDIRECT_DOMAIN)}
        startAdornment={<Adornment>{LABELS.ADORNMENTS.HTTPS}</Adornment>}
        disabled={isUpdating}
      />
    ),
    [FORM_FIELDS.REDIRECT_URL]: () => (
      <Input
        fullWidth
        value={account.orderCompleteRedirectUrl}
        onChange={handleChange(FORM_FIELDS.REDIRECT_URL)}
        startAdornment={<Adornment>{LABELS.ADORNMENTS.HTTPS}</Adornment>}
        disabled={isUpdating}
      />
    ),
    [FORM_FIELDS.PAGE_THEME_MODE]: ({ readonly }) =>
      readonly ? (
        themeMode?.label
      ) : (
        <Select<ThemeModeItem, false>
          items={themeModeItems}
          fullWidth
          value={themeMode}
          disabled={isUpdating || readonly}
          onChange={handleSelectChange(FORM_FIELDS.PAGE_THEME_MODE)}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PAGE_THEME_MODE)}
          searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
          getItemLabel={(item) => item.label}
        />
      ),
    [FORM_FIELDS.PAGE_BACKGROUND_COLOR]: ({ readonly }) => (
      <ColorPicker
        defaultColor={initialValues.pageColor}
        onChange={handleChange(FORM_FIELDS.PAGE_BACKGROUND_COLOR)}
        color={account.pageColor}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.LANDING_LOGO]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.LANDING_LOGO)}
        filledInputLabel={t(LABELS.FORM_FIELDS.LANDING_LOGO)}
        initialFile={account.landingLogo}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.LANDING_LOGO_DARK]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.LANDING_LOGO_DARK)}
        filledInputLabel={t(LABELS.FORM_FIELDS.LANDING_LOGO_DARK)}
        initialFile={account.landingLogoDark}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        darkMode
        disabled={isUpdating || readonly}
      />
    ),
  };

  const fields = formFields.map((formField) => {
    if (shouldBeHidden(formField)) {
      return {
        ...formField,
        hidden: !account.enableRedirectUrl,
        renderInput: fieldRenderers[formField.key],
      };
    }

    return {
      ...formField,
      renderInput: fieldRenderers[formField.key],
    };
  });

  return <FormLayout fields={fields} readonly={readOnlyMode} error={error} />;
};
