import { ReactNode } from 'react';

export enum NotificationType {
  Toast,
  Dialog,
}

export type NotificationKey = string | number;

export enum NotificationSeverity {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  default = 'default',
}

export interface Notification {
  type: NotificationType;
  severity: NotificationSeverity;
  key: NotificationKey;
  message: string | ReactNode;
}

export interface ToastNotification extends Notification {
  persist?: boolean;
  dismissed?: boolean;
  preventDuplicate?: boolean;
}

export interface DialogNotification extends Notification {
  image?: ReactNode;
  onClose?: () => void;
  dialogTitle?: string;
  title?: string;
}
