import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';

import { InputType } from './input';
import { MultilineInputRoot } from './styles';

export interface MultilineInputProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: InputType;
  forwardRef?: HTMLInputElement | ((ref: HTMLInputElement) => void);
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  className?: string;
  rows?: number;
}

export const MultilineInput = ({
  onChange,
  value,
  disabled,
  placeholder,
  fullWidth,
  startAdornment,
  endAdornment,
  type = InputType.Text,
  forwardRef,
  onFocus,
  onBlur,
  className,
  rows,
}: MultilineInputProps) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onChange?.(value);
  };

  return (
    <MultilineInputRoot
      onChange={handleInputChange}
      value={value}
      readOnly={disabled}
      placeholder={placeholder}
      fullWidth={fullWidth}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      type={type}
      ref={forwardRef}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      // @ts-ignore
      multiline
      // @ts-ignore
      rows={rows}
    />
  );
};
