import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import pwcOrdersApi from 'services/PwcOrders';

import accountsApi from '../services/accounts';
import apiKeysApi from '../services/apiKeys';
import assetsApi from '../services/assets';
import customersApi from '../services/customers';
import merchantsApi from '../services/merchants';
import ordersApi from '../services/orders';
import profileApi from '../services/profile';
import reconciliationsApi from '../services/reconciliations';
import staticAssetsApi from '../services/staticAssets';
import usersApi from '../services/users';
import accountSlice from './slices/accountSlice';
import apiKeysSlice from './slices/apiKeysSlice';
import customerSlice from './slices/customerSlice';
import notificationsSlice from './slices/notificationsSlice';
import profileSlice from './slices/profileSlice';
import userSlice from './slices/userSlice';

export const rootReducer = combineReducers({
  user: userSlice,
  account: accountSlice,
  apiKeys: apiKeysSlice,
  profile: profileSlice,
  notifications: notificationsSlice,
  customer: customerSlice,
  [apiKeysApi.reducerPath]: apiKeysApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [pwcOrdersApi.reducerPath]: pwcOrdersApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [merchantsApi.reducerPath]: merchantsApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [staticAssetsApi.reducerPath]: staticAssetsApi.reducer,
  [reconciliationsApi.reducerPath]: reconciliationsApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        apiKeysApi.middleware,
        ordersApi.middleware,
        pwcOrdersApi.middleware,
        accountsApi.middleware,
        merchantsApi.middleware,
        profileApi.middleware,
        usersApi.middleware,
        assetsApi.middleware,
        customersApi.middleware,
        staticAssetsApi.middleware,
        reconciliationsApi.middleware,
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
