import { NotificationKey, NotificationSeverity } from 'types/notifications';

export interface NotifyOptions {
  message: string;
  severity: NotificationSeverity;
  persist?: boolean;
  preventDuplicate?: boolean;
  key?: NotificationKey;
}

export type NotifyFunction = (options: NotifyOptions) => void;

export interface WithNotificationProps {
  notify: NotifyFunction;
}

export enum NotificationEvent {
  PushNotification = 'pushToast',
  PushDialogNotification = 'pushDialog',
  RemoveNotification = 'removeNotification',
}
