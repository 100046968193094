import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps } from 'components/dataTable';
import { ActionMenu, CopyMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { TableCell } from 'components/dataTable/tableCell';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconArrow from 'components/icons/arrow';
import OptionallyVisible from 'components/optionallyVisible';

import RoutePath from 'router/path';

import { OrderSide } from 'types/order';

import { convertValueToDayjs } from 'utils/date';

import { COLUMN_IDS, KEYS, badgeTypeMap, statusTypeMap } from '../../../keys';
import { OrderDataRow, StatusType } from '../../../types';
import { LABELS } from './keys';
import { CryptoCurrency, FiatCurrency, GreyText, OrderSideIcon } from './styles';

const StatusCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const status: StatusType = statusTypeMap[value] || StatusType.Pending;
  const badgeType: BadgeType = badgeTypeMap[status] || BadgeType.Default;
  return (
    <TableCell data-column={column.id}>
      <Badge type={badgeType}>{status}</Badge>
    </TableCell>
  );
};

const TradeSideCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return (
    <TableCell>
      <OrderSideIcon side={value as OrderSide}>
        <IconArrow />
      </OrderSideIcon>
    </TableCell>
  );
};

const GreyTextCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return (
    <TableCell data-column={column.id}>
      <GreyText>{value}</GreyText>
    </TableCell>
  );
};

const DateCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const now = dayjs();
  const dateValue = convertValueToDayjs(value);
  const isSameYear = now.year() === dateValue?.year();
  const format = isSameYear ? KEYS.DATE_FORMAT : KEYS.DATE_FORMAT_WITH_YEAR;
  return <TableCell>{convertValueToDayjs(value)?.format(format)}</TableCell>;
};

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const FiatAmountCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  const currencyCode = row[COLUMN_IDS.FIAT_CURRENCY];
  return (
    <TableCell data-column={column.id}>
      <OptionallyVisible visible={Boolean(value)}>
        <FiatCurrency>
          <span>{value}</span>
          <span>{currencyCode}</span>
        </FiatCurrency>
      </OptionallyVisible>
    </TableCell>
  );
};

const CryptoAmountCell = ({ row, column }: DataTableCellProps) => {
  const [cryptoCurrency] = row[COLUMN_IDS.CRYPTO_CURRENCY].split('.');
  return (
    <TableCell data-column={column.id}>
      <CryptoCurrency>
        <GreyText>{row[column.id]}</GreyText>
        <GreyText>{cryptoCurrency}</GreyText>
      </CryptoCurrency>
    </TableCell>
  );
};

const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const { uuid, userEmail } = row as OrderDataRow;

  return (
    <TableCell>
      <ActionMenu>
        <NavigateMenuItem to={RoutePath.orderById(uuid)}>{t(LABELS.ACTIONS.NAVIGATE_TO_ORDER)}</NavigateMenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_ORDER_ID)}</CopyMenuItem>
        <CopyMenuItem value={userEmail}>{t(LABELS.ACTIONS.COPY_USER_EMAIL)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.STATUS]: StatusCell,
  [COLUMN_IDS.DATE]: DateCell,
  [COLUMN_IDS.ID]: GreyTextCell,
  [COLUMN_IDS.USER]: GreyTextCell,
  [COLUMN_IDS.FIAT_AMOUNT]: FiatAmountCell,
  [COLUMN_IDS.SIDE]: TradeSideCell,
  [COLUMN_IDS.CRYPTO_AMOUNT]: CryptoAmountCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const OrderTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};
