import styled from '@emotion/styled/macro';

export const GreyText = styled.span((props) => ({
  display: 'inline',
  fontSize: 13,
  lineHeight: '13px',
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
}));

export const FiatCurrency = styled.span((props) => ({
  display: 'inline-flex',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 30,
  },
}));
