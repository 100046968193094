import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M7.33398 9.99967H8.66732V11.333H7.33398V9.99967ZM7.33398 4.66634H8.66732V8.66634H7.33398V4.66634ZM7.99398',
  '1.33301C4.31398 1.33301 1.33398 4.31967 1.33398 7.99967C1.33398 11.6797 4.31398 14.6663 7.99398',
  '14.6663C11.6807 14.6663 14.6673 11.6797 14.6673 7.99967C14.6673 4.31967 11.6807 1.33301 7.99398',
  '1.33301ZM8.00065 13.333C5.05398 13.333 2.66732 10.9463 2.66732 7.99967C2.66732 5.05301 5.05398',
  '2.66634 8.00065 2.66634C10.9473 2.66634 13.334 5.05301 13.334 7.99967C13.334 10.9463 10.9473 13.333',
  '8.00065 13.333Z',
].join(' ');

export const IconExclamationCircled = ({ width = 16, height = 16, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconExclamationCircled;
