import styled from '@emotion/styled/macro';

import { Path } from '../../icons/styles';

export const Container = styled.div<{ checked: boolean; disabled?: boolean }>(
  (props) => ({
    width: 16,
    height: 16,
    boxSizing: 'border-box',
    borderWidth: 1.5,
    borderRadius: 16,
    borderStyle: 'solid',
    borderColor: props.checked ? props.theme.colors.accent : props.theme.colors.inactive,
    backgroundColor: props.checked ? props.theme.colors.accent : props.theme.colors.background,
    transition: 'all .3s ease',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    [Path]: {
      fill: props.theme.colors.white,
    },
  }),
  ({ disabled, checked, theme }) =>
    disabled
      ? {
          borderColor: theme.colors.disabled,
          backgroundColor: checked ? theme.colors.disabled : theme.colors.background,
        }
      : {},
);

export const RadioGroupContainer = styled.div<{ horizontal?: boolean }>((props) => ({
  display: 'flex',
  flexDirection: props.horizontal ? 'row' : 'column',
  alignItems: props.horizontal ? 'center' : 'stretch',
  justifyContent: 'flex-start',
  gap: 16,
}));

export const RadioGroupItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
}));

export const RadioGroupItemLabel = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  font: props.theme.fonts.primary,
}));
