import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetReconciliationQuery } from 'services/reconciliations';

import LoadingIndicator from 'components/loadingIndicator';

import { ReconciliationDetails } from './ReconciliationDetails';

export const ReconciliationViewContainer = () => {
  const params = useParams();
  const { uuid } = params;

  const { data: reconciliation, isLoading } = useGetReconciliationQuery(uuid!, {
    skip: !uuid,
  });

  if (isLoading) {
    return <LoadingIndicator padded />;
  }

  if (!reconciliation?.data) {
    return null;
  }

  return <ReconciliationDetails reconciliationData={reconciliation.data} />;
};
