import React from 'react';

import { DetailsSection } from './sections/details/details';
import { ReconciliationSection } from './sections/reconciliation/reconciliation';
import { ReportSection } from './sections/report/report';
import { Container, SubContainer } from './styles';
import { ReconciliationData } from './types';

export interface ReconciliationDetailsProps {
  reconciliationData: ReconciliationData;
}

export const ReconciliationDetails = ({ reconciliationData }: ReconciliationDetailsProps) => {
  const sectionsProps = {
    reconciliationData,
  };

  return (
    <Container>
      <SubContainer>
        <ReconciliationSection {...sectionsProps} />
        <ReportSection {...sectionsProps} />
      </SubContainer>

      <SubContainer>
        <DetailsSection {...sectionsProps} />
      </SubContainer>
    </Container>
  );
};
