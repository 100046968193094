import '@emotion/react';
import { createTheme } from '@mui/material';

import colors from './colors';
import fonts from './fonts';

const defaultTheme = {
  colors,
  fonts,
};

export const theme = defaultTheme;
export const muiTheme = createTheme({
  colors,
  typography: {
    fontFamily: fonts.primary,
  },
});

export default theme;
