import { RouteSetup } from 'types/routing';

import { menuItems } from './menuItems';

export const SuspendedRouterSetup: RouteSetup = {
  availableRoutes: [],
  menuItems,
};

export default SuspendedRouterSetup;
