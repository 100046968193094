export const LABELS = {
  DRAG_AND_DROP_TEXT_EMPTY: ['orderTableCell.inputs.dropzone.placeholder', 'Drop your image here or Choose file'],
  EDIT: ['orderTableCell.inputs.dropzone.edit', 'Edit'],
  DELETE: ['orderTableCell.inputs.dropzone.delete', 'Delete'],
};

export const KEYS = {
  UPLOAD_ICON_CLASS_NAME: 'upload-icon',
  MAX_FILE_SIZE: 1024 * 1024,
};

export const IMAGE_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export const FILE_LIMIT = 1;

export default LABELS;
