import { t } from 'i18next';

import { ReconciliationStatus } from 'types/reconciliations';

export enum ReconciliationBadgeType {
  Success = 'Success',
  Fail = 'Fail',
  Info = 'Info',
  Prohibition = 'Prohibition',
  Default = 'Default',
  Warning = 'Warning',
}

export const LABELS = {
  STATUS: {
    SUCCESSFUL: ['page.reconciliations.components.status.successful', 'Successful'],
    ERROR: ['page.reconciliations.components.status.error', 'Error'],
    IN_PROGRESS: ['page.reconciliations.components.status.inProgress', 'In Progress'],
    MISMATCH: ['page.reconciliations.components.status.mismatch', 'Mismatch'],
  },
};

export const badgePropsMap: Record<string, { type: ReconciliationBadgeType; label: string }> = {
  [ReconciliationStatus.SUCCESSFUL]: {
    type: ReconciliationBadgeType.Success,
    label: t(LABELS.STATUS.SUCCESSFUL),
  },
  [ReconciliationStatus.ERROR]: {
    type: ReconciliationBadgeType.Fail,
    label: t(LABELS.STATUS.ERROR),
  },
  [ReconciliationStatus.IN_PROGRESS]: {
    type: ReconciliationBadgeType.Info,
    label: t(LABELS.STATUS.IN_PROGRESS),
  },
  [ReconciliationStatus.MISMATCH]: {
    type: ReconciliationBadgeType.Prohibition,
    label: t(LABELS.STATUS.MISMATCH),
  },
};
