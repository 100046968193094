import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Asset } from 'types/assets';

import { getCurrencySymbol } from 'utils/currencies';
import { formatFiatCurrencyI18n } from 'utils/i18n';

import { KEYS, LABELS } from '../../keys';
import { OrderCreatePreview } from '../../types';
import {
  BreakdownContainer,
  BreakdownHeader,
  BreakdownRow,
  BreakdownRowSymbol,
  BreakdownRowTitle,
  BreakdownRowValue,
  BreakdownTable,
  Delimeter,
} from './styles';

interface OrderBreakdownProps {
  asset: Asset;
  currency: Asset;
  quote?: OrderCreatePreview;
}
export const OrderBreakdown = ({ quote, asset, currency }: OrderBreakdownProps) => {
  const { t, i18n } = useTranslation();

  const [symbol] = asset.symbol.split(KEYS.SYMBOL_SEPARATOR);

  const cryptoAmount = quote?.cryptoAmount || KEYS.PLACEHOLDER;
  const cryptoCostFiatAmount = quote?.cryptoCostFiatAmount || KEYS.PLACEHOLDER;
  const processingFeeFiatAmount = quote?.processingFeeFiatAmount || KEYS.PLACEHOLDER;
  const fiatAmount = quote?.fiatAmount || KEYS.PLACEHOLDER;
  const currencySymbol = getCurrencySymbol(i18n.language)(currency.symbol);

  return (
    <BreakdownContainer>
      <BreakdownHeader>
        <Trans
          i18nkey={LABELS.CREATE_ORDER_MODAL.TRANSACTION_DETAILS_HEADER[0]}
          values={{
            cryptocurrencyCode: asset.symbol,
            amount: cryptoAmount,
            fiatAmount: quote?.fiatAmount ? formatFiatCurrencyI18n(i18n.language)(Number(quote?.fiatAmount), currency.symbol) : '',
          }}
          defaults={LABELS.CREATE_ORDER_MODAL.TRANSACTION_DETAILS_HEADER[1]}
          components={[<b />]}
        />
      </BreakdownHeader>
      <BreakdownTable>
        <Delimeter />
        <BreakdownRow>
          <BreakdownRowTitle>{`${symbol} ${cryptoAmount}`}</BreakdownRowTitle>
          <BreakdownRowSymbol>{currencySymbol}</BreakdownRowSymbol>
          <BreakdownRowValue>{cryptoCostFiatAmount}</BreakdownRowValue>
        </BreakdownRow>
        <BreakdownRow>
          <BreakdownRowTitle>{t(LABELS.CREATE_ORDER_MODAL.PROCESSING_FEE)}</BreakdownRowTitle>
          <BreakdownRowSymbol>{currencySymbol}</BreakdownRowSymbol>
          <BreakdownRowValue>{processingFeeFiatAmount}</BreakdownRowValue>
        </BreakdownRow>
        <Delimeter />
        <BreakdownRow>
          <BreakdownRowTitle>
            <Trans
              i18nkey={LABELS.CREATE_ORDER_MODAL.TOTAL[0]}
              defaults={LABELS.CREATE_ORDER_MODAL.TOTAL[1]}
              values={{
                currency: currency.symbol,
              }}
              components={[<b />]}
            />
          </BreakdownRowTitle>
          <BreakdownRowSymbol>{currencySymbol}</BreakdownRowSymbol>
          <BreakdownRowValue>{fiatAmount}</BreakdownRowValue>
        </BreakdownRow>
      </BreakdownTable>
    </BreakdownContainer>
  );
};
