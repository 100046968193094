import { createApi } from '@reduxjs/toolkit/query/react';
import API_CONFIG from 'constants/api';
import { defaultOrdersCount } from 'constants/orders';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from 'utils/pagination';

import { auth0TokenStorage, fetchBaseQueryWithAuth } from '../api';
import { CustomHeaderType } from '../api/types';
import Endpoints from '../endpoints';
import { TAGS } from './keys';
import { OrderResponse, OrdersCSVResponse, OrdersListQuery, OrdersListResponse } from './types';

const REDUCER_PATH = 'ordersApi';

export const ordersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.ACCOUNT_RELATED],
  endpoints: (builder) => ({
    getOrdersList: builder.query<OrdersListResponse, OrdersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Orders.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      providesTags: [TAGS.ACCOUNT_RELATED],
      transformResponse: (response: Response<OrdersListResponse>) => {
        if (!response?.data) {
          return { count: defaultOrdersCount, data: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
    }),
    getOrder: builder.query<OrderResponse, string>({
      query: (uuid) => ({
        url: Endpoint.Orders.OrderById(uuid),
        method: HTTPMethod.GET,
      }),
      providesTags: [TAGS.ACCOUNT_RELATED],
    }),
    getOrdersCSV: builder.query<OrdersCSVResponse, OrdersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Orders.CSV,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const getCSVRecords = async (input: object) => {
  const URL = [API_CONFIG.ROOT_URL, Endpoints.Orders.CSV].join('');
  const headers = new Headers();
  headers.set('Content-type', 'application/json');
  const accessToken = await auth0TokenStorage.getAccessToken()();
  if (accessToken) {
    headers.set(CustomHeaderType.Auth, `Bearer ${accessToken}`);
  }
  const response = await fetch(URL, {
    method: HTTPMethod.POST,
    headers,
    body: JSON.stringify(input),
  });
  const blob = await response.blob();
  return blob;
};

export const { getOrdersList } = ordersApi.endpoints;
export const { useGetOrdersListQuery, useGetOrderQuery } = ordersApi;
export default ordersApi;
