import { Asset } from 'types/assets';

import { capitalizeFirstLetter } from './string';

export const getCurrencySymbol = (locale: string) => (currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const getShortenedCryptoCurrencyCode = (cryptocurrencyCode?: string | null) => {
  if (!cryptocurrencyCode) {
    return '';
  }

  return cryptocurrencyCode.split('.')[0];
};

const KEYS = {
  CRYPTO_SYMBOL_SEPARATOR: '.',
  CRYPTO_NETWORK_SPACING_SYMBOLS_EXP: /[_]/gi,
};

export const getCryptoLabel = (asset: Asset) => {
  const [symbol] = asset.symbol.split(KEYS.CRYPTO_SYMBOL_SEPARATOR);
  const chainName = capitalizeFirstLetter(asset.chainCode.replaceAll(KEYS.CRYPTO_NETWORK_SPACING_SYMBOLS_EXP, ' '));
  return `${symbol} - ${chainName}`;
};
