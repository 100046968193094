import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import IconProfile from 'components/icons/profile';
import IconReconciliations from 'components/icons/reconciliations';
import { TabItem } from 'components/tabs';

import RoutePath from 'router/path';

import { KEYS, LABELS } from './keys';
import { Settings } from './settings';

export const SettingsContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch(KEYS.ROUTE_MATCH);
  const { route } = match?.params || {};

  const tabs: TabItem[] = [
    {
      key: KEYS.TABS.USER_MANAGEMENT,
      label: t(LABELS.TABS.USER_MANAGEMENT),
      icon: <IconProfile />,
      onClick: () => navigate(RoutePath.GeneralSettings.Users),
    },
    {
      key: KEYS.TABS.SETTLEMENT_RECONCILIATIONS,
      label: t(LABELS.TABS.SETTLEMENT_RECONCILIATIONS),
      icon: <IconReconciliations />,
      onClick: () => navigate(RoutePath.GeneralSettings.Reconciliations),
    },
  ];

  const selectedTab: TabItem | undefined = tabs.find((tab) => tab.key === route);
  const breadcrumbs = [...LABELS.BREADCRUMBS, selectedTab?.label].filter(Boolean) as string[];

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <Settings tabs={tabs} selected={selectedTab} />
    </PageWrapper>
  );
};
