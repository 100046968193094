import React, { useEffect, useState } from 'react';

import { Select, SelectItem, SelectProps } from 'components/inputs/select/select';

interface SearchableSelectProps<T extends SelectItem, M extends boolean> extends Omit<SelectProps<T, M>, 'onSearch' | 'withSearch'> {
  compareItem?: (search: string, item: T) => boolean;
}

export const compareSelectItem = (search: string, item: any) => item?.label?.toLowerCase().includes(search.toLowerCase());

export const SearchableSelect = <T extends SelectItem, M extends boolean>({
  items,
  compareItem = compareSelectItem,
  multiple,
  value,
  ...restProps
}: SearchableSelectProps<T, M>) => {
  const [shownItems, setShownItems] = useState<T[]>(items);
  const [search, setSearch] = useState<string>('');
  const isMultiple: boolean = Boolean(multiple);

  useEffect(() => {
    if (search) {
      const filteredItems = items.filter((item: T) => compareItem(search, item));
      setShownItems(filteredItems);
      return;
    }

    setShownItems(items);
  }, [items, search]);

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const compareItems = (a: T, b: T) => ((a?.label || '') < (b?.label || '') ? -1 : 1);

  const selectedItems = shownItems
    .filter((item) => (multiple ? (value as T[])?.includes(item) : (value as T)?.key === item.key))
    .sort(compareItems);
  const notSelectedItems = shownItems.filter((item) => !selectedItems.includes(item)).sort(compareItems);
  const sortedItems = [...selectedItems, ...notSelectedItems];

  return (
    // @ts-ignore TODO: SelectValue<T, M> -> T | T[] problem once again
    <Select<T, typeof isMultiple> items={sortedItems} value={value} {...restProps} multiple={multiple} onSearch={handleSearch} withSearch />
  );
};
