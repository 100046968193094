import styled from '@emotion/styled/macro';

import { Title as ModalContentTitle } from 'components/modal/styles';

export const GreyText = styled.span((props) => ({
  display: 'inline',
  fontSize: 13,
  lineHeight: '13px',
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
}));

export const CryptoIcon = styled.img((props) => ({
  width: 20,
  height: 20,
  margin: 0,
  padding: 0,
}));

export const CryptoCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8,
}));

export const DataRow = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: 8,
}));

export const DataRowTitle = styled.span((props) => ({
  color: props.theme.colors.primary,
  fontSize: 13,
}));

export const DataRowValue = styled.div((props) => ({
  color: props.theme.colors.primary,
  fontSize: 13,
  fontWeight: 600,
}));

export const CryptoInlineIcon = styled.img(() => ({
  width: 16,
  height: 16,
}));

export const CryptoLabel = styled.div(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const CryptoName = styled.span((props) => ({
  color: props.theme.colors.primary,
}));

export const CryptoNetwork = styled.span((props) => ({
  color: props.theme.colors.inactive,
  fontWeight: 400,
}));

export const Adornment = styled.div(() => ({
  marginRight: 8,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
}));

export const ModalContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const Title = styled(ModalContentTitle)(() => ({
  textAlign: 'center',
  width: '100%',
}));

export const Error = styled.span((props) => ({
  color: props.theme.colors.error.main,
  fontSize: 13,
}));
