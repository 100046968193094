import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const Badge = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 12px 4px 4px',
  borderRadius: 100,
  gap: 8,
  backgroundColor: props.theme.colors.highlight,
  border: '0.5px solid rgba(98, 118, 160, 0.30)',
}));

export const CloseButton = styled(Button)((props) => ({
  display: 'flex',
  minWidth: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  width: 16,
  height: 16,
}));

export const Label = styled.div((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: 0,
  lineHeight: '15px',
  gap: 4,
}));

export const Name = styled.span((props) => ({
  fontSize: 12,
  lineHeight: '15px',
  color: props.theme.colors.inactive,
}));

export const Value = styled.span((props) => ({
  fontSize: 12,
  lineHeight: '15px',
  color: props.theme.colors.primary,
}));
