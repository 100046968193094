import { OptionallyVisible } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { Delimeter } from 'components/elements/delimeter';
import IconCross from 'components/icons/cross';
import { MultilineInput } from 'components/inputs/input/multilineInput';
import Modal from 'components/modal/modal';
import { Body, CloseButton, PanelTitle } from 'components/modal/styles';

import { CustomerStatus, CustomerTerminationReason } from 'types/customer';

import { KEYS, LABELS } from '../../keys';
import { StatusChangeModalBasicMessage, StatusChangeModalButtons } from './modalComponents';
import { ColumnContainer, Label, TerminationReasonRow } from './styles';

interface ExtendedStatusChangeModalProps {
  open: boolean;
  onClose: () => void;
  status: CustomerStatus;
  onSubmit: (nextStatus: CustomerStatus, options?: { reasonCode?: CustomerTerminationReason; notes?: string }) => Promise<void>;
  isUpdating: boolean;
}

export const BasicStatusChangeModal = ({ open, onClose, status, onSubmit, isUpdating }: ExtendedStatusChangeModalProps) => {
  const { t } = useTranslation();
  const isCustomerActive = status === CustomerStatus.Active;
  const [notes, setNotes] = useState<string>('');

  const handleSubmit = async () => {
    if (!isCustomerActive) {
      const nextStatus = CustomerStatus.Active;
      await onSubmit(nextStatus);
      setNotes('');
      return;
    }

    const nextStatus = CustomerStatus.Blocked;
    const options = { notes };

    await onSubmit(nextStatus, options);
    setNotes('');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Panel
        label={<PanelTitle>{t(LABELS.CHANGE_STATUS.MODAL_TITLE)}</PanelTitle>}
        controls={
          <CloseButton flat onClick={onClose}>
            <IconCross />
          </CloseButton>
        }
      >
        <Body>
          <StatusChangeModalBasicMessage isCustomerActive={isCustomerActive} />
          <Delimeter />
          <OptionallyVisible visible={status === CustomerStatus.Active}>
            <TerminationReasonRow>
              <ColumnContainer>
                <Label>{t(LABELS.CHANGE_STATUS.TERMINATION_NOTES_LABEL)}</Label>
                <MultilineInput
                  fullWidth
                  value={notes}
                  onChange={setNotes}
                  placeholder={t(LABELS.CHANGE_STATUS.TERMINATION_NOTES_PLACEHOLDER)}
                  rows={KEYS.TERMINATION_NOTES_MIN_ROWS}
                />
              </ColumnContainer>
            </TerminationReasonRow>
          </OptionallyVisible>
          <StatusChangeModalButtons onClose={onClose} onSubmit={handleSubmit} isUpdating={isUpdating} />
        </Body>
      </Panel>
    </Modal>
  );
};
