import { Theme } from '@emotion/react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Flat = 'flat',
  Danger = 'danger',
}

export type ButtonVariantProps = { theme: Theme; variant?: ButtonVariant; disabled?: boolean };
