import React, { useEffect, useState } from 'react';

import { Filter } from 'types/filters';

import IconLoading from '../../icons/loading';
import { RemoteSelectFilterPassedProps } from '../types';
import { KEYS } from './keys';

interface RemoteFilterLabelProps {
  filter: Filter<RemoteSelectFilterPassedProps>;
  value: string | string[];
}
export const RemoteFilterLabel = ({ filter, value }: RemoteFilterLabelProps) => {
  const [label, setLabel] = useState<string>('');

  const fetchLabel = async () => {
    const { data } = await filter.getItems({
      ids: value,
    });

    setLabel(data.map((item) => item.label).join(KEYS.STATUS_SEPARATOR));
  };

  useEffect(() => {
    fetchLabel();
  }, [value]);

  if (label) {
    return <span>{label}</span>;
  }

  return <IconLoading scale={0.6} />;
};
