import styled from '@emotion/styled';

import { TableCell } from './tableCell';

export const StyledTableCell = styled(TableCell)((props) => ({
  tableHeadCell: {
    fontSize: '12px !important',
    fontWeight: 500,
    color: props.theme.colors.primary,
  },
}));
