import { createSelector } from '@reduxjs/toolkit';
import { getAccountUsers } from 'services/accounts';

import { RootState } from '../../store';

const selectAccountAccessHandler = (state: RootState) => {
  const { access } = state.account;

  return access?.level;
};

const selectAccountHandler = (state: RootState) => {
  return {
    account: state.account.account,
  };
};

const selectAccountUsersHandler = (state: RootState) => {
  const { isLoading, data } = getAccountUsers.select(state.account.account.uuid)(state);

  return {
    users: data,
    isLoading,
  };
};

export const selectAccount = createSelector([(state: RootState) => state], selectAccountHandler);

export const selectAccountUsers = createSelector([(state: RootState) => state], selectAccountUsersHandler);

export const selectAccountAccess = createSelector([(state: RootState) => state], selectAccountAccessHandler);

export default selectAccount;
