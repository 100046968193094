import styled from '@emotion/styled';

import { LOGO_DEFAULT_SIZE } from 'components/loadingIndicator/loadingIndicator';

export const Container = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - ${LOGO_DEFAULT_SIZE}px)`,
  width: `calc(100vw - ${LOGO_DEFAULT_SIZE}px)`,
}));
