export const KEYS = {
  FORM_FIELDS: {
    USER_ID: 'uuid',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    NEW_PASSWORD: 'newPassword',
    NEW_PASSWORD_REPEAT: 'newPasswordRepeat',
  },
};

export const LABELS = {
  PANEL_TITLE: ['page.profile.userInfo.title', 'Profile'],
  SAVE_BUTTON_LABEL: ['page.profile.userInfo.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.profile.userInfo.cancel', 'Cancel'],
  CHANGE_PASSWORD: ['page.profile.userInfo.changePassword', 'Change password'],
  FORM_FIELDS: {
    USER_ID: ['page.profile.userInfo.form.userId', 'User ID'],
    EMAIL: ['page.profile.userInfo.form.email', 'Email'],
    PASSWORD: ['page.profile.userInfo.form.password', 'Password'],
    FIRST_NAME: ['page.profile.userInfo.form.firstName', 'First Name'],
    LAST_NAME: ['page.profile.userInfo.form.lastName', 'Last Name'],
    PLACEHOLDERS: {
      FIRST_NAME: ['page.profile.userInfo.form.firstName.placeholder', 'First Name'],
      LAST_NAME: ['page.profile.userInfo.form.lastName.placeholder', 'Last Name'],
    },
  },
  CHANGE_PASSWORD_MODAL: {
    TITLE: ['page.profile.userInfo.changePasswordModal.title', 'Change Password'],
    ENTER_NEW_PASSWORD: ['page.profile.userInfo.changePasswordModal.enterNewPassword', 'Enter Your New Password'],
    BUTTONS: {
      CHANGE_PASSWORD: ['page.profile.userInfo.changePasswordModal.buttons.cancel', 'Change Password'],
      CANCEL: ['page.profile.userInfo.changePasswordModal.buttons.cancel', 'Cancel'],
      CLOSE: ['page.profile.userInfo.changePasswordModal.buttons.close', 'Close'],
    },
    NEW_PASSWORD: {
      PLACEHOLDER: ['page.profile.userInfo.changePasswordModal.newPassword.placeholder', 'New Password'],
    },
    NEW_PASSWORD_REPEAT: {
      PLACEHOLDER: ['page.profile.userInfo.changePasswordModal.newPasswordRepeat.placeholder', 'Confirm New Password'],
    },
    PASSWORD_REQUIREMENTS: {
      REMARK: ['page.profile.userInfo.changePasswordModal.passwordRequirements.remark', 'Your password must contain'],
      PASSWORD_LENGTH: ['page.profile.userInfo.changePasswordModal.passwordRequirements.passwordLength', 'At least 8 characters'],
      PASSWORD_STRENGTH_PHRASE: [
        'page.profile.userInfo.changePasswordModal.passwordRequirements.passwordStrengthPhrase',
        'At least 3 of the following',
      ],
      PASSWORD_STRENGTH_REQUIREMENT_1: [
        'page.profile.userInfo.changePasswordModal.passwordRequirements.passwordStrengthRequirement1',
        'Lower case letters (a-z)',
      ],
      PASSWORD_STRENGTH_REQUIREMENT_2: [
        'page.profile.userInfo.changePasswordModal.passwordRequirements.passwordStrengthRequirement2',
        'Upper case letters (A-Z)',
      ],
      PASSWORD_STRENGTH_REQUIREMENT_3: [
        'page.profile.userInfo.changePasswordModal.passwordRequirements.passwordStrengthRequirement3',
        'Numbers (0-9)',
      ],
      PASSWORD_STRENGTH_REQUIREMENT_4: [
        'page.profile.userInfo.changePasswordModal.passwordRequirements.passwordStrengthRequirement4',
        'Special characters (e.g. !@#$%^&*)',
      ],
    },
    VALIDATION_MESSAGES: {
      [KEYS.FORM_FIELDS.NEW_PASSWORD]: [
        'page.profile.userInfo.changePasswordModal.validationMessages.passwordAndConfirmationAreNotTheSame',
        'Password and Password Confirmation are different',
      ],
    },
    SUCCESS_STEP: {
      TITLE: 'Success!',
      TEXT_LINE_1: 'Your password has been successfully changed!',
      TEXT_LINE_2: 'You’ll be logged out in a short time from all your devices',
      TEXT_LINE_3: 'and you will need to login again with your new password.',
    },
  },
};
