import React, { ComponentType, FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { COLUMN_IDS } from '../../keys';
import { AccountNameCell } from './accountNameCell';
import { ActionsCell } from './actionsCell';
import { DefaultCell } from './defaultCell';
import { InviteStatusCell } from './inviteStatusCell';

const cellComponentMap: { [key: string]: ComponentType<DataTableCellProps> } = {
  [COLUMN_IDS.NAME]: AccountNameCell,
  [COLUMN_IDS.INVITE_STATUS]: InviteStatusCell,
  [COLUMN_IDS.ACTIONS]: ActionsCell,
  default: DefaultCell,
};

interface CustomCellComponentFactoryProps {
  setAccountAsDefault: (accountId: string) => void;
  revokeAccessToAccount: (accountId: string) => void;
}

export const customCellComponentFactory =
  ({ setAccountAsDefault, revokeAccessToAccount }: CustomCellComponentFactoryProps): FunctionComponent<DataTableCellProps> =>
  (props: DataTableCellProps) => {
    const { column } = props;
    const SelectedComponent = cellComponentMap[column.id] || cellComponentMap.default;

    return <SelectedComponent {...props} passedProps={{ setAccountAsDefault, revokeAccessToAccount }} />;
  };
