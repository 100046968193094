import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StatusType } from '../types';

const failedBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.error.main}4D`,
  backgroundColor: `${props.theme.colors.error.main}0D`,
  color: props.theme.colors.error.main,
});

const successBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.success.main}4D`,
  backgroundColor: `${props.theme.colors.success.main}0D`,
  color: props.theme.colors.success.main,
});

const pendingBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.inactive}4D`,
  backgroundColor: props.theme.colors.highlight,
  color: props.theme.colors.primary,
});

const getStatusStyle = (props: { theme: Theme; status: StatusType }) => {
  const { status } = props;
  if (status === StatusType.Success) {
    return successBadge(props);
  }

  if (status === StatusType.Failed) {
    return failedBadge(props);
  }

  return pendingBadge(props);
};

export const StatusBadge = styled.span<{ status: StatusType }>(
  (props) => ({
    padding: '3px 6px',
    borderRadius: 5,
    borderWidth: 0.5,
    borderStyle: 'solid',
  }),
  getStatusStyle,
);

export const GreyText = styled.span((props) => ({
  display: 'inline',
  fontSize: 13,
  lineHeight: '13px',
  color: props.theme.colors.inactive,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
  whiteSpace: 'nowrap',
}));

export const CryptoCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 40,
    justifyContent: 'flex-end',
  },
}));

export const FiatCurrency = styled.span((props) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 5,

  span: {
    display: 'inline-flex',
    minWidth: 30,
    justifyContent: 'flex-end',
  },
}));

export const FilterContainer = styled.div(() => ({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
}));

export const Text = styled.span((props) => ({
  fontWeight: 'inherit',
  color: 'inherit',
  fontSize: 14,
  lineHeight: '17px',
}));

export const FilterButton = styled.button<{ active: boolean }>((props) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 10,
  minWidth: 200,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.active ? props.theme.colors.highlight : props.theme.colors.background,
  color: props.active ? props.theme.colors.accent : props.theme.colors.inactive,
  fontWeight: props.active ? 600 : 400,
  borderRadius: 5,
}));
