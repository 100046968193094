import styled from '@emotion/styled/macro';

export const FilterBadges = styled.div((props) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '80%',
  gap: 8,
}));

export const Filters = styled.div(() => ({
  display: 'flex',
  gap: 10,
}));
