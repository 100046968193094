import { useState } from 'react';
import { useCreateAccountMutation } from 'services/accounts';
import { CreateUserPayload } from 'services/users/types';

import { AccountType } from 'types/account';
import { UserRoleType } from 'types/user';

import { getServerSideValidationDetails } from 'utils/error';

import { KEYS } from './keys';
import { CreatedAccount } from './types';

export const useCreateAccountModal = () => {
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<AccountType>(AccountType.Ramp);
  const [createdAccount, setCreatedAccount] = useState<CreatedAccount | null>(null);
  const [createAccount, { isLoading: isSubmittingAccount, error: createAccountError, reset }] = useCreateAccountMutation();

  const handleCreateAccountModalOpen = () => {
    setCreatedAccount({
      username: '',
      firstName: '',
      lastName: '',
      accountName: '',
      shift4MerchantId: '',
    });
    setCreateAccountModalOpen(true);
  };

  const handleCreateAccountModalClose = () => {
    setCreatedAccount(null);
    setCreateAccountModalOpen(false);
  };

  const handleCreateAccount = async (formValues: CreatedAccount) => {
    const { shift4MerchantId, ...commonProps } = formValues;

    const accountToCreate: CreateUserPayload = {
      ...commonProps,
      roles: [UserRoleType.Partner],
    };

    if (accountType === AccountType.Pwc) {
      accountToCreate.accountType = AccountType.Pwc;
      if (shift4MerchantId) {
        accountToCreate.shift4MerchantId = shift4MerchantId;
      }
    }

    const result = await createAccount(accountToCreate);

    return 'data' in result;
  };

  const createAccountErrorMessage = getServerSideValidationDetails(createAccountError, KEYS.FORM_FIELDS.EMAIL);

  return {
    handleCreateAccountModalOpen,
    createdAccount,
    modalProps: {
      isSubmitting: isSubmittingAccount,
      reset,
      open: isCreateAccountModalOpen,
      onClose: handleCreateAccountModalClose,
      onCreateAccount: handleCreateAccount,
      account: createdAccount!,
      error: createAccountErrorMessage,
      setAccountType,
      accountType,
    },
  };
};
