import React from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetAccountApiKeysQuery, useRotateApiKeyForAccountMutation } from 'services/apiKeys';

import selectAccount from 'state/selectors/accounts/accountSelector';

import { APIKeysSection } from './apiKeys';
import { KEYS } from './apiKeys.keys';
import { ApiKeysTableRow, RotateApiKeyFn } from './types';
import { transformApiKeysDataToTableFormat } from './utils';

export const APIKeysSectionContainer = () => {
  const match = useMatch(KEYS.ROUTE_MATCH);
  const { account } = useSelector(selectAccount);
  const { uuid } = match?.params ?? { uuid: account.uuid };

  const { data, isLoading, isUninitialized } = useGetAccountApiKeysQuery(uuid!, {
    skip: !uuid,
  });
  const [rotateApiKeyWithNoAccountId] = useRotateApiKeyForAccountMutation();

  const transformedApiKeysData: ApiKeysTableRow[] = transformApiKeysDataToTableFormat(data);
  const isPending = isUninitialized || isLoading;

  const rotateApiKey: RotateApiKeyFn = async (requestBody) => {
    await rotateApiKeyWithNoAccountId({
      ...requestBody,
      accountUuid: account.uuid,
    });
  };

  return <APIKeysSection apiKeys={transformedApiKeysData} isPending={isPending} rotateApiKey={rotateApiKey} />;
};
