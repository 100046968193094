import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M3.89967 11.3999C4.46634 10.9666 5.09967 10.6248 5.79967 10.3746C6.49967 10.1248 7.23301 9.99992 7.99967',
  '9.99992C8.76634 9.99992 9.49967 10.1248 10.1997 10.3746C10.8997 10.6248 11.533 10.9666 12.0997 11.3999C12.4886',
  '10.9444 12.7915 10.4277 13.0083 9.84992C13.2248 9.27214 13.333 8.65547 13.333 7.99992C13.333 6.52214 12.8137',
  '5.2637 11.775 4.22459C10.7359 3.18592 9.47745 2.66659 7.99967 2.66659C6.5219 2.66659 5.26367 3.18592 4.22501',
  '4.22459C3.1859 5.2637 2.66634 6.52214 2.66634 7.99992C2.66634 8.65547 2.77479 9.27214 2.99167 9.84992C3.20812',
  '10.4277 3.51079 10.9444 3.89967 11.3999ZM7.99967 8.66659C7.34412 8.66659 6.79123 8.4417 6.34101 7.99192C5.89123',
  '7.5417 5.66634 6.98881 5.66634 6.33325C5.66634 5.6777 5.89123 5.12481 6.34101 4.67459C6.79123 4.22481 7.34412',
  '3.99992 7.99967 3.99992C8.65523 3.99992 9.20812 4.22481 9.65834 4.67459C10.1081 5.12481 10.333 5.6777 10.333',
  '6.33325C10.333 6.98881 10.1081 7.5417 9.65834 7.99192C9.20812 8.4417 8.65523 8.66659 7.99967 8.66659ZM7.99967',
  '14.6666C7.07745 14.6666 6.21079 14.4915 5.39967 14.1413C4.58856 13.7915 3.88301 13.3166 3.28301 12.7166C2.68301',
  '12.1166 2.20812 11.411 1.85834 10.5999C1.50812 9.78881 1.33301 8.92214 1.33301 7.99992C1.33301 7.0777 1.50812',
  '6.21103 1.85834 5.39992C2.20812 4.58881 2.68301 3.88325 3.28301 3.28325C3.88301 2.68325 4.58856 2.20814 5.39967',
  '1.85792C6.21079 1.50814 7.07745 1.33325 7.99967 1.33325C8.9219 1.33325 9.78856 1.50814 10.5997 1.85792C11.4108',
  '2.20814 12.1163 2.68325 12.7163 3.28325C13.3163 3.88325 13.7912 4.58881 14.141 5.39992C14.4912 6.21103 14.6663',
  '7.0777 14.6663 7.99992C14.6663 8.92214 14.4912 9.78881 14.141 10.5999C13.7912 11.411 13.3163 12.1166 12.7163',
  '12.7166C12.1163 13.3166 11.4108 13.7915 10.5997 14.1413C9.78856 14.4915 8.9219 14.6666 7.99967 14.6666ZM7.99967',
  '13.3333C8.58856 13.3333 9.14412 13.2473 9.66634 13.0753C10.1886 12.9028 10.6663 12.6555 11.0997 12.3333C10.6663',
  '12.011 10.1886 11.7637 9.66634 11.5913C9.14412 11.4193 8.58856 11.3333 7.99967 11.3333C7.41079 11.3333 6.85523',
  '11.4193 6.33301 11.5913C5.81079 11.7637 5.33301 12.011 4.89967 12.3333C5.33301 12.6555 5.81079 12.9028 6.33301',
  '13.0753C6.85523 13.2473 7.41079 13.3333 7.99967 13.3333ZM7.99967 7.33325C8.28856 7.33325 8.52745 7.23881 8.71634',
  '7.04992C8.90523 6.86103 8.99967 6.62214 8.99967 6.33325C8.99967 6.04436 8.90523 5.80547 8.71634 5.61659C8.52745',
  '5.4277 8.28856 5.33325 7.99967 5.33325C7.71079 5.33325 7.4719 5.4277 7.28301 5.61659C7.09412 5.80547 6.99967',
  '6.04436 6.99967 6.33325C6.99967 6.62214 7.09412 6.86103 7.28301 7.04992C7.4719 7.23881 7.71079 7.33325 7.99967 7.33325Z',
].join(' ');

export const IconProfileCircled = ({ width = 16, height = 16, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fill={color} />
  </Svg>
);

export default IconProfileCircled;
