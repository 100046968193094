import styled from '@emotion/styled/macro';

import { Path } from '../../icons/styles';

export const Search = styled.div((props) => ({}));

export const SearchAdornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));
