import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { fetchBaseQueryWithAuth } from '../api';
import { FileUploadResponse } from './types';

const REDUCER_PATH = 'staticAssetsApi';

export const assetsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  endpoints: (builder) => ({
    uploadAsset: builder.mutation<string, File>({
      query: (file) => {
        const body = new FormData();
        body.append('image', file);

        return {
          url: Endpoint.StaticAssets.Upload,
          method: HTTPMethod.POST,
          prepareHeaders: (headers: Headers) => {
            headers.set('Content-Type', 'multipart/form-data');
            return headers;
          },
          body,
        };
      },
      transformResponse: (response: Response<FileUploadResponse>) => {
        if (response.data) {
          return response?.data.imageUrl;
        }

        return '';
      },
    }),
  }),
});

export const { uploadAsset } = assetsApi.endpoints;
export const { useUploadAssetMutation } = assetsApi;
export default assetsApi;
