import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconClock from 'components/icons/clock';
import IconDots from 'components/icons/dots';

import { COLUMN_IDS, LABELS, badgeTypeMap } from '../keys';
import { UserTableRow } from '../types';
import { ActionsCell, BadgeContent, Menu, MenuItem, MenuTitle } from './usersTableCell.styles';

const StatusTableCell = ({ row, column, passedProps }: DataTableCellProps) => {
  const { t } = useTranslation();
  const status: 'accepted' | 'pending' = row[column.id];
  const badgeType: BadgeType = badgeTypeMap[status] || BadgeType.Default;
  const icon = badgeType === BadgeType.Success ? <IconChecked /> : <IconClock />;
  const label = t(LABELS.STATUS[status.toUpperCase() as 'ACCEPTED' | 'PENDING']);
  return (
    <TableCell>
      <Badge type={badgeType}>
        <BadgeContent>
          {icon}
          <span>{label}</span>
        </BadgeContent>
      </Badge>
    </TableCell>
  );
};

const ActionsTableCell = ({ row, column, passedProps }: DataTableCellProps) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    passedProps?.editUser(row[COLUMN_IDS.ID]);
  };

  const handleRevoke = () => {
    passedProps?.revokeAccess(row[COLUMN_IDS.ID]);
  };

  const handleResend = () => {
    passedProps?.resendInvite(row[COLUMN_IDS.ID]);
  };

  const canEditUser: boolean = passedProps?.canEditUser?.(row);
  const canResendInvite: boolean = passedProps?.canResendInvite?.(row);

  return (
    <TableCell>
      <ActionsCell>
        <DropdownMenu
          buttonProps={{
            flat: true,
          }}
          popperProps={{
            placement: 'bottom-end',
          }}
          buttonLabel={<IconDots />}
          renderMenu={() => (
            <Menu>
              <OptionallyVisible visible={canEditUser}>
                <MenuTitle>{t(LABELS.ACTIONS.TITLE)}</MenuTitle>
                <MenuItem onClick={handleEdit}>{t(LABELS.ACTIONS.EDIT)}</MenuItem>
                <OptionallyVisible visible={canResendInvite}>
                  <MenuItem onClick={handleResend}>{t(LABELS.ACTIONS.RESEND_INVITE)}</MenuItem>
                </OptionallyVisible>
                <MenuItem onClick={handleRevoke}>{t(LABELS.ACTIONS.REVOKE_ACCESS)}</MenuItem>
              </OptionallyVisible>
              <OptionallyVisible visible={!canEditUser}>
                <MenuTitle>{t(LABELS.ACTIONS.NO_ACTIONS_AVAILABLE)}</MenuTitle>
              </OptionallyVisible>
            </Menu>
          )}
        />
      </ActionsCell>
    </TableCell>
  );
};

interface makeUsersTableCellParams {
  editUser: (uuid: string) => void;
  revokeAccess: (uuid: string) => void;
  resendInvite: (uuid: string) => void;
  canEditUser: (user: UserTableRow) => boolean;
  canResendInvite: (user: UserTableRow) => boolean;
}

export const makeUsersTableCell =
  ({ editUser, revokeAccess, canEditUser, resendInvite, canResendInvite }: makeUsersTableCellParams) =>
  (props: DataTableCellProps) => {
    const { row, column } = props;
    if (column.id === COLUMN_IDS.ACTIONS) {
      return <ActionsTableCell {...props} passedProps={{ editUser, revokeAccess, canEditUser, canResendInvite, resendInvite }} />;
    }

    if (column.id === COLUMN_IDS.STATUS) {
      return <StatusTableCell {...props} />;
    }

    return <TableCell>{row[column.id]}</TableCell>;
  };
