export enum FilterType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Date = 'date',
  Amount = 'amount',
  Select = 'select',
  RemoteSelect = 'remoteSelect',
  CurrencySelect = 'currencySelect',
}

export interface FilterSection {
  key: string;
  content: Filter<unknown>[];
}

export type Filter<FilterProps> = {
  type: FilterType;
  label: string;
  key: string;
} & FilterProps;

export interface FilterPreset<T> {
  key: string;
  label: string;
  value: T;
}

export interface DateFilterValue {
  from?: number;
  to?: number;
}

export interface AmountFilterValue {
  gte?: number;
  lte?: number;
}
