export enum OrderSide {
  Buy = 'buy',
  Sell = 'sell',
}

export interface Order {
  uuid: string;
  userUuid: string;
  participantCode: string;
  accountUuid: string | null;
  status: OrderStatus;
  createdAt: string;
  fiatCurrencyCode: string;
  fiatAmount: string;
  cryptoCurrencyCode: string;
  cryptoAmount: string;
  processingFeeFiatAmount: number;
  processingFeePercent: number;
  networkFeeFiatAmount: string;
  networkFeeCryptoAmount: string;
  cryptoCostFiatAmount: string;
  cryptoPrice: string;
  side: OrderSide;
  paymentMethod: string;
  cardBrand: string;
  cardLast4: string;
  failedStatusReason: string | null;
  tradeStatus: OrderTradeStatus;
  onChainStatus: OrderWithdrawalStatus;
  onChainTransactionHash: string;
  onChainExplorerURL: string;
  walletAddress: string;
  userEmail: string;
  fiatConversionRate: string | null;
  securionMerchantId: string;
  securionChargeId: string;
  externalOrderId: string | null;
  externalUserId: string | null;
  processingFeeFiatAmountInUSD: number;
  fiatAmountInUSD: number;
  partnerFeePercent: number;
  partnerFeeFiatAmount: string;
  partnerFeeFiatAmountInUSD: string;
}

export enum OrderChargeStatus {
  Success = 'successful',
  Fail = 'failed',
}

export interface Charge {
  amount: number;
  objectType: string;
  captured: boolean;
  refunded: boolean;
  disputed: boolean;
  status: OrderChargeStatus;
  failureIssuerDeclineCode?: string;
  failureMessage?: string;
  card: {
    objectType: string;
    brand: string;
    last4: string;
  };
}

export interface ExecutedQuote {
  fiatCurrencyCode: string;
  quoteId: string;
  quotedFiatAmount: number;
  networkFeeFiatAmount: number;
  networkFeeCryptoAmount: number;
  cryptoCurrencyCode: string;
  cryptoCostFiatAmount: number;
  cryptoPrice: string;
}

export enum OrderStatus {
  Initiated = 'INITIATED',
  AuthorizationChargeFailed = 'AUTHORIZATION_CHARGE_FAILED',
  GetQuoteToExecuteFailed = 'GET_QUOTE_TO_EXECUTE_FAILED',
  ExecuteQuoteFailed = 'EXECUTE_QUOTE_FAILED',
  CaptureChargeFailed = 'CAPTURE_CHARGE_FAILED',
  Completed = 'COMPLETED',
  SystemError = 'SYSTEM_ERROR',
  Processing = 'PROCESSING',
  ExchangeFailed = 'EXCHANGE_FAILED',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  AwaitingClientConfirmation = 'AWAITING_CLIENT_CONFIRMATION',
  PaymentFailed = 'PAYMENT_FAILED',
}

export enum OrderWithdrawalStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
}

export enum OrderTradeStatus {
  PendingTrade = 'PENDING_TRADE',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Settled = 'SETTLED',
}
