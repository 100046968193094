import Box from '@mui/material/Box';
import { PageWrapper, Typography } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LABELS } from './keys';

export const NotImplementedContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={[t(LABELS.BREADCRUMBS)]}>
      <Box>
        <Typography variant="caption">Location: {location.pathname}</Typography>
        <Typography variant="h4" paragraph>
          {t(LABELS.PAGE_BODY)}
        </Typography>
      </Box>
    </PageWrapper>
  );
};
