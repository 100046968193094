import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { MerchantInfo, MerchantStatus } from 'services/merchants/types';

import { getDefaultDatePresets } from 'components/filters/date/keys';
import { DateFilterPassedProps, RadioFilterPassedProps } from 'components/filters/types';

import { Filter, FilterSection, FilterType } from 'types/filters';

import { COLUMN_IDS, LABELS } from './keys';
import { Filters, MerchantDataRow, RequestFilters } from './types';

export const transformAccountsListDataToTableFormat = (accountRecordInfos?: MerchantInfo[]): MerchantDataRow[] => {
  return accountRecordInfos
    ? accountRecordInfos.map((accountRecordInfo) => ({
        ...accountRecordInfo,
        id: accountRecordInfo.uuid,
      }))
    : [];
};

export const makeFilterSections = (filterMap: Record<string, Filter<unknown>>): FilterSection[] => {
  return [
    {
      key: 'filters',
      content: [filterMap.status, filterMap.date],
    },
  ];
};

export const makeFilters = (): Record<string, Filter<unknown>> => {
  const datePresets = getDefaultDatePresets();
  const statusFilter: Filter<RadioFilterPassedProps> = {
    key: COLUMN_IDS.STATUS,
    label: t(LABELS.FILTERS.STATUS.LABEL),
    type: FilterType.Radio,
    options: [
      {
        key: MerchantStatus.Active,
        value: MerchantStatus.Active,
        label: t(LABELS.FILTERS.STATUS.ACTIVE),
      },
      {
        key: MerchantStatus.Inactive,
        value: MerchantStatus.Inactive,
        label: t(LABELS.FILTERS.STATUS.INACTIVE),
      },
      {
        key: MerchantStatus.Deactivated,
        value: MerchantStatus.Deactivated,
        label: t(LABELS.FILTERS.STATUS.DEACTIVATED),
      },
    ],
  };

  const dateFilter: Filter<DateFilterPassedProps> = {
    key: 'date',
    label: t(LABELS.FILTERS.DATE.LABEL),
    type: FilterType.Date,
    presets: datePresets,
  };

  return [dateFilter, statusFilter].reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur };
  }, {});
};

export const prepareFilters = (filters: Filters): RequestFilters | undefined => {
  if (!filters) {
    return undefined;
  }

  const { date, status } = filters;

  const nextFilters: RequestFilters = {};
  if (date) {
    nextFilters.date = date;
  }

  if (status && !isEmpty(status)) {
    const [value] = status;
    nextFilters.status = value;
  }

  return nextFilters;
};
