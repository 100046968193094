import styled from '@emotion/styled';
import { AppBar, Drawer, IconButton, List, ListItemButton, ListItemText, Link as MuiLink, Toolbar } from '@mui/material';
import { LinkProps as MuiLinkProps } from '@mui/material/Link/Link';
import React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

import { KEYS } from './keys';

export const TopAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.colors.white,
  height: KEYS.TOP_APP_BAR_HEIGHT_MOBILE,
  boxShadow: 'none',
}));

export const TopAppToolBar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  height: KEYS.TOP_APP_BAR_HEIGHT_MOBILE,
}));

export const LogoWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DrawerToggleButton = styled(IconButton)({
  marginLeft: 'auto',
});

export const Main = styled('main')<{
  open?: boolean;
  drawerHeight?: number;
}>(({ theme, open, drawerHeight }) => () => {
  const marginTop = drawerHeight ? drawerHeight + parseInt(theme.spacing(3), 10) : 0;

  return {
    flexGrow: 1,
    width: '100%',
    transition: `margin ${KEYS.MAIN_COMPONENT_TRANSITION_EASING} ${KEYS.MAIN_COMPONENT_TRANSITION_DURATION}`,
    marginTop: open ? 0 : `-${marginTop}px`,
    display: marginTop > 0 ? 'block' : 'none',
  };
});

export const MenuItemsListDrawer = styled(Drawer)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  padding: theme.spacing(3),
  paddingTop: 0,
  '& .MuiDrawer-paper': {
    width: '100%',
    paddingTop: KEYS.TOP_APP_BAR_HEIGHT_MOBILE,
    position: 'static',
    borderBottom: 'none',
  },
}));

export const MenuItemsList = styled(List)({
  paddingBottom: 0,
  paddingTop: 0,
  width: '185px',
});

export const MenuItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  maxHeight: '35px',
  '&:hover': {
    backgroundColor: theme.colors.highlight,
    color: theme.colors.darkAccent,
  },
  '&:active': {
    backgroundColor: theme.colors.container.tertiary,
    color: theme.colors.inactive,
  },
  '&.Mui-selected': {
    backgroundColor: theme.colors.highlight,
  },
}));

export const MenuItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export type LinkProps = RouterLinkProps & MuiLinkProps;

export const Link = styled(MuiLink)(({ theme }) => ({
  width: '100%',
  color: theme.colors.primary,
  textDecoration: 'none',
  '& .Mui-selected': {
    color: theme.colors.accent,
  },
})) as React.ComponentType<LinkProps>;
