import React, { useState } from 'react';

import IconSearch from '../../icons/search';
import { Input } from '../input/input';
import { Search, SearchAdornment } from './styles';

export interface SearchInputProps {
  onSearch: (value: string) => void;
  placeholder?: string;
  search: string;
}
export const SearchInput = ({ onSearch, placeholder = '', search }: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>(search);
  const handleSearch = (value: string) => {
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <Search>
      <Input
        value={searchValue}
        onChange={handleSearch}
        placeholder={placeholder}
        fullWidth
        startAdornment={
          <SearchAdornment>
            <IconSearch width={16} height={16} />
          </SearchAdornment>
        }
      />
    </Search>
  );
};
