import styled from '@emotion/styled/macro';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: 15,
  padding: 10,
}));

export const Item = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const ItemName = styled.div((props) => ({
  color: props.theme.colors.inactive,
  fontSize: 13,
  fontWeight: 400,
  minWidth: 150,
  flex: 1,
}));

export const ItemValue = styled.div((props) => ({
  fontSize: 13,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
  fontWeight: 400,
  flex: 2,
}));
