import React, { ComponentType, FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { COLUMN_IDS } from '../../keys';
import { AccountIdCell } from './accountIdCell';
import { ActionCell } from './actionCell';
import { DateCell } from './dateCell';
import { DefaultCell } from './defaultCell';
import { IsPartnerCertifiedCell } from './isPartnerCertifiedCell';

const cellComponentMap: { [key: string]: ComponentType<DataTableCellProps> } = {
  [COLUMN_IDS.NAME]: DefaultCell,
  [COLUMN_IDS.ID]: AccountIdCell,
  [COLUMN_IDS.CREATED_AT]: DateCell,
  [COLUMN_IDS.IS_CERTIFIED]: IsPartnerCertifiedCell,
  [COLUMN_IDS.ACTIONS]: ActionCell,
  default: DefaultCell,
};

export const PartnerAccountCell: FunctionComponent<DataTableCellProps> = (props) => {
  const { column } = props;
  const SelectedComponent = cellComponentMap[column.id] || cellComponentMap.default;

  return <SelectedComponent {...props} />;
};
