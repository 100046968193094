import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';

export const ActionPanel = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const AddRecordButton = styled(Button)(() => ({
  textTransform: 'uppercase',
}));

export const SettlementContainer = styled.div((props) => ({
  [props.theme.breakpoints.up('xl')]: {
    '& [data-column]': {
      width: '25%',
    },
  },
}));
