import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { FormField } from 'components/form/formField/formField';
import { Input } from 'components/inputs';

import { UserRecord } from 'types/user';

import { KEYS, LABELS } from '../../keys';
import { FieldTitle, FieldValue, FormRow } from './styles';

interface UserInfoSectionFormProps {
  profile: UserRecord;
  onFieldValueChange: (key: string, value: any) => void;
  isUpdating: boolean;
  error?: Record<string, string>;
  onChangePasswordButtonClick: () => void;
}

export const UserInfoSectionForm = ({
  profile,
  onFieldValueChange,
  isUpdating,
  error,
  onChangePasswordButtonClick,
}: UserInfoSectionFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  return (
    <>
      <FormRow>
        <FieldTitle>{t(LABELS.FORM_FIELDS.USER_ID)}</FieldTitle>
        <FieldValue>{profile.uuid}</FieldValue>
      </FormRow>

      <FormRow>
        <FieldTitle>{t(LABELS.FORM_FIELDS.EMAIL)}</FieldTitle>
        <FieldValue autoWidth>{profile.username}</FieldValue>
      </FormRow>

      <FormRow>
        <FieldTitle>{t(LABELS.FORM_FIELDS.PASSWORD)}</FieldTitle>
        <Button secondary onClick={onChangePasswordButtonClick}>
          {t(LABELS.CHANGE_PASSWORD)}
        </Button>
      </FormRow>

      <FormRow>
        <FieldTitle>{t(LABELS.FORM_FIELDS.FIRST_NAME)}</FieldTitle>
        <FieldValue>
          <FormField error={error?.firstName}>
            <Input
              fullWidth
              value={profile.firstName || ''}
              onChange={handleChange(KEYS.FORM_FIELDS.FIRST_NAME)}
              placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.FIRST_NAME)}
              disabled={isUpdating}
            />
          </FormField>
        </FieldValue>
      </FormRow>

      <FormRow>
        <FieldTitle>{t(LABELS.FORM_FIELDS.LAST_NAME)}</FieldTitle>
        <FieldValue>
          <FormField error={error?.lastName}>
            <Input
              fullWidth
              value={profile.lastName || ''}
              onChange={handleChange(KEYS.FORM_FIELDS.LAST_NAME)}
              placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.LAST_NAME)}
              disabled={isUpdating}
            />
          </FormField>
        </FieldValue>
      </FormRow>
    </>
  );
};
