import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';
import { BadgeType } from 'components/elements/badge/types';

import { Customer, LastLoginInfo } from 'types/customer';
import { SortOrder } from 'types/sort';

import { StatusType } from './types';

export const LABELS = {
  YES: ['page.customers.view.true', 'Yes'],
  NO: ['page.customers.view.false', 'No'],
  RETRY_KYC: {
    ALLOW_KYC_RETRY: ['page.customers.view.retryKyc.allowKYCRetry', 'Allow KYC Retry'],
    MODAL_TITLE: ['page.customers.view.retryKyc.modalTitle', 'Allow KYC Retry'],
    CANCEL: ['page.customers.view.retryKyc.cancel', 'Cancel'],
    CONFIRM: ['page.customers.view.retryKyc.confirm', 'Confirm'],
    CONFIRM_TITLE: ['page.customers.view.retryKyc.confirmTitle', 'Are you sure?'],
    CONFIRM_MESSAGE_1: ['page.customers.view.retryKyc.confirmMessage1', 'User will be allowed to retry KYC.'],
    CONFIRM_MESSAGE_2: ['page.customers.view.retryKyc.confirmMessage2', 'Shift4 is paying an extra fee for each KYC attempt'],
    ERROR_TITLE: ['page.customers.view.retryKyc.errorTitle', 'Sorry'],
    ERROR_PANEL_TITLE: ['page.customers.view.retryKyc.errorPanelTitle', 'Retry KYC'],
    ERROR_MESSAGE: ['page.customers.view.retryKyc.errorMessage', 'This user has reached the maximum number of attempts.'],
  },
  BREADCRUMBS: [
    ['page.customers.view.breadcrumbs.customer', 'Customer'],
    ['page.customers.view.breadcrumbs.customerDetails', 'Customer Details'],
  ],
  PAGE_TITLE: ['page.customers.view.title', 'Customer Details'],
  TABLE_COLUMNS: {
    STATUS: ['page.customers.view.table.columns.status', 'Status'],
    ID: ['page.customers.view.table.columns.id', 'Order'],
    DATE: ['page.customers.view.table.columns.date', 'Date'],
    USER: ['page.customers.view.table.columns.user', 'User'],
    FIAT_AMOUNT: ['page.customers.view.table.columns.fiatAmount', 'Fiat Amount'],
    SIDE: ['page.customers.view.table.columns.side', 'Type'],
    CRYPTO_AMOUNT: ['page.customers.view.table.columns.cryptoAmount', 'Crypto Amount'],
    ACTIONS: ['page.customers.view.table.columns.actions', ''],
  },
  STATUS_BAR: {
    USER_ID: ['page.customers.view.statusBar.userId', 'User ID'],
    KYC_STATUS: ['page.customers.view.statusBar.kycStatus', 'KYC Status'],
    STATUS: ['page.customers.view.statusBar.statu', 'Status'],
  },
  KYC_STATUS: {
    APPROVED: ['page.customers.view.table.kycStatus.approved', 'Active'],
    REJECTED: ['page.customers.view.table.kycStatus.rejected', 'Rejected'],
    UNKNOWN: ['page.customers.view.table.kycStatus.unknown', ''],
  },
  CHANGE_STATUS: {
    UNBLOCK_USER: ['page.customers.view.components.status.unblockUser', 'Unblock User'],
    BLOCK_USER: ['page.customers.view.components.status.blockUser', 'Block User'],
    TERMINATE_USER: ['page.customers.view.components.status.terminateUser', 'Terminate User'],
    CANCEL: ['page.customers.view.components.status.cancel', 'Cancel'],
    CONFIRM: ['page.customers.view.components.status.confirm', 'Confirm'],
    MODAL_TITLE: ['page.customers.view.components.status.modalTitle', 'Change Status'],
    CONFIRM_TITLE: ['page.customers.view.components.status.confirmTitle', 'Are you sure?'],
    ERROR_TITLE: ['page.customers.view.components.status.errorTitle', 'Sorry'],
    ERROR_PANEL_TITLE: ['page.customers.view.components.status.errorPanelTitle', 'Change status'],
    ERROR_MESSAGE: [
      'page.customers.view.components.status.errorMessage',
      'Something went wrong during status change. Try again later or contact our support.',
    ],
    BLOCK_MESSAGE: [
      'page.customers.view.components.status.blockMessage',
      'This user won’t be allowed to make any new orders and the email will be blacklisted within Shift4 card processing.',
    ],
    UNBLOCK_MESSAGE: [
      'page.customers.view.components.status.unblockMessage',
      "This user will be unblocked and allowed again to make new orders. <0 /> The user's email will be removed from Shift4 blacklist.",
    ],
    USER_REQUEST: ['page.customers.view.components.status.userRequest', 'User Request'],
    COMPLIANCE_ISSUE: ['page.customers.view.components.status.complianceIssue', 'Compliance Issue'],
    TERMINATION_REASON_LABEL: ['page.customers.view.components.status.terminationReasonLabel', 'Reason (Required)'],
    TERMINATION_NOTES_LABEL: ['page.customers.view.components.status.terminationNotesLabel', 'Notes'],
    TERMINATION_NOTES_PLACEHOLDER: [
      'page.customers.view.components.status.terminationNotesPlaceholder',
      'Please provide additional details that can drastically speed up investigations on the Zero Hash side',
    ],
    TERMINATION_DISCLAIMER: [
      'page.customers.view.components.status.terminationDisclaimer',
      "This action is permanent. <0 /> Once terminated, a user can't be changed back to active or block.",
    ],
  },
  ACTIONS: {
    TITLE: ['page.customers.view.actions.title', 'Actions'],
    OPEN_CUSTOMER_DETAILS: ['page.customers.view.actions.openCustomerDetails', 'Open Customer Details'],
    COPY_USER_ID: ['page.customers.view.actions.copyUserId', 'Copy User ID'],
    COPY_USER_EMAIL: ['page.customers.view.actions.copyUserEmail', 'Copy User E-mail'],
  },
  ORDER_LIST: {
    TITLE: ['page.customers.view.orderList.title', 'Orders'],
    NO_RECORDS_FOUND: ['page.customers.view.orderList.noRecordsFound', 'No orders found'],
  },
  CUSTOMER: {
    TITLE: ['page.customers.view.customer.title', 'Customer'],
    EMAIL: ['page.customers.view.customer.username', 'User Email'],
    USER_ID: ['page.customers.view.customer.userId', 'User ID'],
    PARTICIPANT_CODE: ['page.customers.view.customer.participantCode', 'Participant Code'],
    SIGNUP_DATE: ['page.customers.view.customer.signupDate', 'Signup Date'],
    LAST_LOGIN: ['page.customers.view.customer.lastLogin', 'Last Login'],
    LAST_PARTNER_LOGIN: ['page.customers.view.customer.lastPartnerLogin', 'Last Partner Login'],
    TERMS_OF_SERVICE: ['page.customers.view.customer.termsOfService', 'Accepted ToS'],
    STATUS: ['page.customers.view.customer.status', 'Status'],
    KYC_STATUS: ['page.customers.view.customer.kycStatus', 'KYC Status'],
    KYC_REASON: ['page.customers.view.customer.kycReason', 'KYC Reason'],
    KYC_LAST_UPDATE: ['page.customers.view.customer.kycLastUpdate', 'KYC Last Update'],
    BLOCKED_REASON: ['page.customers.view.customer.blockedReason', 'Blocked Reason'],
    KYC_ATTEMPTS: ['page.customers.view.customer.kycAttempts', 'KYC Attempts'],
    CAN_RETRY_KYC: ['page.customers.view.customer.canRetryKyc', 'Can User Retry KYC?'],
    UPDATE_KYC_STATUS: ['page.customers.view.customer.updateKYCStatus', 'Check Status'],
    UPDATING_KYC_STATUS: ['page.customers.view.customer.updatingKYCStatus', 'Checking Status'],
  },
  LOCATION: {
    TITLE: ['page.customers.view.location.title', 'Location'],
    COUNTRY: ['page.customers.view.location.country', 'Country'],
    STATE: ['page.customers.view.location.state', 'State'],
    IP_COUNTRY: ['page.customers.view.location.ipCountry', 'Last IP Country'],
    IP_STATE: ['page.customers.view.location.ipState', 'Last IP State'],
    COUNTRY_SUPPORTED: ['page.customers.view.location.countrySupported', 'Country Supported'],
    STATE_SUPPORTED: ['page.customers.view.location.stateSupported', 'State Supported'],
  },
};

export const COLUMN_IDS = {
  STATUS: 'status',
  ID: 'uuid',
  DATE: 'createdAt',
  USER: 'userEmail',
  FIAT_AMOUNT: 'fiatAmount',
  FIAT_CURRENCY: 'fiatCurrencyCode',
  SIDE: 'side',
  CRYPTO_AMOUNT: 'cryptoAmount',
  CRYPTO_CURRENCY: 'cryptoCurrencyCode',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS) },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID), sortable: true },
  { id: COLUMN_IDS.DATE, label: t(LABELS.TABLE_COLUMNS.DATE), sortable: true },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT), sortable: true },
  { id: COLUMN_IDS.SIDE, label: t(LABELS.TABLE_COLUMNS.SIDE) },
  { id: COLUMN_IDS.CRYPTO_AMOUNT, label: t(LABELS.TABLE_COLUMNS.CRYPTO_AMOUNT), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const statusTypeMap: Record<string, StatusType> = {
  COMPLETED: StatusType.Success,
  AUTHORIZATION_CHARGE_FAILED: StatusType.Failed,
  EXECUTE_QUOTE_FAILED: StatusType.Failed,
  INITIATED: StatusType.Pending,
};

export const badgeTypeMap: Record<StatusType, BadgeType> = {
  [StatusType.Success]: BadgeType.Success,
  [StatusType.Failed]: BadgeType.Fail,
  [StatusType.Pending]: BadgeType.Default,
};

export const KEYS = {
  US_CODE: 'US',
  ITEMS_PER_PAGE_OPTIONS: [15, 20, 50, 100],
  DATE_FORMAT: 'DD MMM, HH:mm A',
  DATE_FORMAT_WITH_YEAR: 'DD MMM YYYY, HH:mm A',
  TERMINATION_NOTES_MIN_ROWS: 8,
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.DATE,
    DIRECTION: SortOrder.desc,
  },
};

export const customerFormFields: Record<string, keyof Partial<Customer>> = {
  email: 'username',
  id: 'uuid',
  participantCode: 'zHashParticipantCode',
  signupDate: 'signupAt',
  acceptedToS: 'agreedTermsOfService',
  status: 'status',
  kycStatus: 'kycStatus',
  kycReason: 'kycReason',
  kycLastUpdate: 'kycTimestamp',
  isKycRetryAllowed: 'isKycRetryAllowed',
  kycAttemptsUsed: 'kycAttemptsUsed',
  tosAcceptedAt: 'tosAcceptedAt',
  lastLogin: 'lastLogin',
  statusChangeNotes: 'statusChangeNotes',
};

export const customerLocationFormFields: Record<string, keyof Partial<Customer>> = {
  country: 'country',
  state: 'state',
};

export const lastLoginFormFields: Record<string, keyof Partial<LastLoginInfo>> = {
  ipCountry: 'ipCountry',
  ipState: 'ipState',
};

export const extraFields: Record<string, string> = {
  countrySupported: 'countrySupported',
  stateSupported: 'stateSupported',
};
