import styled from '@emotion/styled';
import MuiHomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs as MaterialBreadcrumbs } from '@mui/material';

import { Typography } from '../typography';

export const StyledBreadcrumbs = styled(MaterialBreadcrumbs)(() => ({
  '& .MuiBreadcrumbs-li': { display: 'flex', alignItems: 'center' },
}));

export const StyledTypography = styled(Typography)(() => ({
  marginTop: '1px',
  whiteSpace: 'nowrap',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const HomeIcon = styled(MuiHomeIcon)(() => ({
  fontSize: '16px',
}));
