/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import profileApi from 'services/profile';

import { UserRecord } from 'types/user';

interface ProfileState {
  profile: UserRecord;
}

const SLICE_NAME = 'profile';

const initialState: ProfileState = {
  profile: {
    uuid: '',
    username: '',
    firstName: '',
    lastName: '',
    signupAt: '',
    roles: [],
  },
};

export const profileSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<Partial<UserRecord>>) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints?.getProfile.matchFulfilled, (state, { payload }) => {
      state.profile = payload || initialState.profile;
    });
    builder.addMatcher(profileApi.endpoints?.updateProfile.matchFulfilled, (state, { payload, meta }) => {
      state.profile = {
        ...state.profile,
        ...meta.arg.originalArgs,
      };
    });
  },
});

export default profileSlice.reducer;
