import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantCheckoutSettings } from 'services/merchants/types';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';

import { LandingSettingsForm } from './form/form';
import { LABELS } from './keys';
import { Controls, FormContainer, PanelContent } from './styles';

interface GeneralSectionProps {
  account: MerchantCheckoutSettings;
  isFormChanged: boolean;
  isSaveAvailable: boolean;
  resetFormValues: () => void;
  saveFormValues: () => void;
  onFormValueChange: (key: string, value: any) => void;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
  //  will be used in the future
  previewOptions: {
    publicKey?: string;
    seed: string;
  };
  titleProps: {
    titleVisible: boolean;
    toggleTitleVisible: () => void;
  };
}

export const CheckoutSection = ({
  account,
  isSaveAvailable,
  isFormChanged,
  resetFormValues,
  onFormValueChange,
  saveFormValues,
  isUpdating,
  error,
  readOnlyMode,
  previewOptions,
  titleProps,
}: GeneralSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel
      label={t(LABELS.PANEL_TITLE)}
      controls={
        readOnlyMode ? undefined : (
          <Controls>
            <OptionallyVisible visible={isFormChanged}>
              <Button onClick={resetFormValues} secondary disabled={isUpdating}>
                {t(LABELS.CANCEL_BUTTON_LABEL)}
              </Button>
            </OptionallyVisible>
            <Button onClick={saveFormValues} primary disabled={!isSaveAvailable || isUpdating}>
              {t(LABELS.SAVE_BUTTON_LABEL)}
            </Button>
          </Controls>
        )
      }
    >
      <PanelContent>
        <FormContainer>
          <LandingSettingsForm
            titleProps={titleProps}
            account={account}
            onFieldValueChange={onFormValueChange}
            isUpdating={isUpdating}
            error={error}
            readOnlyMode={readOnlyMode}
          />
        </FormContainer>
      </PanelContent>
    </Panel>
  );
};
