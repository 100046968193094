import styled from '@emotion/styled';

export const TopAreaContainer = styled.div((props) => ({
  display: 'flex',
  padding: '8px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
}));

export const Item = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

export const ItemName = styled.div((props) => ({
  color: props.theme.colors.inactive,
  fontSize: 14,
  fontWeight: 400,
}));

export const ItemValue = styled.div<{ upper?: boolean }>((props) => ({
  fontSize: 14,
  fontWeight: 400,
  textTransform: props.upper ? 'uppercase' : 'none',
}));

export const Delimiter = styled.div((props) => ({
  height: 21,
  width: 1,
  background: props.theme.colors.container.tertiary,
}));
