/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'M11.007 2.57812L11 18.0161C11 18.2813 11.1054 18.5357 11.2929',
    '18.7232C11.4804 18.9108 11.7348 19.0161 12 19.0161C12.2652 19.0161',
    '12.5196 18.9108 12.7071 18.7232C12.8946 18.5357 13 18.2813 13',
    '18.0161L13.007 2.59512L15.919 5.50812C16.1065 5.69559 16.3608 5.80091',
    '16.626 5.80091C16.8912 5.80091 17.1455 5.69559 17.333 5.50812C17.5205',
    '5.32059 17.6258 5.06628 17.6258 4.80112C17.6258 4.53595 17.5205 4.28165',
    '17.333 4.09412L14.122 0.879116C13.8434 0.600336 13.5126 0.379186 13.1485',
    '0.228301C12.7844 0.0774169 12.3941 -0.000244141 12 -0.000244141C11.6059',
    '-0.000244141 11.2156 0.0774169 10.8515 0.228301C10.4874 0.379186 10.1566',
    '0.600336 9.87799 0.879116L6.66699 4.09112C6.47952 4.27865 6.37421 4.53295',
    '6.37421 4.79812C6.37421 5.06328 6.47952 5.31759 6.66699 5.50512C6.85452',
    '5.69259 7.10883 5.79791 7.37399 5.79791C7.63916 5.79791 7.89347 5.69259',
    '8.08099 5.50512L11.007 2.57812Z',
  ].join(' '),
  [
    'M22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8947',
    '21.2652 22 21 22H3C2.73478 22 2.48043 21.8947 2.29289 21.7071C2.10536',
    '21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957',
    '16.1054 1.26522 16 1 16C0.734783 16 0.480429 16.1054 0.292893 16.2929C0.105357',
    '16.4804 0 16.7348 0 17L0 21C0 21.7957 0.31607 22.5587 0.878679 23.1213C1.44129',
    '23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839',
    '22.5587 24 21.7957 24 21V17C24 16.7348 23.8946 16.4804 23.7071',
    '16.2929C23.5196 16.1054 23.2652 16 23 16C22.7348 16 22.4804 16.1054 22.2929',
    '16.2929C22.1053 16.4804 22 16.7348 22 17Z',
  ].join(' '),
];

export const IconUpload = ({ width = 24, height = 24, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {path.map((item, index) => (
      <Path d={item} key={index} />
    ))}
  </Svg>
);

export default IconUpload;
