import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

const DEBOUNCE_TIMEOUT = 300;

export const useDebouncedQuery = <T extends object>(factory: () => T, deps: any[]) => {
  const [query, setQuery] = useState<T>(factory());
  const debouncedUpdate = useCallback(debounce(setQuery, DEBOUNCE_TIMEOUT), []);

  useEffect(() => {
    debouncedUpdate(factory());
  }, deps);

  return query;
};
