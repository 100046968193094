import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';
import { BadgeType } from 'components/elements/badge/types';

export const KEYS = {};

export const LABELS = {
  PANEL_TITLE: ['page.generalSettings.users.title', 'Users'],
  INVITE_USER: ['page.generalSettings.users.inviteUser', 'ADD USER'],
  ACTIONS: {
    NO_ACTIONS_AVAILABLE: ['page.generalSettings.users.actions.noActionsAvailable', 'No available actions'],
    TITLE: ['page.generalSettings.users.actions.title', 'Actions'],
    EDIT: ['page.generalSettings.users.actions.edit', 'Edit'],

    REVOKE_ACCESS: ['page.generalSettings.users.actions.revokeAccess', 'Revoke Access'],
  },
  STATUS: {
    ACCEPTED: ['page.generalSettings.users.status.accepted', 'Active'],
    PENDING: ['page.generalSettings.users.status.pending', 'Pending Invitation'],
  },
  EDIT_MODAL: {
    TITLE: ['page.generalSettings.users.editModal.title', 'Edit User'],
    BUTTONS: {
      EDIT: ['page.generalSettings.users.editModal.buttons.edit', 'Edit'],
      CANCEL: ['page.generalSettings.users.editModal.buttons.cancel', 'Cancel'],
    },
    EMAIL: {
      TITLE: ['page.generalSettings.users.editModal.email.title', 'User email'],
      PLACEHOLDER: ['page.generalSettings.users.editModal.email.placeholder', 'E-mail'],
    },
    PERMISSIONS: {
      LABEL: ['page.generalSettings.users.editModal.permissions.label', 'Select user permissions'],
      SUPER_ADMIN: {
        NAME: ['page.generalSettings.users.editModal.permissions.superAdmin.name', 'Owner'],
        CAPTION: ['page.generalSettings.users.editModal.permissions.superAdmin.caption', 'Can see and manage everything'],
      },
      ADMIN: {
        NAME: ['page.generalSettings.users.editModal.permissions.admin.name', 'Administrator'],
        CAPTION: ['page.generalSettings.users.editModal.permissions.admin.caption', 'Can see and manage everything'],
      },
      SUPPORT: {
        NAME: ['page.generalSettings.users.editModal.permissions.support.name', 'Viewer'],
        CAPTION: ['page.generalSettings.users.editModal.permissions.support.caption', 'Can see everything except no account settings'],
      },
    },
  },
  INVITE_MODAL: {
    TITLE: ['page.generalSettings.users.inviteModal.title', 'Add User'],
    BUTTONS: {
      INVITE: ['page.generalSettings.users.inviteModal.buttons.invite', 'Add'],
      CANCEL: ['page.generalSettings.users.inviteModal.buttons.cancel', 'Cancel'],
    },
    EMAIL: {
      TITLE: ['page.generalSettings.users.inviteModal.email.title', 'Enter user email'],
      PLACEHOLDER: ['page.generalSettings.users.inviteModal.email.placeholder', 'E-mail'],
    },
    PERMISSIONS: {
      LABEL: ['page.generalSettings.users.inviteModal.permissions.label', 'Select user permissions'],
      SUPER_ADMIN: {
        NAME: ['page.generalSettings.users.inviteModal.permissions.superAdmin.name', 'SuperAdmin'],
        CAPTION: ['page.generalSettings.users.inviteModal.permissions.superAdmin.caption', 'Can see and manage everything'],
      },
      ADMIN: {
        NAME: ['page.generalSettings.users.inviteModal.permissions.admin.name', 'Admin'],
        CAPTION: ['page.generalSettings.users.inviteModal.permissions.admin.caption', 'Can see and manage everything'],
      },
      SUPPORT: {
        NAME: ['page.generalSettings.users.inviteModal.permissions.support.name', 'Support'],
        CAPTION: ['page.generalSettings.users.inviteModal.permissions.support.caption', 'Can see everything except no General Settings'],
      },
    },
  },
  TABLE_COLUMNS: {
    EMAIL: ['page.generalSettings.users.table.columns.email', 'Email'],
    ROLES: ['page.generalSettings.users.table.columns.roles', 'Roles'],
    STATUS: ['page.generalSettings.users.table.columns.status', 'Status'],
    ACTIONS: ['page.generalSettings.users.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  ID: 'uuid',
  EMAIL: 'username',
  ROLES: 'roles',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.EMAIL, label: t(LABELS.TABLE_COLUMNS.EMAIL), sortable: true },
  { id: COLUMN_IDS.ROLES, label: t(LABELS.TABLE_COLUMNS.ROLES), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const badgeTypeMap: Record<string, BadgeType> = {
  pending: BadgeType.Default,
  accepted: BadgeType.Success,
};
