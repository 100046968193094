import { AccountInfo } from 'types/account';

import { SettingsFormField } from './types';

export const getFormValues = (data: AccountInfo, formFields: SettingsFormField[]) => {
  const allowedFields = formFields.map((field) => field.key);
  const formValues: Partial<AccountInfo> = Object.entries(data).reduce((output, [key, value]) => {
    if (allowedFields.includes(key as keyof AccountInfo)) {
      return {
        ...output,
        [key]: value,
      };
    }
    return output;
  }, {});

  return formValues;
};
