import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconClock from 'components/icons/clock';

import { LABELS, inviteStatusBadgeTypeMap } from '../../keys';
import { AccountDataRow, InvitationStatus } from '../../types';
import { BadgeContent } from './inviteStatusCell.styles';

export const InviteStatusCell = ({ row, column }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { inviteStatus } = row as AccountDataRow;

  const badgeType = inviteStatusBadgeTypeMap[inviteStatus] || BadgeType.Default;
  const icon = badgeType === BadgeType.Success ? <IconChecked /> : <IconClock />;
  const label = t(LABELS.INVITE_STATUS[inviteStatus.toUpperCase() as InvitationStatus]);

  return (
    <TableCell data-column={column.id}>
      <Badge type={badgeType}>
        <BadgeContent>
          {icon}
          {label}
        </BadgeContent>
      </Badge>
    </TableCell>
  );
};
