import AmexLogo from 'assets/images/cards/v2/AmericanExpress.svg';
import DinersClubLogo from 'assets/images/cards/v2/DinersClub.svg';
import DiscoverLogo from 'assets/images/cards/v2/Discover.svg';
import JCBLogo from 'assets/images/cards/v2/JCB.svg';
import MasterCardLogo from 'assets/images/cards/v2/MasterCard.svg';
import UnknownCardLogo from 'assets/images/cards/v2/Unknown.svg';
import VisaLogo from 'assets/images/cards/v2/Visa.svg';

import { CardBrand } from 'types/customer';

export const cardBrandsImageSource: Record<CardBrand, string> = {
  [CardBrand.Amex]: AmexLogo,
  [CardBrand.DinersClub]: DinersClubLogo,
  [CardBrand.Unknown]: UnknownCardLogo,
  [CardBrand.Discover]: DiscoverLogo,
  [CardBrand.Jcb]: JCBLogo,
  [CardBrand.MasterCard]: MasterCardLogo,
  [CardBrand.Maestro]: MasterCardLogo,
  [CardBrand.Visa]: VisaLogo,
};

export default cardBrandsImageSource;
