import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { Asset } from 'types/assets';
import { Country } from 'types/country';
import { Response } from 'types/http';

import { fetchBaseQueryWithAuth } from '../api';
import { AssetsListResponse, CountriesListResponse } from './types';

const REDUCER_PATH = 'assetsApi';

export const assetsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  endpoints: (builder) => ({
    getAssetList: builder.query<Asset[], void>({
      query: () => Endpoint.Assets.List(false),
      transformResponse: (response: Response<AssetsListResponse>) => {
        if (!response?.data?.assets) {
          return [];
        }

        return response.data?.assets;
      },
    }),
    getActiveAssetList: builder.query<Asset[], void>({
      query: () => Endpoint.Assets.List(true),
      transformResponse: (response: Response<AssetsListResponse>) => {
        if (!response?.data?.assets) {
          return [];
        }

        return response.data?.assets;
      },
    }),
    getCountriesList: builder.query<Country[], void>({
      query: () => Endpoint.Assets.CountriesList,
      transformResponse: (response: Response<CountriesListResponse>) => {
        if (!response?.data?.countries) {
          return [];
        }

        return response.data.countries;
      },
    }),
  }),
});

export const { getAssetList, getCountriesList } = assetsApi.endpoints;
export const { useGetAssetListQuery, useGetActiveAssetListQuery, useGetCountriesListQuery } = assetsApi;
export default assetsApi;
