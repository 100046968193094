import React from 'react';

import OptionallyVisible from '../optionallyVisible';
import { Container, Icon, Label, Tab } from './styles';
import { TabsProps } from './types';

export const Tabs = ({ items, selected }: TabsProps) => (
  <Container>
    {items.map((item) => (
      <Tab selected={item.key === selected?.key} key={item.key} onClick={item.onClick}>
        <OptionallyVisible visible={Boolean(item.icon)}>
          <Icon>{item.icon}</Icon>
        </OptionallyVisible>
        <Label>{item.label}</Label>
      </Tab>
    ))}
  </Container>
);
