import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M6.04488 1.95595C5.03988 0.950954 3.53488 0.745954 2.32488 1.33595L4.49488 3.50595L2.99488 5.00595L0.82488',
  '2.83595C0.23988 4.05095 0.44488 5.54595 1.44988 6.55095C2.37988 7.48095 3.73988 7.72595 4.89488 7.29095L9.44988',
  '11.846C9.64488 12.041 9.95988 12.041 10.1549 11.846L11.3049 10.696C11.4999 10.501 11.4999 10.186 11.3049',
  '9.99095L6.76988 5.45095C7.22988 4.28095 6.98988 2.90095 6.04488 1.95595Z',
].join(' ');

export const IconBuild = ({ width = 12, height = 13, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconBuild;
