import React from 'react';

import { Container, ErrorText } from './styles';

interface ErrorScreenProps {
  error: string;
}

export const ErrorScreen = ({ error }: ErrorScreenProps) => (
  <Container>
    <ErrorText>{error}</ErrorText>
  </Container>
);
