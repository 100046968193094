import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTable } from 'components/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { UserRoleType } from 'types/user';

import { EditUserModal } from './components/editUserModal';
import { InviteButton } from './components/inviteButton';
import { makeUsersTableCell } from './components/usersTableCell';
import { COLUMNS, LABELS } from './keys';
import { ActionPanel } from './styles';
import { UserTableRow } from './types';

export interface UserSectionProps extends WithTranslation {
  users: UserTableRow[];
  isLoading: boolean;
  inviteUser: {
    availableRoles: UserRoleType[];
    error?: Record<string, string>;
    onInvite: (email: string, role: UserRoleType) => Promise<boolean>;
    isSubmitting: boolean;
    reset: () => void;
  };
  editUser: {
    availableRoles: UserRoleType[];
    error?: Record<string, string>;
    onEdit: (email: string, role: UserRoleType) => Promise<boolean>;
    isSubmitting: boolean;
    reset: () => void;
  };
}

export const UsersSectionComponent = ({ users, inviteUser, editUser, isLoading, t }: UserSectionProps) => {
  const [editedUser, setEditedUser] = useState<UserTableRow | null>(null);
  const [, setRevokingUser] = useState<UserTableRow | null>(null);

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <LoadingIndicator padded />
      </Panel>
    );
  }

  const handleEdit = (uuid: string) => {
    if (!uuid) {
      return;
    }

    const user = users.find((user) => user.uuid === uuid) || null;
    setEditedUser(user);
  };

  const handleCloseEdit = () => {
    setEditedUser(null);
  };

  const handleRevoke = (uuid: string) => {
    if (!uuid) {
      return;
    }

    const user = users.find((user) => user.uuid === uuid) || null;
    setRevokingUser(user);
  };

  const UsersTableCell = makeUsersTableCell({
    editUser: handleEdit,
    revokeAccess: handleRevoke,
    availableRoles: editUser.availableRoles,
  });

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <ActionPanel>
        <InviteButton {...inviteUser} />
      </ActionPanel>
      <EditUserModal {...editUser} open={Boolean(editedUser)} onClose={handleCloseEdit} user={editedUser} />
      <DataTable columns={COLUMNS} rows={users} CellComponent={UsersTableCell} />
    </Panel>
  );
};

export const UsersSection = withTranslation()(UsersSectionComponent);
