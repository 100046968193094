import React from 'react';

import { RadioButton } from './radioButton';
import { RadioGroupContainer, RadioGroupItem, RadioGroupItemLabel } from './styles';

interface RadioGroupOption {
  key: string;
  value: string;
  label: string;
}

interface RadioGroupProps {
  options: RadioGroupOption[];
  value: string;
  onChange: (nextValue: string) => void;
  horizontal?: boolean;
  disabled?: boolean;
  className?: string;
}

export const RadioGroup = ({ options, value, onChange, horizontal, disabled, className }: RadioGroupProps) => {
  const getChangeHandler = (option: RadioGroupOption) => () => {
    onChange(option.value);
  };

  return (
    <RadioGroupContainer horizontal={horizontal} className={className}>
      {options.map((option) => (
        <RadioGroupItem key={option.key}>
          <RadioButton value={option.value === value} onChange={getChangeHandler(option)} disabled={disabled} />
          <RadioGroupItemLabel>{option.label}</RadioGroupItemLabel>
        </RadioGroupItem>
      ))}
    </RadioGroupContainer>
  );
};
