import styled from '@emotion/styled/macro';

import { Path } from 'components/icons/styles';

import { OrderSide } from 'types/order';

export const Text = styled.span(() => ({}));

export const OrderSideContainer = styled.div<{ side: OrderSide; asIcon?: boolean }>((props) => {
  const colorStyling =
    props.side === OrderSide.Buy
      ? {
          display: 'flex',
          border: '0.5px solid rgba(151, 30, 247, 0.30)',
          backgroundColor: 'rgba(151, 30, 247, 0.05)',
          boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
          color: '#971EF7',
          // @ts-ignore
          [Path]: {
            stroke: '#971EF7',
          },
        }
      : {
          border: '0.5px solid rgba(34, 83, 255, 0.30)',
          backgroundColor: 'rgba(34, 83, 255, 0.05)',
          boxShadow: '0px 2.727px 10.909px 0px rgba(8, 155, 23, 0.05)',
          transform: props.asIcon ? 'rotate(180deg)' : 'none',
          color: props.theme.colors.accent,
          // @ts-ignore
          [Path]: {
            stroke: props.theme.colors.accent,
          },
        };

  return {
    display: 'flex',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: props.asIcon ? 20 : 'auto',
    height: 20,
    ...colorStyling,
  };
});
