import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFilterValue, FilterPreset } from 'types/filters';
import { DateRange } from 'types/time';

import { Checkbox } from '../../inputs/checkbox/checkbox';
import { DateRangePicker } from '../../inputs/date/dateRange';
import { Container, Label, Preset, Row } from '../styles';
import { LABELS, defaultItem } from './keys';

interface DateFilterProps {
  value: DateFilterValue;
  presets?: FilterPreset<DateFilterValue>[];
  onChange: (value: DateFilterValue | null) => void;
}

export const DateFilter = ({ value, presets, onChange }: DateFilterProps) => {
  const { t } = useTranslation();
  const foundPreset = presets?.find((preset) => isEqual(preset.value, value)) || (value ? defaultItem : null);
  const [selectedPreset, setSelectedPreset] = useState<FilterPreset<DateFilterValue> | null>(foundPreset);

  const handleSelectPreset = (preset: FilterPreset<DateFilterValue> | null) => (value: boolean) => {
    setSelectedPreset(value ? preset : null);
    onChange(preset?.value || null);
  };

  const dateRangeValue = [value?.from, value?.to].map((value) => (value ? dayjs(value) : null)) as DateRange;
  const handleManualDateRange = (range: DateRange) => {
    onChange({
      from: range[0]?.valueOf(),
      to: range[1]?.valueOf(),
    });
  };

  if (presets) {
    return (
      <Container>
        {presets.map((preset) => (
          <Preset key={preset.key}>
            <Checkbox value={selectedPreset?.key === preset.key} onChange={handleSelectPreset(preset)} />
            <Label>{preset.label}</Label>
          </Preset>
        ))}
        <Preset>
          <Checkbox value={selectedPreset === defaultItem} onChange={handleSelectPreset(defaultItem)} />
          <Label>{t(LABELS.CUSTOM_FILTER)}</Label>
        </Preset>
        <Row>
          <DateRangePicker value={dateRangeValue} onChange={handleManualDateRange} disabled={selectedPreset !== defaultItem} />
        </Row>
      </Container>
    );
  }

  return (
    <Preset>
      <Checkbox value={selectedPreset === defaultItem} onChange={handleSelectPreset(null)} />
      <Label>{t(LABELS.CUSTOM_FILTER)}</Label>
      <DateRangePicker value={dateRangeValue} onChange={handleManualDateRange} />
    </Preset>
  );
};
