import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M9 3.75C5.25 3.75 2.0475 6.0825 0.75 9.375C2.0475 12.6675 5.25 15 9 15C12.75 15 15.9525 12.6675 17.25',
  '9.375C15.9525 6.0825 12.75 3.75 9 3.75ZM9 13.125C6.93 13.125 5.25 11.445 5.25 9.375C5.25 7.305 6.93',
  '5.625 9 5.625C11.07 5.625 12.75 7.305 12.75 9.375C12.75 11.445 11.07 13.125 9 13.125ZM9 7.125C7.755',
  '7.125 6.75 8.13 6.75 9.375C6.75 10.62 7.755 11.625 9 11.625C10.245 11.625 11.25 10.62 11.25 9.375C11.25',
  '8.13 10.245 7.125 9 7.125Z',
].join(' ');

const IconVisibility = ({ width = 18, height = 18, color, style, className }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconVisibility;
