import React from 'react';

import { CurrencySelect } from '../../inputs/select/currencySelect/currencySelect';
import { CurrencyItem } from '../../inputs/select/currencySelect/types';
import { Container, Row } from '../styles';
import { CurrencyFilterPassedProps } from '../types';

interface CurrencyFilterProps extends Omit<CurrencyFilterPassedProps, 'type'> {
  value: string | string[];
  onChange: (value: string | string[]) => void;
}

export const CurrencyFilter = ({ value, options, onChange, multiple }: CurrencyFilterProps) => {
  const handleChange = (items: CurrencyItem | CurrencyItem[]) => {
    if (multiple) {
      const nextItems = items as CurrencyItem[];
      onChange(nextItems.map((item) => item.key));
    } else {
      const nextItem = items as CurrencyItem;
      onChange(nextItem.key);
    }
  };

  const selectedItems = multiple
    ? (value as string[]).map((key) => options.find((option) => option.key === key))
    : options.find((option) => option.key === (value as string));

  return (
    <Container>
      <Row>
        <CurrencySelect
          value={selectedItems as CurrencyItem}
          items={options}
          onChange={handleChange}
          getItemLabel={(item) => item.name}
          multiple={multiple}
          closeDropdownOnSelect
        />
      </Row>
    </Container>
  );
};
