import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M10.6668 0.666016H2.66683C1.9335 0.666016 1.3335 1.26602 1.3335',
  '1.99935V11.3327H2.66683V1.99935H10.6668V0.666016ZM12.6668 3.33268H5.3335C4.60016 3.33268 4.00016 3.93268',
  '4.00016 4.66602V13.9993C4.00016 14.7327 4.60016 15.3327 5.3335 15.3327H12.6668C13.4002 15.3327 14.0002',
  '14.7327 14.0002 13.9993V4.66602C14.0002 3.93268 13.4002 3.33268 12.6668 3.33268ZM12.6668',
  '13.9993H5.3335V4.66602H12.6668V13.9993Z',
].join(' ');

export const IconCopy = ({ width = 16, height = 16, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} fillRule="evenodd" clipRule="evenodd" />
  </Svg>
);

export default IconCopy;
