import React, { MouseEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectableItem } from 'components/inputs/select/styles';

import { LABELS } from '../../keys';
import {
  AssetImage,
  AssetListItem,
  AssetSubtitle,
  AssetTitle,
  AssetTitleContainer,
  DataRow,
  DataRowTitle,
  DataRowValue,
  PreviewAssetTitle,
} from './styles';
import { AssetSelectItem } from './types';

interface CurrencySelectSectionProps {
  fiatCurrencyItems: AssetSelectItem[];
  selectedCurrency: AssetSelectItem;
  onCurrencyChange: (item: AssetSelectItem) => void;
}

export const CurrencySelectSection = ({ fiatCurrencyItems, selectedCurrency, onCurrencyChange }: CurrencySelectSectionProps) => {
  const { t } = useTranslation();

  const compareFiatItem = (search: string, item: AssetSelectItem) =>
    item?.label?.toLowerCase().includes(search.toLowerCase()) || item?.symbol.toLowerCase().includes(search.toLowerCase());

  const renderFiatListItem = (asset: AssetSelectItem, selectItem: (event: MouseEvent<HTMLElement>) => void) => (
    <SelectableItem onClick={selectItem} key={asset.key}>
      <AssetListItem>
        <AssetImage src={asset.iconUrl} />
        <AssetTitleContainer>
          <AssetTitle>{t(LABELS.CREATE_ORDER_MODAL.ASSET_TITLE, { title: asset.name })}</AssetTitle>
          <AssetSubtitle>{asset.symbol}</AssetSubtitle>
        </AssetTitleContainer>
      </AssetListItem>
    </SelectableItem>
  );

  const renderSelectedFiatItem = (asset: AssetSelectItem) => {
    if (!asset) {
      return null;
    }

    return (
      <AssetListItem key={asset.key}>
        <AssetImage src={asset.iconUrl} />
        <PreviewAssetTitle>{t(LABELS.CREATE_ORDER_MODAL.ASSET_TITLE, { title: asset.name })}</PreviewAssetTitle>
      </AssetListItem>
    );
  };

  return (
    <DataRow>
      <DataRowTitle>{t(LABELS.CREATE_ORDER_MODAL.CURRENCY)}</DataRowTitle>
      <DataRowValue>
        <SearchableSelect<AssetSelectItem, false>
          fullWidth
          popperProps={{
            disablePortal: true,
          }}
          items={fiatCurrencyItems}
          closeDropdownOnSelect
          getItemLabel={(fiat) => fiat.name}
          value={selectedCurrency}
          renderSelectedItem={renderSelectedFiatItem}
          renderListItem={renderFiatListItem}
          compareItem={compareFiatItem}
          onChange={onCurrencyChange}
        />
      </DataRowValue>
    </DataRow>
  );
};

export default memo(CurrencySelectSection);
