import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M9.83317 8.66675C9.27762 8.66675 8.80539 8.4723 8.4165 8.08342C8.02762',
  '7.69453 7.83317 7.2223 7.83317 6.66675C7.83317 6.11119 8.02762 5.63897',
  '8.4165 5.25008C8.80539 4.86119 9.27762 4.66675 9.83317 4.66675C10.3887',
  '4.66675 10.8609 4.86119 11.2498 5.25008C11.6387 5.63897 11.8332 6.11119',
  '11.8332 6.66675C11.8332 7.2223 11.6387 7.69453 11.2498 8.08342C10.8609 8.4723',
  '10.3887 8.66675 9.83317 8.66675ZM5.1665 10.6667C4.79984 10.6667 4.48606 10.5361',
  '4.22517 10.2747C3.96384 10.0139 3.83317 9.70008 3.83317 9.33342V4.00008C3.83317',
  '3.63341 3.96384 3.31964 4.22517 3.05875C4.48606 2.79741 4.79984 2.66675 5.1665',
  '2.66675H14.4998C14.8665 2.66675 15.1805 2.79741 15.4418 3.05875C15.7027 3.31964',
  '15.8332 3.63341 15.8332 4.00008V9.33342C15.8332 9.70008 15.7027 10.0139 15.4418',
  '10.2747C15.1805 10.5361 14.8665 10.6667 14.4998 10.6667H5.1665ZM6.49984 9.33342H13.1665C13.1665',
  '8.96675 13.2969 8.65275 13.5578 8.39141C13.8192 8.13053 14.1332 8.00008 14.4998 8.00008V5.33341C14.1332',
  '5.33341 13.8192 5.20275 13.5578 4.94141C13.2969 4.68053 13.1665 4.36675 13.1665 4.00008H6.49984C6.49984',
  '4.36675 6.36917 4.68053 6.10784 4.94141C5.84695 5.20275 5.53317 5.33341 5.1665 5.33341V8.00008C5.53317',
  '8.00008 5.84695 8.13053 6.10784 8.39141C6.36917 8.65275 6.49984 8.96675 6.49984 9.33342ZM13.8332 13.3334H2.49984C2.13317',
  '13.3334 1.81939 13.203 1.5585 12.9421C1.29717 12.6807 1.1665 12.3667 1.1665 12.0001V4.66675H2.49984V12.0001H13.8332V13.3334ZM5.1665',
  '9.33342V4.00008V9.33342Z',
].join(' ');

const IconCash = ({ width = 17, height = 16, color, className, style }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <Path fill={color || 'currentColor'} d={path} />
  </Svg>
);

export default IconCash;
