import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { TableCell } from 'components/dataTable';
import { DataTableCellProps } from 'components/dataTable/dataTable.types';
import { IconCross } from 'components/icons/cross';
import { Modal } from 'components/modal/modal';
import { Typography } from 'components/typography';

import { ApiKeyType } from 'types/apiKeys';

import { LABELS } from '../../apiKeys.keys';
import { ApiKeysTableRow, RotateApiKeyFn } from '../../types';
import {
  ButtonClose,
  ButtonRotate,
  Buttons,
  Divider,
  Icon,
  ModalContent,
  ModalControls,
  ModalWrapper,
  QuestionText,
} from './rotateKeyCell.styles';

export const RotateKeyCell: FunctionComponent<DataTableCellProps> = ({ row, passedProps }) => {
  const { isSecretKey } = row as ApiKeysTableRow;
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnButtonClick = () => {
    openModal();
  };

  const handleApiKeyRotate = () => {
    const rotateApiKey = passedProps?.rotateApiKey as RotateApiKeyFn;
    const apiKeyTypeToRotate = isSecretKey ? ApiKeyType.SecretKey : ApiKeyType.PublicKey;

    rotateApiKey({
      type: apiKeyTypeToRotate,
    });
  };

  return (
    <TableCell align="left">
      <ButtonRotate flat onClick={handleOnButtonClick}>
        <Icon /> {t(LABELS.ROTATE)}
      </ButtonRotate>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div>
          <Panel
            label={t(LABELS.MODAL.TITLE)}
            controls={
              <ModalControls>
                <ButtonClose flat onClick={closeModal}>
                  <IconCross />
                </ButtonClose>
              </ModalControls>
            }
          >
            <ModalWrapper>
              <ModalContent>
                <img src={questionImage} alt={t(LABELS.MODAL.CONTENT.QUESTION)} />
                <QuestionText>{t(LABELS.MODAL.CONTENT.QUESTION)}</QuestionText>
                <Typography variant="body2">{t(LABELS.MODAL.CONTENT.REMARK)}</Typography>
              </ModalContent>
              <Divider />
              <Buttons>
                <Button secondary onClick={closeModal}>
                  {t(LABELS.MODAL.BUTTONS.CANCEL)}
                </Button>
                <Button primary onClick={handleApiKeyRotate}>
                  {t(LABELS.MODAL.BUTTONS.CONFIRM)}
                </Button>
              </Buttons>
            </ModalWrapper>
          </Panel>
        </div>
      </Modal>
    </TableCell>
  );
};
