import styled from '@emotion/styled/macro';

import { Path, Svg } from 'components/icons/styles';

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 15,
}));

export const WidgetContainer = styled.div(() => ({}));

export const Widget = styled.iframe(() => ({
  border: 0,
  borderRadius: 5,
  marginLeft: 10,
  boxShadow: '0px 10px 25px 0px rgba(30, 71, 174, 0.20)',
}));

export const LandingTitle = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Title = styled.h5((props) => ({
  margin: 0,
  fontSize: 13,
  fontWeight: 500,
  color: props.theme.colors.primary,
  padding: 10,
}));

export const SubTitle = styled.h6((props) => ({
  margin: 0,
  fontSize: 12,
  fontWeight: 400,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 5,
  color: props.theme.colors.inactive,
  padding: '0px 10px',
  marginTop: -4,
  // @ts-ignore
  [Svg]: {
    transform: 'rotate(180deg)',
  },
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const LandingContainer = styled.div((props) => ({
  borderRadius: 10,
  overflow: 'hidden',
  border: `1px solid ${props.theme.colors.container.tertiary}`,
  maxHeight: 840,
  // @ts-ignore
  [Widget]: {
    margin: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

export const BrowserHead = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.tertiary}`,
  padding: 7,
  display: 'flex',
  justifyContent: 'center',
}));

export const BrowserAddressBar = styled.div((props) => ({
  width: 492,
  height: 28,
  padding: 6,
  boxSizing: 'border-box',
  display: 'flex',
  borderRadius: 5,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F2F2F2',
  // @ts-ignore
  [Path]: {
    fill: '#9E9E9E',
  },
}));

export const Address = styled.div((props) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  justifyContent: 'center',
  fontFamily: props.theme.fonts.primary,
  color: '#4C4C4C',
  fontWeight: 500,
  fontSize: 12.8,
}));
