import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import KEYS from './keys';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  returnEmptyString: false,
  detection: options,
  fallbackLng: KEYS.FALLBACK_LANGUAGE,
});

export default i18n;
