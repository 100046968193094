import React from 'react';

import { RouteSetup } from 'types/routing';

import RoutesWrapper from './routesWrapper';

interface RoutesProps {
  routeSetup: RouteSetup;
}

export const Routes = (props: RoutesProps) => {
  const { routeSetup } = props;

  const { RouterComponent = RoutesWrapper } = routeSetup;

  return <RouterComponent routeSetup={routeSetup} />;
};

export default Routes;
