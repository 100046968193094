import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { OrderStatusBadge } from '../../../components/orderStatusBadge/orderStatusBadge';
import { OrderTypeBadge } from '../../../components/orderTypeBadge/orderTypeBadge';
import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { formatNumber } from '../utils';
import { Delimiter, Item, ItemName, ItemValue, TopAreaContainer } from './styles';

export const TopAreaSection = ({ orderData }: OrderSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <TopAreaContainer>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.ORDER_ID)}:</ItemName>
          <ItemValue>{orderData.order.uuid}</ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.TYPE)}:</ItemName>
          <ItemValue>
            <OrderTypeBadge side={orderData.order.side} />
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.ORDER_TOTAL)}:</ItemName>
          <ItemValue>
            {formatNumber(orderData.order.fiatAmount)} {orderData.order.fiatCurrencyCode}
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.STATUS)}:</ItemName>
          <ItemValue>
            <OrderStatusBadge status={orderData.order.status} />
          </ItemValue>
        </Item>
      </TopAreaContainer>
    </Panel>
  );
};
