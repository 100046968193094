export const getRootUrl = () => {
  if (!window?.s4cConfig?.backendUrl) {
    throw new Error('No backend URL');
  }

  return window.s4cConfig.backendUrl;
};

export const API_CONFIG = {
  TOKEN_KEY: 's4c-dashboard-token',
  ROOT_URL: getRootUrl(),
};

export default API_CONFIG;
