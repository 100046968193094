import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components/elements/badge/badge';

import { OrderWithdrawalStatus } from 'types/order';

import { orderWithdrawalStatusLabelByStatus, orderWithdrawalStatusToBadgeTypeMap } from './keys';

interface OrderWithdrawalStatusBadgeProps {
  status: OrderWithdrawalStatus;
}

export const OrderWithdrawalStatusBadge = ({ status }: OrderWithdrawalStatusBadgeProps) => {
  const { t } = useTranslation();

  const orderWithdrawalStatusBadgeType = orderWithdrawalStatusToBadgeTypeMap[status];
  const orderWithdrawalStatusLabel = t(orderWithdrawalStatusLabelByStatus[status]);

  return <Badge type={orderWithdrawalStatusBadgeType}>{orderWithdrawalStatusLabel}</Badge>;
};
