import styled from '@emotion/styled/macro';

import { ButtonWithLoading } from 'components/buttons/buttonWithLoading';

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
}));

export const ActionButton = styled(ButtonWithLoading)((_) => ({
  maxWidth: 132,
}));
