import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

import { Path } from 'components/icons/styles';

import { BadgeType } from './types';

const failedBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.error.main}4D`,
  backgroundColor: `${props.theme.colors.error.main}0D`,
  color: props.theme.colors.error.main,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.error.main,
  },
});

const successBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.success.main}4D`,
  backgroundColor: `${props.theme.colors.success.main}0D`,
  color: props.theme.colors.success.main,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.success.main,
  },
});

const defaultBadge = (props: { theme: Theme }) => ({
  borderColor: `${props.theme.colors.inactive}4D`,
  backgroundColor: props.theme.colors.highlight,
  color: props.theme.colors.primary,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.primary,
  },
});

const getTypeStyle = (props: { theme: Theme; type: BadgeType }) => {
  const { type } = props;
  if (type === BadgeType.Success) {
    return successBadge(props);
  }

  if (type === BadgeType.Fail) {
    return failedBadge(props);
  }

  return defaultBadge(props);
};

export const BadgeRoot = styled.span<{ type: BadgeType }>(
  (props) => ({
    display: 'inline-flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '3px 6px',
    borderRadius: 5,
    lineHeight: '15px',
    fontSize: 12,
    borderWidth: 0.5,
    borderStyle: 'solid',
  }),
  getTypeStyle,
);

// have to use className because of https://mui.com/material-ui/integrations/interoperability/#portals
export const StyledTooltip = styled(({ className, children, ...otherProps }: TooltipProps) => (
  <Tooltip {...otherProps} classes={{ popper: className }}>
    {children}
  </Tooltip>
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 5,
    border: `0.5px solid ${props.theme.colors.inactive}4D`,
    background: props.theme.colors.background,
    color: props.theme.colors.text.primary,
    fontSize: 12,
  },
}));
