/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';

import nothingFoundImage from 'assets/images/nothingFound.svg';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { ColorPicker } from 'components/inputs/color/colorPicker';
import { CurrencySelect } from 'components/inputs/select/currencySelect/currencySelect';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';
import { Select, SelectItem } from 'components/inputs/select/select';
import { BarSwitch, BarSwitchOption } from 'components/inputs/switch/barSwitch';
import { Switch } from 'components/inputs/switch/switch';

import { AccountWidgetSettings } from 'types/account';

import { FileDropzone } from '../../components/fileDropzone';
import { FORM_FIELDS, KEYS, LABELS, formFields, logoAcceptRules, widgetModeItems } from '../keys';
import { WidgetModeItem } from '../types';
import { BorderRadiusIcon } from './borderRadiusSelect/borderRadiusIcon';
import { NothingFoundCaption, NothingFoundContainer, NothingFoundImage } from './styles';

const borderRadiusOptions: string[] = ['0', '2', '5', '10', '20'];

interface WidgetSettingFormProps {
  account: AccountWidgetSettings;
  initialValues: AccountWidgetSettings;
  onFieldValueChange: (key: string, value: any) => void;
  disabledFields: Record<string, boolean>;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
  cryptoCurrencies: CurrencyItem[];
  fiatCurrencies: CurrencyItem[];
}

export const WidgetSettingForm = ({
  account,
  onFieldValueChange,
  disabledFields,
  isUpdating,
  error,
  readOnlyMode,
  cryptoCurrencies,
  fiatCurrencies,
  initialValues,
}: WidgetSettingFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleSelectChange = (fieldName: string) => (value: SelectItem) => {
    onFieldValueChange(fieldName, value.key);
  };

  const handleMultipleSelectChange = (fieldName: string) => (values: SelectItem[]) => {
    onFieldValueChange(
      fieldName,
      values.map((value) => value.key),
    );
  };

  const handleLogoChange = (fieldName: keyof AccountWidgetSettings) => (files: File[]) => {
    const onChange = handleChange(fieldName);
    const [logo] = files;
    const isRemovingLogo = !logo && account[fieldName];
    if (isRemovingLogo) {
      onChange('');
      return;
    }

    onChange(logo);
  };

  const handleBorderRadiusChange = (option: BarSwitchOption) => {
    handleChange(FORM_FIELDS.BORDER_RADIUS)(option.value);
  };

  const defaultCryptoCurrency = cryptoCurrencies.find((item) => item.key === account[FORM_FIELDS.DEFAULT_CRYPTO_CURRENCY]);
  const supportedCryptoCurrency = cryptoCurrencies.filter((item) => (account.supportedCryptoCurrenciesCodes || []).includes(item.key));
  const popularCryptoCurrency = cryptoCurrencies.filter((item) => (account.popularCryptoCurrenciesCodes || []).includes(item.key));
  const supportedFiatCurrency = fiatCurrencies.filter((item) => (account.supportedFiatCurrenciesCodes || []).includes(item.key));
  const widgetMode = widgetModeItems.find((item) => item.key === account[FORM_FIELDS.THEME_MODE]);

  const fieldRenderers: Record<string, FormFieldDescription['renderInput']> = {
    [FORM_FIELDS.THEME_COLOR]: ({ readonly }) => (
      <ColorPicker
        defaultColor={initialValues.widgetColor || KEYS.DEFAULT_WIDGET_COLOR}
        onChange={handleChange(FORM_FIELDS.THEME_COLOR)}
        color={account.widgetColor || KEYS.DEFAULT_WIDGET_COLOR}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.THEME_MODE]: ({ readonly }) =>
      readonly ? (
        widgetMode?.label
      ) : (
        <Select<WidgetModeItem, false>
          items={widgetModeItems}
          fullWidth
          value={widgetMode}
          disabled={isUpdating}
          onChange={handleSelectChange(FORM_FIELDS.THEME_MODE)}
          placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.THEME_MODE)}
          searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
          getItemLabel={(item) => item.label}
        />
      ),
    [FORM_FIELDS.BORDER_RADIUS]: ({ readonly }) => (
      <BarSwitch
        options={borderRadiusOptions.map((option) => ({ value: option, label: option, icon: <BorderRadiusIcon radius={option} /> }))}
        value={(account.cornerRadius ?? KEYS.DEFAULT_BORDER_RADIUS).toString()}
        onChange={handleBorderRadiusChange}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.DISPLAY_ILLUSTRATIONS]: ({ readonly }) => (
      <Switch
        disabled={isUpdating || readonly}
        value={account.displayIllustration}
        onChange={handleChange(FORM_FIELDS.DISPLAY_ILLUSTRATIONS)}
      />
    ),
    [FORM_FIELDS.DEFAULT_CRYPTO_CURRENCY]: ({ readonly }) => (
      <CurrencySelect<CurrencyItem, false>
        items={cryptoCurrencies}
        fullWidth
        value={defaultCryptoCurrency}
        disabled={isUpdating || readonly}
        onChange={handleSelectChange(FORM_FIELDS.DEFAULT_CRYPTO_CURRENCY)}
        placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.DEFAULT_CRYPTO_CURRENCY)}
        searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
        getItemLabel={(item: CurrencyItem) => item.name}
        nothingFoundMessage={
          <NothingFoundContainer>
            <NothingFoundImage src={nothingFoundImage} />
            <NothingFoundCaption>{t(LABELS.CRYPTO_NOT_FOUND)}</NothingFoundCaption>
          </NothingFoundContainer>
        }
      />
    ),
    [FORM_FIELDS.SUPPORTED_CRYPTO_CURRENCIES]: ({ readonly }) => (
      <CurrencySelect<CurrencyItem, true>
        items={cryptoCurrencies}
        fullWidth
        value={supportedCryptoCurrency}
        disabled={isUpdating || readonly}
        multiple
        onChange={handleMultipleSelectChange(FORM_FIELDS.SUPPORTED_CRYPTO_CURRENCIES)}
        placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.SUPPORTED_CRYPTO_CURRENCIES)}
        searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
        getItemLabel={(item: CurrencyItem) => item.name}
        nothingFoundMessage={
          <NothingFoundContainer>
            <NothingFoundImage src={nothingFoundImage} />
            <NothingFoundCaption>{t(LABELS.CRYPTO_NOT_FOUND)}</NothingFoundCaption>
          </NothingFoundContainer>
        }
      />
    ),
    [FORM_FIELDS.POPULAR_CRYPTO_CURRENCIES]: ({ readonly }) => (
      <CurrencySelect<CurrencyItem, true>
        items={cryptoCurrencies}
        fullWidth
        multiple
        value={popularCryptoCurrency}
        disabled={isUpdating || readonly}
        onChange={handleMultipleSelectChange(FORM_FIELDS.POPULAR_CRYPTO_CURRENCIES)}
        placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.POPULAR_CRYPTO_CURRENCIES)}
        searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
        getItemLabel={(item: CurrencyItem) => item.name}
        nothingFoundMessage={
          <NothingFoundContainer>
            <NothingFoundImage src={nothingFoundImage} />
            <NothingFoundCaption>{t(LABELS.CRYPTO_NOT_FOUND)}</NothingFoundCaption>
          </NothingFoundContainer>
        }
      />
    ),
    [FORM_FIELDS.SUPPORTED_FIAT_CURRENCIES]: ({ readonly }) => (
      <CurrencySelect<CurrencyItem, true>
        items={fiatCurrencies}
        fullWidth
        multiple
        value={supportedFiatCurrency}
        disabled={isUpdating || readonly}
        onChange={handleMultipleSelectChange(FORM_FIELDS.SUPPORTED_FIAT_CURRENCIES)}
        placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.SUPPORTED_FIAT_CURRENCIES)}
        searchResultsLabel={t(LABELS.SEARCH_RESULTS)}
        getItemLabel={(item: CurrencyItem) => item.name}
        nothingFoundMessage={
          <NothingFoundContainer>
            <NothingFoundImage src={nothingFoundImage} />
            <NothingFoundCaption>{t(LABELS.CRYPTO_NOT_FOUND)}</NothingFoundCaption>
          </NothingFoundContainer>
        }
      />
    ),
    [FORM_FIELDS.WIDGET_LOGO]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.WIDGET_LOGO)}
        filledInputLabel={t(LABELS.FORM_FIELDS.WIDGET_LOGO)}
        initialFile={account.widgetLogo}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.WIDGET_LOGO_DARK]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.WIDGET_LOGO_DARK)}
        filledInputLabel={t(LABELS.FORM_FIELDS.WIDGET_LOGO_DARK)}
        initialFile={account.widgetLogoDark}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        disabled={isUpdating || readonly}
        darkMode
      />
    ),
  };

  const fields = formFields.map((formField) => ({
    ...formField,
    renderInput: fieldRenderers[formField.key],
  }));

  return <FormLayout fields={fields} readonly={readOnlyMode} error={error} />;
};
