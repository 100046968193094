import { t } from 'i18next';

import { ColumnDecoration, DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const LABELS = {
  BREADCRUMBS: [['page.merchantAccounts.breadcrumbs', 'Merchant Accounts']],
  SEARCH_PLACEHOLDER: ['page.merchantAccounts.list.search.placeholder', 'Search by Account Name, Account ID, or Shift4 Merchant ID'],
  PAGE_TITLE: ['page.merchantAccounts.list.title', 'Merchant Accounts'],
  TABLE_COLUMNS: {
    NAME: ['page.merchantAccounts.list.table.columns.name', 'Account Name'],
    ID: ['page.merchantAccounts.list.table.columns.accountId', 'Account ID'],
    CREATED_AT: ['page.merchantAccounts.list.table.columns.createdAt', 'Created at'],
    STATUS: ['page.merchantAccounts.list.table.columns.status', 'Status'],
    ACTIONS: ['page.merchantAccounts.list.table.columns.actions', ''],
  },
  ACTIONS: {
    TITLE: ['page.merchantAccounts.list.actions.title', 'Actions'],
    OPEN_DETAILS: ['page.merchantAccounts.list.actions.openDetails', 'Open Account Details'],
    COPY_ACCOUNT_ID: ['page.merchantAccounts.list.actions.copyAccountId', 'Copy Account ID'],
  },
  BADGES: {
    ACTIVE: ['page.merchant.list.badges.status.active', 'Active'],
    INACTIVE: ['page.merchant.list.badges.status.inactive', 'Inactive'],
    DEACTIVATED: ['page.merchant.list.badges.status.deactivated', 'Deactivated'],
  },
  FILTERS: {
    STATUS: {
      LABEL: ['page.merchant.list.filter.status.label', 'Status'],
      ACTIVE: ['page.merchant.list.filter.status.active', 'Active'],
      INACTIVE: ['page.merchant.list.filter.status.inactive', 'Inactive'],
      DEACTIVATED: ['page.merchant.list.filter.status.deactivated', 'Deactivated'],
    },
    DATE: {
      LABEL: ['page.merchant.list.filter.date.label', 'Creation Date'],
    },
  },
};

export const COLUMN_IDS = {
  NAME: 'name',
  ID: 'uuid',
  CREATED_AT: 'createdAt',
  STATUS: 'status',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.NAME, label: t(LABELS.TABLE_COLUMNS.NAME), decoration: ColumnDecoration.bold, sortable: true },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID), sortable: true },
  { id: COLUMN_IDS.CREATED_AT, label: t(LABELS.TABLE_COLUMNS.CREATED_AT), sortable: true },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const KEYS = {
  FILTER_KEY: 'merchants',
  SEARCH_DEBOUNCE_TIMEOUT: 1000,
  TABLE_DATE_FORMAT: 'MMM DD, YYYY HH:mm A',
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.CREATED_AT,
    DIRECTION: SortOrder.desc,
  },
};
