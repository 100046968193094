import { t } from 'i18next';

import { OrderStatus } from 'types/pwcOrder';

export enum PwcOrderBadgeType {
  Initiated = 'INITIATED',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
}

export const LABELS = {
  STATUS: {
    INITIATED: ['page.pwcOrders.components.status.initiated', 'Initiated'],
    AWAITING_DEPOSIT: ['page.pwcOrders.components.status.awaitingDeposit', 'Awaiting Deposit'],
    COMPLETED: ['page.pwcOrders.components.status.completed', 'Completed'],
    EXPIRED: ['page.pwcOrders.components.status.expired', 'Expired'],
    CANCELLED: ['page.pwcOrders.components.status.canceled', 'Cancelled'],
  },
};

export const badgePropsMap: Record<string, { type: PwcOrderBadgeType; label: string }> = {
  [OrderStatus.Initiated]: {
    type: PwcOrderBadgeType.Initiated,
    label: t(LABELS.STATUS.INITIATED),
  },
  [OrderStatus.AwaitingDeposit]: {
    type: PwcOrderBadgeType.AwaitingDeposit,
    label: t(LABELS.STATUS.AWAITING_DEPOSIT),
  },
  [OrderStatus.Completed]: {
    type: PwcOrderBadgeType.Completed,
    label: t(LABELS.STATUS.COMPLETED),
  },
  [OrderStatus.Expired]: {
    type: PwcOrderBadgeType.Expired,
    label: t(LABELS.STATUS.EXPIRED),
  },
  [OrderStatus.Canceled]: {
    type: PwcOrderBadgeType.Canceled,
    label: t(LABELS.STATUS.CANCELLED),
  },
};
