import { PageWrapper } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserLevel } from 'state/slices/userSlice';

import { UserAccessLevel } from 'types/user';

import { LABELS } from './keys';
import { Profile } from './profile';

export const ProfileContainer = () => {
  const userLevel = useSelector(selectUserLevel);

  const showAccessSection = Boolean(userLevel && userLevel >= UserAccessLevel.Support);

  const showAccountsSection = Boolean(userLevel && userLevel <= UserAccessLevel.Partner);
  const showDeleteProfileSection = Boolean(userLevel && userLevel <= UserAccessLevel.Partner);

  const breadcrumbs = LABELS.BREADCRUMBS;

  return (
    <PageWrapper breadcrumbs={breadcrumbs}>
      <Profile
        showAccessSection={showAccessSection}
        showAccountsSection={showAccountsSection}
        showDeleteProfileSection={showDeleteProfileSection}
      />
    </PageWrapper>
  );
};
