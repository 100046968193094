export const KEYS = {
  DATE_FORMAT: 'MMM DD, HH:mm A',
  DATE_FORMAT_WITH_YEAR: 'MMM DD, YYYY, HH:mm A',
  AMOUNT_PLACEHOLDER: '0',
};

export const LABELS = {
  CSV_DOWNLOAD_ERROR: ['page.settlementReconciliations.csv.downloadError', 'Something went wrong, please try again later'],
  CSV_FILE_NAME: ['page.settlementReconciliations.csv.fileName', 'Settlement Reconciliations export {{date}}.csv'],
};
