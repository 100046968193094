import omit from 'lodash/omit';
import { useState } from 'react';

const filterStorage: Record<string, any> = {};

export const usePersistentFilters = <T extends object>(defaultState: T, key: string) => {
  const initialState = filterStorage[key] || defaultState;
  const [filters, setFiltersRaw] = useState<T>(initialState);

  const setFilters = (value: T) => {
    setFiltersRaw(value);
    filterStorage[key] = value;
  };

  const clearFilters = () => setFilters(initialState);
  const setFilterField = (field: keyof T) => (value: T[keyof T]) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const updateFilters = (nextFilters: T) => {
    setFilters(nextFilters);
  };

  const clearFilterField = (field: keyof T) => {
    setFilters(omit(filters, [field]) as T);
  };

  return {
    filters,
    setFilterField,
    clearFilters,
    updateFilters,
    clearFilterField,
  };
};

export const useFilters = <T extends object>(initialState: T) => {
  const [filters, setFilters] = useState<T>(initialState);
  const clearFilters = () => setFilters(initialState);
  const setFilterField = (field: keyof T) => (value: T[keyof T]) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const updateFilters = (nextFilters: T) => {
    setFilters(nextFilters);
  };

  const clearFilterField = (field: keyof T) => {
    setFilters(omit(filters, [field]) as T);
  };

  return {
    filters,
    setFilterField,
    clearFilters,
    updateFilters,
    clearFilterField,
  };
};
