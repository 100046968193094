import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTable } from 'components/dataTable';

import { COLUMNS, LABELS } from './keys';
import { DataTableContainer } from './styles';
import { AccessTableRow } from './types';

interface AccessSectionComponentProps extends WithTranslation {
  tableData: AccessTableRow[];
}

const AccessSectionComponent = ({ t, tableData }: AccessSectionComponentProps) => {
  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <DataTableContainer>
        <DataTable columns={COLUMNS} rows={tableData} />
      </DataTableContainer>
    </Panel>
  );
};

export const AccessSection = withTranslation()(AccessSectionComponent);
