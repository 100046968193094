import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const SectionWrapper = styled.div((props) => ({
  display: 'flex',
  padding: 10,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 15,
  alignSelf: 'stretch',
}));

export const Item = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 20,
}));

export const ItemName = styled.div((props) => ({
  width: 150,
  minWidth: 150,
  color: props.theme.colors.inactive,
  fontSize: 13,
  fontWeight: 400,
}));

export const ItemValue = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
}));

export const Delimiter = styled.div((props) => ({
  height: 0.5,
  background: props.theme.colors.container.tertiary,
  width: '100%',
}));

export const AmountBlock = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 8,
}));

export const AmountValue = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
}));

export const AmountNumericValue = styled.div((props) => ({
  fontVariantNumeric: 'lining-nums tabular-nums',
}));

export const DownloadButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 12px 4px 8px',
}));
