import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from 'utils/pagination';

import { fetchBaseQueryWithAuth } from '../api';
import { ReconciliationListQuery, ReconciliationResponse, ReconciliationsListResponse } from './types';

const REDUCER_PATH = 'reconciliationsApi';

export const reconciliationsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  endpoints: (builder) => ({
    getReconciliationsList: builder.query<ReconciliationsListResponse, ReconciliationListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Reconciliations.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<ReconciliationsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
    }),
    getReconciliation: builder.query<ReconciliationResponse, string>({
      query: (uuid) => ({
        url: Endpoint.Reconciliations.ById(uuid),
        method: HTTPMethod.GET,
      }),
    }),
    getReconciliationCSV: builder.query<Blob, string>({
      query: (uuid) => ({
        url: Endpoint.Reconciliations.CSV(uuid),
        method: HTTPMethod.GET,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const { useGetReconciliationsListQuery, useGetReconciliationQuery, useLazyGetReconciliationCSVQuery } = reconciliationsApi;
export default reconciliationsApi;
