import { TooltipProps } from '@mui/material/Tooltip';
import React, { ReactNode } from 'react';

import { BadgeRoot, StyledTooltip } from './styles';
import { BadgeType } from './types';

interface BadgeProps {
  type: BadgeType;
  children: string | ReactNode | ReactNode[];
  tooltip?: string;
  tooltipProps?: TooltipProps;
}

export const Badge = ({ type, children, tooltip, tooltipProps }: BadgeProps) => (
  <StyledTooltip
    title={tooltip || ''}
    placement="top"
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
    {...tooltipProps}
  >
    <BadgeRoot type={type}>{children}</BadgeRoot>
  </StyledTooltip>
);
