import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';
import { useGetOrderQuery } from 'services/orders';

import { Button } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator';

import RoutePath from 'router/path';

import { OrderSide } from 'types/order';

import { useRedirectOnAccountSwitch } from '../../utils/useRedirectOnAccountSwitch';
import ReceiptBuy from './buy';
import { LABELS } from './keys';
import ReceiptSell from './sell';
import { ReceiptWrapper } from './styles';

export const OrderReceiptContainer = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { uuid } = params;
  const { toPDF, targetRef } = usePDF({
    filename: `receipt-${uuid}.pdf`,
    page: {
      margin: 10,
    },
    overrides: {
      pdf: {
        unit: 'px',
        format: [200, 125],
        orientation: 'landscape',
      },
    },
  });
  useRedirectOnAccountSwitch(RoutePath.Ramp.Orders.Root);

  const { data: order, isLoading } = useGetOrderQuery(uuid!, {
    skip: !uuid,
  });

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  const Receipt = order?.data?.order?.side === OrderSide.Buy ? ReceiptBuy : ReceiptSell;

  const onGetPDFClick = () => {
    toPDF();
  };

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!order?.data) {
    return null;
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <ReceiptWrapper>
        <Receipt targetRef={targetRef} order={order!.data.order} />
        <Button onClick={onGetPDFClick}>{t(LABELS.GET_PDF)}</Button>
      </ReceiptWrapper>
    </PageWrapper>
  );
};
