import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M11.8346 1.34102L10.6596 0.166016L6.0013 4.82435L1.34297 0.166016L0.167969 1.34102L4.8263 5.99935L0.167969',
  '10.6577L1.34297 11.8327L6.0013 7.17435L10.6596 11.8327L11.8346 10.6577L7.1763 5.99935L11.8346 1.34102Z',
].join(' ');

export const IconCross = ({ width = 12, height = 12, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconCross;
