import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountOwnerType } from 'services/merchants/types';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';

import { CreateAccountModalForm } from './components';
import { KEYS, LABELS } from './keys';
import { Buttons, Delimeter, ModalControls, ModalRoot, PanelContent } from './styles';
import { useCreateBankAccountsModal } from './useCreateBankAccountsModal';

export const CreateBankAccountsModal = ({
  error,
  isSubmitting,
  reset,
  open,
  onClose,
  account,
  onCreateAccount,
  setAccountType,
  accountType,
  countryProps,
}: ReturnType<typeof useCreateBankAccountsModal>['modalProps']) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState(account);

  const handleFormValueChange = (fieldName: string, value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const handleCreateAccount = async () => {
    const success = await onCreateAccount(formValues);
    if (success) {
      onClose();
    }
  };

  const allRequiredFieldsNonEmpty =
    countryProps.selectedCountry &&
    Object.entries(formValues)
      .filter(([key]) => !KEYS.OPTIONAL_BOTH.includes(key))
      .filter(([key]) => {
        if (accountType === AccountOwnerType.Individual) {
          return !KEYS.OPTIONAL_INDIVIDUAL.includes(key);
        }

        return !KEYS.OPTIONAL_BUSINESS.includes(key);
      })
      .every(([, value]) => value.trim() !== '');

  return (
    <Dialog open={open} scroll="body" onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.CREATE_MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            <CreateAccountModalForm
              countryProps={countryProps}
              setAccountType={setAccountType}
              accountType={accountType}
              formValues={formValues}
              onFieldValueChange={handleFormValueChange}
              error={error}
            />

            <Delimeter />

            <Buttons>
              <Button secondary onClick={closeModal} disabled={isSubmitting}>
                {t(LABELS.CREATE_MODAL.BUTTONS.CANCEL)}
              </Button>
              <Button primary onClick={handleCreateAccount} disabled={isSubmitting || !allRequiredFieldsNonEmpty}>
                {t(LABELS.CREATE_MODAL.BUTTONS.CREATE)}
              </Button>
            </Buttons>
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Dialog>
  );
};
