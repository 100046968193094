import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import questionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { ButtonWithLoading } from 'components/buttons/buttonWithLoading';
import { Buttons, Content, Image, Message, Title } from 'components/modal/styles';

import { LABELS } from '../../keys';

interface StatusChangeModalBasicMessageProps {
  isCustomerActive: boolean;
}

export const StatusChangeModalBasicMessage = ({ isCustomerActive }: StatusChangeModalBasicMessageProps) => {
  const { t } = useTranslation();
  const [messageKey, messageDefault] = isCustomerActive ? LABELS.CHANGE_STATUS.BLOCK_MESSAGE : LABELS.CHANGE_STATUS.UNBLOCK_MESSAGE;

  return (
    <Content>
      <Image src={questionImage} />
      <Title>{t(LABELS.CHANGE_STATUS.CONFIRM_TITLE)}</Title>
      <Message>
        <Trans i18nKey={messageKey} defaults={messageDefault} components={[<br />]} />
      </Message>
    </Content>
  );
};

interface StatusChangeModalButtonsProps {
  isUpdating: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const StatusChangeModalButtons = ({ onClose, onSubmit, isUpdating }: StatusChangeModalButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Buttons>
      <Button secondary onClick={onClose}>
        {t(LABELS.CHANGE_STATUS.CANCEL)}
      </Button>
      <ButtonWithLoading primary onClick={onSubmit} isLoading={isUpdating} disabled={isUpdating}>
        {t(LABELS.CHANGE_STATUS.CONFIRM)}
      </ButtonWithLoading>
    </Buttons>
  );
};
