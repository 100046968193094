import React from 'react';

import { Country } from 'types/country';

import { Container, CountryIcon, CountryName } from './styles';

interface CountryFieldProps {
  country?: Country | null;
}

export const CountryField = ({ country }: CountryFieldProps) =>
  country ? (
    <Container>
      <CountryIcon src={country.iconUrl} />
      <CountryName>{country.name}</CountryName>
    </Container>
  ) : null;
