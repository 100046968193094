import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';
import { TableCell } from 'components/dataTable';
import { Path } from 'components/icons/styles';

import { PAYMENT_METHODS_COLUMN_IDS } from './keys';

export const ActionPanel = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const AddRecordButton = styled(Button)(() => ({
  textTransform: 'uppercase',
}));

export const PaymentMethodsTableCell = styled(TableCell)((props) => ({
  color: props.theme.colors.inactive,
}));

export const PaymentMethodsContainer = styled.div((props) => ({
  [`& [data-column="${PAYMENT_METHODS_COLUMN_IDS.NAME}"]`]: {
    minWidth: 500,
  },
  [`
    & [data-column="${PAYMENT_METHODS_COLUMN_IDS.BUY}"],
    & [data-column="${PAYMENT_METHODS_COLUMN_IDS.SELL}"]
  `]: {
    maxWidth: 100,
    minWidth: 50,
  },
}));

export const PaymentMethodName = styled.span((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 5,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const InfoIconContainer = styled.div(() => ({
  display: 'flex',
}));

export const StyledTooltip = styled.div((props) => ({
  display: 'flex',
  padding: '10px 18px',
  maxWidth: 400,
  border: `1px solid ${props.theme.colors.container.tertiary}`,
  borderRadius: 5,
  marginLeft: -15,
  marginBottom: 15,
  backgroundColor: props.theme.colors.background,
  boxShadow: '0px 3px 12px 0px rgba(41, 27, 79, 0.05)',
  fontSize: 12,
  fontWeight: 400,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));
