import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionImage from 'assets/images/question.svg';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import Modal from 'components/modal/modal';

import { LABELS } from '../../keys';
import { Buttons, Caption, ContentContainer, Delimeter, Header, ModalControls, ModalRoot, PanelContent } from './styles';

interface SuspendMerchantModalProps {
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}

export const SuspendMerchantModal = ({ onConfirm, open, onClose, children }: PropsWithChildren<SuspendMerchantModalProps>) => {
  const { t } = useTranslation();

  const closeModal = () => {
    onClose();
  };

  const handleConfirm = async () => {
    onConfirm();
    closeModal();
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalRoot>
        <Panel
          label={t(LABELS.MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <PanelContent>
            <ContentContainer>
              <img src={QuestionImage} alt={t(LABELS.MODAL.CONFIRM_CHOICE_IMAGE_ALT)} />
              <Header>{t(LABELS.MODAL.HEADER)}</Header>
              <Caption>{children}</Caption>
            </ContentContainer>
            <Delimeter />
            <Buttons>
              <Button secondary onClick={closeModal}>
                {t(LABELS.MODAL.CANCEL)}
              </Button>
              <Button primary onClick={handleConfirm}>
                {t(LABELS.MODAL.CONFIRM)}
              </Button>
            </Buttons>
          </PanelContent>
        </Panel>
      </ModalRoot>
    </Modal>
  );
};
