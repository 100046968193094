import { useAuth0 } from '@auth0/auth0-react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNotifications } from 'providers/notifications/useNotifications';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAcceptInviteMutation } from 'services/users';
import { AcceptInviteResponse } from 'services/users/types';

import { ErrorScreen } from 'pages/error/error';
import { LoadingScreen } from 'pages/loading/loading';

import RoutePath from 'router/path';

import { APIError } from 'types/error';
import { ReduxToolkitError } from 'types/http';

import getQueryParam from 'utils/url';

import { KEYS } from './keys';

export const AcceptInvite = () => {
  const inviteKey = getQueryParam(KEYS.INVITE_KEY_QUERY_PARAM);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { pushDialog } = useNotifications();
  const [acceptInvite, { isLoading, error }] = useAcceptInviteMutation();

  const handleAcceptInvite = async (inviteKey: string | null) => {
    const canNotAccept = !isAuthenticated || !inviteKey;
    if (canNotAccept) {
      return;
    }

    const result = await acceptInvite(inviteKey);
    if ('error' in result) {
      const { error } = result as { error: SerializedError | FetchBaseQueryError };
      if ('data' in error) {
        const { errorMessage } = error.data as APIError;
        pushDialog({
          dialogTitle: ' ',
          message: errorMessage,
        });
      }
      navigate(RoutePath.Profile.Root);
      return;
    }

    if ('data' in result) {
      const { success } = (result as { data: AcceptInviteResponse }).data.data || {};
      if (success) {
        navigate(RoutePath.Root);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => handleAcceptInvite(inviteKey), KEYS.INVITE_ACCEPT_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const errorMessage = (error as ReduxToolkitError)?.data?.errorMessage || '';

  return <ErrorScreen error={errorMessage} />;
};
