import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  height: '100%',
  fontFamily: theme.fonts.primary,
}));

export const Header = styled.div((_) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Card = styled.div(({ theme }) => ({
  background: theme.colors.container.primary,
  borderRadius: 5,
  display: 'flex',
  gap: 15,
  flexDirection: 'column',
  padding: `12px 10px`,
}));

export const LoadingCard = styled(Card)((_) => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

export const HeaderInfo = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

export const HeaderInfoBlock = styled.div(({ theme }) => ({
  color: theme.colors.grey,
  display: 'flex',
  fontSize: 14,
  fontWeight: 600,
}));

export const AccountIdInfoBlock = styled(HeaderInfoBlock)((_) => ({}));

export const HeaderInfoBlockLighterFont = styled(HeaderInfoBlock)((_) => ({
  fontWeight: 400,
}));

export const OrderIdHeader = styled(HeaderInfoBlock)((_) => ({
  fontSize: 12,
}));

export const Section = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.background,
  borderRadius: 5,
  padding: `0 12px 12px`,
}));

export const SectionTitle = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 10,
  marginBottom: 10,
  fontSize: 12,
  fontWeight: 600,
  color: theme.colors.grey,
}));

export const SectionTitleCellLeft = styled.div(({ theme }) => ({
  fontWeight: 600,
  color: `${theme.colors.grey} !important`,
}));

export const SectionTitleCellRight = styled.div(({ theme }) => ({
  fontWeight: 400,
  color: `${theme.colors.grey} !important`,
}));

export const ErrorStatus = styled.div(({ theme }) => ({
  fontWeight: 400,
  color: `${theme.colors.error} !important`,
}));

export const SectionSeparator = styled.div(({ theme }) => ({
  height: '0.5px',
  backgroundColor: theme.colors.lightGrey,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 5,
  marginBottom: 5,
}));

export const ArrowIconContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background,
  width: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SectionContent = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SectionContentItem = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 6,
  marginBottom: 6,
}));

export const Circle = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 16,
  height: 16,
  borderRadius: '50%',
  backgroundColor: theme.colors.container.primary,
}));

export const WalletCell = styled.div(({ theme }) => ({
  fontSize: 8,
  color: theme.colors.text.primary,
}));

export const ButtonWrapper = styled.div((_) => ({
  width: '100%',
  marginTop: -10,
}));

export const ImportantCell = styled.div(({ theme }) => ({
  color: theme.colors.text.primary,
  fontSize: 14,
  fontWeight: 600,
}));

export const Cell = styled.div(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.text.primary,
}));

export const CardBrandCell = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  fontSize: 12,
  color: theme.colors.text.primary,
}));

export const HiddenCell = styled.div(() => ({
  visibility: 'hidden',
}));

export const TransactionIdInformation = styled.div(({ theme }) => ({
  color: theme.colors.text.primary,
  fontSize: 8,
  fontWeight: 400,
  whiteSpace: 'nowrap',
}));

export const TransactionIdLink = styled.a(({ theme }) => ({
  marginLeft: 10,
  fontSize: 8,
  fontWeight: 400,
  color: `${theme.colors} !important`,
  textDecoration: 'underline',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const TransactionIdText = styled.span(({ theme }) => ({
  fontWeight: 400,
  marginLeft: 5,
  color: `${theme.colors.text.primary} !important`,
}));

export const CurrencyLogo = styled.img((_) => ({
  height: 16,
  marginRight: 8,
}));

export const TotalAmount = styled.span((_) => ({
  marginLeft: 5,
}));

export const Amount = styled(Cell)((_) => ({
  textAlign: 'end',
}));

export const TransactionIdContentItem = styled.div(() => ({
  overflow: 'hidden',
}));

export const MinorText = styled.span(({ theme }) => ({
  color: theme.colors.grey,
}));

export const PageError = styled.div(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 20,
  paddingTop: 12,
  color: theme.colors.grey,
  fontWeight: 600,
}));

export const Grid = styled.div((_) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 0.1fr auto',
  gap: '10px 20px',
}));

export const ReceiptWrapper = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  maxWidth: 750,
}));
