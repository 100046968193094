import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

export const KEYS = {
  FEE_CURRENCY: 'USD',
  FEE_SYMBOL: '$',
  PLACEHOLDER: '-',
  SYMBOL_SEPARATOR: '.',
  NETWORK_SEPARATOR: '_',
  QUOTE_REFRESH_INTERVAL: 5000,
  UPDATE_ORDER_PREVIEW_TIMEOUT: 1000,
  AMOUNT_EXP: /^[0-9]*\.{0,1}[0-9]*$/,
};

export const LABELS = {
  ERRORS: {
    INVALID_ASSET: ['pages.customers.view.balances.error.invalidAsset', 'Invalid Cryptocurrency selected'],
    TITLE: ['pages.customers.view.balances.error.refundErrorTitle', 'Can not submit refund'],
    ORDER_TITLE: ['pages.customers.view.balances.error.orderErrorTitle', 'Can not create order'],
  },
  REFUND_MODAL: {
    TITLE: ['pages.customers.view.balances.refundModal.title', 'Refund User'],
    HEADER: ['pages.customers.view.balances.refundModal.header', 'Please verify the Refund details'],
    CRYPTOCURRENCY: ['pages.customers.view.balances.refundModal.cryptocurrency', 'Cryptocurrency'],
    TOTAL_REFUNDED: ['pages.customers.view.balances.refundModal.totalRefunded', 'Total Refunded'],
    NETWORK_FEE: ['pages.customers.view.balances.refundModal.networkFee', 'Network Fee for this transaction'],
    WALLET: ['pages.customers.view.balances.refundModal.wallet', 'Receiving Wallet'],
    WALLET_PLACEHOLDER: ['pages.customers.view.balances.refundModal.walletPlaceholder', 'Wallet Address'],
    CANCEL: ['pages.customers.view.balances.refundModal.cancel', 'Cancel'],
    CONFIRM: ['pages.customers.view.balances.refundModal.confim', 'Confirm'],
  },
  CREATE_ORDER_MODAL: {
    TITLE: ['pages.customers.view.balances.createOrderModal.title', 'Create Sell Order'],
    HEADER: ['pages.customers.view.balances.createOrderModal.header', 'Please verify the Sell Order details'],
    CRYPTOCURRENCY: ['pages.customers.view.balances.createOrderModal.cryptocurrency', 'Cryptocurrency'],
    CURRENCY: ['pages.customers.view.balances.createOrderModal.currency', 'Currency'],
    CURRENCY_PLACEHOLDER: ['pages.customers.view.balances.createOrderModal.currencyPlaceholder', 'Select Currency'],
    AMOUNT: ['pages.customers.view.balances.createOrderModal.amount', 'Amount'],
    AMOUNT_PLACEHOLDER: ['pages.customers.view.balances.createOrderModal.amountPlaceholder', 'Enter amount'],
    TRANSACTION_DETAILS: ['pages.customers.view.balances.createOrderModal.transactionDetails', 'Transaction Breakdown'],
    TRANSACTION_DETAILS_HEADER: [
      'pages.customers.view.balances.createOrderModal.transactionDetailsHeader',
      '<0>Sell {{amount}} {{cryptocurrencyCode}}</0> receive ~{{fiatAmount}}',
    ],
    PROCESSING_FEE: ['pages.customers.view.balances.createOrderModal.processingFee', 'Processing Fee'],
    TOTAL: ['pages.customers.view.balances.createOrderModal.total', '<0>Total {{currency}}</0> (estimate)'],
    CARD_TITLE: ['pages.customers.view.balances.createOrderModal.cardTitle', '•••• {{last4}}'],
    ASSET_TITLE: ['pages.customers.view.balances.createOrderModal.assetTitle', '{{title}}'],
    CARD: ['pages.customers.view.balances.createOrderModal.card', 'Customer Card Details'],
    CANCEL: ['pages.customers.view.balances.createOrderModal.cancel', 'Cancel'],
    CONFIRM: ['pages.customers.view.balances.createOrderModal.confim', 'Confirm'],
  },
  ACTIONS: {
    TITLE: ['pages.customers.view.balances.columns.actions.dropdownTitle', 'Actions'],
    REFUND: ['pages.customers.view.balances.columns.actions.refundUser', 'Refund User'],
    CREATE_ORDER: ['pages.customers.view.balances.columns.actions.createOrder', 'Create Sell Order'],
  },
  TABLE_COLUMNS: {
    AMOUNT: ['pages.customers.view.balances.columns.amount.title', 'Amount'],
    ASSET: ['pages.customers.view.balances.columns.asset.title', 'Cryptocurrency'],
    ACTIONS: ['pages.customers.view.balances.columns.actions.title', ''],
  },
  BALANCE_LIST: {
    TITLE: ['page.customers.view.balances.title', 'Deposit Balances'],
    REFRESH: ['page.customers.view.balances.refresh', 'CHECK BALANCE'],
    REFRESHING: ['page.customers.view.balances.refreshing', 'CHECKING'],
    NO_RECORDS_FOUND: ['page.customers.view.balances.noRecordsFound', 'No balances found'],
  },
};

export const COLUMN_IDS = {
  ASSET: 'asset',
  AMOUNT: 'amount',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.ASSET, label: t(LABELS.TABLE_COLUMNS.ASSET) },
  { id: COLUMN_IDS.AMOUNT, label: t(LABELS.TABLE_COLUMNS.AMOUNT) },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
