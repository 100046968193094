import { defaultOrdersCount } from 'constants/orders';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderSide } from 'types/order';

import { LABELS } from '../keys';
import { FilterButton, FilterContainer, Text } from './styles';

export interface OrderSideFilterProps {
  side?: OrderSide;
  count?: {
    total: number;
    buy: number;
    sell: number;
  };
  onChange: (side?: OrderSide) => void;
}
export const OrderSideFilter = ({ side, onChange, count = defaultOrdersCount }: OrderSideFilterProps) => {
  const { t } = useTranslation();

  const handleChange = (nextSide?: OrderSide) => () => {
    onChange(nextSide);
  };

  return (
    <FilterContainer>
      <FilterButton active={!side} onClick={handleChange()}>
        <Text>{t(LABELS.FILTERS.SIDE.TOTAL)}</Text>
        <Text>{count.total}</Text>
      </FilterButton>
      <FilterButton active={side === OrderSide.Buy} onClick={handleChange(OrderSide.Buy)}>
        <Text>{t(LABELS.FILTERS.SIDE.BUY)}</Text>
        <Text>{count.buy}</Text>
      </FilterButton>
      <FilterButton active={side === OrderSide.Sell} onClick={handleChange(OrderSide.Sell)}>
        <Text>{t(LABELS.FILTERS.SIDE.SELL)}</Text>
        <Text>{count.sell}</Text>
      </FilterButton>
    </FilterContainer>
  );
};
