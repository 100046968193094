import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { DataTableRow } from 'components/dataTable';
import DataTable from 'components/dataTable/dataTable';

import { TablePagination } from 'types/pagination';

import { OrderTableCell } from './components/orderTableCell';
import { COLUMNS, LABELS } from './keys';
import { Container } from './styles';
import { ReconciliationDataRow } from './types';

interface ReconciliationListPageProps {
  reconciliations: ReconciliationDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  onRowClick: (row: DataTableRow) => void;
}

export const SettlementReconciliationList = ({
  reconciliations,
  isLoading,
  pagination,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
}: ReconciliationListPageProps) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <Container>
        <DataTable
          columns={COLUMNS}
          rows={reconciliations}
          loading={isLoading}
          CellComponent={OrderTableCell}
          pagination={pagination}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onRowClick={onRowClick}
        />
      </Container>
    </Panel>
  );
};
