import React, { ComponentType, FunctionComponent } from 'react';

import { DataTableCellProps } from 'components/dataTable/dataTable.types';

import { SETTLEMENT_COLUMN_IDS } from '../../keys';
import { DateCell } from './dateCell';
import { DefaultCell } from './defaultCell';
import { UppercaseCell } from './uppercaseCell';

const cellComponentMap: { [key: string]: ComponentType<DataTableCellProps> } = {
  [SETTLEMENT_COLUMN_IDS.CURRENCY]: UppercaseCell,
  [SETTLEMENT_COLUMN_IDS.BANK_NAME]: DefaultCell,
  [SETTLEMENT_COLUMN_IDS.CREATED_AT]: DateCell,
  [SETTLEMENT_COLUMN_IDS.BRIDGE_ACCOUNT_ID]: DefaultCell,

  default: DefaultCell,
};

export const BankAccountCell: FunctionComponent<DataTableCellProps> = (props) => {
  const { column } = props;
  const SelectedComponent = cellComponentMap[column.id] || cellComponentMap.default;

  return <SelectedComponent {...props} />;
};
