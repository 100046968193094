import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { LABELS } from './keys';

const DeleteProfileSectionComponent = ({ t }: WithTranslation) => {
  return <Panel label={t(LABELS.PANEL_TITLE)} />;
};

export const DeleteProfileSection = withTranslation()(DeleteProfileSectionComponent);
