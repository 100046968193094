import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { FunctionComponent, ReactNode } from 'react';

import { ComparatorType } from 'utils/tables/getComparator';

import { SelectItem } from '../inputs/select/select';

export enum ColumnDecoration {
  bold = 'bold',
}
export interface DataTableColumn {
  id: string;
  label: string | ReactNode;
  sortable?: boolean;
  orderBy?: string;
  orderType?: ComparatorType;
  hidden?: boolean;
  disablePadding?: boolean;
  minWidth?: number;
  cellComponent?: FunctionComponent<DataTableCellProps>;
  decoration?: ColumnDecoration;
  headCellProps?: TableCellProps;
}

export interface DataTableRow {
  id: number | string;
  [key: string]: any;
}

export interface DataTableCellProps {
  row: DataTableRow;
  column: DataTableColumn;
  updateRow?: (nextRow: Partial<DataTableRow>, shouldBeRemoved?: boolean) => Promise<any>;
  passedProps?: Record<string, any>;
}

export const EMPTY_COLUMN_ID = 'empty-column-id';

export const EMPTY_DATA_COLUMN: DataTableColumn = {
  id: EMPTY_COLUMN_ID,
  label: '',
};

export interface ItemsPerPageOption extends SelectItem {
  value: number;
  label: string;
}
