import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M12 6.75V14.25H6V6.75H12ZM10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3H11.625L10.875',
  '2.25ZM13.5 5.25H4.5V14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25Z',
].join(' ');

const IconDelete = ({ width = 18, height = 18, color, style, className }: IconProps) => (
  <Svg className={className} style={style} width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" fill={color} d={path} />
  </Svg>
);

export default IconDelete;
