import dayjs from 'dayjs';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconCrossCircled from 'components/icons/crossCircled';

import { AmountFilterValue, DateFilterValue, Filter, FilterType } from 'types/filters';

import { asArray } from 'utils/string';

import { CheckboxFilterPassedProps, RemoteSelectFilterPassedProps, SelectFilterPassedProps } from '../types';
import { KEYS, LABELS } from './keys';
import { RemoteFilterLabel } from './remoteFilterLabel';
import { Badge, CloseButton, Label, Name, Value } from './styles';

export interface FilterBadgeProps {
  filter: Filter<unknown>;
  value: DateFilterValue | AmountFilterValue | string | string[];
  onClear: () => void;
}

export const FilterBadge = ({ value, filter, onClear }: FilterBadgeProps) => {
  const { t } = useTranslation();

  const formatValue = (value: DateFilterValue | AmountFilterValue | string | string[], filter: Filter<unknown>): JSX.Element | string => {
    if (filter.type === FilterType.Date) {
      const filterValue = value as DateFilterValue;
      const isBeforeVariant = !filterValue.from && filterValue.to;
      if (isBeforeVariant) {
        return t(LABELS.DATE_BEFORE, { date: dayjs(filterValue.to).format(KEYS.DATE_FORMAT), interpolation: { escapeValue: false } });
      }

      const isAfterVariant = !filterValue.to && filterValue.from;
      if (isAfterVariant) {
        return t(LABELS.DATE_AFTER, { date: dayjs(filterValue.from).format(KEYS.DATE_FORMAT), interpolation: { escapeValue: false } });
      }

      return [dayjs(filterValue.from).format(KEYS.DATE_FORMAT), dayjs(filterValue.to).format(KEYS.DATE_FORMAT)].join(KEYS.DATE_SEPARATOR);
    }

    if (filter.type === FilterType.Amount) {
      const filterValue = value as AmountFilterValue;
      if (filterValue.gte === filterValue.lte) {
        return t(LABELS.IS_EQUAL, filterValue as Record<string, string>);
      }

      if (!filterValue.lte) {
        return t(LABELS.MORE_THAN, filterValue as Record<string, string>);
      }

      if (!filterValue.gte) {
        return t(LABELS.LESS_THAN, filterValue as Record<string, string>);
      }

      return t(LABELS.IS_BETWEEN, filterValue as Record<string, string>);
    }

    if (filter.type === FilterType.Checkbox) {
      const filterObject = filter as Filter<CheckboxFilterPassedProps>;
      const filterValue = value as string[];
      const output = filterObject.options
        .filter((option) => !isEmpty(intersection(option.value, filterValue)))
        .map((option) => option.label)
        .join(KEYS.STATUS_SEPARATOR);

      return output;
    }

    if (filter.type === FilterType.Radio) {
      const filterObject = filter as Filter<CheckboxFilterPassedProps>;
      const filterValue = value as string[];
      const option = filterObject.options.find((option) => !isEmpty(intersection(asArray(option.value), asArray(filterValue))));

      if (!option) {
        return '';
      }

      return option.label;
    }

    if (filter.type === FilterType.Select) {
      const filterObject = filter as Filter<SelectFilterPassedProps>;
      const filterValue = value as string[];
      return filterObject.options
        .filter((option) => filterValue.includes(option.key))
        .map((option) => option.label)
        .join(KEYS.STATUS_SEPARATOR);
    }

    if (filter.type === FilterType.RemoteSelect) {
      const filterObject = filter as Filter<RemoteSelectFilterPassedProps>;
      const filterValue = value as string[];

      return <RemoteFilterLabel filter={filterObject} value={filterValue} />;
    }

    return value as string;
  };

  return (
    <Badge>
      <CloseButton flat onClick={onClear}>
        <IconCrossCircled />
      </CloseButton>
      <Label>
        <Name>{filter.label}:</Name>
        <Value>{formatValue(value, filter)}</Value>
      </Label>
    </Badge>
  );
};
