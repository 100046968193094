import React from 'react';
import { useTranslation } from 'react-i18next';

import OptionallyVisible from 'components/optionallyVisible';

import { AccountType } from 'types/account';

import { LABELS } from '../keys';
import { CreatedAccount } from '../types';
import { BoldText, Heading, TextBlock, VerifyContainer } from './verify.styles';

interface CreateAccountModalVerifyProps {
  formValues: CreatedAccount;
  accountType: AccountType;
}

export const CreateAccountModalVerify = ({ formValues, accountType }: CreateAccountModalVerifyProps) => {
  const { t } = useTranslation();

  return (
    <VerifyContainer>
      <Heading>{t(LABELS.CREATE_MODAL.VERIFY_STEP.TITLE)}</Heading>
      <TextBlock>
        <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.PRODUCT.TITLE)}</div>
        <BoldText>
          {accountType === AccountType.Pwc
            ? t(LABELS.CREATE_MODAL.VERIFY_STEP.PRODUCT.PWC)
            : t(LABELS.CREATE_MODAL.VERIFY_STEP.PRODUCT.RAMP)}
        </BoldText>
      </TextBlock>

      <TextBlock>
        <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.ACCOUNT_NAME_LABEL)}</div>
        <BoldText>{formValues.accountName}</BoldText>
      </TextBlock>

      <OptionallyVisible visible={accountType === AccountType.Pwc}>
        <TextBlock>
          <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.MERCHANT_ID_LABEL)}</div>
          <BoldText>{formValues.shift4MerchantId ? formValues.shift4MerchantId : t(LABELS.CREATE_MODAL.VERIFY_STEP.NO_VALUE)}</BoldText>
        </TextBlock>
      </OptionallyVisible>

      <TextBlock>
        <div>{t(LABELS.CREATE_MODAL.VERIFY_STEP.ACCOUNT_OWNER_LABEL)}</div>
        <BoldText>{formValues.username}</BoldText>
        <BoldText>
          {formValues.firstName} {formValues.lastName}
        </BoldText>
      </TextBlock>
    </VerifyContainer>
  );
};
