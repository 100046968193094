import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  pushDialogNotification,
  pushToastNotification,
  removeNotification as removeStateNotification,
  selectDialogNotifications,
  selectToastNotifications,
} from 'state/slices/notificationsSlice';

import { DialogNotification, ToastNotification } from 'types/notifications';

import { notificationDispatcher } from './notificationDispatcher';
import { NotificationEvent } from './types';

export const useNotificationsController = () => {
  const dispatch = useDispatch();
  const toastNotifications = useSelector(selectToastNotifications);
  const dialogNotifications = useSelector(selectDialogNotifications);

  const subscribe = () => {
    notificationDispatcher.on(NotificationEvent.PushNotification, pushToast);
    notificationDispatcher.on(NotificationEvent.PushDialogNotification, pushDialog);
    notificationDispatcher.on(NotificationEvent.RemoveNotification, removeNotification);
  };

  const unsubscribe = () => {
    notificationDispatcher.off(NotificationEvent.PushNotification, pushToast);
    notificationDispatcher.off(NotificationEvent.PushDialogNotification, pushDialog);
    notificationDispatcher.off(NotificationEvent.RemoveNotification, removeNotification);
  };

  useEffect(() => {
    subscribe();
    return unsubscribe;
  }, []);

  const pushToast = (notification: ToastNotification) => {
    dispatch(pushToastNotification(notification));
  };

  const removeNotification = (key: string | number) => {
    dispatch(removeStateNotification(key));
  };

  const pushDialog = (notification: DialogNotification) => {
    dispatch(pushDialogNotification(notification));
  };

  return {
    removeNotification,
    pushToast,
    pushDialog,
    toastNotifications,
    dialogNotifications,
  };
};
