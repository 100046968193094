import styled from '@emotion/styled/macro';

import { Root as ButtonRoot } from 'components/buttons/styles';
import { Path } from 'components/icons/styles';

export const ModalRoot = styled.div((_) => ({
  outline: 'none',
}));

export const ModalControls = styled.div((_) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const PanelContent = styled.div((_) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  maxWidth: 420,
  padding: '0 12px 12px 12px',
}));

export const Delimeter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
}));

export const Buttons = styled.div((_) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const ContentContainer = styled.div(() => ({
  minWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 15,
  alignItems: 'center',
}));

export const Caption = styled.div((props) => ({
  fontWeight: 400,
  fontSize: 13,
  color: props.theme.colors.primary,
}));

export const Header = styled.div((props) => ({
  fontWeight: 600,
  fontSize: 16,
  color: props.theme.colors.primary,
}));
