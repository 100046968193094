/* eslint-disable react/jsx-no-useless-fragment */
import { useAuth0 } from '@auth0/auth0-react';
import { throttle } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { auth0TokenStorage } from 'services/api';
import { useGetProfileQuery } from 'services/profile';
import { useGetAccountsListQuery } from 'services/users';

import { LoadingScreen } from 'pages/loading/loading';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { setAccount } from 'state/slices/accountSlice';
import { selectAuthError, selectUserLevel } from 'state/slices/userSlice';
import { useAppDispatch } from 'state/store';
import { processUserInfo } from 'state/thunks/user/processUserInfo.thunk';

import { UserAccessLevel } from 'types/user';

import { KEYS } from './keys';
import { useBackgroundAuthCheck } from './useBackgroundAuthCheck';

export const UserControlContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { user: auth0User, getAccessTokenSilently, isAuthenticated, isLoading, logout } = useAuth0();
  const { account } = useSelector(selectAccount);
  const { data: accountsData } = useGetAccountsListQuery({});
  const userLevel = useSelector(selectUserLevel);

  const isPartner = userLevel === UserAccessLevel.Partner;
  const accounts = isPartner ? accountsData?.data || [] : [];
  useGetProfileQuery();

  const checkToken = useCallback(
    throttle(() => {
      getAccessTokenSilently({ cacheMode: 'off' }).catch(logout);
    }, KEYS.CHECK_AUTH_THROTTLE),
    [getAccessTokenSilently],
  );

  useBackgroundAuthCheck(checkToken);
  const dispatch = useAppDispatch();
  const authError = useSelector(selectAuthError);
  const { isLoading: areAccountsLoading } = useGetAccountsListQuery(
    {},
    {
      skip: !isAuthenticated,
    },
  );

  auth0TokenStorage.setAuth0AccessTokenFn(getAccessTokenSilently);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(processUserInfo({ auth0User, getAccessTokenSilently }));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isPartner) {
      const accountIsAvailable = accounts.find((record) => record.account.uuid === account.uuid);
      if (!accountIsAvailable) {
        const [nextAccount] = accounts;
        dispatch(setAccount(nextAccount));
      }
    }
  }, [accounts]);

  if (authError) {
    return <div>{KEYS.AUTH_ERROR_MESSAGE}</div>;
  }

  if (isLoading || areAccountsLoading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};
