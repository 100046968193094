import { ClickAwayListener } from '@mui/base';
import { Popper, PopperProps } from '@mui/base/Popper';
import isFunction from 'lodash/isFunction';
import React, { ReactNode, useState } from 'react';

import { Button, ButtonProps } from 'components/buttons/button';
import OptionallyVisible from 'components/optionallyVisible';

import { DropdownContainer, Wrapper } from './styles';

interface RenderedButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}

interface DropdownListProps {
  renderMenu: (close: () => void) => ReactNode | ReactNode[];
  buttonLabel?: string | ReactNode;
  buttonProps?: Partial<ButtonProps>;
  renderButton?: (props: RenderedButtonProps) => ReactNode;
  popperProps?: Partial<PopperProps>;
  disabled?: boolean;
}

export const DropdownMenu = ({
  buttonLabel,
  buttonProps = {},
  renderButton,
  popperProps = {},
  disabled,
  renderMenu,
}: DropdownListProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleWrapperClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (anchorEl) {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const shouldRenderButton = isFunction(renderButton);

  const content = renderMenu(handleClose);

  return (
    <Wrapper onClick={handleWrapperClick}>
      <OptionallyVisible visible={shouldRenderButton}>
        {renderButton?.({
          open,
          onClick: handleClick,
        })}
      </OptionallyVisible>
      <OptionallyVisible visible={!shouldRenderButton}>
        <Button {...buttonProps} onClick={handleClick}>
          {buttonLabel}
        </Button>
      </OptionallyVisible>
      <Popper {...popperProps} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <DropdownContainer>{content}</DropdownContainer>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};
