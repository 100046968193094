import { OptionallyVisible } from 'components';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import LoadingIndicator from 'components/loadingIndicator';

import { UserRecord } from 'types/user';

import { ChangePasswordModal } from './components/changePasswordModal/changePasswordModal';
import { useChangePasswordModal } from './components/changePasswordModal/useChangePasswordModal';
import { UserInfoSectionForm } from './components/form/form';
import { LABELS } from './keys';
import { Controls, FormContainer } from './styles';

interface UsersInfoSectionComponentProps extends WithTranslation {
  profile: UserRecord;
  isProfileInfoPending: boolean;
  isFormChanged: boolean;
  resetFormValues: () => void;
  saveFormValues: () => void;
  onFormValueChange: (key: string, value: any) => void;
  isUpdating: boolean;
  formError?: Record<string, string>;
}

const UsersInfoSectionComponent = ({
  profile,
  isProfileInfoPending,
  isFormChanged,
  resetFormValues,
  isUpdating,
  saveFormValues,
  onFormValueChange,
  formError,
  t,
}: UsersInfoSectionComponentProps) => {
  const { handleChangePasswordModalOpen, passwordFields, modalProps } = useChangePasswordModal();

  if (isProfileInfoPending) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <LoadingIndicator padded />
      </Panel>
    );
  }

  return (
    <Panel
      label={t(LABELS.PANEL_TITLE)}
      controls={
        <Controls>
          <OptionallyVisible visible={isFormChanged}>
            <Button onClick={resetFormValues} secondary disabled={isUpdating}>
              {t(LABELS.CANCEL_BUTTON_LABEL)}
            </Button>
          </OptionallyVisible>
          <Button onClick={saveFormValues} primary disabled={!isFormChanged || isUpdating}>
            {t(LABELS.SAVE_BUTTON_LABEL)}
          </Button>
        </Controls>
      }
    >
      <FormContainer>
        <UserInfoSectionForm
          profile={profile}
          onFieldValueChange={onFormValueChange}
          isUpdating={isUpdating}
          error={formError}
          onChangePasswordButtonClick={handleChangePasswordModalOpen}
        />
      </FormContainer>
      <OptionallyVisible visible={Boolean(passwordFields)}>
        <ChangePasswordModal {...modalProps} />
      </OptionallyVisible>
    </Panel>
  );
};

export const UserInfoSection = withTranslation()(UsersInfoSectionComponent);
