import styled from '@emotion/styled';
import { Modal as MUIModal } from '@mui/base';

import { Button } from '../buttons';
import { Root as ButtonRoot } from '../buttons/styles';

export const Backdrop = styled.div((props) => ({
  zIndex: -1,
  position: 'fixed',
  inset: 0,
  backgroundColor: props.theme.colors.primary,
  opacity: 0.2,
}));

export const ModalRoot = styled(MUIModal)((props) => ({
  position: 'fixed',
  zIndex: 1300,
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
}));

export const ButtonLabel = styled.span(() => ({
  textTransform: 'uppercase',
}));

export const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '16px 10px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Body = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 360,
  maxWidth: 420,
  padding: '5px 10px 20px 10px',
}));

export const Image = styled.img(() => ({
  maxWidth: 112,
  maxHeight: 72,
}));

export const Title = styled.span((props) => ({
  fontSize: 16,
  fontWeight: 600,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const Message = styled.span((props) => ({
  fontSize: 13,
  fontWeight: 400,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
  textAlign: 'center',
}));

export const PanelTitle = styled.span((props) => ({
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 500,
  color: props.theme.colors.primary,
  fontFamily: props.theme.fonts.primary,
}));

export const CloseButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 'unset',
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: 20,
}));

export const Delimeter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
}));

export const ModalControls = styled.div((props) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const Text = styled.span((props) => ({
  fontSize: 13,
  fontColor: props.theme.colors.text.primary,
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));
