import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M6.2002 11C5.50853 11 4.85853 10.8687 4.2502 10.606C3.64186 10.3437 3.1127',
  '9.9875 2.6627 9.5375C2.2127 9.0875 1.85653 8.55833 1.5942 7.95C1.33153 7.34167',
  '1.2002 6.69167 1.2002 6C1.2002 5.30833 1.33153 4.65833 1.5942 4.05C1.85653 3.44167',
  '2.2127 2.9125 2.6627 2.4625C3.1127 2.0125 3.64186 1.65617 4.2502 1.3935C4.85853 1.13117',
  '5.50853 1 6.2002 1C6.89186 1 7.54186 1.13117 8.1502 1.3935C8.75853 1.65617 9.2877 2.0125',
  '9.7377 2.4625C10.1877 2.9125 10.5439 3.44167 10.8062 4.05C11.0689 4.65833 11.2002 5.30833',
  '11.2002 6C11.2002 6.69167 11.0689 7.34167 10.8062 7.95C10.5439 8.55833 10.1877 9.0875 9.7377',
  '9.5375C9.2877 9.9875 8.75853 10.3437 8.1502 10.606C7.54186 10.8687 6.89186 11 6.2002 11ZM6.2002',
  '10C7.31686 10 8.26269 9.6125 9.03769 8.8375C9.81269 8.0625 10.2002 7.11667 10.2002 6C10.2002 5.55',
  '10.1272 5.11667 9.9812 4.7C9.83553 4.28333 9.62519 3.9 9.35019 3.55L3.7502 9.15C4.1002 9.425 4.48353',
  '9.63533 4.9002 9.781C5.31686 9.927 5.7502 10 6.2002 10ZM3.0502 8.45L8.6502 2.85C8.3002 2.575 7.91686',
  '2.36467 7.5002 2.219C7.08353 2.073 6.6502 2 6.2002 2C5.08353 2 4.1377 2.3875 3.3627 3.1625C2.5877 3.9375',
  '2.2002 4.88333 2.2002 6C2.2002 6.45 2.2732 6.88333 2.4192 7.3C2.56486 7.71667 2.7752 8.1 3.0502 8.45Z',
].join(' ');

export const IconProhibitedCircled = ({ width = 13, height = 12, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconProhibitedCircled;
