import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps } from 'components/dataTable';
import { ActionMenu, MenuItem } from 'components/dataTable/cells/actionMenu';
import { TableCell } from 'components/dataTable/tableCell';

import { Asset } from 'types/assets';

import { capitalizeFirstLetter } from 'utils/string';

import { COLUMN_IDS, KEYS, LABELS } from '../keys';
import { CryptoCurrency, CryptoIcon, GreyText } from './styles';

const DefaultCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];
  return <TableCell data-column={column.id}>{value}</TableCell>;
};

const AssetCell = ({ row, column }: DataTableCellProps) => {
  const assetData = row[column.id];
  const networkName = assetData.chainCode.split(KEYS.NETWORK_SEPARATOR).map(capitalizeFirstLetter).join(' ');
  const [coinCode] = assetData.symbol.split(KEYS.SYMBOL_SEPARATOR);
  return (
    <TableCell data-column={column.id}>
      <CryptoCurrency>
        <CryptoIcon src={assetData.iconUrl} />
        {assetData.name}
        <GreyText>{`${coinCode} - ${networkName}`}</GreyText>
      </CryptoCurrency>
    </TableCell>
  );
};

export const ActionsCell = ({ row, passedProps }: DataTableCellProps) => {
  const { t } = useTranslation();

  const handleRefund = () => {
    passedProps?.refund(row.asset, row.amount);
  };

  const handleCreateOrder = () => {
    passedProps?.createOrder(row.asset, row.amount);
  };

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <MenuItem onClick={handleRefund}>{t(LABELS.ACTIONS.REFUND)}</MenuItem>
        <MenuItem onClick={handleCreateOrder}>{t(LABELS.ACTIONS.CREATE_ORDER)}</MenuItem>
      </ActionMenu>
    </TableCell>
  );
};

const cellComponentMap: Record<string, FunctionComponent<DataTableCellProps>> = {
  [COLUMN_IDS.ASSET]: AssetCell,
  [COLUMN_IDS.ACTIONS]: ActionsCell,
  default: DefaultCell,
};

export const BalanceTableCell = (props: DataTableCellProps) => {
  const { column } = props;
  const Component = cellComponentMap[column.id] || cellComponentMap.default;
  return <Component {...props} />;
};

export interface BalanceTableCellPassedProps {
  refund?: (asset: Asset, amount: number) => void;
  createOrder?: (asset: Asset, amount: number) => void;
}

export const makeBalanceTableCell =
  ({ refund, createOrder }: BalanceTableCellPassedProps) =>
  (props: DataTableCellProps) => <BalanceTableCell {...props} passedProps={{ refund, createOrder }} />;
