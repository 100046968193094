import React from 'react';
import { Outlet } from 'react-router-dom';

import { TabItem, Tabs } from 'components/tabs';

interface AccountSettingsPageProps {
  tabs: TabItem[];
  selected?: TabItem;
}

export const AccountSettings = ({ tabs, selected }: AccountSettingsPageProps) => {
  return (
    <>
      <Tabs items={tabs} selected={selected} />
      <Outlet />
    </>
  );
};
