import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = 'M4.99896 7.79909L2.19896 4.99909L1.26562 5.93242L4.99896 9.66576L12.999 1.66576L12.0656 0.732422L4.99896 7.79909Z';

export const IconChecked = ({ width = 14, height = 14, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} />
  </Svg>
);

export default IconChecked;
