import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M1.9987 0.333984C1.08203 0.333984 0.332031 1.08398 0.332031 2.00065C0.332031 2.91732 1.08203 3.66732 1.9987',
  '3.66732C2.91536 3.66732 3.66536 2.91732 3.66536 2.00065C3.66536 1.08398 2.91536 0.333984 1.9987 0.333984ZM11.9987',
  '0.333984C11.082 0.333984 10.332 1.08398 10.332 2.00065C10.332 2.91732 11.082 3.66732 11.9987 3.66732C12.9154',
  '3.66732 13.6654 2.91732 13.6654 2.00065C13.6654 1.08398 12.9154 0.333984 11.9987 0.333984ZM6.9987 0.333984C6.08203',
  '0.333984 5.33203 1.08398 5.33203 2.00065C5.33203 2.91732 6.08203 3.66732 6.9987 3.66732C7.91536 3.66732 8.66536',
  '2.91732 8.66536 2.00065C8.66536 1.08398 7.91536 0.333984 6.9987 0.333984Z',
].join(' ');

export const IconDots = ({ width = 14, height = 4, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
  </Svg>
);

export default IconDots;
