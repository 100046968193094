import PAGINATION_OPTIONS from 'constants/pagination';
import isNumber from 'lodash/isNumber';

import { Pagination, RequestPagination, ServerPagination } from 'types/pagination';

export const getDefaultPagination = (itemsPerPage = PAGINATION_OPTIONS.DEFAULT_PAGINATION_NUMBER_OF_ITEMS_PER_PAGE): Pagination => ({
  page: 1,
  itemsPerPage,
  count: 0,
  pages: 1,
});

export const decoratePagination = (paginationResponse: ServerPagination): Pagination => {
  const output: Pagination = {
    ...paginationResponse,
    pages: 0,
  };

  const canCalculatePages =
    isNumber(paginationResponse.count) &&
    isNumber(paginationResponse.itemsPerPage) &&
    Boolean(paginationResponse?.itemsPerPage) &&
    paginationResponse.count > 0;

  if (canCalculatePages && paginationResponse?.itemsPerPage) {
    output.pages = Math.ceil(paginationResponse.count / paginationResponse.itemsPerPage);
  }

  return output;
};

export const undecoratePagination = (pagination: Pagination): RequestPagination => ({
  itemsPerPage: pagination.itemsPerPage,
  page: pagination.page,
});
