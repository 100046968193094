import React from 'react';

import LoadingIndicator from '../loadingIndicator';
import { Button, ButtonProps } from './button';

export interface ButtonWithLoadingProps extends ButtonProps {
  isLoading?: boolean;
}

export const ButtonWithLoading = ({ isLoading, children, ...buttonProps }: ButtonWithLoadingProps) => {
  const content = isLoading ? <LoadingIndicator size={0.8} /> : children;
  return <Button {...buttonProps}>{content}</Button>;
};
