import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import { OrderWithdrawalStatusBadge } from '../../../components/orderStatusBadge/orderWithdrawalStatusBadge';
import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';
import { getIsMemoOrTagSet, getWalletMemoOrTag } from '../utils';

export interface DepositSectionProps extends OrderSectionProps {
  extended?: boolean;
}

export const DepositSection = ({ orderData, extended }: DepositSectionProps) => {
  const { t } = useTranslation();

  if (!extended) {
    return (
      <Panel label={t(LABELS.SECTIONS.DEPOSIT.TITLE)}>
        <SectionWrapper>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.DEPOSIT.DEPOSIT_STATUS)}</ItemName>
            <ItemValue>
              <OrderWithdrawalStatusBadge status={orderData.order.onChainStatus} />
            </ItemValue>
          </Item>
        </SectionWrapper>
      </Panel>
    );
  }

  return (
    <Panel label={t(LABELS.SECTIONS.DEPOSIT.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.DEPOSIT.WALLET_ADDRESS)}</ItemName>
          <ItemValue>{orderData.order.walletAddress}</ItemValue>
        </Item>

        <OptionallyVisible visible={getIsMemoOrTagSet(orderData)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.DEPOSIT.WALLET_MEMO_TAG)}</ItemName>
            <ItemValue>{getWalletMemoOrTag(orderData)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DEPOSIT.DEPOSIT_STATUS)}</ItemName>
          <ItemValue>
            <OrderWithdrawalStatusBadge status={orderData.order.onChainStatus} />
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DEPOSIT.TRANSACTION_HASH)}</ItemName>
          <OnChainTransactionHash>
            <a href={orderData.order.onChainExplorerURL} target="_blank">
              {orderData.order.onChainTransactionHash}
            </a>
          </OnChainTransactionHash>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
