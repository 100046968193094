import styled from '@emotion/styled/macro';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 5,
  padding: 0,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.background,
}));

export const Label = styled.span((props) => ({
  fontSize: 16,
  fontWeight: 500,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.primary,
  lineHeight: '16px',
}));

export const Header = styled.div(() => ({
  padding: '16px 20px',
  boxSizing: 'border-box',
  minHeight: 48,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Controls = styled.div(() => ({}));

export const Content = styled.div(() => ({
  padding: '5px 10px',
}));
