import React from 'react';

import IconArrow from 'components/icons/arrow';

import { OrderSide } from 'types/order';

import { capitalizeFirstLetter } from 'utils/string';

import { OrderSideContainer, Text } from './styles';

export interface OrderTypeBadgeProps {
  side: OrderSide;
  asIcon?: boolean;
}

export const OrderTypeBadge = ({ side, asIcon }: OrderTypeBadgeProps) => {
  const content = asIcon ? <IconArrow /> : <Text>{capitalizeFirstLetter(side)}</Text>;
  return (
    <OrderSideContainer side={side} asIcon={asIcon}>
      {content}
    </OrderSideContainer>
  );
};
