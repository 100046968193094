import styled from '@emotion/styled';

export const StatusBarContainer = styled.div((props) => ({
  display: 'flex',
  padding: '8px 16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: 50,
}));

export const Item = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

export const ItemName = styled.div((props) => ({
  color: props.theme.colors.inactive,
  fontSize: 14,
  fontWeight: 400,
}));

export const ItemValue = styled.div((props) => ({
  fontSize: 14,
  fontWeight: 400,
}));

export const Delimiter = styled.div((props) => ({
  height: 21,
  width: 1,
  background: props.theme.colors.container.tertiary,
}));
