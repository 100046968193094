import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantInfo, MerchantStatus } from 'services/merchants/types';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';

import { FORM_FIELDS, LABELS } from '../keys';
import { ActionButton, Adornment } from './styles';

interface GeneralAccountSettingFormProps {
  merchant: MerchantInfo;
  onFieldValueChange: (key: string, value: any) => void;
  onStatusChange: (status: MerchantStatus) => void;
  hiddenFields: Record<string, boolean>;
  isUpdating: boolean;
  isUpdatingStatus: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
}

export const GeneralAccountSettingForm = ({
  merchant,
  onFieldValueChange,
  onStatusChange,
  hiddenFields,
  isUpdating,
  isUpdatingStatus,
  error,
  readOnlyMode,
}: GeneralAccountSettingFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const fields: FormFieldDescription[] = [
    {
      key: FORM_FIELDS.ACCOUNT_ID,
      label: t(LABELS.FORM_FIELDS.ACCOUNT_ID),
      hidden: hiddenFields[FORM_FIELDS.ACCOUNT_ID],
      renderInput: () => merchant.uuid,
    },
    {
      key: FORM_FIELDS.ACCOUNT_NAME,
      label: t(LABELS.FORM_FIELDS.ACCOUNT_NAME),
      hidden: hiddenFields[FORM_FIELDS.ACCOUNT_NAME],
      renderInput: ({ readonly }) =>
        readonly ? (
          merchant.name
        ) : (
          <Input
            fullWidth
            value={merchant.name}
            onChange={handleChange(FORM_FIELDS.ACCOUNT_NAME)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NAME)}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.MERCHANT_ID,
      label: t(LABELS.FORM_FIELDS.MERCHANT_ID),
      hidden: hiddenFields[FORM_FIELDS.MERCHANT_ID],
      renderInput: ({ readonly }) =>
        readonly ? (
          merchant.shift4MerchantId
        ) : (
          <Input
            fullWidth
            value={merchant.shift4MerchantId ?? ''}
            onChange={handleChange(FORM_FIELDS.MERCHANT_ID)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.MERCHANT_ID)}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.BRIDGE_CUSTOMER_ID,
      label: t(LABELS.FORM_FIELDS.BRIDGE_CUSTOMER_ID),
      hidden: hiddenFields[FORM_FIELDS.BRIDGE_CUSTOMER_ID],
      renderInput: () => merchant.bridgeCustomerId,
    },
    {
      key: FORM_FIELDS.MESH_SUBCLIENT_ID,
      label: t(LABELS.FORM_FIELDS.MESH_SUBCLIENT_ID),
      hidden: hiddenFields[FORM_FIELDS.MESH_SUBCLIENT_ID],
      renderInput: ({ readonly }) =>
        readonly ? (
          merchant.meshSubclientId
        ) : (
          <Input
            fullWidth
            value={merchant.meshSubclientId ?? ''}
            onChange={handleChange(FORM_FIELDS.MESH_SUBCLIENT_ID)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.MESH_SUBCLIENT_ID)}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.WEBHOOK_URL,
      hidden: hiddenFields[FORM_FIELDS.WEBHOOK_URL],
      label: t(LABELS.FORM_FIELDS.WEBHOOK_URL),
      renderInput: ({ readonly }) =>
        readonly ? (
          `${LABELS.ADORNMENTS.HTTPS}${merchant.webhookUrl}`
        ) : (
          <Input
            fullWidth
            value={merchant.webhookUrl ?? ''}
            onChange={handleChange(FORM_FIELDS.WEBHOOK_URL)}
            startAdornment={<Adornment>{LABELS.ADORNMENTS.HTTPS}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.PROCESSING_FEE,
      label: t(LABELS.FORM_FIELDS.PROCESSING_FEE),
      hidden: hiddenFields[FORM_FIELDS.PROCESSING_FEE],
      renderInput: ({ readonly }) =>
        readonly ? (
          t(LABELS.FORM_FIELDS.VALUES.PROCESSING_FEE, { fee: merchant.processingFee })
        ) : (
          <Input
            fullWidth
            value={merchant.processingFee?.toString()}
            onChange={handleChange(FORM_FIELDS.PROCESSING_FEE)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PROCESSING_FEE)}
            type={InputType.Number}
            endAdornment={<Adornment>{LABELS.ADORNMENTS.PERCENT}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.STATUS,
      label: t(LABELS.FORM_FIELDS.STATUS),
      hidden: hiddenFields[FORM_FIELDS.STATUS],
      renderInput: () =>
        ({
          [MerchantStatus.Active]: t(LABELS.FORM_FIELDS.VALUES.MERCHANT_STATUS_ACTIVE),
          [MerchantStatus.Inactive]: t(LABELS.FORM_FIELDS.VALUES.MERCHANT_STATUS_INACTIVE),
          [MerchantStatus.Deactivated]: t(LABELS.FORM_FIELDS.VALUES.MERCHANT_STATUS_DEACTIVATED),
        })[merchant.status],
    },
    {
      key: FORM_FIELDS.CHANGE_STATUS,
      label: t(LABELS.FORM_FIELDS.CHANGE_STATUS),
      hidden: hiddenFields[FORM_FIELDS.CHANGE_STATUS],
      renderInput: () => {
        if (merchant.status === MerchantStatus.Active) {
          return (
            <ActionButton
              disabled={isUpdating}
              isLoading={isUpdatingStatus}
              danger
              onClick={() => onStatusChange(MerchantStatus.Deactivated)}
            >
              {t(LABELS.FORM_FIELDS.ACTIONS.DEACTIVATE)}
            </ActionButton>
          );
        }
        return (
          <ActionButton disabled={isUpdating} isLoading={isUpdatingStatus} secondary onClick={() => onStatusChange(MerchantStatus.Active)}>
            {t(LABELS.FORM_FIELDS.ACTIONS.ACTIVATE)}
          </ActionButton>
        );
      },
    },
  ];

  return <FormLayout fields={fields} readonly={readOnlyMode} error={error} />;
};
