import styled from '@emotion/styled/macro';

import { Path } from '../../icons/styles';

export const Handle = styled.div((props) => ({
  width: 14,
  height: 14,
  borderRadius: 14,
  boxSizing: 'border-box',
  border: `0.4px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.background,
  transition: 'all .3s ease',
}));

export const Container = styled.div<{ checked: boolean }>((props) => ({
  width: 34,
  padding: 2.5,
  borderRadius: 82,
  backgroundColor: props.checked ? props.theme.colors.accent : props.theme.colors.disabled,
  boxShadow: '0px 0px 1.636px 0px rgba(0, 0, 0, 0.20) inset',
  display: 'flex',
  transition: 'all .3s ease',
  cursor: 'pointer',
  // @ts-ignore
  [Handle]: {
    transform: `translateX(${props.checked ? 15 : 0}px)`,
  },
}));

export const BarContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '4px 8px',
  gap: 8,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.background,
  height: 32,
  fontSize: 13,
  lineHeight: '16px',
  boxSizing: 'border-box',
  borderRadius: 5,
}));

export const Item = styled.div<{ selected: boolean }>((props) => ({
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  borderRadius: 5,
  backgroundColor: props.selected ? props.theme.colors.highlight : props.theme.colors.background,
  color: props.selected ? props.theme.colors.accent : props.theme.colors.inactive,
  cursor: 'pointer',

  // @ts-ignore
  [Path]: {
    fill: props.selected ? props.theme.colors.accent : props.theme.colors.inactive,
  },
}));

export const Icon = styled.div((props) => ({}));

export const Label = styled.span((props) => ({
  color: 'inherit',
}));
