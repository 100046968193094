import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useGetMerchantQuery } from 'services/merchants';

import IconBuild from 'components/icons/build';
import IconCrypto from 'components/icons/crypto';
import IconProfile from 'components/icons/profile';
import IconRefundAlt from 'components/icons/refundAlt';
import LoadingIndicator from 'components/loadingIndicator/loadingIndicator';
import { TabItem } from 'components/tabs';

import RoutePath from 'router/path';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { UserAccessLevel } from 'types/user';

import { AccountSettings } from './accountSettings';
import { KEYS, LABELS } from './keys';

export const MerchantViewContainer = () => {
  const location = useLocation();
  const segments = location.pathname.split('/').filter(Boolean);
  const lastSegment = segments[segments.length - 1];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchId = useMatch(KEYS.ROUTE_MATCH_ID);
  const match = useMatch(KEYS.ROUTE_MATCH);
  const selectedMatch = matchId || match;
  const { account } = useSelector(selectAccount);
  const { route } = selectedMatch?.params || {};
  const { uuid } = selectedMatch?.params || { uuid: account.uuid };

  const { isLoading } = useGetMerchantQuery(uuid!, {
    skip: !uuid,
  });

  const userLevel = useSelector(selectUserLevel);
  const isSupport = userLevel === UserAccessLevel.Support;

  const tabs: TabItem[] = [
    {
      key: KEYS.TABS.GENERAL,
      label: t(LABELS.TABS.GENERAL),
      icon: <IconBuild />,
      onClick: () =>
        navigate(
          selectedMatch?.params?.uuid ? RoutePath.pwc.accountById(selectedMatch?.params?.uuid) : RoutePath.Pwc.AccountSettings.General,
        ),
    },
    ...(!isSupport
      ? [
          {
            key: KEYS.TABS.SETTLEMENT,
            label: t(LABELS.TABS.SETTLEMENT),
            icon: <IconRefundAlt />,
            onClick: () => navigate(RoutePath.Pwc.AccountSettings.Settlement),
          },
          {
            key: KEYS.TABS.API_KEYS,
            label: t(LABELS.TABS.API_KEYS),
            icon: <IconCrypto />,
            onClick: () => navigate(RoutePath.Pwc.AccountSettings.APIKeys),
          },
        ]
      : []),
    {
      key: KEYS.TABS.USER_MANAGEMENT,
      label: t(LABELS.TABS.USER_MANAGEMENT),
      icon: <IconProfile />,
      onClick: () => navigate(RoutePath.Pwc.AccountSettings.Users),
    },
  ];

  const selectedTab: TabItem | undefined = tabs.find((tab) => tab.key === route || tab.key === lastSegment);

  const breadcrumbsStart = uuid
    ? LABELS.S4C_USER_BREADCRUMBS.map((item) => t(item))
    : LABELS.PARTNER_USER_BREADCRUMBS.map((item) => t(item));

  const breadcrumbs = [...breadcrumbsStart, selectedTab?.label].filter(Boolean) as string[];

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <AccountSettings tabs={tabs} selected={selectedTab} />
    </PageWrapper>
  );
};
