import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { CopyMenuItem, NavigateMenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';

import RoutePath from 'router/path';

import { LABELS } from '../../keys';
import { PartnerAccountDataRow } from '../../types';

export const ActionCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();

  const { uuid } = row as PartnerAccountDataRow;

  return (
    <TableCell>
      <ActionMenu title={t(LABELS.ACTIONS.TITLE)}>
        <NavigateMenuItem to={RoutePath.accountById(uuid)}>{t(LABELS.ACTIONS.OPEN_DETAILS)}</NavigateMenuItem>
        <CopyMenuItem value={uuid}>{t(LABELS.ACTIONS.COPY_ACCOUNT_ID)}</CopyMenuItem>
      </ActionMenu>
    </TableCell>
  );
};
