import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M13.5 11.25V13.5H4.5V11.25H3V13.5C3 14.325 3.675 15 4.5 15H13.5C14.325 15 15 14.325 15 13.5V11.25H13.5ZM12.75',
  '8.25L11.6925 7.1925L9.75 9.1275V3H8.25V9.1275L6.3075 7.1925L5.25 8.25L9 12L12.75 8.25Z',
].join(' ');

export const IconDownload = ({ width = 18, height = 18, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d={path} />
  </Svg>
);

export default IconDownload;
