import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';

export const SettlementDetailsSection = ({ orderData }: OrderPwcSectionProps) => {
  const { t } = useTranslation();

  const { cryptoExchange } = orderData;

  return (
    <Panel label={t(LABELS.SECTIONS.SETTLEMENT_DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_STATUS)}</ItemName>
          <ItemValue>{orderData.cryptoExchange?.settlementStatus}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_PAYMENT_RAIL)}</ItemName>
          <ItemValue>{orderData.cryptoExchange?.settlementPaymentRail}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_CURRENCY)}</ItemName>
          <ItemValue>{orderData.cryptoExchange?.settlementCurrency?.toUpperCase()}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DESTINATION_WALLET)}</ItemName>
          <ItemValue>{cryptoExchange?.crypto?.wallet}</ItemValue>
        </Item>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_TRANSACTION_HASH)}</ItemName>
          <OnChainTransactionHash displayFullHash>
            <a href={cryptoExchange?.crypto?.onChain?.explorerUrl} target="_blank">
              {cryptoExchange?.crypto?.onChain?.transactionHash}
            </a>
          </OnChainTransactionHash>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
