import React from 'react';

export interface Props {
  visible?: boolean;
  fallback?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
}

export const OptionallyVisible = ({ visible, children, fallback = null }: Props) =>
  (visible && children ? children : fallback) as React.JSX.Element;

export default OptionallyVisible;
