import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import { CurrencyItem } from 'components/inputs/select/currencySelect/types';

import { AccountWidgetSettings } from 'types/account';

import { WidgetPreview } from '../components/widget/widget';
import { WidgetSettingForm } from './form/form';
import { LABELS } from './keys';
import { Controls, FormContainer, PanelContent } from './styles';

interface WidgetSectionProps {
  account: AccountWidgetSettings;
  initialValues: AccountWidgetSettings;
  isFormChanged: boolean;
  isSaveAvailable: boolean;
  resetFormValues: () => void;
  saveFormValues: () => void;
  onFormValueChange: (key: string, value: any) => void;
  disabledFields: Record<string, boolean>;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
  cryptoCurrencies: CurrencyItem[];
  fiatCurrencies: CurrencyItem[];
  previewOptions: {
    publicKey?: string;
    seed: string;
  };
}

export const WidgetSection = ({
  account,
  isSaveAvailable,
  isFormChanged,
  resetFormValues,
  onFormValueChange,
  saveFormValues,
  disabledFields,
  isUpdating,
  error,
  readOnlyMode,
  cryptoCurrencies,
  fiatCurrencies,
  initialValues,
  previewOptions,
}: WidgetSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel
      label={t(LABELS.PANEL_TITLE)}
      controls={
        readOnlyMode ? undefined : (
          <Controls>
            <OptionallyVisible visible={isFormChanged}>
              <Button onClick={resetFormValues} secondary disabled={isUpdating}>
                {t(LABELS.CANCEL_BUTTON_LABEL)}
              </Button>
            </OptionallyVisible>
            <Button onClick={saveFormValues} primary disabled={!isSaveAvailable || isUpdating}>
              {t(LABELS.SAVE_BUTTON_LABEL)}
            </Button>
          </Controls>
        )
      }
    >
      <PanelContent>
        <FormContainer>
          <WidgetSettingForm
            account={account}
            initialValues={initialValues}
            onFieldValueChange={onFormValueChange}
            disabledFields={disabledFields}
            isUpdating={isUpdating}
            error={error}
            readOnlyMode={readOnlyMode}
            cryptoCurrencies={cryptoCurrencies}
            fiatCurrencies={fiatCurrencies}
          />
        </FormContainer>
        <WidgetPreview params={account} {...previewOptions} />
      </PanelContent>
    </Panel>
  );
};
