import styled from '@emotion/styled/macro';

import { Path } from '../icons/styles';
import { ButtonVariant, ButtonVariantProps } from './types';

export const Text = styled.span((props) => ({
  fontFamily: props.theme.fonts.primary,
  fontSize: 13,
  fontWeight: 600,
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
}));

const disabledButton = (props: ButtonVariantProps) => {
  if (!props.disabled) {
    return {};
  }

  return {
    backgroundColor: props.theme.colors.container.secondary,
    border: `0.5px solid ${props.theme.colors.container.secondary}`,
    // @ts-ignore
    [Text]: {
      color: props.theme.colors.inactive,
    },
  };
};

const primaryButton = (props: ButtonVariantProps) =>
  props.disabled
    ? disabledButton(props)
    : {
        backgroundColor: props.disabled ? props.theme.colors.container.secondary : props.theme.colors.accent,
        border: `0.5px solid ${props.disabled ? props.theme.colors.container.secondary : props.theme.colors.accent}`,
        boxShadow: `0px 2px 5px 0px ${props.theme.colors.shadows['25']}`,
        // @ts-ignore
        [Text]: {
          color: props.disabled ? props.theme.colors.inactive : props.theme.colors.white,
        },
      };

const secondaryButton = (props: ButtonVariantProps) =>
  props.disabled
    ? disabledButton(props)
    : {
        backgroundColor: props.theme.colors.background,
        border: `0.5px solid ${props.theme.colors.container.tertiary}`,
        boxShadow: `0px 2px 5px 0px ${props.theme.colors.shadows['10']}`,
        // @ts-ignore
        [Text]: {
          color: props.theme.colors.accent,
        },
      };

const dangerButton = (props: ButtonVariantProps) =>
  props.disabled
    ? disabledButton(props)
    : {
        backgroundColor: props.disabled ? props.theme.colors.container.secondary : props.theme.colors.error.main,
        border: 0,
        // @ts-ignore
        [Text]: {
          color: props.disabled ? props.theme.colors.inactive : props.theme.colors.white,
        },
      };

const flatButton = (props: ButtonVariantProps) => {
  const style = {
    backgroundColor: props.theme.colors.transparent,
    border: 0,
    borderRadius: 5,
    padding: '4px 8px',
  };

  const textStyle = {
    // @ts-ignore
    [Text]: {
      color: props.theme.colors.primary,
    },
    // @ts-ignore
    [Path]: {
      fill: props.theme.colors.primary,
    },
  };

  const disabledTextStyle = {
    cursor: 'default',
    // @ts-ignore
    [Text]: {
      color: props.theme.colors.disabled,
    },
    // @ts-ignore
    [Path]: {
      fill: props.theme.colors.disabled,
    },
  };

  const hoverStyle = {
    '&: hover': {
      backgroundColor: props.theme.colors.highlight,
      // @ts-ignore
      [Text]: {
        color: props.theme.colors.darkAccent,
      },
      // @ts-ignore
      [Path]: {
        fill: props.theme.colors.darkAccent,
      },
    },
  };

  if (props.disabled) {
    return {
      ...style,
      ...disabledTextStyle,
    };
  }

  return {
    ...style,
    ...textStyle,
    ...hoverStyle,
  };
};

const buttonVariant = (props: ButtonVariantProps) => {
  if (props.variant === ButtonVariant.Secondary) {
    return secondaryButton(props);
  }

  if (props.variant === ButtonVariant.Flat) {
    return flatButton(props);
  }

  if (props.variant === ButtonVariant.Danger) {
    return dangerButton(props);
  }

  return primaryButton(props);
};

export const Root = styled.button<{ variant?: ButtonVariant; disabled?: boolean; small?: boolean }>(
  (props) => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 95,
    padding: props.small ? '4px 12px' : '8px 12px',
    cursor: 'pointer',
  }),
  disabledButton,
  buttonVariant,
);
