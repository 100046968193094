import { TypographyProps } from '@mui/material/Typography';
import React from 'react';

import { StyledMaterialTypography } from './typography.styles';

export const Typography = (props: TypographyProps) => {
  return <StyledMaterialTypography {...props} />;
};

export default Typography;
