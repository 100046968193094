import styled from '@emotion/styled';

export const VerifyContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: 16,
  fontSize: 13,
  textAlign: 'center',
}));

export const Heading = styled.div(() => ({
  width: 280,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
}));

export const TextBlock = styled.div((props) => ({
  gap: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: props.theme.colors.text.primary,
}));

export const BoldText = styled.div(() => ({
  fontWeight: 600,
}));
