import styled from '@emotion/styled/macro';

import { Button } from 'components/buttons';
import { Container as PanelContainer } from 'components/containers/panel/styles';

export const Container = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: 25,
  // @ts-ignore
  [PanelContainer]: {
    flex: 1,
  },
}));

export const ToSTimeContainer = styled.span(() => ({
  display: 'inline-flex',
  gap: 10,
  alignItems: 'center',
}));

export const KYCStatusContainer = styled.div(() => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));

export const RefreshButton = styled(Button)(() => ({
  padding: '4px 12px',
  marginLeft: 12,
  minWidth: 120,
}));

export const StatusButton = styled(Button)(() => ({
  padding: '4px 12px',
  minWidth: 120,
}));
