import styled from '@emotion/styled/macro';
import { Input, InputOwnProps } from '@mui/base';

import { InputType } from './input';

export const InputRoot = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ type: InputType }>((props) => ({
  width: props.fullWidth ? '100%' : 'auto',
  backgroundColor: props.theme.colors.container.primary,
  border: `1px solid ${props.theme.colors.container.primary}`,
  display: 'flex',
  color: props.theme.colors.primary,
  padding: '8px 12px',
  '&.Mui-focused, &.base--focused': props.readOnly
    ? {}
    : {
        border: `1px solid ${props.theme.colors.accent}`,
        backgroundColor: props.theme.colors.background,
        input: {
          backgroundColor: props.theme.colors.background,
          color: props.theme.colors.primary,
        },
        textarea: {
          backgroundColor: props.theme.colors.background,
          color: props.theme.colors.primary,
        },
      },
  borderRadius: 5,
  'input, textArea': {
    border: 0,
    flex: 1,
    backgroundColor: props.theme.colors.container.primary,
    fontSize: 13,
    padding: 0,
    lineHeight: '16px',
    fontFamily: props.theme.fonts.primary,
    color: props.theme.colors.primary,
    fontWeight: 500,
    outline: 'none',
    '&::placeholder': {
      color: props.theme.colors.inactive,
    },
  },
}));

export const MultilineInputRoot = styled(Input)<InputOwnProps & { type: InputType }>((props) => ({
  width: props.fullWidth ? '100%' : 'auto',
  backgroundColor: props.theme.colors.container.primary,
  border: `1px solid ${props.theme.colors.container.primary}`,
  display: 'flex',
  color: props.theme.colors.primary,
  padding: '8px 12px',
  '&.Mui-focused, &.base--focused': props.readOnly
    ? {}
    : {
        border: `1px solid ${props.theme.colors.accent}`,
        backgroundColor: props.theme.colors.background,
        input: {
          backgroundColor: props.theme.colors.background,
          color: props.theme.colors.primary,
        },
        textarea: {
          backgroundColor: props.theme.colors.background,
          color: props.theme.colors.primary,
        },
      },
  borderRadius: 5,
  'input, textArea': {
    resize: 'none',
    border: 0,
    flex: 1,
    backgroundColor: props.theme.colors.container.primary,
    fontSize: 13,
    padding: 0,
    lineHeight: '16px',
    fontFamily: props.theme.fonts.primary,
    color: props.theme.colors.primary,
    fontWeight: 500,
    outline: 'none',
    '&::placeholder': {
      color: props.theme.colors.inactive,
    },
  },
}));
