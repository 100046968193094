export const LABELS = {
  CREATE_MODAL: {
    TITLE: ['settlement.createAccountModal.title', 'Add Bank Account'],
    ACCOUNT_TYPE: {
      INDIVIDUAL: ['settlement.createAccountModal.header.individualTitle', 'Individual'],
      BUSINESS: ['settlement.createAccountModal.header.businessTitle', 'Business'],
    },
    BUTTONS: {
      CREATE: ['settlement.createAccountModal.buttons.create', 'Add'],
      CANCEL: ['settlement.createAccountModal.buttons.cancel', 'Cancel'],
      CLOSE: ['settlement.createAccountModal.buttons.close', 'Close'],
    },
    SECTIONS: {
      ACCOUNT_TYPE: {
        TITLE: ['settlement.createAccountModal.sections.accountTypeSection.title', 'Account Owner Type'],
      },
      CURRENCY_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.currencySection.title', 'Currency'],
      },
      BANK_NAME_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.bankNameSection.title', 'Bank Name'],
      },
      ACCOUNT_OWNER_NAME_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.accountOwnerSection.title', 'Account Owner Name'],
      },
      ACCOUNT_NUMBER_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.accountNumberSection.title', 'Account Number'],
      },
      ROUTING_NUMBER_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.routingNumberSection.title', 'Routing Number'],
      },
      BUSINESS_NAME_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.businessNameSection.title', 'Business Name'],
      },
      ADDRESS_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.addressSection.title', 'Address'],
      },
      FIRST_NAME_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.firstNameSection.title', 'First Name'],
      },
      LAST_NAME_SECTION: {
        TITLE: ['settlement.createAccountModal.sections.lastNameSection.title', 'Last Name'],
      },
    },
    FORM_FIELDS: {
      OPTIONAL: ['settlement.createAccountModal.form.optional', 'Optional'],
      PLACEHOLDERS: {
        BUSINESS_NAME: ['settlement.createAccountModal.form.businessName.placeholder', 'Enter Business Name'],
        BANK_NAME: ['settlement.createAccountModal.form.bankName.placeholder', 'Enter Bank Name'],
        ACCOUNT_OWNER_NAME: ['settlement.createAccountModal.form.accountOwnerName.placeholder', 'Enter Account Owner Name'],
        ACCOUNT_NUMBER: ['settlement.createAccountModal.form.accountNumber.placeholder', '0000 0000 0000'],
        ROUTING_NUMBER: ['settlement.createAccountModal.form.routingNumber.placeholder', '000 000 000'],
        STREET_LINE_1: ['settlement.createAccountModal.form.streetLine1.placeholder', 'Street Line 1'],
        STREET_LINE_2: ['settlement.createAccountModal.form.streetLine2.placeholder', 'Street Line 2'],
        CITY: ['settlement.createAccountModal.form.city.placeholder', 'City'],
        STATE: ['settlement.createAccountModal.form.state.placeholder', 'State'],
        POSTAL_CODE: ['settlement.createAccountModal.form.postalCode.placeholder', 'Postal Code'],
        COUNTRY: ['settlement.createAccountModal.form.country.placeholder', 'Country'],
        FIRST_NAME: ['settlement.createAccountModal.form.firstName.placeholder', 'Enter First Name'],
        LAST_NAME: ['settlement.createAccountModal.form.lastName.placeholder', 'Enter Last Name'],
      },
    },
  },
};

const FORM_FIELDS = {
  BUSINESS_NAME: 'businessName',
  BANK_NAME: 'bankName',
  ACCOUNT_NUMBER: 'accountNumber',
  ROUTING_NUMBER: 'routingNumber',
  ACCOUNT_OWNER_NAME: 'accountOwnerName',
  STREET_LINE_1: 'streetLine1',
  STREET_LINE_2: 'streetLine2',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postalCode',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
};

export const KEYS = {
  MASK_ACCOUNT_NUMBER: '9999 9999 9999',
  MASK_ROUTING_NUMBER: '999 999 999',
  HARDCODED_CURRENCY: 'usd',
  DEFAULT_ERROR_LOCATION: 'address.country',
  FORM_FIELDS,
  OPTIONAL_BOTH: [FORM_FIELDS.STREET_LINE_2, FORM_FIELDS.STATE],
  OPTIONAL_INDIVIDUAL: [FORM_FIELDS.BUSINESS_NAME],
  OPTIONAL_BUSINESS: [FORM_FIELDS.FIRST_NAME, FORM_FIELDS.LAST_NAME],
};
