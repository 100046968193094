export const KEYS = {
  DATE_FORMAT: 'D MMM YYYY, h:mm A',
  BASE_CURRENCY_UNIT: 1,
  APPROX_EQUAL_SYMBOL: '≈',
  DASH: '–',
};

export const LABELS = {
  BREADCRUMBS: [
    ['page.pwcOrders.view.breadcrumbs.orders', 'Orders'],
    ['page.pwcOrders.view.breadcrumbs.orderDetails', 'Order details'],
  ],
  PAGE_TITLE: ['page.pwcOrders.view.title', 'Order details'],
  SECTIONS: {
    ORDER: {
      TITLE: ['page.pwcOrders.view.sections.order.title', 'Order'],
      ORDER_ID: ['page.pwcOrders.view.sections.order.orderId', 'Order ID'],
      EXTERNAL_ORDER_ID: ['page.pwcOrders.view.sections.order.externalOrderId', 'External Order ID'],
      MERCHANT_NAME: ['page.pwcOrders.view.sections.order.merchantName', 'Merchant Name'],
      CREATION_TIME: ['page.pwcOrders.view.sections.order.creationTime', 'Creation Time'],
      EXPIRATION_TIME: ['page.pwcOrders.view.sections.order.expirationTime', 'Expiration Time'],
      DATE: ['page.pwcOrders.view.sections.order.date', 'Date'],
      STATUS: ['page.pwcOrders.view.sections.order.status', 'Status'],
      CRYPTO_AMOUNT: ['page.pwcOrders.view.sections.order.cryptoAmount', 'Crypto Amount'],
      FIAT_AMOUNT: ['page.pwcOrders.view.sections.order.fiatAmount', 'Fiat Amount'],
      PROCESSING_FEE_AMOUNT: ['page.pwcOrders.view.sections.order.processingFeeAmount', 'Processing Fee Amount'],
      PROCESSING_FEE_PERCENT: ['page.pwcOrders.view.sections.order.processingFeePercent', 'Processing Fee %'],
      NETWORK_FEE: ['page.pwcOrders.view.sections.order.networkFee', 'Network Fee'],
      CRYPTO_PRICE: ['page.pwcOrders.view.sections.order.cryptoPrice', 'Crypto Price'],
      ITEMS: ['page.pwcOrders.view.sections.order.items', 'Items'],
      REDIRECT_URL: ['page.pwcOrders.view.sections.order.redirectUrl', 'Redirect URL'],
    },
    EXCHANGE_DETAILS: {
      TITLE: ['page.pwcOrders.view.sections.exchangeDetails.title', 'Crypto Exchange Details'],
      LIQUIDITY_PROVIDER: ['page.pwcOrders.view.sections.exchangeDetails.liquidityProvider', 'Liquidity Provider'],
      DEPOSIT_ADDRESS: ['page.pwcOrders.view.sections.exchangeDetails.depositAddress', 'Deposit Address'],
      DEPOSIT_MEMO: ['page.pwcOrders.view.sections.exchangeDetails.depositMemo', 'Deposit Memo'],
      DEPOSIT_RECEIVED_TIME: ['page.pwcOrders.view.sections.exchangeDetails.depositReceivedTime', 'Deposit Received Time'],
      SETTLEMENT_STATUS: ['page.pwcOrders.view.sections.exchangeDetails.settlementStatus', 'Settlement Status'],
      SETTLEMENT_PAYMENT_RAIL: ['page.pwcOrders.view.sections.exchangeDetails.settlementPaymentRail', 'Settlement Payment Rail'],
      SETTLEMENT_CURRENCY: ['page.pwcOrders.view.sections.exchangeDetails.settlementCurrency', 'Settlement Currency'],
      BANK_ACCOUNT_NAME: ['page.pwcOrders.view.sections.exchangeDetails.bankAccountName', 'Bank Account Name'],
      DESTINATION_WALLET: ['page.pwcOrders.view.sections.exchangeDetails.destinationWallet', 'Destination Wallet'],
      SETTLEMENT_TRANSACTION_HASH: [
        'page.pwcOrders.view.sections.exchangeDetails.settlementTransactionHash',
        'Settlement Transaction Hash',
      ],
    },
    SETTLEMENT_DETAILS: {
      TITLE: ['page.pwcOrders.view.sections.settlementDetails.title', 'Crypto Settlement Details'],
      SETTLEMENT_STATUS: ['page.pwcOrders.view.sections.settlementDetails.settlementStatus', 'Settlement Status'],
      SETTLEMENT_PAYMENT_RAIL: ['page.pwcOrders.view.sections.settlementDetails.settlementPaymentRail', 'Settlement Payment Rail'],
      SETTLEMENT_CURRENCY: ['page.pwcOrders.view.sections.settlementDetails.settlementCurrency', 'Settlement Currency'],
      DESTINATION_WALLET: ['page.pwcOrders.view.sections.settlementDetails.destinationWallet', 'Destination Wallet'],
      SETTLEMENT_TRANSACTION_HASH: [
        'page.pwcOrders.view.sections.settlementDetails.settlementTransactionHash',
        'Settlement Transaction Hash',
      ],
    },
    CUSTOMER: {
      TITLE: ['page.pwcOrders.view.sections.customer.title', 'Customer'],
      CUSTOMER_EMAIL: ['page.pwcOrders.view.sections.customer.userEmail', 'Customer Email'],
      CUSTOMER_ID: ['page.pwcOrders.view.sections.customer.userId', 'Customer ID'],
      EXTERNAL_CUSTOMER_ID: ['page.pwcOrders.view.sections.customer.externalUserId', 'External Customer ID'],
    },
    PAYMENT: {
      TITLE: ['page.pwcOrders.view.sections.payment.title', 'Crypto Payment Details'],
      CRYPTOCURRENCY: ['page.pwcOrders.view.sections.payment.cryptocurrency', 'Cryptocurrency'],
      NETWORK: ['page.pwcOrders.view.sections.payment.network', 'Network'],
      PAYMENT_PROVIDER: ['page.pwcOrders.view.sections.payment.paymentProvider', 'Payment Provider'],
      PAYMENT_PROVIDER_ID: ['page.pwcOrders.view.sections.payment.paymentProviderId', 'Provider Txn ID'],
      PAYMENT_CONNECTION_TYPE: ['page.pwcOrders.view.sections.payment.paymentProviderConnectionType', 'Payment Connection Type'],
      PAYMENT_CONNECTION_NAME: ['page.pwcOrders.view.sections.payment.paymentProviderConnectionName', 'Payment Connection Name'],
      PAYMENT_STATUS: ['page.pwcOrders.view.sections.payment.paymentProviderStatus', 'Payment Status'],
      EXECUTED_TIME: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt', 'Executed Time'],
      DEPOSIT_ADDRESS: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt.walletAddress', 'From Wallet Address'],
      DEPOSIT_MEMO: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt.walletMemo', 'From Wallet Memo'],
      TRANSACTION_HASH: ['page.pwcOrders.view.sections.payment.transactionHash', 'Transaction Hash'],
    },
    TOP_AREA: {
      ORDER_ID: ['page.pwcOrders.view.sections.statusBar.orderId', 'Order ID'],
      ORDER_TOTAL: ['page.pwcOrders.view.sections.statusBar.orderTotal', 'Order total'],
      STATUS: ['page.pwcOrders.view.sections.statusBar.status', 'Status'],
      TYPE: ['page.pwcOrders.view.sections.statusBar.type', 'Type'],
    },
  },
};
