import React from 'react';

import OptionallyVisible from 'components/optionallyVisible';

import { CustomerSection } from './sections/customer/customer';
import { ExchangeDetailsSection } from './sections/exchangeDetails/exchangeDetails';
import { OrderSection } from './sections/order/order';
import { PaymentSection } from './sections/payment/payment';
import { SettlementDetailsSection } from './sections/settlementDetails/settlementDetails';
import { TopAreaSection } from './sections/topArea/topArea';
import { Container, SubContainer } from './styles';
import { OrderPwcData } from './types';

export interface OrderDetailsProps {
  orderPwcData: OrderPwcData;
  shouldShowExchangeDetailsSection: boolean;
  shouldShowPaymentDetailsSection: boolean;
  hasExtendedAccess: boolean;
  hasExtendedDetails: boolean;
  shouldShowSettlementDetailsSection: boolean;
  shouldShowPaymentSectionAdditionalFields: boolean;
  isS4cUser: boolean;
}

export const OrderDetails = ({
  orderPwcData,
  shouldShowExchangeDetailsSection,
  hasExtendedAccess,
  hasExtendedDetails,
  shouldShowSettlementDetailsSection,
  shouldShowPaymentDetailsSection,
  shouldShowPaymentSectionAdditionalFields,
  isS4cUser,
}: OrderDetailsProps) => {
  const sectionsProps = {
    orderData: orderPwcData,
  };

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <OrderSection {...sectionsProps} isS4cUser={isS4cUser} />

          <OptionallyVisible visible={shouldShowExchangeDetailsSection}>
            <ExchangeDetailsSection hasExtendedDetails={hasExtendedDetails} {...sectionsProps} />
          </OptionallyVisible>

          <OptionallyVisible visible={shouldShowSettlementDetailsSection}>
            <SettlementDetailsSection {...sectionsProps} />
          </OptionallyVisible>
        </SubContainer>

        <SubContainer>
          <CustomerSection {...sectionsProps} showCustomerLink={hasExtendedAccess} />
          <OptionallyVisible visible={shouldShowPaymentDetailsSection}>
            <PaymentSection {...sectionsProps} shouldShowPaymentSectionAdditionalFields={shouldShowPaymentSectionAdditionalFields} />
          </OptionallyVisible>
        </SubContainer>
      </Container>
    </>
  );
};
