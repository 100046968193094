import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'm9.146 4.181.423-.422A4.507 4.507 0 0 0 6 1.999 4.506 4.506',
    '0 0 0 1.5 6.5C1.5 8.982 3.519 11 6 11c2.225 0 4.14-1.658 4.454-3.856a.75.75',
    '0 0 1 1.485.212c-.42 2.933-2.973 5.144-5.94 5.144C2.693 12.5 0 9.809 0 6.5c0-3.308',
    '2.692-6 6-6 1.801 0 3.501.811 4.636 2.192l.552-.552c.3-.3.812-.087.812.337v1.915c0',
    '.332-.27.602-.602.602H9.483a.476.476 0 0 1-.337-.813ZM6 10.5a.75.75 0 0 0 .75-.75v-.335A1.797',
    '1.797 0 0 0 8 7.707c0-.739-.442-1.392-1.127-1.665l-1.189-.476A.294.294 0 0 1 5.794 5h.59c.09 0',
    '.171.047.217.125a.75.75 0 0 0 1.299-.75 1.755 1.755 0 0 0-1.15-.836V3.25a.75.75 0 1 0-1.5 0v.334c-.723.231-1.25.91-1.25',
    '1.71 0 .738.443 1.391 1.128 1.665l1.188.475a.294.294 0 0 1-.11.566h-.59a.248.248 0 0 1-.217-.125.75.75 0',
    '0 0-1.298.75c.25.432.671.733 1.149.836v.289c0 .414.335.75.75.75Z',
  ].join(' '),
  ['M0 0h12v12H0z'].join(' '),
];

export const IconReconciliations = ({ width = 14, height = 15, color, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <Path d={path[0]} fill={color} />
    </g>
    <defs>
      <clipPath id="a">
        <Path d={path[1]} fill={color} transform="translate(0 .5)" />
      </clipPath>
    </defs>
  </Svg>
);

export default IconReconciliations;
