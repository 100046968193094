import React, { ReactNode, useEffect, useState } from 'react';

import IconChevron from '../../icons/chevron';
import { Checkbox } from '../../inputs/checkbox/checkbox';
import OptionallyVisible from '../../optionallyVisible';
import { ExpandButton, ItemContainer, ItemContent, ItemLabel, ItemLabelRow } from './styles';

interface FilterItemProps {
  selected: boolean;
  label: string;
  children: null | ReactNode | ReactNode[];
  onSelect: () => void;
}

export const FilterItem = ({ selected, label, children, onSelect }: FilterItemProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(selected);
  }, [selected]);

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <ItemContainer>
        <ItemLabelRow>
          <Checkbox value={selected} onChange={onSelect} />
          <ItemLabel>{label}</ItemLabel>
          <OptionallyVisible visible={selected}>
            <ExpandButton open={isExpanded} flat onClick={toggleExpand}>
              <IconChevron />
            </ExpandButton>
          </OptionallyVisible>
        </ItemLabelRow>
      </ItemContainer>
      <ItemContent expanded={isExpanded}>{children}</ItemContent>
    </>
  );
};
