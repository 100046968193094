import { t } from 'i18next';

import { BadgeType } from 'components/elements/badge/types';

import { CustomerKYCStatus } from 'types/customer';

export const LABELS = {
  KYC_STATUS: {
    APPROVED: ['page.customers.list.table.kycStatus.approved', 'Approved'],
    REJECTED: ['page.customers.list.table.kycStatus.rejected', 'Rejected'],
    UNKNOWN: ['page.customers.list.table.kycStatus.unknown', ''],
    PENDING_APPROVAL: ['page.customers.list.table.kycStatus.pendingApproval', 'Pending Approval'],
    SUBMITTED: ['page.customers.list.table.kycStatus.submitted', 'Submitted'],
  },
};

export const badgePropsMap: Record<string, { type: BadgeType; label: string }> = {
  [CustomerKYCStatus.Approved]: {
    type: BadgeType.Success,
    label: t(LABELS.KYC_STATUS.APPROVED),
  },
  [CustomerKYCStatus.Rejected]: {
    type: BadgeType.Fail,
    label: t(LABELS.KYC_STATUS.REJECTED),
  },
  [CustomerKYCStatus.PendingApproval]: {
    type: BadgeType.Default,
    label: t(LABELS.KYC_STATUS.PENDING_APPROVAL),
  },
  [CustomerKYCStatus.Submitted]: {
    type: BadgeType.Default,
    label: t(LABELS.KYC_STATUS.SUBMITTED),
  },
  default: {
    type: BadgeType.Default,
    label: t(LABELS.KYC_STATUS.UNKNOWN),
  },
};
