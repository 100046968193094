/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import apiKeysApi from 'services/apiKeys';

import { ApiKeys } from 'types/apiKeys';

interface ApiKeysState {
  apiKeys: ApiKeys | null;
}
const SLICE_NAME = 'apiKeys';

const initialState: ApiKeysState = {
  apiKeys: null,
};

export const apiKeysSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(apiKeysApi.endpoints?.getAccountApiKeys.matchFulfilled, (state, { payload }) => {
      state.apiKeys = payload || null;
    });
  },
});

export default apiKeysSlice.reducer;
