import React, { useEffect, useState } from 'react';

import IconChecked from '../../icons/checked';
import OptionallyVisible from '../../optionallyVisible';
import { Container } from './styles';

interface SwitchProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

export const RadioButton = ({ onChange, value, disabled }: SwitchProps) => {
  const [checked, setChecked] = useState<boolean>(Boolean(value));

  useEffect(() => {
    setChecked(Boolean(value));
  }, [value]);

  const handleSwitch = () => {
    if (disabled) {
      return;
    }

    const nextValue = !value;
    setChecked(nextValue);
    onChange?.(nextValue);
  };

  return (
    <Container onClick={handleSwitch} checked={checked} disabled={disabled}>
      <OptionallyVisible visible={checked}>
        <IconChecked width={14} height={14} />
      </OptionallyVisible>
    </Container>
  );
};
