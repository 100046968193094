import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components/elements/badge/badge';

import { OrderTradeStatus } from 'types/order';

import { orderTradeStatusLabelByStatus, tradeStatusToBadgeTypeMap } from './keys';

interface OrderTradeStatusBadgeProps {
  status: OrderTradeStatus;
}

export const OrderTradeStatusBadge = ({ status }: OrderTradeStatusBadgeProps) => {
  const { t } = useTranslation();

  const orderStatusBadgeType = tradeStatusToBadgeTypeMap[status];
  const orderStatusLabel = t(orderTradeStatusLabelByStatus[status]);

  return <Badge type={orderStatusBadgeType}>{orderStatusLabel}</Badge>;
};
