import styled from '@emotion/styled/macro';

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 25px',
}));

export const Row = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '9.5px 0',
}));

export const Preset = styled.div(() => ({
  display: 'flex',
  gap: 10,
  padding: '9.5px 0',
}));

export const Label = styled.span((props) => ({
  margin: 0,
  fontSize: 12,
  color: props.theme.colors.primary,
}));
