import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from 'services/profile';

import { getServerSideValidationDetails } from 'utils/error';

import { KEYS, LABELS } from '../../keys';
import { ChangePasswordFormFields } from './types';

export const useChangePasswordModal = () => {
  const { t } = useTranslation();

  const [passwordFields, setPasswordFields] = useState<ChangePasswordFormFields | null>(null);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [changePassword, { isLoading: isSubmitting, error: changePasswordError }] = useChangePasswordMutation();
  const { logout } = useAuth0();

  const [hasPasswordValidationError, setHasPasswordValidationError] = useState<boolean>(false);

  const handleChangePasswordModalOpen = () => {
    setPasswordFields({
      newPassword: '',
      newPasswordRepeat: '',
    });
    setChangePasswordModalOpen(true);
  };

  const handleChangePasswordModalClose = () => {
    setPasswordFields(null);
    setChangePasswordModalOpen(false);
  };

  const handleFinishPasswordChange = () => {
    handleChangePasswordModalClose();
    logout();
  };

  const validatePassword = (formValues: ChangePasswordFormFields) => formValues.newPassword === formValues.newPasswordRepeat;

  const handleChangePassword = async (formValues: ChangePasswordFormFields) => {
    if (!validatePassword(formValues)) {
      setHasPasswordValidationError(true);
      return false;
    }

    setHasPasswordValidationError(false);

    const result = await changePassword({ newPassword: formValues.newPassword });

    return 'data' in result;
  };

  const getPasswordValidationMessage = () => ({
    [KEYS.FORM_FIELDS.NEW_PASSWORD]: t(LABELS.CHANGE_PASSWORD_MODAL.VALIDATION_MESSAGES[KEYS.FORM_FIELDS.NEW_PASSWORD]),
  });

  const changePasswordErrorMessage = hasPasswordValidationError
    ? getPasswordValidationMessage()
    : getServerSideValidationDetails(changePasswordError, KEYS.FORM_FIELDS.NEW_PASSWORD);

  return {
    handleChangePasswordModalOpen,
    passwordFields,
    modalProps: {
      open: isChangePasswordModalOpen,
      onClose: handleChangePasswordModalClose,
      onFinish: handleFinishPasswordChange,
      passwordFields: passwordFields!,
      onChangePassword: handleChangePassword,
      isSubmitting,
      error: changePasswordErrorMessage,
    },
  };
};
