import { User as Auth0User } from '@auth0/auth0-react';
import { JwtPayload } from 'jwt-decode';

export enum UserRoleType {
  SuperAdmin = 'super-admin',
  Admin = 'admin',
  Support = 'support',
  Partner = 'partner',
}

export enum UserAccessLevel {
  SuperAdmin = 8,
  Admin = 7,
  Support = 6,
  Partner = 5,
}

export interface UserAccessTokenInfo extends JwtPayload {
  s4corRoles: UserRoleType[];
}

export interface UserRecord {
  uuid: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  signupAt: string;
  roles: UserRoleType[];
  defaultAccountUuid?: string;
}

export type UserIdTokenInfo = Auth0User;

export type UserData = UserAccessTokenInfo & UserIdTokenInfo;
