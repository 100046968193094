import styled from '@emotion/styled';

import { Root as ButtonRoot } from 'components/buttons/styles';
import { Path } from 'components/icons/styles';
import { InputRoot } from 'components/inputs/input/styles';

export const ModalRoot = styled.div((props) => ({
  outline: 'none',
}));

export const ModalControls = styled.div((props) => ({
  // @ts-ignore
  [ButtonRoot]: {
    minWidth: 'unset',
    width: 20,
    height: 20,
    padding: 4,
    span: {
      lineHeight: 0.5,
    },
  },
}));

export const PanelContent = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  padding: '0 12px 12px 12px',
}));

export const PasswordSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  // @ts-ignore
  [InputRoot]: {
    width: 290,
  },
}));

export const RequirementsSection = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 440,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: 13,
}));

export const FirstRequirementsList = styled.ul((props) => ({
  margin: 0,
  padding: 0,
  paddingLeft: 20,
}));

export const SecondRequirementsList = styled.ul((props) => ({
  margin: 0,
  padding: 0,
  paddingLeft: 40,
}));

export const RequirementsListItem = styled.li((props) => ({
  paddingTop: 6,
}));
export const Label = styled.span((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: props.theme.fonts.primary,
}));

export const Buttons = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
}));

export const Adornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));

export const Delimiter = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.container.primary}`,
  width: '100%',
}));

export const SuccessContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: 16,
  fontSize: 13,
  textAlign: 'center',
}));

export const Heading = styled.div(() => ({
  width: 421,
  fontSize: 16,
  fontWeight: 600,
}));

export const TextBlock = styled.div(() => ({
  textAlign: 'center',
}));

export const PasswordChangedImg = styled.img((props) => ({
  width: 113,
  height: 72,
}));
