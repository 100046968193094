/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { UserData } from 'types/user';

import { processUserInfo } from '../thunks/user/processUserInfo.thunk';

interface UserState {
  userLevel: number | null;
  userData: UserData | null;
  authToken: string | null;
  authError?: string;
}
const SLICE_NAME = 'user';

const initialState: UserState = {
  userLevel: null,
  userData: null,
  authToken: null,
};

export const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUserLevel: (state, action: PayloadAction<number>) => {
      state.userLevel = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.authToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(processUserInfo.rejected, (state, action) => {
      state.authError = action.error.message;
    });
  },
});

export const { setUserLevel, setUserData, setAuthToken } = userSlice.actions;

export const selectUserLevel = (state: RootState) => state.user.userLevel;
export const selectAuthError = (state: RootState) => state.user.authError;
export const selectUserRole = (state: RootState) => state.user.userData?.s4corRoles[0];

export default userSlice.reducer;
