import { OptionallyVisible } from 'components';
import React, { useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { TablePagination } from 'types/pagination';

import { customCellComponentFactory } from './components/cells';
import { MissingAccountsWarnings } from './components/missingAccountsWarning';
import { RevokeAccountAccessModal } from './components/revokeAccountAccessModal';
import { COLUMNS, LABELS } from './keys';
import { DataTableContainer } from './styles';
import { AccountDataRow } from './types';

interface AccountsSectionComponentProps extends WithTranslation {
  accounts: AccountDataRow[];
  isLoading: boolean;
  pagination: TablePagination;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (itemsPerPage: number) => void;
  setAccountAsDefault: (accountId: string) => void;
  revokeAccessToAccount: (accountId: string) => void;
}

const AccountsSectionComponent = ({
  accounts,
  isLoading,
  pagination,
  onChangePage,
  onChangeRowsPerPage,
  setAccountAsDefault,
  revokeAccessToAccount,
  t,
}: AccountsSectionComponentProps) => {
  const [selectedAccount, setSelectedAccount] = useState<AccountDataRow | null>(null);
  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] = useState(false);

  const hasAccounts = Boolean(accounts.length);

  const findAccount = (uuid: string) => {
    if (!uuid) {
      return null;
    }

    return accounts.find((account) => account.uuid === uuid) || null;
  };

  const openRevokeAccessModal = (uuid: string) => {
    const user = findAccount(uuid);
    if (user) {
      setSelectedAccount(user);
      setIsRevokeAccessModalOpen(true);
    }
  };

  const closeRevokeAccessModal = () => {
    setSelectedAccount(null);
    setIsRevokeAccessModalOpen(false);
  };

  const CustomCellComponent = useMemo(
    () => customCellComponentFactory({ setAccountAsDefault, revokeAccessToAccount: openRevokeAccessModal }),
    [setAccountAsDefault, openRevokeAccessModal],
  );

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <OptionallyVisible visible={isLoading}>
          <LoadingIndicator padded />
        </OptionallyVisible>
      </Panel>
    );
  }

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={hasAccounts}>
        <DataTableContainer>
          <DataTable
            columns={COLUMNS}
            rows={accounts}
            CellComponent={CustomCellComponent}
            pagination={pagination}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </DataTableContainer>
      </OptionallyVisible>

      <OptionallyVisible visible={!hasAccounts}>
        <MissingAccountsWarnings />
      </OptionallyVisible>

      <RevokeAccountAccessModal
        isOpen={isRevokeAccessModalOpen}
        account={selectedAccount}
        onClose={closeRevokeAccessModal}
        onRevokeAccess={revokeAccessToAccount}
      />
    </Panel>
  );
};

export const AccountsSection = withTranslation()(AccountsSectionComponent);
