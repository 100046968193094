import { t } from 'i18next';

import { BadgeType } from 'components/elements/badge/types';

import { CustomerStatus } from 'types/customer';

export const LABELS = {
  STATUS: {
    ACTIVE: ['page.customers.list.table.status.active', 'Active'],
    BLOCKED: ['page.customers.list.table.status.blocked', 'Blocked'],
    TERMINATED: ['page.customers.list.table.status.terminated', 'Terminated'],
  },
};

export const badgePropsMap: Record<string, { type: BadgeType; label: string }> = {
  [CustomerStatus.Active]: {
    type: BadgeType.Success,
    label: t(LABELS.STATUS.ACTIVE),
  },
  [CustomerStatus.Blocked]: {
    type: BadgeType.Fail,
    label: t(LABELS.STATUS.BLOCKED),
  },
  [CustomerStatus.Terminated]: {
    type: BadgeType.Fail,
    label: t(LABELS.STATUS.TERMINATED),
  },
};
