import { AppState, Auth0Provider } from '@auth0/auth0-react';
import AUTH0_CONFIG from 'constants/auth0';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserControlContainer } from './userControl.container';

export const Auth0ProviderWithRedirectCallback: React.FC<PropsWithChildren> = ({ children }) => {
  const domain = AUTH0_CONFIG.AUTH0_DOMAIN;
  const clientId = AUTH0_CONFIG.AUTH0_CLIENT_ID;
  const redirectUri = `${window.location.origin}/`;
  const audience = AUTH0_CONFIG.AUTH0_AUDIENCE;
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    const redirectUrl = appState?.returnTo || window.location.pathname;
    navigate(redirectUrl);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <UserControlContainer>{children}</UserControlContainer>
    </Auth0Provider>
  );
};
