import { ProviderContext, SnackbarKey, SnackbarOrigin, SnackbarProvider } from 'notistack';
import React, { useRef } from 'react';

import IconCross from 'components/icons/cross';

import { DialogNotifications } from './dialogNotifications';
import { StyledToast, ToastCloseButton } from './styles';
import ToastNotificationController from './toastNotificationController';

export const NotificationProvider = () => {
  const notistackRef = useRef<ProviderContext>();

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const action = (key: SnackbarKey) => (
    <ToastCloseButton flat onClick={() => notistackRef.current?.closeSnackbar(key)}>
      <IconCross />
    </ToastCloseButton>
  );

  return (
    <SnackbarProvider
      Components={{
        error: StyledToast,
      }}
      maxSnack={10}
      dense
      anchorOrigin={anchorOrigin}
      ref={notistackRef as React.Ref<SnackbarProvider>}
      action={action}
    >
      <ToastNotificationController />
      <DialogNotifications />
    </SnackbarProvider>
  );
};

export default NotificationProvider;
