import styled from '@emotion/styled/macro';

import { Path } from 'components/icons/styles';

export const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 8,
  gap: 17,
  minHeight: 37,
  borderRadius: 5,
  border: `0.5px solid ${props.theme.colors.container.tertiary}`,
  backgroundColor: props.theme.colors.background,
  alignItems: 'center',
}));

export const Label = styled.span((props) => ({
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
  fontWeight: 500,
  fontSize: 14,
}));

export const Tab = styled.div<{ selected: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 8px',
  borderRadius: 5,
  gap: 5,
  cursor: 'pointer',
  backgroundColor: props.selected ? props.theme.colors.highlight : props.theme.colors.transparent,
  // @ts-ignore
  [Label]: {
    color: props.selected ? props.theme.colors.accent : props.theme.colors.inactive,
  },
  // @ts-ignore
  [Path]: {
    fill: props.selected ? props.theme.colors.accent : props.theme.colors.inactive,
  },
}));

export const Icon = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));
