import styled from '@emotion/styled/macro';

import { InputRoot } from '../../inputs/input/styles';

export const Inputs = styled.div(() => ({
  display: 'flex',
  gap: 10,
  padding: '9.5px 0',
  // @ts-ignore
  [InputRoot]: {
    minWidth: 0,
    flex: 1,
    input: {
      minWidth: 0,
    },
  },
}));
