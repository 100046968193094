import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import selectAccount from 'state/selectors/accounts/accountSelector';

export const useRedirectOnAccountSwitch = (redirectPath: string) => {
  const { account } = useSelector(selectAccount);
  const [currentAccount] = useState<string>(account?.uuid);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAccount !== account.uuid) {
      navigate(redirectPath);
    }
  }, [account]);
};
