import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetProfileQuery, useUpdateProfileMutation } from 'services/profile';

import { selectProfile } from 'state/selectors/profile/profileSelector';

import { UserRecord } from 'types/user';

import { getServerSideValidationDetails } from 'utils/error';

import { KEYS } from './keys';
import { UserInfoSection } from './userInfo';

export const UserInfoSectionContainer = () => {
  const { profile } = useSelector(selectProfile);

  const { isLoading, isUninitialized } = useGetProfileQuery();
  const [updateProfile, { isLoading: isUpdating, error: updateProfileError }] = useUpdateProfileMutation();

  const [values, setValues] = useState<UserRecord>(profile);

  useEffect(() => {
    setValues(profile);
  }, [profile]);

  const handleFormValueChange = (key: string, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const resetFormValues = () => {
    setValues(profile);
  };

  const saveFormValues = () => {
    const preparedValues = {
      firstName: values.firstName,
      lastName: values.lastName,
    };
    updateProfile(preparedValues);
  };

  const isFormChanged = JSON.stringify(values) !== JSON.stringify(profile);
  const isProfileInfoPending = isLoading || isUninitialized;

  const updateProfileErrorMessage = getServerSideValidationDetails(updateProfileError, KEYS.FORM_FIELDS.USER_ID);

  return (
    <UserInfoSection
      profile={values}
      isProfileInfoPending={isProfileInfoPending}
      isFormChanged={isFormChanged}
      isUpdating={isUpdating}
      saveFormValues={saveFormValues}
      resetFormValues={resetFormValues}
      onFormValueChange={handleFormValueChange}
      formError={updateProfileErrorMessage}
    />
  );
};
