import i18next from 'locales/i18next';

import { UserRoleType } from 'types/user';

import { LABELS, s4cUserRoleToLabelMap } from './keys';
import { AccessTableRow } from './types';

const { t } = i18next;

export const transformRoleDataToTableFormat = (role: UserRoleType): AccessTableRow[] => {
  return [
    {
      id: t(LABELS.NAME_LABEL),
      name: t(LABELS.NAME_LABEL),
      roles: s4cUserRoleToLabelMap[role]!,
    },
  ];
};
