import { t } from 'i18next';

import { FormFieldType } from 'components/form/formLayout/types';

import { AccountEmbeddableThemeMode, AccountLandingPageSettings } from 'types/account';

import { SettingsFormField } from '../../types';
import { ThemeModeItem } from './types';

export const KEYS = {
  MAX_LOGO_SIZE: 1024 * 1024,
  UUID: 'uuid',
};

export const LABELS = {
  SEARCH_RESULTS: ['page.accountSettings.widget.form.searchResults', 'Results'],
  CRYPTO_NOT_FOUND: ['page.accountSettings.widget.form.cryptoNotFound', 'We don’t support this cryptocurrency yet! '],
  PANEL_TITLE: ['page.accountSettings.landing.title', 'Landing Page'],
  SAVE_BUTTON_LABEL: ['page.accountSettings.widget.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.accountSettings.landing.cancel', 'Cancel'],
  FORM_FIELDS: {
    CUSTOM_LANDING: ['page.accountSettings.landing.form.landingPageEnabled.label', 'Custom Landing Page'],
    LANDING_PAGE_URL: ['page.accountSettings.landing.form.urlSlug.label', 'Your Landing Page URL'],
    PAGE_TITLE: ['page.accountSettings.landing.form.pageTitle.label', 'Page Title'],
    REDIRECT_ENABLED: ['page.accountSettings.landing.form.redirectEnabled.label', 'Redirect User'],
    REDIRECT_URL: ['page.accountSettings.landing.form.orderCompleteRedirectUrl.label', 'Redirect URL'],
    REDIRECT_DOMAIN: ['page.accountSettings.landing.form.redirectDomain.label', 'Redirect Domain'],
    PAGE_THEME_MODE: ['page.accountSettings.landing.form.pageThemeMode.label', 'Page Theme Mode'],
    PAGE_BACKGROUND_COLOR: ['page.accountSettings.landing.form.pageColor.label', 'Page Background Color'],
    LANDING_LOGO: ['page.accountSettings.landing.form.landingLogo.label', 'Logo in Light Mode Widget'],
    LANDING_LOGO_DARK: ['page.accountSettings.landing.form.landingLogoDark.label', 'Logo in Dark Mode Widget'],
    ERRORS: {
      MAX_FILE_SIZE: ['page.accountSettings.landing.form.landingLogo.maxFileSizeError', 'Maximum file size is 1MB'],
    },
    PLACEHOLDERS: {
      LOGO: ['page.accountSettings.landing.form.landingLogo.placeholder', 'Logo'],
      LANDING_PAGE_URL: ['page.accountSettings.landing.form.urlSlug.placeholder', 'slug'],
      PAGE_TITLE: ['page.accountSettings.landing.form.pageTitle.placeholder', 'Your Page Title'],
      PAGE_THEME_MODE: ['page.accountSettings.landing.form.pageThemeMode.placeholder', 'Theme Mode'],
    },
    MESSAGES: {
      LOGO: {
        SUPPORTED_FORMAT: ['page.accountSettings.landing.form.messages.landingLogo.supportedFormat', 'Supported Formats: JPG, PNG'],
        MAX_FILE_SIZE: ['page.accountSettings.landing.form.messages.landingLogo.maxFileSize', 'Maximum Size: 1MB'],
        SAVE_TO_UPDATE: [
          'page.accountSettings.landing.form.messages.landingLogo.saveToUpdate',
          'Image will be updated in preview only after save',
        ],
      },
    },
  },
  LANDING_MODES: {
    LIGHT: ['page.accountSettings.landing.form.landingThemeMode.label.light', 'Light'],
    DARK: ['page.accountSettings.landing.form.landingThemeMode.label.dark', 'Dark'],
    SAME_AS_BROWSER: ['page.accountSettings.landing.form.landingThemeMode.label.sameAsBrowser', 'Same as browser'],
  },
  ADORNMENTS: {
    HTTPS: 'https://',
    URL_SLUG_PREFIX: 'https://crypto.shift4.com/',
  },
};

export const logoAcceptRules = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const themeModeItems: ThemeModeItem[] = [
  {
    key: AccountEmbeddableThemeMode.Light,
    label: t(LABELS.LANDING_MODES.LIGHT),
  },
  {
    key: AccountEmbeddableThemeMode.Dark,
    label: t(LABELS.LANDING_MODES.DARK),
  },
  {
    key: AccountEmbeddableThemeMode.SameAsBrowser,
    label: t(LABELS.LANDING_MODES.SAME_AS_BROWSER),
  },
];

export const FORM_FIELDS: Record<string, keyof AccountLandingPageSettings> = {
  CUSTOM_LANDING: 'landingPageEnabled',
  LANDING_PAGE_URL: 'urlSlug',
  PAGE_TITLE: 'pageTitle',
  REDIRECT_ENABLED: 'enableRedirectUrl',
  REDIRECT_DOMAIN: 'redirectDomain',
  REDIRECT_URL: 'orderCompleteRedirectUrl',
  PAGE_THEME_MODE: 'pageThemeMode',
  PAGE_BACKGROUND_COLOR: 'pageColor',
  LANDING_LOGO: 'landingLogo',
  LANDING_LOGO_DARK: 'landingLogoDark',
};

export const formFields: SettingsFormField[] = [
  {
    key: FORM_FIELDS.CUSTOM_LANDING,
    label: t(LABELS.FORM_FIELDS.CUSTOM_LANDING),
    type: FormFieldType.Color,
  },
  {
    key: FORM_FIELDS.LANDING_PAGE_URL,
    label: t(LABELS.FORM_FIELDS.LANDING_PAGE_URL),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.PAGE_TITLE,
    label: t(LABELS.FORM_FIELDS.PAGE_TITLE),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.REDIRECT_ENABLED,
    label: t(LABELS.FORM_FIELDS.REDIRECT_ENABLED),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.REDIRECT_DOMAIN,
    label: t(LABELS.FORM_FIELDS.REDIRECT_DOMAIN),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.REDIRECT_URL,
    label: t(LABELS.FORM_FIELDS.REDIRECT_URL),
    type: FormFieldType.Text,
  },
  {
    key: FORM_FIELDS.PAGE_THEME_MODE,
    label: t(LABELS.FORM_FIELDS.PAGE_THEME_MODE),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.PAGE_BACKGROUND_COLOR,
    label: t(LABELS.FORM_FIELDS.PAGE_BACKGROUND_COLOR),
    type: FormFieldType.Custom,
  },
  {
    key: FORM_FIELDS.LANDING_LOGO,
    label: t(LABELS.FORM_FIELDS.LANDING_LOGO),
    type: FormFieldType.File,
  },
  {
    key: FORM_FIELDS.LANDING_LOGO_DARK,
    label: t(LABELS.FORM_FIELDS.LANDING_LOGO_DARK),
    type: FormFieldType.File,
  },
];
