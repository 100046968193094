/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled/macro';
import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  [
    'M6 3.42913C6 2.48235 6.76751 1.71484 7.71429 1.71484H18.8571C25.0112 1.71484 30 6.70367 30 12.8577V32.572C30',
    '33.5188 29.2325 34.2863 28.2857 34.2863H7.71429C6.76751 34.2863 6 33.5188 6 32.572V3.42913Z',
  ].join(' '),
  ['M18.8574 1.71484C25.0115 1.71484 30.0003 6.70367 30.0003 12.8577H22.286C20.3924 12.8577 18.8574 11.3227 18.8574 9.42913V1.71484Z'].join(
    ' ',
  ),
  [
    'M11.1426 18.0007C11.1426 17.5273 11.5263 17.1436 11.9997 17.1436H23.9997C24.4731 17.1436 24.8569 17.5273 24.8569',
    '18.0007C24.8569 18.4741 24.4731 18.8578 23.9997 18.8578H11.9997C11.5263 18.8578 11.1426 18.4741 11.1426 18.0007Z',
  ].join(' '),
  [
    'M11.1426 23.1433C11.1426 22.6699 11.5263 22.2861 11.9997 22.2861H22.2854C22.7588 22.2861 23.1426 22.6699 23.1426',
    '23.1433C23.1426 23.6167 22.7588 24.0004 22.2854 24.0004H11.9997C11.5263 24.0004 11.1426 23.6167 11.1426 23.1433Z',
  ].join(' '),
  [
    'M11.1426 28.2859C11.1426 27.8125 11.5263 27.4287 11.9997 27.4287H18.8569C19.3303 27.4287 19.714 27.8125 19.714',
    '28.2859C19.714 28.7592 19.3303 29.143 18.8569 29.143H11.9997C11.5263 29.143 11.1426 28.7592 11.1426 28.2859Z',
  ].join(' '),
];

const Container = styled(Path)((props) => ({
  fill: props.theme.colors.container.secondary,
}));

const Corner = styled(Path)((props) => ({
  fill: props.theme.colors.container.primary,
}));

const Strip = styled(Path)((props) => ({
  fill: props.theme.colors.disabled,
}));

export const IconFile = ({ width = 36, height = 36, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Container d={path[0]} />
    <Corner d={path[1]} />
    <Strip d={path[2]} />
    <Strip d={path[3]} />
    <Strip d={path[4]} />
  </Svg>
);

export default IconFile;
