/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantCheckoutSettings } from 'services/merchants/types';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input } from 'components/inputs';
import { Switch } from 'components/inputs/switch/switch';

import { FORM_FIELDS, KEYS, LABELS, formFields, logoAcceptRules } from '../keys';
import { MerchantSettingsFormField } from '../types';
import { FileDropzone } from './styles';

interface LandingSettingsFormProps {
  account: MerchantCheckoutSettings;
  onFieldValueChange: (key: string, value: any) => void;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
  titleProps: {
    titleVisible: boolean;
    toggleTitleVisible: () => void;
  };
}

export const LandingSettingsForm = ({
  account,
  onFieldValueChange,
  isUpdating,
  error,
  readOnlyMode,
  titleProps: { titleVisible, toggleTitleVisible },
}: LandingSettingsFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const handleLogoChange = (fieldName: keyof MerchantCheckoutSettings) => (files: File[]) => {
    const onChange = handleChange(fieldName);
    const [logo] = files;
    const isRemovingLogo = !logo && account[fieldName];
    if (isRemovingLogo) {
      onChange('');
      return;
    }

    onChange(logo);
  };

  const shouldBeHidden = (formField: MerchantSettingsFormField) =>
    [FORM_FIELDS.TITLE].includes(formField.key as keyof MerchantCheckoutSettings);

  const fieldRenderers: Record<string, FormFieldDescription['renderInput']> = {
    [KEYS.TITLE_ENABLED]: ({ readonly }) => <Switch value={titleVisible} onChange={toggleTitleVisible} disabled={readonly} />,
    [FORM_FIELDS.TITLE]: () => (
      <Input fullWidth value={account.title ?? undefined} onChange={handleChange(FORM_FIELDS.TITLE)} disabled={isUpdating} />
    ),
    [FORM_FIELDS.LIGHT_MODE_URL]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.LIGHT_MODE_URL)}
        filledInputLabel={t(LABELS.FORM_FIELDS.LIGHT_MODE_URL)}
        initialFile={account.lightModeLogoUrl}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        disabled={isUpdating || readonly}
      />
    ),
    [FORM_FIELDS.DARK_MODE_URL]: ({ readonly }) => (
      <FileDropzone
        onFileChange={handleLogoChange(FORM_FIELDS.DARK_MODE_URL)}
        filledInputLabel={t(LABELS.FORM_FIELDS.DARK_MODE_URL)}
        initialFile={account.darkModeLogoUrl}
        hints={[
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SUPPORTED_FORMAT),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.MAX_FILE_SIZE),
          t(LABELS.FORM_FIELDS.MESSAGES.LOGO.SAVE_TO_UPDATE),
        ]}
        accept={logoAcceptRules}
        darkMode
        disabled={isUpdating || readonly}
      />
    ),
  };

  const fields = formFields.map((formField) => {
    if (shouldBeHidden(formField)) {
      return {
        ...formField,
        hidden: !titleVisible,
        renderInput: fieldRenderers[formField.key],
      };
    }

    return {
      ...formField,
      renderInput: fieldRenderers[formField.key],
    };
  });

  return <FormLayout fields={fields} readonly={readOnlyMode} error={error} />;
};
